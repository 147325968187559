import { AbilityBuilder, Ability } from "@casl/ability";

export default function defineAbilityFor(rolePermissions) {
  const { can, cannot, build } = new AbilityBuilder(Ability);

  if (rolePermissions && rolePermissions.length > 0) {
    rolePermissions.forEach(({ permissions }) => {
      permissions.forEach((permission) => {
        const [action, subject] = permission.split("__");
        can(action, subject);
      });
    });
  }

  return build();
}
