import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Form, Button, Modal } from "react-bootstrap";
import { PriceInput } from "components";

import { useDispatch, useSelector } from "react-redux";

import GutterQuoteList from "./GutterQuoteList";

import styles from "lib/config/baseStyles";

import displayMsg from "components/displayMsg";
import SelectSuppliers, {
  SelectSuppliersSection,
  SupplierItemOnlyName,
} from "../../SelectSupliers";
import userDetails from "hooks/user/userDetails";
import {
  addMiscGutterQuote,
  updateCurrentProposal,
} from "features/QuotesProposals/redux/proposals/proposals.actions";
import {
  hasSupplierFieldChanged,
  supplierEmptyText,
} from "features/QuotesProposals/utils";
import QuoteUpdateLoader from "components/common/loaders/QuoteUpdateLoader";
import {
  useAddGutterlMisc,
  useChangeGutterSupplier,
  useGutterSuppliers,
  useUpdateGutterQuoteLine,
} from "features/QuotesProposals/hooks/useGutter";
import QuoteSkeleton from "../../SkeletonLoader/Quote";
import { toast } from "react-toastify";

export default function Gutters({}) {
  const dispatch = useDispatch();
  const proposalData = useSelector((state) => state.proposal);
  const {
    selectedProposal,
    gutterSuppliersError,
    isLoadingUpdateGutterSupplier,
  } = proposalData;

  const {
    suppliers,
    isLoading: isLoadingSuppliers,
    isError,
    error: fetchSupplierError,
  } = useGutterSuppliers();

  const {
    data,
    isLoading: isLoadingUpdateSupplier,
    isError: isUpdate,
    isSuccess,
    mutate: updateSupplierMutation,
  } = useChangeGutterSupplier();

  const {
    isLoading: isLoadingAddGutterMisc,
    isError: addGutterMiscError,
    mutate: addGutterMiscMutation,
  } = useAddGutterlMisc();

  const {
    isLoading: isLoadingUpdateQuoteLine,
    isError: updateQuoteLineError,
    mutate: updateQuotelineMutation,
  } = useUpdateGutterQuoteLine();

  const selectedSupplier =
    proposalData?.selectedProposal?.quotes?.gutters?.supplier;

  const onChangeSupplier = (e) => {
    const supplierId = e.target.value;
    const body = {
      requestId: selectedProposal?.requestId,
      supplierId: supplierId,
      proposalId: selectedProposal._id,
    };

    console.log({
      bodyupdate: body,
    });
    updateSupplierMutation(body, {
      onSuccess: (data) => {
        // Assuming `data.result` contains the updated proposals
        const updatedProposal = data?.result?.find(
          (proposal) => proposal._id === selectedProposal?._id // Adjust key based on response
        );
        if (updatedProposal) {
          // Dispatch an action to update the selectedProposal in Redux
          dispatch(updateCurrentProposal(updatedProposal));
        }

        console.log("Gutter supplier updated successfully.");
        toast.success("Gutter supplier updated successfully!");
      },

      onError: (error) => {
        console.error("Error updating gutter supplier:", error.message);
        handleGlobalError(error, dispatch);
      },
    });
  };

  const onAddMisc = () => {
    const body = {
      supplierId: selectedSupplier,
      proposalId: selectedProposal._id,
      gutterQuoteId: selectedProposal.quotes.gutters._id,
      uomPrice: "",
      uomQty: "",
      style: "",
    };
    addGutterMiscMutation(body, {
      onSuccess: (data) => {
        // Assuming `data.result` contains the updated proposals
        const updatedProposal = data?.result?.find(
          (proposal) => proposal._id === selectedProposal?._id // Adjust key based on response
        );
        console.log("updatedProposal123", updatedProposal);
        if (updatedProposal) {
          // Dispatch an action to update the selectedProposal in Redux
          dispatch(updateCurrentProposal(updatedProposal));
        }

        console.log("Misc item added successfully.");
        toast.success("Misc item added successfully!");
      },

      onError: (error) => {
        console.error("Error adding Misc item:", error.message);
        handleGlobalError(error, dispatch);
      },
    });
  };

  const retry = () => {
    saveSectionDataToDB(quoteGuttersSectionData);
  };

  const totalPrice = selectedProposal?.quotes?.gutters?.total;
  console.log({
    selectedSupplierGutter: selectedSupplier,
    suppliers,
  });

  if (
    isLoadingSuppliers ||
    isLoadingUpdateSupplier ||
    isLoadingAddGutterMisc ||
    isLoadingUpdateQuoteLine
  ) {
    return <QuoteSkeleton />;
  }

  const updateGutterQuoteLine = (body) => {
    updateQuotelineMutation(body, {
      onSuccess: (data) => {
        // Assuming `data.result` contains the updated proposals
        const updatedProposal = data?.result?.find(
          (proposal) => proposal._id === selectedProposal?._id // Adjust key based on response
        );
        console.log("updatedProposal123", updatedProposal);
        if (updatedProposal) {
          // Dispatch an action to update the selectedProposal in Redux
          dispatch(updateCurrentProposal(updatedProposal));
        }

        console.log("Gutter quote updated successfully.");
        toast.success("Gutter quote updated successfully!");
      },

      onError: (error) => {
        console.error("Error updating gutter quote:", error.message);
        handleGlobalError(error, dispatch);
      },
    });
  };
  return (
    <div style={{ margin: "1em" }}>
      <section>
        <Row>
          <Col style={styles.reduceWidthCol}>
            <h4>Gutters / Leaders</h4>
          </Col>

          <Col className="d-flex justify-content-end">
            {/* {isLoadingProposal ? null : error ? (
                  <SmRetryBtn retry={retry} />
                ) : null} */}
            <QuoteUpdateLoader
              loading={isLoadingUpdateGutterSupplier}
              retry={retry}
            />
          </Col>
        </Row>

        <SelectSuppliers
          selectedSupplier={selectedSupplier}
          onSelectSupplier={onChangeSupplier}
          loading={false}
          suppliers={suppliers}
          emptyText={supplierEmptyText(suppliers?.length)}
          SupplierItem={SupplierItemOnlyName}
          // isLockQuoteData={isLockQuoteData}
          isOriginalSupplier={hasSupplierFieldChanged(
            selectedProposal.originalQuoteSupplier,
            "gutterSupplierId",
            selectedSupplier
          )}
        />
        <SelectSuppliersSection>
          <Col>
            <Form.Label style={{ marginTop: "10%", marginLeft: "30%" }}>
              Type
            </Form.Label>
          </Col>
          <Col>
            <Form.Label style={{ marginTop: "10%", marginRight: "13%" }}>
              Style
            </Form.Label>
          </Col>
          <Col>
            <Form.Label style={{ marginTop: "10%" }}>Size</Form.Label>
          </Col>
          <Col>
            <Form.Label style={{ marginTop: "10%" }}>Color</Form.Label>
          </Col>
        </SelectSuppliersSection>

        <GutterQuoteList
          displayMsg={displayMsg}
          tabData={selectedProposal?.quotes?.gutters?.quotes}
          selectedProposal={selectedProposal}
          updateGutterQuoteLine={updateGutterQuoteLine}
        />
        <MiscItemComponent
          onAddMisc={onAddMisc}
          // deleteItem={deleteItem}
          // editItem={editItem}
          // isLockQuoteData={isLockQuoteData}
        />
        {/* 
        {quoteGuttersSectionData && (
          <TotalSection total={quoteGuttersSectionData.guttersSubTotal} />
        )} */}
        <TotalSection total={totalPrice} />
      </section>
    </div>
  );
}

function TotalSection({ total }) {
  return (
    <Row>
      <Col style={styles.reduceWidthCol} lg="6"></Col>
      <Col style={styles.reduceWidthCol} style={{ flex: "77%" }}>
        <Row>
          <Col style={styles.reduceWidthCol} style={{ textAlign: "right" }}>
            <Form.Label style={{ marginTop: "2%" }}>
              Gutter Labor Sub Total:
            </Form.Label>
          </Col>
        </Row>
      </Col>
      <Col style={styles.reduceWidthCol}>
        <PriceInput
          price={total}
          disabled={true}
          style={{ background: "#28a745", color: "#fff" }}
        />
      </Col>
    </Row>
  );
}

const MiscItemComponent = ({ onAddMisc, isLockQuoteData }) => (
  <Row>
    <Col style={styles.reduceWidthCol} lg="2">
      <Button onClick={onAddMisc} disabled={isLockQuoteData?.isLocked}>
        Add Misc Item
      </Button>
    </Col>
  </Row>
);
