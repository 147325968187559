import React, { useState, useEffect, useCallback } from "react";
import { Row, Col, Form, Button, Container } from "react-bootstrap";
import { PriceInput, PopupModal, LoadingLoader } from "components";

import { useDispatch, useSelector } from "react-redux";

import styles from "lib/config/baseStyles";
import displayMsg from "components/displayMsg";

import SelectSuppliers, {
  SelectOption,
  SupplierItem,
  SelectSuppliersSection,
} from "../../SelectSupliers";

import QuoteDataList from "./QuoteDataList";
import {
  addMiscMaterialQuote,
  updateCurrentProposal,
  updateProposalInfo,
} from "features/QuotesProposals/redux/proposals/proposals.actions";
import {
  hasSupplierFieldChanged,
  supplierEmptyText,
} from "features/QuotesProposals/utils";
import QuoteUpdateLoader from "components/common/loaders/QuoteUpdateLoader";
import Spinner from "components/common/spinner/spinner";

import QuoteSkeleton from "../../SkeletonLoader/Quote";
import { handleGlobalError } from "utils/utils";
import { toast } from "react-toastify";
import {
  useUpdateProposalTaxInfo,
  useUpdateMaterialQuoteline,
  useChangeMaterialSupplier,
  useMaterialSuppliers,
  useAddMaterialMisc,
} from "features/QuotesProposals/hooks/useMaterial";
import { debounce } from "lodash";
//new commit
export default function Material({
  loadingSuppliers,
  metricId,
  isLockQuoteData,
}) {
  const dispatch = useDispatch();

  const {
    selectedProposal,
    isLoadingAddMiscMaterial,
    isLoadingDeleteMaterialMisc,
  } = useSelector((state) => state.proposal) || {};

  const selectedSupplier = selectedProposal?.quotes?.materials?.supplier;
  const {
    suppliers,
    isLoading: isLoadingSuppliers,
    isError,
    error: fetchSupplierError,
  } = useMaterialSuppliers();

  const {
    isLoading: isLoadingUpdateSupplier,
    isError: isUpdate,
    isSuccess,
    mutate: updateSupplierMutation,
  } = useChangeMaterialSupplier();

  const {
    isLoading: isLoadingUpdateInfoProposal,
    isError: updateQuoteLineError,
    mutate: updateQuotelineMutation,
  } = useUpdateMaterialQuoteline();

  const {
    isLoading: isLoadingAddMaterialMisc,
    isError: addMaterialMiscError,
    mutate: addMaterialMiscMutation,
  } = useAddMaterialMisc();

  console.log("update", {
    isError: isUpdate,
    isSuccess,
  });
  if (
    isLoadingSuppliers ||
    isLoadingUpdateSupplier ||
    isLoadingUpdateInfoProposal ||
    isLoadingAddMaterialMisc
  ) {
    return <QuoteSkeleton />;
  }

  if (fetchSupplierError) {
    return <div>Error: {fetchSupplierError.message}</div>; // Show error message if there's an error
  }

  const MiscItemComponent = ({ onAddMisc, isLockQuoteData }) => (
    <Row>
      <Col style={styles.reduceWidthCol} lg="2">
        <Button onClick={onAddMisc} disabled={isLoadingAddMiscMaterial}>
          Add Misc Item
        </Button>
      </Col>
    </Row>
  );

  const retry = () => {
    // const dataArr = quoteMaterialSectionData.sectionData.slice();
    // const data = getSubmissionData(dataArr);
    // //data = { ...data, proposalId: data.proposalId._id };
    // debounceSaveData(data);
  };

  const onSelectSupplier = (e) => {
    const supplierId = e.target.value;
    // changeSupplier(e.target.value, setSelectedSupplier);
    console.log({
      supplierId,
      e,
    });
    const body = {
      requestId: selectedProposal?.requestId,
      supplierId: supplierId,
      proposalId: selectedProposal._id,
    };

    console.log({
      bodyupdate: body,
    });

    updateSupplierMutation(body, {
      onSuccess: (data) => {
        // Assuming `data.result` contains the updated proposals
        const updatedProposal = data?.result?.find(
          (proposal) => proposal._id === selectedProposal?._id // Adjust key based on response
        );
        console.log("updatedProposal123", updatedProposal);
        if (updatedProposal) {
          // Dispatch an action to update the selectedProposal in Redux
          dispatch(updateCurrentProposal(updatedProposal));
        }

        console.log("Material supplier updated successfully.");
        toast.success("Material supplier updated successfully!");
      },

      onError: (error) => {
        console.error("Error updating material supplier:", error.message);
        handleGlobalError(error, dispatch);
      },
    });

    // dispatch(changeMaterialSupplier(body));
  };

  const changeQuantiy = (quantity, materialId, price, miscSeries) => {
    // Dispatch action to get series list
    const body = {
      requestId: selectedProposal?.requestId,
      proposalId: selectedProposal._id,
      materialQuoteId: selectedProposal?.quotes?.materials?._id,
      materialId: materialId,
    };

    if (price) {
      body.uomPrice = price;
    }

    if (quantity) {
      body.uomQty = quantity;
    }

    if (miscSeries) {
      body.miscSeries = miscSeries;
    }

    updateQuotelineMutation(body, {
      onSuccess: (data) => {
        // Assuming `data.result` contains the updated proposals
        const updatedProposal = data?.result?.find(
          (proposal) => proposal._id === selectedProposal?._id // Adjust key based on response
        );
        console.log("updatedProposal123", updatedProposal);
        if (updatedProposal) {
          // Dispatch an action to update the selectedProposal in Redux
          dispatch(updateCurrentProposal(updatedProposal));
        }

        console.log("Material supplier updated successfully.");
        toast.success("Material supplier updated successfully!");
      },

      onError: (error) => {
        console.error("Error updating material supplier:", error.message);
        handleGlobalError(error, dispatch);
      },
    });
  };

  const onAddMisc = () => {
    try {
      const body = {
        supplierId: selectedSupplier?.supplier?._id,

        proposalId: selectedProposal._id,
        materialQuoteId: selectedProposal.quotes.materials._id,
        series: "",
        uomPrice: "",
        uomQty: "",
      };
      addMaterialMiscMutation(body, {
        onSuccess: (data) => {
          // Assuming `data.result` contains the updated proposals
          const updatedProposal = data?.result?.find(
            (proposal) => proposal._id === selectedProposal?._id // Adjust key based on response
          );
          console.log("updatedProposal123", updatedProposal);
          if (updatedProposal) {
            // Dispatch an action to update the selectedProposal in Redux
            dispatch(updateCurrentProposal(updatedProposal));
          }

          console.log("Misc item added successfully.");
          toast.success("Misc item added successfully!");
        },

        onError: (error) => {
          console.error("Error adding Misc item:", error.message);
          handleGlobalError(error, dispatch);
        },
      });
    } catch (error) {
      console.log({
        error,
      });
    }
  };

  return (
    <div style={{ margin: "1rem" }}>
      <Row>
        <Col style={styles.reduceWidthCol}>
          <Row lg="12">
            <Col>
              <h4 style={{ marginBottom: "-.5rem" }}>Material </h4>
            </Col>
            <Col className="d-flex justify-content-end">
              {/* {isLoadingProposal ? null : error ? (
                  <SmRetryBtn retry={retry} />
                ) : null} */}
              <QuoteUpdateLoader
                loading={
                  isLoadingUpdateInfoProposal ||
                  isLoadingAddMaterialMisc ||
                  isLoadingDeleteMaterialMisc
                }
                retry={retry}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row style={{ marginBottom: "2rem" }}>
        {selectedSupplier?._id ? (
          <SelectSuppliers
            selectedSupplier={selectedSupplier?._id}
            onSelectSupplier={onSelectSupplier}
            loading={loadingSuppliers}
            suppliers={suppliers}
            emptyText={supplierEmptyText(suppliers?.length)}
            SupplierItem={SupplierItem}
            isLockQuoteData={isLockQuoteData}
            isOriginalSupplier={hasSupplierFieldChanged(
              selectedProposal.originalQuoteSupplier,
              "materialSupplierId",
              selectedSupplier?._id
            )}
          />
        ) : null}{" "}
      </Row>
      <SelectSuppliersSection>
        <Col>
          <Form.Label style={{ marginTop: "15%" }}>Manufacturer</Form.Label>
        </Col>
        <Col>
          <Form.Label style={{ marginTop: "15%" }}>Material</Form.Label>
        </Col>
        <Col>
          <Form.Label style={{ marginLeft: "170%", marginTop: "15%" }}>
            Series
          </Form.Label>
        </Col>

        <Col></Col>
        <Col></Col>
      </SelectSuppliersSection>

      <QuoteDataList
        metricId={metricId}
        selectedProposal={selectedProposal}
        changeQuantiy={changeQuantiy}
        tabData={selectedProposal?.quotes?.materials?.quotes}
        selectedSupplier={selectedSupplier}
        displayMsg={displayMsg}
        isLockQuoteData={isLockQuoteData}
      />

      <MiscItemComponent onAddMisc={onAddMisc} />
      {selectedProposal ? (
        <MaterialQuoteBillingInfo
          selectedProposal={selectedProposal}
          dispatch={dispatch}
        />
      ) : null}
    </div>
  );
}

function MaterialQuoteBillingInfo({
  selectedProposal,
  isLockQuoteData,
  dispatch,
}) {
  const [showTaxExemptId, setShowTaxExempt] = useState(false);
  const [taxExemptId, setTaxExemptId] = useState("");
  const [stateTaxId, setStateTaxId] = useState("");
  const stateTaxes = useSelector((state) => state.staticAll.stateTaxes);
  const { quotes } = selectedProposal;

  const selectedTax = quotes?.materials?.tax?.stateTax;

  const selectedExemptId = quotes?.materials?.tax?.exemptId;

  const {
    data,
    isLoading: isLoadingUpdateProposalTax,
    isError: isUpdate,
    isSuccess,
    mutate: updateProposalTaxInfoMutatation,
  } = useUpdateProposalTaxInfo();

  useEffect(() => {
    if (selectedExemptId && !selectedTax) {
      setStateTaxId("Exempt");
      setTaxExemptId(selectedExemptId);
      setShowTaxExempt(true);
    } else {
      setTaxExemptId("");
      setShowTaxExempt(false);
    }
  }, [selectedExemptId]);

  useEffect(() => {
    if (selectedTax && !selectedExemptId) {
      setStateTaxId(selectedTax);
    } else {
      setStateTaxId("");
    }
  }, [selectedTax]);

  const onChangeTax = (taxId) => {
    if (taxId === "Exempt") {
      setStateTaxId("Exempt");
      setShowTaxExempt(true);
      return;
    }
    console.log({
      here: taxId,
    });
    // Dispatch action to get series list
    const body = {
      requestId: selectedProposal?.requestId,
      // proposalId,
      proposalId: selectedProposal._id,
      materialQuoteId: selectedProposal?.quotes?.materials?._id,

      stateTaxId: taxId,
      taxExempt: null,

      // materialQuoteId
    };

    updateProposalTaxInfoMutatation(body, {
      onSuccess: (data) => {
        // Assuming `data.result` contains the updated proposals
        const updatedProposal = data?.result?.find(
          (proposal) => proposal._id === selectedProposal?._id // Adjust key based on response
        );
        console.log("updatedProposal123", updatedProposal);
        if (updatedProposal) {
          // Dispatch an action to update the selectedProposal in Redux
          dispatch(updateCurrentProposal(updatedProposal));
        }

        console.log("Tax info updated successfully.");
        toast.success("Tax updated successfully!");
      },

      onError: (error) => {
        console.error("Error updating tax info:", error.message);
        handleGlobalError(error, dispatch);
      },
    });
  };

  const debouncedHandleTaxExempt = useCallback(
    debounce((value) => {
      const body = {
        requestId: selectedProposal?.requestId,
        // proposalId,
        proposalId: selectedProposal._id,
        materialQuoteId: selectedProposal?.quotes?.materials?._id,

        taxExempt: value,
        stateTaxId: null,
        // materialQuoteId
      };

      updateProposalTaxInfoMutatation(body, {
        onSuccess: (data) => {
          // Assuming `data.result` contains the updated proposals
          const updatedProposal = data?.result?.find(
            (proposal) => proposal._id === selectedProposal?._id // Adjust key based on response
          );
          console.log("updatedProposal123", updatedProposal);
          if (updatedProposal) {
            // Dispatch an action to update the selectedProposal in Redux
            dispatch(updateCurrentProposal(updatedProposal));
          }

          console.log("Tax info updated successfully.");
          toast.success("Tax updated successfully!");
        },

        onError: (error) => {
          console.error("Error updating tax info:", error.message);
          handleGlobalError(error, dispatch);
        },
      });
    }, 800), // Adjust the delay time as needed
    []
  );

  const onChangeTaxExempt = (event) => {
    const value = event.target.value;
    setTaxExemptId(value);

    if (value) debouncedHandleTaxExempt(value);
  };

  const renderRow = (
    label,
    value,
    isSelect = false,
    isGreenBackground = false
  ) => {
    return (
      <div className="d-flex justify-content-end align-items-center w-100 mb-2">
        <p className="mb-0 me-3">{label}</p>
        {isSelect ? (
          <Form.Group className="mb-0">
            {isLoadingUpdateProposalTax ? (
              <div
                as="select"
                style={{
                  width: "200px", // Fixed width for the select dropdown
                }}
              >
                <Spinner variant="secondary" />
              </div>
            ) : (
              <div className="d-flex gap-2">
                {showTaxExemptId && (
                  <Form.Group>
                    <Form.Control
                      type="text"
                      onChange={onChangeTaxExempt}
                      value={taxExemptId}
                      placeholder="Tax Exempt ID#"
                      style={{ textAlign: "center" }}
                    />
                  </Form.Group>
                )}
                <Form.Control
                  as="select"
                  style={{
                    width: "200px", // Fixed width for the select dropdown
                    ...(!stateTaxId
                      ? { borderColor: "red", color: "red" }
                      : {}),
                  }}
                  value={!showTaxExemptId ? stateTaxId : "Exempt"}
                  onChange={(event) => onChangeTax(event.target.value)}
                  disabled={isLockQuoteData?.isLocked}
                >
                  <option value="">Select</option>
                  {stateTaxes?.length &&
                    stateTaxes.map((taxData) => (
                      <option key={taxData._id} value={taxData._id}>
                        {`${taxData.per}% (${taxData.country})`}
                      </option>
                    ))}
                  <option value="Exempt">Exempt</option>
                </Form.Control>
              </div>
            )}
          </Form.Group>
        ) : (
          <PriceInput
            input="Total"
            price={value}
            disabled
            style={{
              width: "200px",
              ...(isGreenBackground
                ? { background: "#28a745", color: "#fff" }
                : {}),
            }}
          />
        )}
      </div>
    );
  };
  return (
    <Container fluid className="mt-4">
      <Row className="justify-content-end d-flex align-items-center">
        {/* Reusable row component for label and input */}
        {renderRow("Material Sub Total:", quotes?.materials?.total)}
        {renderRow(
          "State Tax:",
          stateTaxId || taxExemptId ? quotes?.materials?.subtotal : 0,
          true
        )}
        {renderRow(
          "Total:",
          stateTaxId || taxExemptId ? quotes?.materials?.subtotal : 0,
          false,
          true
        )}
      </Row>
    </Container>
  );
}
