export const getQuoteMainTabs = (type) => [
    {
        name: "Estimator",
        id: "001",
    },
    {
        name: `${type ? `Proposal/Contract` : `Proposal`}`,
        id: "002",
    },
    {
        name: "Job Notes",
        id: "003",
    },
];
