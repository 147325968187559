import React, { useState, useEffect, useCallback } from "react";
import { Row, Col, Form, Button, Alert } from "react-bootstrap";
import SelectState from "components/select-state/select-state";
import { useSelector, useDispatch } from "react-redux";
import {
  getDropDowns,
  getDDData,
  addDDData,
  updateDDData,
  deleteDDData,
  updateQuote,
} from "lib/redux/drop-down/drop-down.action";
import { toast } from "react-toastify";
import InitialLoader from "components/InitilalLoader";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Link, useHistory } from "react-router-dom";
import useContactData from "hooks/useContactManagement";
import useWeatherZipCode from "hooks/useWeatherZipCode";
import useSchedulingModal from "hooks/SchedulingModal/useSchedulingModal";
import { Can, useAbility } from "components/can";
import { permissions } from "lib/config/permissionSettings";

const StaticTexts = {
  sorryMsg: "Sorry, You request cannot be processed!",
  newDataId: "NEW_DATA",
};

const { sorryMsg, newDataId } = StaticTexts;

const ManageDropDown = ({
  label,
  sources,
  addSourceData,
  editSourceData,
  deleteSourceData,
  dropDown,
  addSources,
  dataId,
  apiId,
}) => {
  const [addSource, setAddSource] = useState(false);
  const [editSource, setEditSource] = useState("");
  const [tempValue, setTempValue] = useState("");
  const [processing, setProcessing] = useState("");
  const [source, setSource] = useState("");
  const { dataList, gettingDDData, listErr } = useSelector(
    (state) => state.ddList
  );

  const dispatch = useDispatch();

  const loadData = () => {
    dispatch(getDDData(apiId));
    setAddSource("");
    setEditSource("");
    setTempValue("");
    setSource("");
  };

  useEffect(() => {
    loadData();
  }, [apiId]);

  useEffect(() => {
    if (dataList) {
      addSources(dataList);
    }
  }, [dataList]);

  const edit = (index, value) => {
    setEditSource(index);
    setTempValue(value);
  };

  const add = () => {
    setProcessing(newDataId);
    dispatch(
      addDDData(apiId, {
        [dataId]: source,
      })
    )
      .then((res) => res.data)
      .then((res) => {
        if (res.success) {
          addSourceData({ [dataId]: source, _id: res.result, editable: true });
          setSource("");
          setTempValue("");
          setAddSource(false);
          setEditSource("");
          toast.success("Added Successfully!");
        } else {
          toast.error(sorryMsg);
        }
      })
      .catch((err) => {
        toast.error(err.meesage);
      })
      .finally(() => {
        setProcessing("");
      });
  };

  const cancel = () => {
    setEditSource("");
    setTempValue("");
  };
  const handleEdit = () => {
    setProcessing(editSource);
    dispatch(
      updateDDData(apiId, editSource, {
        [dataId]: tempValue,
      })
    )
      .then((res) => res.data)
      .then((res) => {
        if (res.success) {
          editSourceData(editSource, {
            [dataId]: tempValue,
            _id: editSource,
            editable: true,
          });
          setEditSource("");
          setTempValue("");
          toast.success("Updated Successfully!");
        } else {
          toast.error(sorryMsg);
        }
      })
      .catch((err) => {
        toast.error(err.meesage);
      })
      .finally(() => {
        setProcessing("");
      });
  };
  const deleteLead = (id) => {
    setProcessing(id);
    dispatch(deleteDDData(apiId, id))
      .then((res) => res.data)
      .then((res) => {
        if (res.success) {
          deleteSourceData(id);
          toast.success("Deleted Successfully!");
        } else {
          toast.error(sorryMsg);
        }
      })
      .catch((err) => {
        toast.error(err.meesage);
      })
      .finally(() => {
        setProcessing("");
      });
  };

  const checkExists = useCallback((data, list) => {
    if (!data || list.length === 0) return false;
    return list.some(
      (info) => info[dataId].toLowerCase().trim() === data.toLowerCase().trim()
    );
  });

  const onKeyUp = (e, id) => {
    if (e.key === "Enter") {
      const { value } = e.target;
      handleEdit(id);
    }
  };

  const showLeadSource = (sources) => (
    <TransitionGroup className="list">
      {sources.map((sourceData) => (
        <CSSTransition
          key={sourceData._id}
          timeout={500}
          classNames="list_item"
        >
          <div key={sourceData._id} className="source_list">
            {editSource === sourceData._id ? (
              <Button onClick={() => cancel()} variant="danger">
                Cancel
              </Button>
            ) : (
              sourceData.editable && (
                <Button onClick={() => edit(sourceData._id)}>Edit</Button>
              )
            )}
            <div>
              {editSource === sourceData._id ? (
                <Form.Group className="mx-2">
                  <Form.Control
                    name="tempValue"
                    type="text"
                    value={tempValue}
                    onKeyUp={(e) => onKeyUp(e, sourceData._id)}
                    onChange={(e) => setTempValue(e.target.value)}
                  />
                </Form.Group>
              ) : (
                <Form.Group className="mx-2">
                  <Form.Control
                    disabled
                    value={sourceData[dataId]}
                    type="text"
                  />
                </Form.Group>
              )}
            </div>

            {editSource === sourceData._id ? (
              <Button
                disabled={
                  !tempValue ||
                  sources.includes(tempValue) ||
                  processing === sourceData._id
                }
                onClick={() => handleEdit(sourceData._id)}
                variant="success"
              >
                Save
              </Button>
            ) : (
              sourceData.editable && (
                <Button
                  disabled={processing === sourceData._id}
                  onClick={() => deleteLead(sourceData._id)}
                  variant="danger"
                >
                  Delete
                </Button>
              )
            )}
          </div>
        </CSSTransition>
      ))}
    </TransitionGroup>
  );

  if (gettingDDData || listErr) {
    return <InitialLoader error={listErr} onReload={loadData} />;
  }

  return (
    <div>
      <Button
        style={{ marginBottom: "1em" }}
        onClick={() => setAddSource(!addSource)}
      >
        Add
      </Button>
      <div className="_lead_src">
        {label && (
          <i className="heading">
            {sources.length > 0 ? label : "No data available."}
          </i>
        )}

        <CSSTransition
          in={addSource}
          timeout={500}
          classNames="list_item"
          unmountOnExit
        >
          <div className="source_list">
            <Button
              variant="danger"
              onClick={() => {
                setAddSource(false);
                setSource("");
              }}
            >
              Cancel
            </Button>
            <div>
              <Form.Group>
                <Form.Control
                  autoFocus
                  type="text"
                  value={source}
                  onChange={(e) => setSource(e.target.value)}
                />
              </Form.Group>
            </div>
            <Button
              variant="success"
              disabled={
                checkExists(source, sources) ||
                !source.trim() ||
                processing === newDataId
              }
              onClick={add}
            >
              Save
            </Button>
          </div>
        </CSSTransition>

        {Array.isArray(sources) && showLeadSource(sources)}
      </div>
    </div>
  );
};

function MangeQuote({ quote, changeQuote, apiId, setQuote }) {
  const [Standard, setStandard] = useState("");
  const [Minimum, setMinimum] = useState("");
  const [Max, setMax] = useState("");
  const [processing, setProcessing] = useState("");

  const { dataList, gettingDDData, listErr } = useSelector(
    (state) => state.ddList
  );
  const dispatch = useDispatch();

  const loadData = () => {
    dispatch(getDDData(apiId));
  };
  useEffect(() => {
    loadData();
  }, [apiId]);

  useEffect(() => {
    if (dataList) {
      setQuote(dataList);
    }
  }, [dataList]);

  const [editQuote, setEditQuote] = useState(false);
  const edit = () => {
    setEditQuote(true);
    setStandard(quote.standard);
    setMinimum(quote.min);
    setMax(quote.max);
  };

  const save = () => {
    setProcessing(newDataId);
    dispatch(
      updateQuote({
        standard: Standard,
        min: Minimum,
        max: Max,
      })
    )
      .then((res) => res.data)
      .then((res) => {
        if (res.success) {
          changeQuote({
            standard: Standard,
            min: Minimum,
            max: Max,
            editable: true,
          });
          setEditQuote(false);
          setStandard("");
          setMinimum("");
          setMax("");
          toast.success("Updated Successfully!");
        } else {
          toast.error(sorryMsg);
        }
      })
      .catch((err) => {
        toast.error(err.meesage);
      })
      .finally(() => {
        setProcessing("");
      });
  };

  const keys = { Standard: "standard", Minimum: "min", Max: "max" };
  if (gettingDDData || listErr) {
    return <InitialLoader error={listErr} onReload={loadData} />;
  }
  return (
    <CSSTransition in timeout={500} classNames="list_item" unmountOnExit>
      <div className="_quote">
        {editQuote ? (
          <Button variant="danger" onClick={() => setEditQuote(false)}>
            Cancel
          </Button>
        ) : quote.editable ? (
          <Button onClick={edit}>Edit</Button>
        ) : null}
        <Row>
          {Object.keys(keys).map((key, i) => {
            const id = keys[key];
            return (
              <Col key={key}>
                <Form.Group>
                  <Form.Label>{key}</Form.Label>
                  {editQuote ? (
                    <Form.Control
                      autoFocus
                      value={
                        i === 0
                          ? Standard
                          : i === 1
                          ? Minimum
                          : i === 2
                          ? Max
                          : ""
                      }
                      onChange={(e) =>
                        i === 0
                          ? setStandard(e.target.value)
                          : i === 1
                          ? setMinimum(e.target.value)
                          : i === 2
                          ? setMax(e.target.value)
                          : {}
                      }
                    />
                  ) : (
                    <Form.Control disabled value={`${quote[id]}%`} />
                  )}
                </Form.Group>
              </Col>
            );
          })}
        </Row>
        {editQuote && (
          <Button
            variant="success"
            disabled={
              !Standard ||
              !Minimum ||
              !Max ||
              isNaN(Standard) ||
              isNaN(Minimum) ||
              isNaN(Max) ||
              processing === newDataId
            }
            onClick={() => save()}
          >
            Save
          </Button>
        )}
      </div>
    </CSSTransition>
  );
}

function MangeTaxes({
  taxes,
  addSourceData,
  editSourceData,
  deleteSourceData,
  addTaxes,
  apiId,
}) {
  const [addTax, setAddTax] = useState(false);
  const [editTax, setEditTax] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [per, setPer] = useState("");
  const [tempState, setTempState] = useState("");
  const [tempCountry, setTempCountry] = useState("");
  const [tempPer, setTempPer] = useState("");
  const [processing, setProcessing] = useState("");

  const { dataList, gettingDDData, listErr } = useSelector(
    (state) => state.ddList
  );

  const dispatch = useDispatch();

  const loadData = () => {
    dispatch(getDDData(apiId));
  };

  useEffect(() => {
    loadData();
  }, [apiId]);

  useEffect(() => {
    if (dataList) {
      addTaxes(dataList);
    }
  }, [dataList]);

  const edit = (index, data) => {
    setEditTax(index);
    setTempState(data.state);
    setTempCountry(data.country);
    setTempPer(data.per);
  };

  const add = () => {
    setProcessing(newDataId);
    dispatch(
      addDDData(apiId, {
        state,
        country,
        per,
      })
    )
      .then((res) => res.data)
      .then((res) => {
        if (res.success) {
          addSourceData({
            _id: res.result,
            state,
            country,
            per,
            editable: true,
          });
          clearAll();
          setAddTax(false);
          toast.success("Added Successfully!");
        } else {
          toast.error(sorryMsg);
        }
      })
      .catch((err) => {
        toast.error(err.meesage);
      })
      .finally(() => {
        setProcessing("");
      });
  };

  const clearAll = () => {
    setState("");
    setCountry("");
    setPer("");
  };

  const cancel = () => {
    setEditTax("");
    clearAll();
  };
  const handleEdit = () => {
    setProcessing(editTax);
    dispatch(
      updateDDData(apiId, editTax, {
        state: tempState,
        country: tempCountry,
        per: tempPer,
      })
    )
      .then((res) => res.data)
      .then((res) => {
        if (res.success) {
          editSourceData(editTax, {
            _id: editTax,
            editable: true,
            state: tempState,
            country: tempCountry,
            per: tempPer,
          });
          clearAll();
          setEditTax("");
          toast.success("Updated Successfully!");
        } else {
          toast.error(sorryMsg);
        }
      })
      .catch((err) => {
        toast.error(err.meesage);
      })
      .finally(() => {
        setProcessing("");
      });
  };

  const deleteLead = (id) => {
    setProcessing(id);
    dispatch(deleteDDData(apiId, id))
      .then((res) => res.data)
      .then((res) => {
        if (res.success) {
          deleteSourceData(id);
          toast.success("Deleted Successfully!");
        } else {
          toast.error(sorryMsg);
        }
      })
      .catch((err) => {
        toast.error(err.meesage);
      })
      .finally(() => {
        setProcessing("");
      });
  };

  const showTaxes = (taxes) => (
    <TransitionGroup className="list">
      {taxes.map((data) => (
        <CSSTransition key={data._id} timeout={500} classNames="list_item">
          <div className="_quote taxes" key={data._id}>
            {editTax === data._id ? (
              <Button onClick={() => cancel()} variant="danger">
                Cancel
              </Button>
            ) : data.editable ? (
              <Button onClick={() => edit(data._id, data)}>Edit</Button>
            ) : null}
            {editTax === data._id ? (
              <Row>
                <Col>
                  <Form.Group>
                    <SelectState
                      label="State"
                      onChange={(e) => setTempState(e.target.value)}
                      value={tempState}
                      errAt={false}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>County</Form.Label>
                    <Form.Control
                      type="text"
                      onChange={(e) => setTempCountry(e.target.value)}
                      value={tempCountry}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>Percentage</Form.Label>
                    <Form.Control
                      type="number"
                      onChange={(e) => setTempPer(e.target.value)}
                      value={tempPer}
                    />
                  </Form.Group>
                </Col>
              </Row>
            ) : (
              <Row>
                <Col>
                  <Form.Group>
                    <SelectState
                      disabled
                      label="State"
                      value={data.state}
                      errAt={false}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>County</Form.Label>
                    <Form.Control type="text" disabled value={data.country} />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>Percentage</Form.Label>
                    <Form.Control type="text" disabled value={`${data.per}%`} />
                  </Form.Group>
                </Col>
              </Row>
            )}
            {editTax === data._id ? (
              <Button
                disabled={
                  !tempState ||
                  !tempCountry.trim() ||
                  !tempPer ||
                  isNaN(tempPer) ||
                  processing === data._id
                }
                onClick={() => handleEdit(data._id)}
                variant="success"
              >
                Save
              </Button>
            ) : (
              <Button
                disabled={processing === data._id}
                onClick={() => deleteLead(data._id)}
                variant="danger"
              >
                Delete
              </Button>
            )}
          </div>
        </CSSTransition>
      ))}
    </TransitionGroup>
  );

  if (gettingDDData || listErr) {
    return <InitialLoader error={listErr} onReload={loadData} />;
  }

  return (
    <div>
      <Button
        style={{ marginBottom: "1em" }}
        onClick={() => setAddTax(!addTax)}
      >
        Add
      </Button>
      <CSSTransition
        in={addTax}
        timeout={500}
        classNames="list_item"
        unmountOnExit
      >
        <div className="_quote taxes">
          <Button onClick={() => setAddTax(false)} variant="danger">
            Cancel
          </Button>
          <Row>
            <Col>
              <Form.Group>
                <SelectState
                  autoFocus
                  label="State"
                  value={state}
                  onChange={(value) => setState(value.target.value)}
                  errAt={false}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>County</Form.Label>
                <Form.Control
                  onChange={(e) => setCountry(e.target.value)}
                  type="text"
                  value={country}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Percentage</Form.Label>
                <Form.Control
                  type="number"
                  onChange={(e) => setPer(e.target.value)}
                  value={per}
                />
              </Form.Group>
            </Col>
          </Row>
          <Button
            variant="success"
            disabled={
              !state ||
              !country.trim() ||
              !per ||
              isNaN(per) ||
              processing === newDataId
            }
            onClick={add}
          >
            Save
          </Button>
        </div>
      </CSSTransition>
      {Array.isArray(taxes) && showTaxes(taxes)}
    </div>
  );
}
/**
 *
 * @returns
 *
 */
const SchedulingModal = () => {
  const { user } = useSelector((state) => state.login);
  const { dataList, gettingDDData, listErr } = useSelector(
    (state) => state.ddList
  );

  const {
    increaseProjectManager,
    increaseSales,
    increaseRepair,
    setAdditionalRepairtime,
    additionalRepairTime,
    setAdditionalSalesTime,
    additionalSalesTime,
    setAdditionalProjectManagementTime,
    additionalProjectmanagementTime,

    showRepairSave,
    setShowRepairSave,
    showProjectSave,
    setShowProjectSave,
    showSalesSave,
    setShowSalesSave,
    handleOnClickSalesSave,
    handleOnClickRepairSave,
    handleOnClickProjectSave,
    isLoading,
    increaseSalesGap,
    increaseRepairGap,
    handleOnClickRepairGapSave,
    handleOnClickSalesGapSave,
    setShowSalesGapSave,
    setShowRepairGapSave,
    showSalesGapSave,
    showRepairGapSave,
    setAdditionalRepairGapTime,
    setAdditionalSalesGapTime,
    additionalRepairGapTime,
    additionalSalesGapTime,
  } = useSchedulingModal(user);

  const dispatch = useDispatch();

  const loadData = () => {
    dispatch(getDDData(apiId));
  };
  if (isLoading || gettingDDData || listErr) {
    return <InitialLoader error={listErr} onReload={loadData} />;
  }
  return (
    <div style={{ marginTop: "3%" }}>
      <Row>
        <Col md="4">
          <Col md="12">
            <h6>Sales Appointments Window Time</h6>
          </Col>
          <Row>
            <Col md="5">
              <Form.Group
              // style={styles.formInput}
              >
                <Form.Control
                  name="pType"
                  type="number"
                  value={additionalSalesTime}
                  disabled
                  onChange={(e) => setAdditionalSalesTime(+e.target.value)}
                ></Form.Control>
              </Form.Group>
            </Col>
            <Col md="2">
              <button
                type="button"
                className="btn btn-outline-dark"
                style={{ margin: "0px 10px" }}
                onClick={() => increaseSales(false)}
                disabled={!showSalesSave}
              >
                -
              </button>
            </Col>
            <Col md="2">
              <button
                type="button"
                className="btn btn-outline-dark"
                onClick={() => increaseSales()}
                disabled={!showSalesSave}
              >
                +
              </button>
            </Col>
            <Col md="3">
              {showSalesSave ? (
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={handleOnClickSalesSave}
                >
                  Save
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => setShowSalesSave(true)}
                >
                  Edit
                </button>
              )}
            </Col>
          </Row>
        </Col>
        <Col md="4">
          <Col md="12">
            <h6>Repair Appointments Window Time</h6>
          </Col>
          <Row>
            <Col md="5">
              <Form.Group
              // style={styles.formInput}
              >
                <Form.Control
                  name="pType"
                  type="number"
                  value={additionalRepairTime}
                  disabled
                  onChange={(e) => setAdditionalRepairtime(+e.target.value)}
                ></Form.Control>
              </Form.Group>
            </Col>
            <Col md="2">
              <button
                type="button"
                className="btn btn-outline-dark"
                style={{ margin: "0px 10px" }}
                onClick={() => increaseRepair(false)}
                disabled={!showRepairSave}
              >
                -
              </button>
            </Col>
            <Col md="2">
              <button
                type="button"
                className="btn btn-outline-dark"
                onClick={() => increaseRepair()}
                disabled={!showRepairSave}
              >
                +
              </button>
            </Col>
            <Col md="3">
              {showRepairSave ? (
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={handleOnClickRepairSave}
                >
                  Save
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => setShowRepairSave(true)}
                >
                  Edit
                </button>
              )}
            </Col>
          </Row>
        </Col>
        <Col md="4">
          <Col md="12">
            <h6>Time Between Project Management Start Times</h6>
          </Col>
          <Row>
            <Col md="5">
              <Form.Group
              // style={styles.formInput}
              >
                <Form.Control
                  name="pType"
                  type="number"
                  value={additionalProjectmanagementTime}
                  disabled
                  onChange={(e) =>
                    setAdditionalProjectManagementTime(+e.target.value)
                  }
                ></Form.Control>
              </Form.Group>
            </Col>
            <Col md="2">
              <button
                type="button"
                className="btn btn-outline-dark"
                style={{ margin: "0px 10px" }}
                onClick={() => increaseProjectManager(false)}
                disabled={!showProjectSave}
              >
                -
              </button>
            </Col>
            <Col md="2">
              <button
                type="button"
                className="btn btn-outline-dark"
                onClick={() => increaseProjectManager()}
                disabled={!showProjectSave}
              >
                +
              </button>
            </Col>
            <Col md="3">
              {showProjectSave ? (
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={handleOnClickProjectSave}
                >
                  Save
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => setShowProjectSave(true)}
                >
                  Edit
                </button>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      <Row style={{ marginTop: "5%" }}>
        <Col md="4">
          <Col md="12">
            <h6>Sales Minimum Gap Time Between Appointments</h6>
          </Col>
          <Row>
            <Col md="5">
              <Form.Group
              // style={styles.formInput}
              >
                <Form.Control
                  name="pType"
                  type="number"
                  value={additionalSalesGapTime}
                  disabled
                  onChange={(e) => setAdditionalSalesGapTime(+e.target.value)}
                ></Form.Control>
              </Form.Group>
            </Col>
            <Col md="2">
              <button
                type="button"
                className="btn btn-outline-dark"
                style={{ margin: "0px 10px" }}
                onClick={() => increaseSalesGap(false)}
                disabled={!showSalesGapSave}
              >
                -
              </button>
            </Col>
            <Col md="2">
              <button
                type="button"
                className="btn btn-outline-dark"
                onClick={() => increaseSalesGap()}
                disabled={!showSalesGapSave}
              >
                +
              </button>
            </Col>
            <Col md="3">
              {showSalesGapSave ? (
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={handleOnClickSalesGapSave}
                >
                  Save
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => setShowSalesGapSave(true)}
                >
                  Edit
                </button>
              )}
            </Col>
          </Row>
        </Col>
        <Col md="4">
          <Col md="12">
            <h6>Repair Minimum Gap Time Between Appointments</h6>
          </Col>
          <Row>
            <Col md="5">
              <Form.Group
              // style={styles.formInput}
              >
                <Form.Control
                  name="pType"
                  type="number"
                  value={additionalRepairGapTime}
                  disabled
                  onChange={(e) => setAdditionalRepairGapTime(+e.target.value)}
                ></Form.Control>
              </Form.Group>
            </Col>
            <Col md="2">
              <button
                type="button"
                className="btn btn-outline-dark"
                style={{ margin: "0px 10px" }}
                onClick={() => increaseRepairGap(false)}
                disabled={!showRepairGapSave}
              >
                -
              </button>
            </Col>
            <Col md="2">
              <button
                type="button"
                className="btn btn-outline-dark"
                onClick={() => increaseRepairGap()}
                disabled={!showRepairGapSave}
              >
                +
              </button>
            </Col>
            <Col md="3">
              {showRepairGapSave ? (
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={handleOnClickRepairGapSave}
                >
                  Save
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => setShowRepairGapSave(true)}
                >
                  Edit
                </button>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

/**
 *
 *
 */

const ManageZipCode = () => {
  const [editZipCode, setEditZipCode] = useState(true);
  const [addZipCode, setAddZipCode] = useState(true);
  const [zipValue, setZipValue] = useState();
  const { zipCode, isError, loading, update_weather_zip_code } =
    useWeatherZipCode();

  if (isError || loading) {
    return <InitialLoader error={isError} />;
  }
  return (
    <div style={{ display: "flex" }}>
      {!editZipCode ? (
        <Button
          onClick={() => {
            setEditZipCode(!editZipCode);
          }}
          variant="danger"
        >
          Cancel
        </Button>
      ) : (
        true && (
          <Button
            onClick={() => {
              setEditZipCode(!editZipCode);
            }}
          >
            Edit
          </Button>
        )
      )}
      <div>
        {
          <Form.Group className="mx-2">
            <Form.Control
              name="tempValue"
              type="text"
              value={
                zipValue || zipValue == ""
                  ? zipValue
                  : zipCode?.zip
                  ? zipCode?.zip
                  : ""
              }
              onChange={(e) => {
                setZipValue(e.target.value);
              }}
              disabled={editZipCode}
            />
          </Form.Group>
        }
      </div>

      <Button
        disabled={editZipCode}
        onClick={() => {
          update_weather_zip_code({ zipCode: Number(zipValue) });
          setEditZipCode(!editZipCode);
        }}
        variant="success"
      >
        Save
      </Button>
    </div>
  );
};

export default function DropdownMenus() {
  const [dropDown, setDropDown] = useState("");

  const [leadSources, setLeadSources] = useState([]);
  const [personnel, setPersonnel] = useState([]);
  const [subContractor, setSubContractor] = useState([]);
  // const [permission, setPermission] = useState({});

  const [dayLost, setDayLost] = useState([]);
  const [taxes, setTaxes] = useState([]);
  const [ddMenu, setDdMenu] = useState([]);
  const [err, setErr] = useState({
    errType: "danger",
    errMsg: "",
  });
  const { ddData, ddErr, gettingDropDown } = useSelector(
    (state) => state.ddMenu
  );
  const ability = useAbility();
  const calendarDayLostForDropDown = ability.can(
    "update",
    permissions.update.admin_drop_downs_production_calendar_day_lost
  );

  const adminDropDown = ability.can(
    "update",
    permissions.update.admin_drop_downs
  );

  const { companyData } = useContactData();
  const { user } = useSelector((state) => state.login);

  const dispatch = useDispatch();
  const loadData = () => dispatch(getDropDowns());

  useEffect(() => {
    loadData();
  }, []);

  const displayDropdowns = (types) => {
    if (adminDropDown) {
      const filterData = types.filter((elem) => {
        return elem.dropName === "Production Calendar Day Lost";
      });
      return filterData.map((type) => (
        <option key={type.dropId} value={type.dropId}>
          {type.dropName}
        </option>
      ));
    }

    if (calendarDayLostForDropDown) {
      return types.map((type) => (
        <option key={type.dropId} value={type.dropId}>
          {type.dropName}
        </option>
      ));
    }

    return [];
  };
  useEffect(() => {
    if (ddErr) {
      setErr({
        ...err,
        errMsg: ddErr,
      });
    }
  }, [ddErr]);

  useEffect(() => {
    const clear = () =>
      setTimeout(() => {
        clearMsg();
      }, 3500);
    clear();
    return () => clearTimeout(clear);
  }, [err]);

  const clearMsg = () => {
    setErr({
      errType: "danger",
      errMsg: "",
    });
  };

  const [quote, setQuote] = useState({
    standard: 0,
    min: 0,
    max: 0,
    editable: true,
  });

  const changeQuote = (data) => {
    setQuote({ ...data });
  };

  const addTax = (data) => {
    setTaxes([...taxes, data]);
  };

  const editTax = (idx, data) => {
    taxes.splice(getIdx(taxes, idx), 1, data);
    setTaxes([...taxes]);
  };

  const deleteTax = (idx) => {
    taxes.splice(getIdx(taxes, idx), 1);
    setTaxes([...taxes]);
  };

  const addDayLost = (data) => {
    setDayLost([...dayLost, data]);
  };

  const editDayLost = (idx, data) => {
    dayLost.splice(getIdx(dayLost, idx), 1, data);
    setDayLost([...dayLost]);
  };

  const deleteDayLost = (idx) => {
    dayLost.splice(getIdx(dayLost, idx), 1);
    setDayLost([...dayLost]);
  };

  const addSubContractor = (data) => {
    setSubContractor([...subContractor, data]);
  };

  const editSubContractor = (idx, data) => {
    subContractor.splice(getIdx(subContractor, idx), 1, data);
    setSubContractor([...subContractor]);
  };

  const deleteSubContractor = (idx) => {
    subContractor.splice(getIdx(subContractor, idx), 1);
    setSubContractor([...subContractor]);
  };

  const addPersonnel = (data) => {
    setPersonnel([...personnel, data]);
  };

  const editPersonnel = (idx, data) => {
    personnel.splice(getIdx(personnel, idx), 1, data);
    setPersonnel([...personnel]);
  };

  const deletePersonnel = (idx) => {
    personnel.splice(getIdx(personnel, idx), 1);
    setPersonnel([...personnel]);
  };

  const addLeadSource = (data) => {
    setLeadSources([...leadSources, data]);
  };

  const editLeadSource = (idx, data) => {
    leadSources.splice(getIdx(leadSources, idx), 1, data);
    setLeadSources([...leadSources]);
  };

  const deleteLeadSource = (idx) => {
    leadSources.splice(getIdx(leadSources, idx), 1);
    setLeadSources([...leadSources]);
  };

  const getIdx = (list, id) => list.findIndex((data) => data._id === id);

  const switchCustomDropDown = (list) => {
    switch (dropDown) {
      case list[0].dropId:
        return (
          <ManageDropDown
            dataId="leadSource"
            label="Lead Sources"
            apiId="leadSources"
            sources={leadSources}
            addSources={setLeadSources}
            addSourceData={addLeadSource}
            editSourceData={editLeadSource}
            deleteSourceData={deleteLeadSource}
            dropDown={dropDown}
          />
        );

      /*
       *material and carting suppliers and subcontacting personel data removed from dropdown
       *
       */
      case list[1].dropId:
        return (
          <MangeTaxes
            apiId="stateTax"
            addTaxes={setTaxes}
            taxes={taxes}
            addSourceData={addTax}
            editSourceData={editTax}
            deleteSourceData={deleteTax}
          />
        );

      case list[2].dropId:
        return (
          <MangeQuote
            quote={quote}
            setQuote={setQuote}
            changeQuote={changeQuote}
            apiId="quoteMarkup"
          />
        );

      case list[3].dropId:
        return (
          <ManageDropDown
            dataId="title"
            apiId="dayLost"
            sources={dayLost}
            addSources={setDayLost}
            addSourceData={addDayLost}
            editSourceData={editDayLost}
            deleteSourceData={deleteDayLost}
            dropDown={dropDown}
          />
        );
      case list[4]?.dropId:
        return <SchedulingModal />;

      case list[5]?.dropId:
        return <ManageZipCode />;

      default:
        return null;
    }
  };

  if (ddErr) {
    return <InitialLoader error={ddErr} onReload={loadData} />;
  }

  return (
    <div className="_container_right">
      <div style={{ margin: "1em" }}>
        {err.errMsg && (
          <Row>
            <Col xs="12">
              <Alert variant={err.errType}>{err.errMsg}</Alert>
            </Col>
          </Row>
        )}
        <Row>
          <Col>
            <div>
              <h4 style={{ margin: "0" }}>
                <Link
                  to={{
                    pathname: "/dashboard/help",
                    search: "Admin Drop Downs",
                  }}
                  target="_blank"
                  rel="noreferrer"
                  style={{ cursor: "pointer" }}
                >
                  <b style={{ color: "red" }}>?</b>
                </Link>
                &nbsp; &nbsp; &nbsp; Add, Edit or Delete all customizable Drop
                Down items for the entire application.
              </h4>
              <i style={{ fontSize: ".9em" }}>
                Note: If not listed here, it is not a customizable Drop Down
                Menu. Example is the &apos;Phone Type&apos; on New Lead screen.
              </i>
            </div>
          </Col>
        </Row>
        <Row style={{ margin: "2em 0 1em 0", maxWidth: "350px" }}>
          <Col>
            <Form.Group>
              <Form.Control
                as="select"
                value={dropDown}
                onChange={(e) => setDropDown(e.target.value)}
                disabled={
                  companyData &&
                  companyData.length &&
                  companyData[0].paymentStatus == "Frozen 1"
                    ? true
                    : false
                }
              >
                <option value="">
                  {gettingDropDown ? "Loading Data..." : "Choose..."}
                </option>
                {ddData && displayDropdowns(ddData)}
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>
        <Can do="read" on={permissions.read.admin_drop_downs}>
          <Row className="_scrollable_section">
            <Col>
              {ddData && ddData.length > 0 && switchCustomDropDown(ddData)}
            </Col>
          </Row>
        </Can>
      </div>
    </div>
  );
}
