import React from "react";
import DisplayOtherModalExtended from "./DisplayOtherModalExtended";
import PropTypes from "prop-types";

const DisplayOtherModal = ({
  show,
  label,
  hide,
  displayOtherModalData,
  arrName,
  displayOtherModalOtherData,
  displayOtherTypeArr,
  handleClickTypeData,
  workType,
  handleOtherClickTypeData,
}) => {
  return (
    <DisplayOtherModalExtended
      addData={() => {}}
      addSecondaryData={() => {}}
      show={show}
      label={label}
      arrName={arrName}
      hide={hide}
      displayOtherExtendedModalData={displayOtherModalData}
      displayOtherExtendedModalOtherData={displayOtherModalOtherData}
      displayOtherTypeArr={displayOtherTypeArr}
      handleClickTypeData={handleClickTypeData}
      workType={workType}
      handleOtherClickTypeData={handleOtherClickTypeData}
    />
  );
};

DisplayOtherModal.propTypes = {
  show: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  hide: PropTypes.func.isRequired,
  displayOtherModalData: PropTypes.array.isRequired,
  array: PropTypes.any,
  arrName: PropTypes.string.isRequired,
  other: PropTypes.object,
  displayOtherModalOtherData: PropTypes.array.isRequired,
  heading1: PropTypes.string,
  disable: PropTypes.bool,
  handleRemovalTypeChange: PropTypes.func.isRequired,
  addFloor: PropTypes.func.isRequired,
  handleOtherRemovalTypeChange: PropTypes.func.isRequired,
  displayOtherTypeArr: PropTypes.array,
  handleClickTypeData: PropTypes.func.isRequired,
  workType: PropTypes.any,
  handleOtherClickTypeData: PropTypes.func.isRequired,
};

export default DisplayOtherModal;
