import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import React from "react";
import PropTypes from "prop-types"; // Import PropTypes for validation

const QuoteSkeleton = ({ count = 10, height = 20 }) => {
  return (
    <div className="mt-5">
      <Skeleton count={count} height={height} />
    </div>
  );
};

// Prop validation
QuoteSkeleton.propTypes = {
  count: PropTypes.number, // count should be a number
  height: PropTypes.number, // count should be a number
};

export default QuoteSkeleton;
