// src/api/materialApi.js

import { ApiEndpoints } from "lib/config/baseSettings";
import { axiosInstance } from "services";

export const getMaterialActiveSuppliers = async () => {
    try {

        const URL = `${ApiEndpoints.PROPOSALS.GET_ACTIVE_MATERIAL_SUPPLIERS}`;

        const response = await axiosInstance.get(URL);
        return response.data;
    } catch (error) {
        throw new Error(error.message || "An error occurred while fetching material suppliers");
    }
};


export const changeMaterialSupplier = async (data) => {
    try {
        const URL = `${ApiEndpoints.PROPOSALS.CHANGE_MATERIAL_SUPPLIER_PROPOSAL}`;
        const response = await axiosInstance.patch(URL, data);
        return response.data;
    } catch (error) {
        throw error;
    }
};


export const fetchSeriesTypes = async ({ category, manufacturer, categoryType, supplierId }) => {
    const params = new URLSearchParams();
    params.set("category", category);
    params.set("manufacture", manufacturer);
    params.set("categoryType", categoryType);
    params.set("supplierId", supplierId);

    const URL = `${ApiEndpoints.PROPOSALS.GET_SERIES}?${params}`;

    const response = await axiosInstance.get(URL);
    return response.data; // Return only the data
};

export const updateMaterialProposalTaxInfo = async (data) => {
    try {
        const URL = `${ApiEndpoints.PROPOSALS.UPDATE_PROPOSAL_INFO}`;

        const response = await axiosInstance.patch(URL, data);
        return response.data;
    } catch (error) {
        throw error;
    }
};


export const updateMaterialQuoteLine = async (data) => {
    try {
        const URL = `${ApiEndpoints.PROPOSALS.UPDATE_PROPOSAL_INFO}`;

        const response = await axiosInstance.patch(URL, data);
        return response.data;
    } catch (error) {
        throw error;
    }
};

// Add Misc Material to the Quote
export const addMaterialMisc = async (data) => {
    const URL = `${ApiEndpoints.PROPOSALS.ADD_MISC_MATERIAL}`;
    try {
        const response = await axiosInstance.post(URL, data);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const deleteMaterialMiscQuote = async (data) => {
    try {
        const URL = `${ApiEndpoints.PROPOSALS.DELETE_MISC_MATERIAL_QUOTE}`;

        const response = await axiosInstance.delete(URL, { data });
        return response.data;
    } catch (error) {
        throw error;
    }
};