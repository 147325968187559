export const permissions = {
  read: {
    dashboard: "dashboard",
    dashboard_new_lead: "dashboard.new-lead",
    calendar: "calendar",
    calendar_sales: "calendar.sales",
    calendar_own_sales: "calendar.own-sales",
    calendar_repairs: "calendar.repairs",
    calendar_own_repairs: "calendar.own-repairs",
    activity: "activity",
    activity_sales: "activity.sales",
    activity_own_sales: "activity.own-sales",
    activity_repairs: "activity.repairs",
    activity_own_repairs: "activity.own-repairs",
    metrics: "metrics",
    quotes_and_proposals: "quotes-and-proposals",
    project_management: "project-management",
    admin_subscriptions_users: "admin.subscriptions-users.own",
    admin_subscriptions_users_own: "admin.subscriptions-users.own",
    admin_proposals_text_and_templates: "admin.proposals-text-and-templates",
    admin_drop_downs: "admin.drop-downs",
    admin_suppliers_material_and_labor: "admin.suppliers-material-and-labor",
    admin_suppliers_material_and_labor_contact_info:
      "admin.suppliers-material-and-labor.contact-info",
    contact_management: "contact-management",
    contact_management_own: "contact-management.own",
    production_management: "production-management",
  },
  update: {
    dashboard_new_lead: "dashboard.new-lead",
    calendar_sales: "calendar.sales",
    calendar_own_sales: "calendar.own-sales",
    calendar_repairs: "calendar.repairs",
    calendar_own_repairs: "calendar.own-repairs",
    activity_sales: "activity.sales",
    activity_own_sales: "activity.own-sales",
    activity_repairs: "activity.repairs",
    activity_own_repairs: "activity.own-repairs",
    metrics: "metrics",
    metrics_own: "metrics.own",
    quotes_and_proposals: "quotes-and-proposals",
    quotes_and_proposals_own: "quotes-and-proposals.own",
    project_management: "project-management",
    admin_subscriptions_users: "admin.subscriptions-users.own",
    admin_subscriptions_users_own_password:
      "admin.subscriptions-users.own-password",
    admin_proposals_text_and_templates: "admin.proposals-text-and-templates",
    admin_drop_downs: "admin.drop-downs",
    admin_drop_downs_production_calendar_day_lost:
      "admin.drop-downs.production-calendar-day-lost",
    admin_suppliers_material_and_labor: "admin.suppliers-material-and-labor",
    contact_management: "contact-management",
    contact_management_own: "contact-management.own",
    production_management: "production-management",
  },
  delete: {
    dashboard_new_lead: "dashboard.new-lead",
    calendar_sales: "calendar.sales",
    calendar_own_sales: "calendar.own-sales",
    calendar_repairs: "calendar.repairs",
    calendar_own_repairs: "calendar.own-repairs",
    activity_sales: "activity.sales",
    activity_own_sales: "activity.own-sales",
    activity_repairs: "activity.repairs",
    activity_own_repairs: "activity.own-repairs",
    metrics: "metrics",
    metrics_own: "metrics.own",
    quotes_and_proposals: "quotes-and-proposals",
    quotes_and_proposals_own: "quotes-and-proposals.own",
    project_management: "project-management",
    admin_subscriptions_users: "admin.subscriptions-users.own",
    admin_subscriptions_users_own_password:
      "admin.subscriptions-users.own-password",
    admin_proposals_text_and_templates: "admin.proposals-text-and-templates",
    admin_drop_downs: "admin.drop-downs",
    admin_drop_downs_production_calendar_day_lost:
      "admin.drop-downs.production-calendar-day-lost",
    admin_suppliers_material_and_labor: "admin.suppliers-material-and-labor",
    contact_management: "contact-management",
    contact_management_own: "contact-management.own",
    production_management: "production-management",
  },
  create: {
    dashboard_new_lead: "dashboard.new-lead",
    calendar_sales: "calendar.sales",
    calendar_own_sales: "calendar.own-sales",
    calendar_repairs: "calendar.repairs",
    calendar_own_repairs: "calendar.own-repairs",
    activity_sales: "activity.sales",
    activity_own_sales: "activity.own-sales",
    activity_repairs: "activity.repairs",
    activity_own_repairs: "activity.own-repairs",
    metrics: "metrics",
    metrics_own: "metrics.own",
    quotes_and_proposals: "quotes-and-proposals",
    quotes_and_proposals_own: "quotes-and-proposals.own",
    project_management: "project-management",
    admin_subscriptions_users: "admin.subscriptions-users.own",
    admin_subscriptions_users_own_password:
      "admin.subscriptions-users.own-password",
    admin_proposals_text_and_templates: "admin.proposals-text-and-templates",
    admin_drop_downs: "admin.drop-downs",
    admin_drop_downs_production_calendar_day_lost:
      "admin.drop-downs.production-calendar-day-lost",
    admin_suppliers_material_and_labor: "admin.suppliers-material-and-labor",
    contact_management: "contact-management",
    contact_management_own: "contact-management.own",
    production_management: "production-management",
  },
};
