import React, { useMemo } from "react";
import { Link, Route, Switch } from "react-router-dom";
import ContactManagement from "features/contact-management/contact-management";
import NewLead from "features/new-lead/new-lead";
import "./dashboard.scss";
import ProductionSchedule from "features/ProductionSchedule/ProductionSchedule";
import { permissions } from "lib/config/permissionSettings";
import { useAbility } from "components/can";

const Dashboard = (props) => {
  const {
    location: { pathname },
    match: { path },
  } = props;
  const ability = useAbility();
  const activePath = pathname.split("/")[2];

  const dashboardLinks = useMemo(() => {
    const tabs = [];
    if (ability.can("read", permissions.read.production_management)) {
      tabs.push({
        title: "Production Schedule",
        link: "production",
        component: ProductionSchedule,
      });
    }
    if (ability.can("create", permissions.create.dashboard_new_lead)) {
      tabs.push({ title: "New Lead", link: "new-lead", component: NewLead });
    }
    if (ability.can("read", permissions.read.contact_management)) {
      tabs.push({
        title: "Contact Management",
        link: "contactmanagement",
        component: ContactManagement,
      });
    }
    return tabs;
  }, [ability]);

  return (
    <>
      <div className="wrapper">
        <div className="_tab">
          {dashboardLinks.map((tab) => (
            <Link
              key={tab.link}
              to={`${path}/${tab.link}`}
              className={`_tabItem ${activePath === tab.link ? "active" : ""}`}
              style={{ fontSize: "1.4rem" }}
            >
              {tab.title}
            </Link>
          ))}
        </div>
        <Switch>
          {dashboardLinks.map((tab) => (
            <Route
              key={tab.link}
              path={`${path}/${tab.link}`}
              component={tab.component}
            />
          ))}
        </Switch>
      </div>
    </>
  );
};

export default Dashboard;
