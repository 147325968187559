import { ApiEndpoints } from "lib/config/baseSettings";
import { axiosInstance } from "services";

export const createAlternativeQuoteApi = async (data) => {
    try {
        const URL = `${ApiEndpoints.PROPOSALS.GENERATE}`;
        const response = await axiosInstance.post(URL, data);
        return response.data;
    } catch (error) {
        throw error;
    }
};
