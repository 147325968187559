import { getLastElement } from "utils/utils";
import { metricsActionTypes } from "./metrics.types";

const INITIAL_STATE = {
  isLoading: false,
  data: {},
  errors: "", // General error
  currentAddedSection: {},

  isLoadingAdd: false,
  addError: {}, // Error related to adding
  addResponse: {},

  isLoadingEdit: false,
  editError: {}, // Error related to editing
  editResponse: {},

  isLoadingDeleTallyRecord: false,

  isLoadingDelete: false,
  deleteError: {}, // Error related to deletion
  deleteResponse: "", // Initialized as an empty string
};

export const MetricV2Data = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case metricsActionTypes.METRIC_ROOFING_GET_START:
      return {
        ...state,
        isLoading: true,
        data: {},
        errors: "", // Reset general errors when loading starts
      };

    case metricsActionTypes.METRIC_ROOFING_GET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        errors: null, // Clear general errors
        addError: null, // Clear add errors
        editError: null, // Clear edit errors
        deleteError: null, // Clear delete errors
        deleteResponse: "",
      };
    case metricsActionTypes.METRIC_ROOFING_GET_FAILURE:
      return {
        ...state,
        isLoading: false,
        data: {},
        errors: action.payload, // Set general error on failure
      };

    case metricsActionTypes.METRIC_ADD_ROOFING_START:
      return {
        ...state,
        isLoadingAdd: true,
        data: {
          address: state?.data?.address || "",
          hasRoof: state?.data?.hasRoof || false,
          hasGutters: state?.data?.hasGutters || false,
        },
        errors: "", // Reset general errors when loading starts
      };

    case metricsActionTypes.METRIC_ADD_ROOFING_SUCCESS:
      return {
        ...state,
        isLoadingAdd: false,
        errors: null, // Clear general errors
        addError: null, // Clear add errors
        editError: null, // Clear edit errors
        deleteError: null, // Clear delete errors
        deleteResponse: "", // Reset delete response
        currentAddedSection: getLastElement(action.payload),
        data: {
          ...state.data,
          sections: [...action.payload],
        },
      };

    case metricsActionTypes.METRIC_ADD_ROOFING_FAILURE:
      return {
        ...state,
        isLoadingAdd: false,
        addError: action.payload, // Set general error on failure
      };

    case metricsActionTypes.SECTION_UPDATE_START: {
      const { typeOfOperation } = action.payload;

      const { loadingKey, errorKey } = getUpdateKeys(typeOfOperation);

      return {
        ...state,
        [loadingKey]: true,
        [errorKey]: null, // Reset the relevant error state
      };
    }

    case metricsActionTypes.SECTION_UPDATE__SUCCESS: {
      const { responseData, typeOfOperation } = action.payload;
      const { loadingKey, errorKey } = getUpdateKeys(typeOfOperation);
      console.log({ responseData });

      return {
        ...state,
        [loadingKey]: false,
        errors: null, // Clear general errors
        // currentAddedSection: updatedSection,
        data: {
          ...state.data,
          isModifiedMetrics: responseData.isModifiedMetrics, // Corrected assignment
          sections: [...responseData.sections],
        },
        addError: null, // Clear add errors
        [errorKey]: null, // Reset the relevant error state
        deleteError: null, // Clear delete errors
        deleteResponse: "", // Reset delete response
      };
    }

    case metricsActionTypes.SECTION_UPDATE__FAILURE: {
      const { typeOfOperation, message } = action.payload;

      const { loadingKey, errorKey } = getUpdateKeys(typeOfOperation);

      return {
        ...state,
        [loadingKey]: false,
        editResponse: {},
        editError: message, // Set edit error on failure
      };
    }

    case metricsActionTypes.SECTION_DELETE_START:
      return {
        ...state,
        isLoadingDelete: true,
        deleteResponse: "",
        deleteError: null, // Reset delete errors when deletion starts
      };

    case metricsActionTypes.SECTION_DELETE__SUCCESS:
      return {
        ...state,
        isLoadingDelete: false,
        deleteResponse: action.payload,
        errors: null, // Clear general errors
        addError: null, // Clear add errors
        editError: null, // Clear edit errors
        deleteError: null, // Clear delete errors
      };

    case metricsActionTypes.SECTION_DELETE__FAILURE:
      return {
        ...state,
        isLoadingDelete: false,
        deleteResponse: "",
        deleteError: action.payload, // Set delete error on failure
      };

    default:
      return state;
  }
};

const getUpdateKeys = (typeOfOperation) => {
  if (typeOfOperation === "deleteMetricTally") {
    return {
      loadingKey: "isLoadingDeleTallyRecord",
      errorKey: "editError",
      responseKey: "editResponse",
    };
  }

  return {
    loadingKey: "isLoadingEdit",
    errorKey: "editError",
    responseKey: "editResponse", // No response key for general updates
  };
};
