export const metricsActionTypes = {
  METRIC_ADD_ROOFING_START: "METRIC_ADD_ROOFING_START_GUTTER",
  METRIC_ADD_ROOFING_SUCCESS: "METRIC_ADD_ROOFING_SUCCESS_GUTTER",
  METRIC_ADD_ROOFING_FAILURE: "METRIC_ADD_ROOFING_FAILURE_GUTTER",

  METRIC_GET_ROOFING_SUCCESS: "METRIC_GET_ROOFING_SUCCESS_GUTTER",
  METRIC_GET_ROOFING_START: "METRIC_GET_ROOFING_START_GUTTER",
  METRIC_GET_ROOFING_FAILURE: "METRIC_GET_ROOFING_FAILURE_GUTTER",

  SECTION_UPDATE_START: "SECTION_UPDATE_START_GUTTER",
  SECTION_UPDATE__SUCCESS: "SECTION_UPDATE__SUCCESS_GUTTER",
  SECTION_UPDATE__FAILURE: "SECTION_UPDATE__FAILURE_GUTTER",

  SECTION_DELETE_START: "SECTION_DELETE_START_GUTTER",
  SECTION_DELETE__SUCCESS: "SECTION_DELETE__SUCCESS_GUTTER",
  SECTION_DELETE__FAILURE: "SECTION_DELETE__FAILURE_GUTTER",
};
