import React, { useState, useEffect, useCallback } from "react";
import "./Proposal.scss";
import { Row, Col, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { fetchStaticAll } from "lib/redux/static-all/static-all.actions";
import {
  fetchAutoText,
  fetchStandardStatements,
  updateStandardStatements,
} from "lib/redux/auto-text/auto-text.actions";
import InitialLoader from "components/InitilalLoader";
import DisplayData from "./DisplayData";
import StandardStatements from "./StandardStatments";
import { Link } from "react-router-dom";
import useContactData from "hooks/useContactManagement";
import PercentageModal from "features/QuotesProposals/ProposalTab/PercentageModal";
import { useAbility } from "components/can";
import { permissions } from "lib/config/permissionSettings";

const HeaderText =
  "Proposal auto text are line item statements specifically inserted and unique to each proposal created. Organized Roofer has provided a general list of work type descriptions and common proposal statements. All are easily customizable to suit your descriptive preferences. Simply click the 'Edit' button next to each line item then 'Save'. If any line item is not needed, simply click exclude (where displayed) and it will be omitted from future proposals. You will also have the opportunity to make changes when writing proposals.";

export default function Proposal() {
  const [statementId, setStatementId] = useState("");
  const { companyData } = useContactData();

  const { data, errors, isLoading } = useSelector((state) => state.staticAll);
  const { gettingTexts, autoTexts, autoTextsErr } = useSelector(
    (state) => state.autoTexts
  );
  const { gettingStatements, statements, statementsErr } = useSelector(
    (state) => state.standardStatements
  );
  const [statementsCategory, setStatementsCategory] = useState([]);
  const [per, setPer] = useState(0);

  const [percentage, setPercent] = useState(per);
  const [displayText, setDisplayText] = useState("");

  const [percentageModal, setPercentageModal] = useState(false);
  const [fixedStandardStatements, setFixedStandardStatements] = useState([]);
  const [percentageStatementIdx, setPercentageStatementIdx] = useState("");

  const [textId, setTextId] = useState("");
  const [isSelected, setIsSelected] = useState(false);
  const [updateFixedStandardStatementsPercentage] = useState();

  const dispatch = useDispatch();
  const ability = useAbility();

  useEffect(() => {
    dispatch(fetchStaticAll());
  }, []);

  useEffect(() => {
    setFixedStandardStatements(statements);
  }, [statements]);

  useEffect(() => {
    if (data && data.autoTextDropDown) {
      setStatementsCategory(data.autoTextDropDown);
    }
  }, [data]);

  useEffect(() => {
    if (errors) {
      toast.error(errors);
    }
  }, [errors]);

  const onSuccess = () => {
    toast.success("Successfully updated the statement.");
  };

  const onError = (errMsg) => {
    toast.error(errMsg);
  };

  const loadDropDownData = useCallback(() => {
    const isStandardStatements =
      statementsCategory.length > 0 &&
      statementId === statementsCategory[0].dropId;

    if (statementId) {
      if (isStandardStatements) {
        return dispatch(fetchStandardStatements());
      }

      dispatch(fetchAutoText(statementId));
    }
  }, [statementId]);

  const changePer = (value) => setPer(value);

  const changePerData = (increase = true) => {
    let newPer = percentage;
    if (increase) {
      newPer += 1;
    } else {
      newPer -= 1;
    }
    setPercent(newPer < 0 ? 0 : newPer);
  };
  const applyChanges = () => {
    changePer(percentage);

    let postData = {
      isSelected: isSelected === undefined ? isSelected : isSelected,
      displayText,
      percentage,
    };
    dispatch(updateStandardStatements(textId, postData, onSuccess, onError));
    dispatch(fetchStandardStatements());

    hidePercentageStepperModal();
  };

  const showPercentageStepperModal = (displaytext, idx) => {
    setPercent(displaytext.percentage);
    setDisplayText(displaytext.displayText);
    setIsSelected(displaytext.isSelected);
    setTextId(displaytext._id);
    setPercentageModal(true);
    const idd = fixedStandardStatements.findIndex(
      (list) => list._id === displaytext._id
    );
    setPercentageStatementIdx(idd);
  };

  const hidePercentageStepperModal = () => {
    setPercentageModal(false);
  };

  const cancelChanges = () => {
    setPercent(per);
    hidePercentageStepperModal();
  };

  useEffect(() => {
    if (statementId) {
      loadDropDownData();
    }
  }, [statementId]);

  const proposalPointerEvent = ability.can(
    "update",
    permissions.update.admin_proposals_text_and_templates
  );

  useEffect(() => {
    if (autoTextsErr) {
      toast.error("Unable to get the selected drop down's data");
    }
  }, [autoTextsErr]);

  const switchDropdownBody = () => {
    if (gettingTexts || gettingStatements || autoTextsErr || statementsErr) {
      return (
        <InitialLoader
          error={autoTextsErr || statementsErr}
          onReload={loadDropDownData}
        />
      );
    }
    switch (statementId) {
      case statementsCategory[0].dropId:
        return (
          <StandardStatements
            data={fixedStandardStatements}
            showPercentageStepperModal={showPercentageStepperModal}
            permission={proposalPointerEvent}
          />
        );

      case statementsCategory[1].dropId:
      case statementsCategory[2].dropId:
      case statementsCategory[3].dropId:
      case statementsCategory[4].dropId:
      case statementsCategory[7].dropId:
      case statementsCategory[10].dropId:
      case statementsCategory[11].dropId:
        return (
          <DisplayData data={autoTexts} permission={proposalPointerEvent} />
        );

      default:
        return null;
    }
  };

  return (
    <>
      <div className="_container_right _user_page">
        <div className="__proposal">
          <Row>
            <Col>
              <Link
                to={{
                  pathname: "/dashboard/help",
                  search: "Admin Proposals Text & Template",
                }}
                target="_blank"
                rel="noreferrer"
                style={{ cursor: "pointer" }}
              >
                <b style={{ color: "red" }}>?</b>
              </Link>
              &nbsp; &nbsp; &nbsp;
              <span>{HeaderText}</span>
            </Col>
          </Row>
          <Row style={{ marginTop: "2em", marginBottom: "2em" }}>
            <Col lg="3">
              <Form.Group>
                <Form.Label>Select Statement Category</Form.Label>
                <Form.Control
                  as="select"
                  value={statementId}
                  onChange={(e) => setStatementId(e.target.value)}
                  disabled={
                    companyData &&
                    companyData.length &&
                    companyData[0].paymentStatus == "Frozen 1"
                      ? true
                      : false
                  }
                >
                  <option value="">select...</option>
                  {statementsCategory.length > 0 &&
                    statementsCategory.map((statement) => (
                      <option key={statement.dropId} value={statement.dropId}>
                        {statement.dropName}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          {statementsCategory.length > 0 && switchDropdownBody()}
        </div>
      </div>
      <PercentageModal
        onHide={hidePercentageStepperModal}
        show={percentageModal}
        percent={percentage}
        onCancel={cancelChanges}
        onSubmit={applyChanges}
        changePerData={changePerData}
        submitText="Apply"
        heading={displayText.replace(":", "")}
      />
    </>
  );
}
