import React, { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm";
import { Elements } from "@stripe/react-stripe-js";
import {
  get_stripe_test_public_key,
  get_stripe_test_secret_key,
} from "lib/redux/user/user.actions";
import useSubscription from "hooks/useSubscription";

const StripePaymentPage = (props) => {
  const { company_id, email, type, onHide, from, additionalUser } = props;
  const [stripeState, setStripeState] = useState({
    stripePromise: null,
    clientSecret: "",
    payIntent: null,
  });
  const [stripeClientId, setStripeClientId] = useState();

  const { particularCompanyData } = useSubscription();

  useEffect(() => {
    if (particularCompanyData?.length) {
      const [{ stripeCustomerId }] = particularCompanyData;
      if (stripeCustomerId) setStripeClientId(stripeCustomerId);
    }
  }, [particularCompanyData]);

  useEffect(() => {
    const initializeStripe = async () => {
      try {
        // Get both keys in parallel
        const [publicKeyResponse, secretKeyResponse] = await Promise.all([
          get_stripe_test_public_key(),
          get_stripe_test_secret_key(),
        ]);

        if (
          publicKeyResponse?.data?.success &&
          secretKeyResponse?.data?.success
        ) {
          const stripe = await loadStripe(publicKeyResponse.data.result);
          setStripeState({
            stripePromise: stripe,
            clientSecret: secretKeyResponse.data.result.client_secret,
            payIntent: secretKeyResponse.data.result.intent,
          });
        }
      } catch (error) {
        console.error("Failed to initialize Stripe:", error);
      }
    };

    initializeStripe();
  }, []); // Run once on mount

  const appearance = {
    theme: "stripe",
  };

  const options = {
    clientSecret: stripeState.clientSecret,
    appearance,
  };

  // Add cleanup effect
  useEffect(() => {
    return () => {
      // Reset state when component unmounts
      setStripeState({
        stripePromise: null,
        clientSecret: "",
        payIntent: null,
      });
      setStripeClientId(null);
    };
  }, []);

  // Only render Elements when we have all required data
  return (
    <>
      {stripeState.stripePromise && stripeState.clientSecret && (
        <Elements stripe={stripeState.stripePromise} options={options}>
          <CheckoutForm
            company_id={company_id}
            payIntent={stripeState.payIntent}
            clientSecret={stripeState.clientSecret}
            email={email}
            stripeClientId={stripeClientId}
            type={type}
            onHide={onHide}
            from={from}
            additionalUser={additionalUser}
            resetStripeData={() => {
              setStripeState({
                stripePromise: null,
                clientSecret: "",
                payIntent: null,
              });
              setStripeClientId(null);
            }}
          />
        </Elements>
      )}
    </>
  );
};

export default StripePaymentPage;
