import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import Welcome from "./features/Welcome";
import Login from "./features/Login/Login";
import Signup from "./features/Signup/Signup";
import ForgotPass from "./features/ForgotPassword/ForgotPass";
import PublicRoutes from "./lib/routes/PublicRoutes";
import ProtectedRoutes from "./lib/routes/ProtectedRoutes";
import { LoadingLoader } from "components";
import { validateToken } from "lib/redux/user/user.actions";
import Completion from "components/Stripe/Completeion";
import Fail from "components/Stripe/Fail";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllPermissions,
  getRolePermissions,
} from "lib/redux/permission/permission.actions";
import DashboardPanel from "features/dashboard-panel/dashboard-panel";
import {
  fetchStaticAll,
  getStateTaxes,
} from "lib/redux/static-all/static-all.actions";
import GlobalErrorHandler from "components/common/global-error/GlobalErrorHandler"; // Import your GlobalErrorHandler component

const AppRoute = () => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.login);

  // Load the static and master data
  useEffect(() => {
    // Load taxrate master data of company
    dispatch(getStateTaxes());
    dispatch(fetchStaticAll());
  }, [dispatch]);

  const staticAll = useSelector((state) => state.staticAll);
  console.log({
    staticAllV2: staticAll,
  });

  useEffect(() => {
    if (user) {
      dispatch(getAllPermissions());
      dispatch(getRolePermissions());
    }
  }, [user, dispatch]);

  // When go back online
  const disableLoading = () => {
    setLoading(false);
  };

  useEffect(() => {
    dispatch(validateToken(disableLoading));
  }, [dispatch]);

  if (loading) {
    return <LoadingLoader />;
  }

  return (
    <div>
      <ToastContainer />
      <GlobalErrorHandler /> {/* Add GlobalErrorHandler here */}
      <Router basename="/">
        <Route path="/" exact component={Welcome} />
        <PublicRoutes path="/login" component={Login} />
        <PublicRoutes path="/signup" component={Signup} />
        <ProtectedRoutes path="/dashboard" component={DashboardPanel} />
        <Route path="/forgotpassword/:email/:otp" component={ForgotPass} />
        <Route path="/completion" component={Completion} />
        <Route path="/fail" component={Fail} />
      </Router>
    </div>
  );
};

export default AppRoute;
