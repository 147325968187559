import { useMutation, useQuery } from "@tanstack/react-query";
import { addLabourMisc, changeLabourSupplier, deleteLabourMiscQuote, getLabourActiveSuppliers, updateLabourQuoteLine } from "../api/labourApi";

export const useLabourSuppliers = () => {
    const { data, isLoading, isError, error } = useQuery(
        ["labourSuppliers"], // Query key for caching
        getLabourActiveSuppliers,
        {
            // Ensures the query runs once and doesn't repeat unless needed
            staleTime: 1000 * 60 * 5,  // 5 minutes for caching data
            cacheTime: 1000 * 60 * 30, // Cache for 30 minutes before garbage collection
        }
    );

    return {
        suppliers: data?.result || [],  // Return suppliers from the data or empty array if undefined
        isLoading,
        isError,
        error,
    };
};

export const useChangeLabourSupplier = () => {
    return useMutation(changeLabourSupplier);
};

// Update a proposal tax info
export const useUpdateLabourQuoteline = () => {
    return useMutation(updateLabourQuoteLine);
};


// Hook for adding Misc Material to Quote
export const useAddLabourlMisc = () => {
    return useMutation(addLabourMisc);
};


// Hook for adding Misc Material to Quote
export const useDeleteLabourMisc = () => {
    return useMutation(deleteLabourMiscQuote);
};
