// metricsActions.js
import { categoryMapping, handleGlobalError } from "utils/utils";
import {
  addMetricApi,
  deleteMetricApi,
  editMetricApi,
  getMetricsApi,
} from "../../services/metricsService";
import {
  resetAllMetrics,
  updateMetrics,
} from "../roof-metric-collection/metric.action";
import { metricsActionTypes } from "./metrics.types";

export const addMetric = (data) => {
  return async (dispatch) => {
    dispatch({ type: metricsActionTypes.METRIC_ADD_ROOFING_START });

    try {
      const responseData = await addMetricApi(data);

      if (responseData) {
        dispatch({
          type: metricsActionTypes.METRIC_ADD_ROOFING_SUCCESS,
          payload: responseData.metric.sections,
        });
      } else {
        dispatch({
          type: metricsActionTypes.METRIC_ADD_ROOFING_FAILURE,
          payload: responseData.error,
        });
      }
    } catch (error) {
      dispatch({
        type: metricsActionTypes.METRIC_ADD_ROOFING_FAILURE,
        payload: error.message,
      });
      handleGlobalError(error, dispatch);
    }
  };
};

export const getMetrics = (requestId) => {
  return async (dispatch) => {
    dispatch({ type: metricsActionTypes.METRIC_ROOFING_GET_START });

    try {
      const responseData = await getMetricsApi(requestId);
      console.log({ responseData });
      if (responseData) {
        dispatch({
          type: metricsActionTypes.METRIC_ROOFING_GET_SUCCESS,
          payload: responseData,
        });
      } else {
        dispatch({
          type: metricsActionTypes.METRIC_ROOFING_GET_FAILURE,
          payload: responseData.error,
        });
      }
    } catch (error) {
      dispatch({
        type: metricsActionTypes.METRIC_ROOFING_GET_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const editSectionMetric = (data, typeOfOperation = "updatesection") => {
  return async (dispatch) => {
    // Dispatch the start action to indicate the beginning of the update
    dispatch({
      type: metricsActionTypes.SECTION_UPDATE_START,
      payload: { typeOfOperation },
    });
    const sectionId = data?.sectionId;
    try {
      console.log({ data });
      // Call the API with the provided data
      const responseData = await editMetricApi(data);

      // Check if the responseData exists (indicating success)
      if (responseData) {
        const reducerPayload = {
          responseData: responseData.data,
          typeOfOperation,
        };

        dispatch({
          type: metricsActionTypes.SECTION_UPDATE__SUCCESS,
          payload: reducerPayload,
        });
        const updatedSection = responseData.data.sections.find(
          (section) => section._id === sectionId
        );

        const newSection = Object.keys(updatedSection).reduce((acc, key) => {
          const newKey = categoryMapping[key] || key;
          acc[newKey] = updatedSection[key];
          return acc;
        }, {});

        console.log({
          newSection,
        });

        dispatch(updateMetrics(newSection));
      } else {
        // If responseData is null or undefined, dispatch the failure action

        dispatch({
          type: metricsActionTypes.SECTION_UPDATE__FAILURE,
          payload: responseData.error,
        });
        handleGlobalError(responseData.error, dispatch);
      }
    } catch (error) {
      console.log({ responseDataError: error });
      // Catch any errors and dispatch the failure action
      dispatch({
        type: metricsActionTypes.SECTION_UPDATE__FAILURE,
        payload: { message: error.message, typeOfOperation },
      });
      handleGlobalError(error, dispatch);
    }
  };
};

export const deleteSectionMetric = (data) => {
  return async (dispatch) => {
    dispatch({ type: metricsActionTypes.SECTION_DELETE_START });

    try {
      const responseData = await deleteMetricApi(data);
      console.log({ responseData });
      if (responseData) {
        dispatch({
          type: metricsActionTypes.SECTION_DELETE__SUCCESS,
          payload: responseData,
        });
      } else {
        dispatch({
          type: metricsActionTypes.SECTION_DELETE__FAILURE,
          payload: responseData.error,
        });
      }
    } catch (error) {
      dispatch({
        type: metricsActionTypes.SECTION_DELETE__FAILURE,
        payload: error.message,
      });
    }
  };
};
