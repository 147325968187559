import React, { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Row, Col, Button, Form, Modal } from "react-bootstrap";
import Components, { LoadingLoader } from "components";
import { useDispatch, useSelector } from "react-redux";
import styles from "lib/config/baseStyles";

import { toast } from "react-toastify";
import DebrisRemoval from "./components/EstimatorTabs/Carting";
import Material from "./components/EstimatorTabs/Material";
import RoofingLabor from "./components/EstimatorTabs/Labor";
import NewQuoteModal from "./components/NewQuoteModal";
import { QuoteNamePopup } from "./components/QuoteNameInput";
import AlternativeQuoteModal from "./components/AlternatveQuoteModal";
import { getMetrics } from "features/metric/redux/metrics-v2/metrics.actions";
import Gutters from "./components/EstimatorTabs/Gutters";
import TotalQuote from "./components/EstimatorTabs/TotalQuote";
import ConfirmationModal from "components/common/modals/ConfirmationModal";
import { deleteProposalApi } from "./services/proposalService";
import { handleGlobalError } from "utils/utils";
import { useCreateAlternativeProposal } from "./hooks/useAlternativeQuote";
import { useQueryClient } from "@tanstack/react-query";
import {
  useDeleteOneQuoteProposal,
  useUpdateProposalTitle,
} from "./hooks/useProposals";

export default function Estimator({ requestId }) {
  const [selectedTab, setSelectedTab] = useState();
  const [showNewQuote, setShowNewQuote] = useState(false);
  const [altProposalName, setAltProposalName] = useState("");
  const [showDeleteQuoteModal, setShowDeleteQuoteModal] = useState(false);

  const history = useHistory();
  const queryClient = useQueryClient(); // Access the query client to invalidate queries

  const [tabs, setTab] = useState(["Total"]);
  const [showEditProposalNameModal, setshowEditProposalNameModal] =
    useState(false);

  const [showMetricSelectionModal, setShowMetricSelectionModal] =
    useState(false);
  const dispatch = useDispatch();

  const proposalData = useSelector((state) => state.proposal);
  const { isLoadingProposal, selectedProposal } = proposalData;

  const [quoteName, setQuoteName] = useState("");

  const {
    mutate: deleteQuoteProposal,
    isLoading: isLoadingDeleteQuoteProposal,
    isError,
    error,
  } = useDeleteOneQuoteProposal(requestId);

  const memoizedRequestFor = useMemo(
    () => selectedProposal?.requestFor,
    [selectedProposal]
  );

  const allowEdit = selectedProposal?.status !== "As-Collected";

  const {
    data,
    isLoading: isLoadingUpdateSupplier,
    isError: isUpdate,
    isSuccess,
    mutate: createAlternativeProposalMutation,
  } = useCreateAlternativeProposal();

  const {
    isLoading: isLoadingUpdateProposalTitle,
    isError: isUpdateProposalTitleError,
    mutate: updateProposalTitleMutation,
  } = useUpdateProposalTitle();

  useEffect(() => {
    setQuoteName(selectedProposal?.title);
  }, [selectedProposal?.title]);

  console.log({
    quoteName,
  });
  useEffect(() => {
    if (memoizedRequestFor && !selectedTab) {
      const { hasGutters, hasRoof } = memoizedRequestFor;

      // Initialize the tabs based on conditions
      const newTabs = [
        ...(hasRoof ? ["Material", "Labor"] : []),
        ...(hasGutters ? ["Gutter"] : []),
        "Carting", // Always add "Carting" as the last tab
      ];

      // Update the tabs state, avoiding duplicates
      setTab((prevState) => [...new Set([...newTabs, ...prevState])]);

      // Set the default selected tab only once
      const defaultTab = hasRoof
        ? "Material"
        : hasGutters
        ? "Gutter"
        : "Carting";
      if (!selectedTab) {
        setSelectedTab(defaultTab);
      }
    }
  }, [memoizedRequestFor]);

  const changeTab = (tab) => (selectedTab !== tab ? setSelectedTab(tab) : {});

  const hideNQModal = (setShowModal) => {
    setShowNewQuote(false);
    setShowModal(true);
  };

  const displayBuildingsOrSections = () => {
    if (showBuilding) {
      hideNQModal(setBuildingModal);
    } else {
      hideNQModal(setSectionModal);
    }
  };

  const switchTab = () => {
    switch (selectedTab) {
      case "Material":
        return <Material />;

      case "Labor":
        return <RoofingLabor />;

      case "Gutter":
        return <Gutters requestId={requestId} />;
      case "Carting":
        return <DebrisRemoval requestId={requestId} />;
      case "Total":
        return <TotalQuote />;

      default:
        return (
          <div style={{ paddingTop: "50px" }}>
            <div className="__msg">
              Please selecte one of the above tab to continue.
            </div>
          </div>
        );
    }
  };

  const closeQuoteDeleteModal = () => {
    setShowDeleteQuoteModal(false);
  };

  const LinkBtn = ({ to }) => (
    <Link rel="noreferrer" to={to}>
      <Button variant="outline-secondary">
        <strong>Open Metric</strong>
      </Button>
    </Link>
  );

  const showAlternateQuoteModal = () => {
    setShowNewQuote(true);
    // setName("");
  };

  const getMetricLink = () => {
    const { requestFor } = selectedProposal;
    let metricLink = "";
    if (requestFor?.hasGutters && requestFor?.hasRoof) {
      metricLink = `/dashboard/metrics/roof/${requestId}`;
    } else if (requestFor?.hasGutters) {
      metricLink = `/dashboard/metrics/gutters/${requestId}`;
    } else {
      metricLink = `/dashboard/metrics/roof/${requestId}`;
    }

    console.log({ metricLink });

    return metricLink;
  };

  const SupplierInfo = () => (
    <div className="_cont_tab __cont_quote">
      <div className="_quote_cont">
        <Row
          style={{
            justifyContent: "space-between",
          }}
          className="__quote_estimator_head"
        >
          <Col style={styles.reduceWidthCol}>
            <div className="__quote_top_btns">
              <Button
                // disabled={isLockQuoteData?.isLocked}
                variant="danger"
                // onClick={() => setQLockedModal(true)}
              >
                {/* <strong>
                  {isLockQuoteData?.isLocked ? "Locked" : "Lock Quote"}
                </strong> */}
                Lock Quote
              </Button>
              <Button
                variant="danger"
                onClick={() => setShowDeleteQuoteModal(true)}
                // disabled={isLockQuoteData?.isLocked}
              >
                <strong>Delete Quote</strong>
              </Button>
              <Button
                variant="outline-secondary"
                disabled={!allowEdit}
                onClick={() => {
                  setshowEditProposalNameModal(true);
                }}
              >
                <strong>Edit Name</strong>
              </Button>
              <LinkBtn to={getMetricLink()} />
              {/* {!type && (
                <Button
                  variant="outline-secondary"
                  onClick={showAlternateQuoteModal}
                >
                  <strong>Alternate Quote</strong>
                </Button>
              )} */}
              <Button
                variant="outline-secondary"
                onClick={showAlternateQuoteModal}
              >
                <strong>Alternate Quote</strong>
              </Button>

              <Button
              // onClick={changeTabProposal}
              >
                <strong>Write Proposal</strong>
              </Button>
            </div>
          </Col>
        </Row>
      </div>
      &nbsp;&nbsp;
      {/* {isLockQuoteData?.isLocked && (
        <span style={{ fontWeight: "bold", fontSize: "larger" }}>
          {moment(isLockQuoteData?.isLockedTime).format("lll")}
        </span>
      )} */}
      <div className="__quote_new_tab">
        <ul>
          {tabs?.map((tab) => {
            // if (hideTabMappig[tab]) return null;

            return (
              <li
                key={tab}
                role="button"
                onClick={() => changeTab(tab)}
                className={selectedTab === tab ? "active" : ""}
              >
                {tab}
              </li>
            );
          })}
        </ul>
      </div>
      {switchTab()}
    </div>
  );
  const deleteProposal = async () => {
    const body = {
      requestId,
      proposalId: selectedProposal?._id,
    };

    deleteQuoteProposal(body, {
      onSuccess: (data) => {
        // Handle the success response
        console.log("Delete successful:", data);

        // Show success message
        toast.success(data?.result?.message);

        // Invalidate any queries that need to be refetched, e.g., proposals or metrics
        queryClient.invalidateQueries(["proposals"]);

        // If there are no more proposals, navigate away
        if (!data?.result?.proposalCount) {
          history.push(`/dashboard/metrics/roof/${requestId}`);
        }
      },
      onError: (error) => {
        // Handle any errors during the mutation
        console.error("Error deleting proposal:", error);
        toast.error(
          error?.message || "An error occurred while deleting the proposal."
        );
      },
      onSettled: () => {
        // Optionally handle any post-request operations, like hiding loading state
        setShowDeleteQuoteModal(false);
      },
    });
  };
  const onHideModal = () => {
    setShowNewQuote(false);
  };

  const onHide = () => {
    setShowMetricSelectionModal(false);
  };

  const handleChangeNewAltProposal = (event) => {
    const alterProposalName = event.target.value;
    setAltProposalName(alterProposalName);
  };

  const onSaveNewProposalName = () => {
    setShowNewQuote(false);
    setShowMetricSelectionModal(true);
    dispatch(getMetrics(requestId));
  };

  const onSaveNewQuote = (sections) => {
    const body = {
      requestId: requestId,
      alternativeProposal: {
        name: altProposalName,
        sections: sections,
      },
    };
    createAlternativeProposalMutation(body, {
      onSuccess: (data) => {
        toast.success("Alternative proposal created successfully!");
        console.log("Proposal generated successfully:", data);
        setShowMetricSelectionModal(false);
        queryClient.invalidateQueries(["proposals"]);

        setAltProposalName("");
      },

      onError: (error) => {
        console.error("Error updating carting supplier:", error.message);
        handleGlobalError(error, dispatch);
        setShowMetricSelectionModal(false);
      },
    });
  };

  const handleEditProposalName = (newName) => {
    const body = {
      newTitle: newName,
      proposalId: selectedProposal?._id,
    };
    updateProposalTitleMutation(body, {
      onSuccess: (data) => {
        // Handle the success response
        console.log("Delete successful:", data);

        // Show success message
        toast.success(data?.result?.message);

        // Invalidate any queries that need to be refetched, e.g., proposals or metrics
        queryClient.invalidateQueries(["proposals"]);
      },
      onError: (error) => {
        // Handle any errors during the mutation
        console.error("Error deleting proposal:", error);
        toast.error(
          error?.message || "An error occurred while deleting the proposal."
        );
      },
      onSettled: () => {
        // Optionally handle any post-request operations, like hiding loading state
        setshowEditProposalNameModal(false);
      },
    });
  };

  return (
    <div className="__quote_estimator">
      <NewQuoteModal
        show={showNewQuote}
        onHide={onHideModal}
        name={altProposalName}
        handleChange={(e) => handleChangeNewAltProposal(e)}
        onSaveProposal={onSaveNewProposalName}
        // confirm={displayBuildingsOrSections}
        // disableSave={!name.trim()}
      />

      {/* Edit proposal */}
      <QuoteNamePopup
        currentName={quoteName}
        setDisplayInp={setshowEditProposalNameModal}
        displayInp={showEditProposalNameModal}
        changeQuoteName={handleEditProposalName}
        processing={isLoadingUpdateProposalTitle}
      />

      {SupplierInfo()}

      <AlternativeQuoteModal
        altProposalName={altProposalName}
        show={showMetricSelectionModal}
        onHide={onHide}
        onSaveNewQuote={onSaveNewQuote}
        isLoadingUpdateSupplier={isLoadingUpdateSupplier}
      />
      <ConfirmationModal
        showConfirmationModal={showDeleteQuoteModal}
        showNoBtn={true}
        processing={isLoadingDeleteQuoteProposal}
        onOkay={deleteProposal}
        hideConfirmationModal={closeQuoteDeleteModal}
        btnText="Yes, Delete"
        heading="Quote & Proposal Delete Confirmation"
        msgContent="Deleting 'As-Collected' is permanent. Continue with delete?"
        msgContent2="Note: If deleting the final Quote & proposal from the list, you will be redirected to the metrics screen. Click 'Generate Quote' to start over."
      />
    </div>
  );
}
