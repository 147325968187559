import { getLastElement } from "utils/utils";
import { proposalTypes } from "./proposals.types";

const INITIAL_STATE = {
  isLoadingProposal: false,
  data: {},
  errors: "", // General error
  currentAddedSection: {},

  isLoadingGenerate: false,
  generateError: {}, // Error related to adding
  generateResponse: {},

  isLoadingEdit: false,
  editError: {}, // Error related to editing
  editResponse: {},

  isLoadingDelete: false,
  deleteError: {}, // Error related to deletion
  deleteResponse: "", // Initialized as an empty string

  selectedProposal: "",

  isLoadingUpdateMaterialSupplier: false,
  updateProposalError: {}, // Error related to deletion
  updateProposalResponse: "", // Initialized as an empty string

  isLoadingUpdateLabourSupplier: false,
  updateProposalError: {}, // Error related to deletion
  updateProposalResponse: "", // Initialized as an empty string

  isLoadingUpdateGutterSupplier: false,
  updateProposalError: {}, // Error related to deletion
  updateProposalResponse: "", // Initialized as an empty string

  isLoadingUpdateCartingSupplier: false,
  updateCartingsupplierError: {}, // Error related to deletion
  updateCartingsupplierResponse: "", // Initialized as an empty string

  isLoadingAddMiscMaterial: false,
  miscMaterialAddError: {}, // Error related to adding
  miscMaterialAddResponse: {},

  isLoadingSeries: false,
  seriesError: {}, // Error related to deletion
  seriesResponse: "", // Initialized as an empty string

  isLoadingUpdateInfoProposal: false,
  updateInfoProposalError: {}, // Error related to deletion
  updateProposalInfoResponse: "", // Initialized as an empty string

  isLoadingUpdateProposalTax: false,
  updateProposalTaxError: {}, // Error related to deletion
  updateProposalTaxResponse: "", // Initialized as an empty string

  isLoadingUpdateCartingSupplier: false,
  updateCartingSupplierError: {}, // Error related to deletion
  updateCartingSupplierResponse: "", // Initialized as an empty string

  // Suppliers
  isLoadingCartingSuppliers: false,
  cartingSuppliersError: {}, // Error related to adding
  cartingSuppliers: {},

  isLoadingMaterialSuppliers: false,
  materialSuppliersError: {}, // Error related to adding
  materialSuppliers: {},

  isLoadingLabourSuppliers: false,
  labourSuppliersError: {}, // Error related to adding
  labourSuppliers: {},

  isLoadingGutterSuppliers: false,
  gutterSuppliersError: {}, // Error related to adding
  gutterSuppliers: {},

  isLoadingCartingTypes: false,
  cartingTypesError: {}, // Error related to adding
  cartingTypes: {},

  isLoadingDeleteCarting: false,
  cartingDeleteError: {}, // Error related to adding
  cartingDeleteResponse: {},

  isLoadingAddCarting: false,
  cartingAddError: {}, // Error related to adding
  cartingAddResponse: {},

  isLoadingUpdateCarting: false,
  cartingUpdateError: {}, // Error related to Updateing
  cartingUpdateResponse: {},

  isLoadingAddLabourMisc: false,
  addLabourMiscError: {}, // Error related to adding
  addLabourMiscResponse: {},

  isLoadingUpdateLabourQuote: false,
  updateLabourQuoteError: {}, // Error related to adding
  updateLabourQuoteResponse: {},

  isLoadingUpdateGutterQuote: false,
  updateGutterQuoteError: {}, // Error related to adding
  updateGutterQuoteResponse: {},

  isLoadingAddGutterMisc: false,
  addGutterMiscError: {}, // Error related to adding
  addGutterMiscResponse: {},

  // Delete material misc
  isLoadingDeleteMaterialMisc: false,
  deleteMaterialMiscError: {}, // Error related to adding
  deleteMaterialMiscResponse: {},

  // Delete labour misc
  isLoadingDeleteLabourMisc: false,
  deleteLabourMiscError: {}, // Error related to adding
  deleteLabourMiscResponse: {},

  // Delete gutter misc
  isLoadingDeleteGutterMisc: false,
  deleteGutterMiscError: {}, // Error related to adding
  deleteGutterMiscResponse: {},

  isLoadingQuoteMarkup: false,
  quoteMarkupError: {}, // Error related to deletion
  quoteMarkupResponse: "", // Initialized as an empty string
};

export const ProposalV2 = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case proposalTypes.GENERATE_PROPOSAL_START:
      return {
        ...state,
        isLoadingGenerate: true,
        data: {},
        errors: "", // Reset general errors when loading starts
      };

    case proposalTypes.GENERATE_PROPOSAL__SUCCESS: {
      return {
        ...state,
        isLoadingGenerate: false,
        errors: null, // Clear general errors
        addError: null, // Clear add errors
        editError: null, // Clear edit errors
        deleteError: null, // Clear delete errors
        deleteResponse: "", // Reset delete response
        generateError: null,
        data: action.payload,
      };
    }

    case proposalTypes.GENERATE_PROPOSAL__FAILURE:
      return {
        ...state,
        isLoadingGenerate: false,
        generateError: action.payload, // Set general error on failure
      };

    case proposalTypes.REGENERATE_PROPOSAL_START:
      return {
        ...state,
        isLoadingGenerate: true,
        data: {},
        errors: "", // Reset general errors when loading starts
      };

    case proposalTypes.REGENERATE_PROPOSAL__SUCCESS: {
      return {
        ...state,
        isLoadingGenerate: false,
        errors: null, // Clear general errors
        addError: null, // Clear add errors
        editError: null, // Clear edit errors
        deleteError: null, // Clear delete errors
        deleteResponse: "", // Reset delete response
        generateResponse: action.payload,
        generateError: null,
      };
    }

    case proposalTypes.REGENERATE_PROPOSAL__FAILURE:
      return {
        ...state,
        isLoadingGenerate: false,
        generateError: action.payload, // Set general error on failure
      };

    case proposalTypes.PROPOSAL_GET_START:
      return {
        ...state,
        isLoadingProposal: true,
        data: {},
        errors: "", // Reset general errors when loading starts
      };

    case proposalTypes.PROPOSAL_GET_SUCCESS: {
      console.log({ app: action.payload });

      let proposal = "";
      if (action.payload?.length) {
        proposal = action.payload?.[0];
      }
      return {
        ...state,
        isLoadingProposal: false,
        errors: null, // Clear general errors
        addError: null, // Clear add errors
        editError: null, // Clear edit errors
        deleteError: null, // Clear delete errors
        deleteResponse: "", // Reset delete response
        data: action.payload,
        selectedProposal: proposal,
      };
    }

    case proposalTypes.PROPOSAL_GET_FAILURE:
      return {
        ...state,
        isLoadingProposal: false,
        generateError: action.payload, // Set general error on failure
      };

    case proposalTypes.UPDATE_CURRENT_SELECTED_QUOTE: {
      return {
        ...state,
        selectedProposal: action.payload,
      };
    }

    // chnage material supplier
    case proposalTypes.UPDATE_MATERIAL_SUPPLIER_START:
      return {
        ...state,
        isLoadingUpdateMaterialSupplier: true,
        errors: "", // Reset general errors when loading starts
        updateProposalError: null,
      };

    case proposalTypes.UPDATE_MATERIAL_SUPPLIER_SUCCESS: {
      const updatedProposal = action.payload?.find(
        (proposal) => state.selectedProposal._id === proposal._id
      );

      console.log({
        proposalPayload: action.payload,
        updatedProposal,
      });

      return {
        ...state,
        isLoadingUpdateMaterialSupplier: false,
        errors: null, // Clear general errors
        addError: null, // Clear add errors
        editError: null, // Clear edit errors
        deleteError: null, // Clear delete errors
        deleteResponse: "", // Reset delete response
        generateResponse: null,
        updateProposalError: null,
        selectedProposal: updatedProposal,
      };
    }

    case proposalTypes.UPDATE_MATERIAL_SUPPLIER_FAILURE:
      return {
        ...state,
        isLoadingUpdateMaterialSupplier: false,
        updateProposalError: action.payload, // Set general error on failure
      };

    // chnage labour supplier
    case proposalTypes.UPDATE_LABOUR_SUPPLIER_START:
      return {
        ...state,
        isLoadingUpdateLabourSupplier: true,
        errors: "", // Reset general errors when loading starts
        updateProposalError: null,
      };

    case proposalTypes.UPDATE_LABOUR_SUPPLIER_SUCCESS: {
      const updatedProposal = action.payload?.find(
        (proposal) => state.selectedProposal._id === proposal._id
      );

      console.log({
        proposalPayload: action.payload,
        updatedProposal,
      });

      return {
        ...state,
        isLoadingUpdateLabourSupplier: false,
        errors: null, // Clear general errors
        addError: null, // Clear add errors
        editError: null, // Clear edit errors
        deleteError: null, // Clear delete errors
        deleteResponse: "", // Reset delete response
        generateResponse: null,
        updateProposalError: null,
        selectedProposal: updatedProposal,
      };
    }

    case proposalTypes.UPDATE_LABOUR_SUPPLIER_FAILURE:
      return {
        ...state,
        isLoadingUpdateLabourSupplier: false,
        updateProposalError: action.payload, // Set general error on failure
      };

    // chnage gutter supplier
    case proposalTypes.UPDATE_GUTTER_SUPPLIER_START:
      return {
        ...state,
        isLoadingUpdateGutterSupplier: true,
        errors: "", // Reset general errors when loading starts
        updateProposalError: null,
      };

    case proposalTypes.UPDATE_GUTTER_SUPPLIER_SUCCESS: {
      const updatedProposal = action.payload?.find(
        (proposal) => state.selectedProposal._id === proposal._id
      );

      console.log({
        proposalPayload: action.payload,
        updatedProposal,
      });

      return {
        ...state,
        isLoadingUpdateGutterSupplier: false,
        errors: null, // Clear general errors
        addError: null, // Clear add errors
        editError: null, // Clear edit errors
        deleteError: null, // Clear delete errors
        deleteResponse: "", // Reset delete response
        generateResponse: null,
        updateProposalError: null,
        selectedProposal: updatedProposal,
      };
    }

    case proposalTypes.UPDATE_GUTTER_SUPPLIER_FAILURE:
      return {
        ...state,
        isLoadingUpdateGutterSupplier: false,
        updateProposalError: action.payload, // Set general error on failure
      };

    // Series reducer
    case proposalTypes.MATERIAL_SERIES_GET_START:
      return {
        ...state,
        isLoadingSeries: true,
        seriesResponse: {},
        seriesError: "", // Reset general errors when loading starts
      };

    case proposalTypes.MATERIAL_SERIES_GET_SUCCESS: {
      console.log({ app: action.payload });
      return {
        ...state,
        isLoadingSeries: false,
        seriesResponse: action.payload,
        seriesError: "", // Reset general errors when loading starts
      };
    }

    case proposalTypes.MATERIAL_SERIES_GET_FAILURE:
      return {
        ...state,
        isLoadingSeries: false,
        seriesError: action.payload, // Set general error on failure
        seriesResponse: {},
      };

    // Update info of materials
    case proposalTypes.UPDATE_PROPOSAL_INFO_START: {
      const { typeOfOperation } = action.payload;
      const { loadingKey, errorKey } = getUpdateKeys(typeOfOperation);

      return {
        ...state,
        [loadingKey]: true,
        [errorKey]: null, // Reset the relevant error state
      };
    }

    case proposalTypes.UPDATE_PROPOSAL_INFO_SUCCESS: {
      const { responseData, typeOfOperation } = action.payload;

      const { loadingKey, responseKey } = getUpdateKeys(typeOfOperation);

      const updatedProposal = responseData?.find(
        (proposal) => state.selectedProposal?._id === proposal._id
      );

      if (!state.selectedProposal) {
        return {
          ...state,
          [loadingKey]: false,
        };
      }

      const newProposal = {
        ...state.selectedProposal,
        quotes: {
          ...state.selectedProposal.quotes,
          materials:
            updatedProposal?.quotes?.materials ||
            state.selectedProposal.quotes.materials,
        },
      };

      console.log({ sss: action.payload, newProposal });

      return {
        ...state,
        [loadingKey]: false,
        ...(responseKey && { [responseKey]: "Tax updated successfully" }),
        selectedProposal: newProposal,
      };
    }

    case proposalTypes.UPDATE_PROPOSAL_INFO_FAILURE: {
      const { error, typeOfOperation } = action.payload;
      const { loadingKey, errorKey } = getUpdateKeys(typeOfOperation);

      return {
        ...state,
        [loadingKey]: false,
        [errorKey]: error,
      };
    }

    // Get material active suppliers
    case proposalTypes.GET_MATERIAL_SUPPLIERS_START:
      return {
        ...state,
        isLoadingMaterialSuppliers: true,
        materialSuppliersError: action.payload, // Set general error on failure
        materialSuppliers: null,
      };

    case proposalTypes.GET_MATERIAL_SUPPLIERS_SUCCESS: {
      console.log({ app: action.payload });
      return {
        ...state,
        isLoadingMaterialSuppliers: false,
        materialSuppliers: action.payload,
        materialSuppliersError: "", // Reset general errors when loading starts
      };
    }

    case proposalTypes.GET_MATERIAL_SUPPLIERS_FAILURE:
      return {
        ...state,
        isLoadingMaterialSuppliers: false,
        materialSuppliersError: action.payload, // Set general error on failure
        materialSuppliers: {},
      };

    // Get gutter active suppliers
    case proposalTypes.GET_GUTTER_SUPPLIERS_START:
      return {
        ...state,
        isLoadingGutterSuppliers: true,
        gutterSuppliersError: action.payload, // Set general error on failure
        gutterSuppliers: null,
      };
    case proposalTypes.GET_GUTTER_SUPPLIERS_SUCCESS: {
      console.log({ app: action.payload });
      return {
        ...state,
        isLoadingGutterSuppliers: false,
        gutterSuppliers: action.payload,
        gutterSuppliersError: "", // Reset general errors when loading starts
      };
    }

    case proposalTypes.GET_GUTTER_SUPPLIERS_FAILURE:
      return {
        ...state,
        isLoadingGutterSuppliers: false,
        gutterSuppliersError: action.payload, // Set general error on failure
        gutterSuppliers: {},
      };

    // Get labour active suppliers
    case proposalTypes.GET_LABOUR_SUPPLIERS_START:
      return {
        ...state,
        isLoadingLabourSuppliers: true,
        labourSuppliersError: action.payload, // Set general error on failure
        labourSuppliers: null,
      };

    case proposalTypes.GET_LABOUR_SUPPLIERS_SUCCESS: {
      console.log({ app: action.payload });
      return {
        ...state,
        isLoadingLabourSuppliers: false,
        labourSuppliers: action.payload,
        labourSuppliersError: "", // Reset general errors when loading starts
      };
    }

    case proposalTypes.GET_LABOUR_SUPPLIERS_FAILURE:
      return {
        ...state,
        isLoadingLabourSuppliers: false,
        labourSuppliersError: action.payload, // Set general error on failure
        labourSuppliers: {},
      };

    // Add material misc quotes
    case proposalTypes.ADD_MATERIAL_MISC_START:
      return {
        ...state,
        isLoadingAddMiscMaterial: true,
        miscMaterialAddError: action.payload, // Set general error on failure
        cartingAddResponse: null,
      };
    case proposalTypes.ADD_MATERIAL_MISC_SUCCESS: {
      const updatedProposal = action.payload?.find(
        (proposal) => state.selectedProposal?._id === proposal._id
      );

      console.log({
        updatedProposal,
      });

      if (!state.selectedProposal) {
        // Handle case where there's no selectedProposal, if applicable
        return state;
      }

      const newCartingQuote = {
        ...state.selectedProposal,
        quotes: {
          ...state.selectedProposal.quotes,
          materials:
            updatedProposal?.quotes?.materials ||
            state.selectedProposal.quotes.materials, // Fallback to existing dumpsters
        },
      };

      return {
        ...state,
        isLoadingAddMiscMaterial: false,
        selectedProposal: newCartingQuote,
        miscMaterialAddError: null,
      };
    }
    case proposalTypes.ADD_MATERIAL_MISC_FAILURE:
      return {
        ...state,
        isLoadingAddMiscMaterial: false,
        miscMaterialAddError: action.payload, // Set general error on failure
      };

    // Update carting supplier
    case proposalTypes.UPDATE_CARTING_SUPPLIER_START:
      return {
        ...state,
        isLoadingUpdateCartingSupplier: true,
        updateCartingSupplierError: null,
      };

    case proposalTypes.UPDATE_CARTING_SUPPLIER_SUCCESS: {
      const updatedProposal = action.payload?.find(
        (proposal) => state.selectedProposal._id === proposal._id
      );

      console.log({
        proposalPayload: action.payload,
        updatedProposal,
      });
      return {
        ...state,
        isLoadingUpdateCartingSupplier: false,
        updateCartingSupplierError: {},
        selectedProposal: updatedProposal,
      };
    }
    case proposalTypes.UPDATE_CARTING_SUPPLIER_FAILURE:
      return {
        ...state,
        isLoadingUpdateCartingSupplier: false,
        updateCartingSupplierError: action.payload, // Set general error on failure
      };

    // Get carting active suppliers
    case proposalTypes.GET_CARTING_SUPPLIERS_START:
      return {
        ...state,
        isLoadingCartingSuppliers: true,
        cartingSuppliersError: action.payload, // Set general error on failure
        cartingSuppliers: null,
      };

    case proposalTypes.GET_CARTING_SUPPLIERS_SUCCESS: {
      console.log({ app: action.payload });
      return {
        ...state,
        isLoadingCartingSuppliers: false,
        cartingSuppliers: action.payload,
        cartingSuppliersError: "", // Reset general errors when loading starts
      };
    }

    case proposalTypes.GET_CARTING_SUPPLIERS_FAILURE:
      return {
        ...state,
        isLoadingCartingSuppliers: false,
        cartingSuppliersError: action.payload, // Set general error on failure
        cartingSuppliers: {},
      };

    // Get carting types
    case proposalTypes.GET_CARTING_TYPES_START:
      return {
        ...state,
        isLoadingCartingTypes: true,
        cartingTypesError: action.payload, // Set general error on failure
        cartingTypes: null,
      };

    case proposalTypes.GET_CARTING_TYPES_SUCCESS: {
      console.log({ app: action.payload });
      return {
        ...state,
        isLoadingCartingTypes: false,
        cartingTypes: action.payload,
        cartingTypesError: "", // Reset general errors when loading starts
      };
    }

    case proposalTypes.GET_CARTING_TYPES_FAILURE:
      return {
        ...state,
        isLoadingCartingTypes: false,
        cartingTypesError: action.payload, // Set general error on failure
        cartingTypes: {},
      };

    // Delete carting
    case proposalTypes.DELETE_CARTING_START:
      return {
        ...state,
        isLoadingDeleteCarting: true,
        cartingDeleteError: action.payload, // Set general error on failure
        cartingDeleteResponse: null,
      };
    case proposalTypes.DELETE_CARTING_SUCCESS: {
      const updatedProposal = action.payload?.find(
        (proposal) => state.selectedProposal?._id === proposal._id
      );

      if (!state.selectedProposal) {
        // Handle case where there's no selectedProposal, if applicable
        return state;
      }

      const newCartingQuote = {
        ...state.selectedProposal,
        quotes: {
          ...state.selectedProposal.quotes,
          dumpsters:
            updatedProposal?.quotes?.dumpsters ||
            state.selectedProposal.quotes.dumpsters, // Fallback to existing dumpsters
        },
      };

      console.log({
        proposalPayload: action.payload,
        updatedProposal,
      });

      return {
        ...state,
        isLoadingDeleteCarting: false,
        cartingDeleteError: null,
        selectedProposal: newCartingQuote,
      };
    }

    case proposalTypes.DELETE_CARTING_FAILURE:
      return {
        ...state,
        isLoadingDeleteCarting: false,
        cartingDeleteError: action.payload, // Set general error on failure
      };

    // Add carting
    case proposalTypes.ADD_CARTING_START:
      return {
        ...state,
        isLoadingAddCarting: true,
        cartingAddError: action.payload, // Set general error on failure
        cartingAddResponse: null,
      };
    case proposalTypes.ADD_CARTING_SUCCESS: {
      const updatedProposal = action.payload?.find(
        (proposal) => state.selectedProposal?._id === proposal._id
      );

      console.log({
        updatedProposal,
      });

      if (!state.selectedProposal) {
        // Handle case where there's no selectedProposal, if applicable
        return state;
      }

      const newCartingQuote = {
        ...state.selectedProposal,
        quotes: {
          ...state.selectedProposal.quotes,
          dumpsters:
            updatedProposal?.quotes?.dumpsters ||
            state.selectedProposal.quotes.dumpsters, // Fallback to existing dumpsters
        },
      };

      return {
        ...state,
        isLoadingAddCarting: false,
        selectedProposal: newCartingQuote,
        cartingAddError: null,
      };
    }
    case proposalTypes.ADD_CARTING_FAILURE:
      return {
        ...state,
        isLoadingAddCarting: false,
        cartingAddError: action.payload, // Set general error on failure
      };

    // Update carting
    case proposalTypes.UPDATE_CARTING_START:
      return {
        ...state,
        isLoadingUpdateCarting: true,
        cartingUpdateError: action.payload, // Set general error on failure
        cartingAddResponse: null,
      };
    case proposalTypes.UPDATE_CARTING_SUCCESS: {
      const updatedProposal = action.payload?.find(
        (proposal) => state.selectedProposal?._id === proposal._id
      );

      console.log({
        updatedProposal,
      });

      if (!state.selectedProposal) {
        // Handle case where there's no selectedProposal, if applicable
        return state;
      }

      const newCartingQuote = {
        ...state.selectedProposal,
        quotes: {
          ...state.selectedProposal.quotes,
          dumpsters:
            updatedProposal?.quotes?.dumpsters ||
            state.selectedProposal.quotes.dumpsters, // Fallback to existing dumpsters
        },
      };

      return {
        ...state,
        isLoadingUpdateCarting: false,
        selectedProposal: newCartingQuote,
        cartingUpdateError: null,
      };
    }

    case proposalTypes.UPDATE_CARTING_FAILURE:
      return {
        ...state,
        isLoadingUpdateCarting: false,
        cartingUpdateError: action.payload, // Set general error on failure
      };

    // Add labour misc
    case proposalTypes.ADD_LABOUR_MISC_START:
      return {
        ...state,
        isLoadingAddLabourMisc: true,
        addLabourMiscError: action.payload, // Set general error on failure
        cartingAddResponse: null,
      };
    case proposalTypes.ADD_LABOUR_MISC_SUCCESS: {
      const updatedProposal = action.payload?.find(
        (proposal) => state.selectedProposal?._id === proposal._id
      );

      console.log({
        updatedProposal,
      });

      if (!state.selectedProposal) {
        // Handle case where there's no selectedProposal, if applicable
        return state;
      }

      const newCartingQuote = {
        ...state.selectedProposal,
        quotes: {
          ...state.selectedProposal.quotes,
          labours:
            updatedProposal?.quotes?.labours ||
            state.selectedProposal.quotes.labours, // Fallback to existing dumpsters
        },
      };
      return {
        ...state,
        isLoadingAddLabourMisc: false,
        selectedProposal: newCartingQuote,
        addLabourMiscError: null,
      };
    }
    case proposalTypes.ADD_LABOUR_MISC_FAILURE:
      return {
        ...state,
        isLoadingAddLabourMisc: false,
        addLabourMiscError: action.payload, // Set general error on failure
      };

    // Update labour quote
    case proposalTypes.UPDATE_LABOUR_START:
      return {
        ...state,
        isLoadingUpdateLabourQuote: true,
        updateLabourQuoteError: null,
      };

    case proposalTypes.UPDATE_LABOUR_SUCCESS: {
      const updatedProposal = action.payload?.find(
        (proposal) => state.selectedProposal?._id === proposal._id
      );

      console.log({
        updatedProposal,
      });

      if (!state.selectedProposal) {
        // Handle case where there's no selectedProposal, if applicable
        return state;
      }

      const newMateriaQuote = {
        ...state.selectedProposal,
        quotes: {
          ...state.selectedProposal.quotes,
          labours:
            updatedProposal?.quotes?.labours ||
            state.selectedProposal.quotes.labours, // Fallback to existing dumpsters
        },
      };

      return {
        ...state,
        isLoadingUpdateLabourQuote: false,
        updateLabourQuoteError: null,
        selectedProposal: newMateriaQuote,
      };
    }

    case proposalTypes.UPDATE_LABOUR_FAILURE:
      return {
        ...state,
        isLoadingUpdateLabourQuote: false,
        updateLabourQuoteError: action.payload, // Set general error on failure
      };

    // Add gutter misc
    case proposalTypes.ADD_GUTTER_MISC_START:
      return {
        ...state,
        isLoadingAddGutterMisc: true,
        addGutterMiscError: action.payload, // Set general error on failure
        cartingAddResponse: null,
      };
    case proposalTypes.ADD_GUTTER_MISC_SUCCESS: {
      const updatedProposal = action.payload?.find(
        (proposal) => state.selectedProposal?._id === proposal._id
      );

      console.log({
        updatedProposal,
      });

      if (!state.selectedProposal) {
        // Handle case where there's no selectedProposal, if applicable
        return state;
      }

      const newCartingQuote = {
        ...state.selectedProposal,
        quotes: {
          ...state.selectedProposal.quotes,
          gutters:
            updatedProposal?.quotes?.gutters ||
            state.selectedProposal.quotes.gutters, // Fallback to existing dumpsters
        },
      };
      return {
        ...state,
        isLoadingAddGutterMisc: false,
        selectedProposal: newCartingQuote,
        addGutterMiscError: null,
      };
    }
    case proposalTypes.ADD_GUTTER_MISC_FAILURE:
      return {
        ...state,
        isLoadingAddGutterMisc: false,
        addGutterMiscError: action.payload, // Set general error on failure
      };

    // Update gutter quote
    case proposalTypes.UPDATE_GUTTER_START:
      return {
        ...state,
        updateGutterQuoteError: {},
        isLoadingUpdateGutterQuote: true,
      };

    case proposalTypes.UPDATE_GUTTER_SUCCESS: {
      const updatedProposal = action.payload?.find(
        (proposal) => state.selectedProposal?._id === proposal._id
      );

      console.log({
        updatedProposal,
      });

      if (!state.selectedProposal) {
        // Handle case where there's no selectedProposal, if applicable
        return state;
      }

      const newMateriaQuote = {
        ...state.selectedProposal,
        quotes: {
          ...state.selectedProposal.quotes,
          gutters:
            updatedProposal?.quotes?.gutters ||
            state.selectedProposal.quotes.gutters, // Fallback to existing dumpsters
        },
      };

      return {
        ...state,
        updateGutterQuoteError: {},
        isLoadingUpdateGutterQuote: false,
        selectedProposal: newMateriaQuote,
      };
    }

    case proposalTypes.UPDATE_GUTTER_FAILURE:
      return {
        ...state,
        updateGutterQuoteError: action.payload,
        isLoadingUpdateGutterQuote: false, // Set general error on failure
      };

    // Delete material misc
    case proposalTypes.DELETE_MATERIAL_MISC_START:
      return {
        ...state,
        isLoadingDeleteMaterialMisc: true,
        deleteMaterialMiscError: action.payload, // Set general error on failure
        deleteMaterialMiscResponse: null,
      };
    case proposalTypes.DELETE_MATERIAL_MISC_SUCCESS: {
      const updatedProposal = action.payload?.find(
        (proposal) => state.selectedProposal?._id === proposal._id
      );

      if (!state.selectedProposal) {
        // Handle case where there's no selectedProposal, if applicable
        return state;
      }

      const newCartingQuote = {
        ...state.selectedProposal,
        quotes: {
          ...state.selectedProposal.quotes,
          materials:
            updatedProposal?.quotes?.materials ||
            state.selectedProposal.quotes.materials, // Fallback to existing dumpsters
        },
      };

      return {
        ...state,
        isLoadingDeleteMaterialMisc: false,
        deleteMaterialMiscError: null,
        selectedProposal: newCartingQuote,
      };
    }

    case proposalTypes.DELETE_MATERIAL_MISC_FAILURE:
      return {
        ...state,
        isLoadingDeleteMaterialMisc: false,
        deleteMaterialMiscError: action.payload, // Set general error on failure
      };

    // Delete labour misc
    case proposalTypes.DELETE_MATERIAL_MISC_SUCCESS:
      return {
        ...state,
        isLoadingDeleteLabourMisc: true,
        deleteLabourMiscError: action.payload, // Set general error on failure
        deleteLabourMiscResponse: null,
      };
    case proposalTypes.DELETE_LABOUR_MISC_SUCCESS: {
      const updatedProposal = action.payload?.find(
        (proposal) => state.selectedProposal?._id === proposal._id
      );

      if (!state.selectedProposal) {
        // Handle case where there's no selectedProposal, if applicable
        return state;
      }

      const newCartingQuote = {
        ...state.selectedProposal,
        quotes: {
          ...state.selectedProposal.quotes,
          labours:
            updatedProposal?.quotes?.labours ||
            state.selectedProposal.quotes.labours, // Fallback to existing dumpsters
        },
      };

      console.log({
        proposalPayload: action.payload,
        updatedProposal,
      });

      return {
        ...state,
        isLoadingDeleteLabourMisc: false,
        deleteLabourMiscError: null,
        selectedProposal: newCartingQuote,
      };
    }

    case proposalTypes.DELETE_MATERIAL_MISC_FAILURE:
      return {
        ...state,
        isLoadingDeleteLabourMisc: false,
        deleteLabourMiscError: action.payload, // Set general error on failure
      };

    // Delete gutter misc
    case proposalTypes.DELETE_GUTTER_MISC_START:
      return {
        ...state,
        isLoadingDeleteGutterMisc: true,
        deleteGutterMiscError: action.payload, // Set general error on failure
        deleteGutterMiscResponse: null,
      };
    case proposalTypes.DELETE_GUTTER_MISC_SUCCESS: {
      const updatedProposal = action.payload?.find(
        (proposal) => state.selectedProposal?._id === proposal._id
      );

      if (!state.selectedProposal) {
        // Handle case where there's no selectedProposal, if applicable
        return state;
      }

      const newCartingQuote = {
        ...state.selectedProposal,
        quotes: {
          ...state.selectedProposal.quotes,
          gutters:
            updatedProposal?.quotes?.gutters ||
            state.selectedProposal.quotes.gutters, // Fallback to existing dumpsters
        },
      };

      console.log({
        proposalPayload: action.payload,
        updatedProposal,
      });

      return {
        ...state,
        isLoadingDeleteGutterMisc: false,
        deleteGutterMiscError: null,
        selectedProposal: newCartingQuote,
      };
    }

    case proposalTypes.DELETE_GUTTER_MISC_FAILURE:
      return {
        ...state,
        isLoadingDeleteGutterMisc: false,
        deleteGutterMiscError: action.payload, // Set general error on failure
      };

    // Get quote material
    case proposalTypes.GET_QUOTE_MARKUP_START:
      return {
        ...state,
        isLoadingQuoteMarkup: true,
        quoteMarkupResponse: {},
        quoteMarkupError: "", // Reset general errors when loading starts
      };

    case proposalTypes.GET_QUOTE_MARKUP_SUCCESS: {
      console.log({ app: action.payload });
      return {
        ...state,
        isLoadingQuoteMarkup: false,
        quoteMarkupResponse: action.payload,
        quoteMarkupError: "", // Reset general errors when loading starts
      };
    }

    case proposalTypes.GET_QUOTE_MARKUP_FAILURE:
      return {
        ...state,
        isLoadingQuoteMarkup: false,
        quoteMarkupError: action.payload, // Set general error on failure
        quoteMarkupResponse: {},
      };

    default:
      return state;
  }
};

const getUpdateKeys = (typeOfOperation) => {
  if (typeOfOperation === "taxUpdate") {
    return {
      loadingKey: "isLoadingUpdateProposalTax",
      errorKey: "updateProposalTaxError",
      responseKey: "updateProposalTaxResponse",
    };
  }
  return {
    loadingKey: "isLoadingUpdateInfoProposal",
    errorKey: "updateInfoProposalError",
    responseKey: null, // No response key for general updates
  };
};
