import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import GuttersOptionsTabModal from "./OptionModal/GuttersOptionsTabModal";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import GutterDisplayBtnsTab from "./GutterDisplayBtnsTab";
import { HideModalGutter } from "features/metric/redux/gutter-metric-collection/gutter-metric.action";

const GuttersMetricTab = ({ sectionsData, requestId }) => {
  const [activeTab, setActiveTab] = useState("guttersAndLeaders");
  const dispatch = useDispatch();

  const {
    modalData: { showModal = false, data },
  } = useSelector((state) => state.gutterMetric);

  const gutterMetric = useSelector((state) => state.gutterMetric);

  console.log({
    sectionsData,
  });
  const metric = useSelector((state) => state.modalData);

  console.log({ metric });

  const renderTabDimenData = (currentTab) => {
    return GutterDisplayBtnsTab({ btns: currentTab.subTabs });
  };

  const hideModal = () => {
    dispatch(HideModalGutter());
  };

  return (
    <>
      <Tabs
        id="controlled-tab-example"
        activeKey={activeTab}
        onSelect={(key) => {
          console.log({ key });
          setActiveTab(key);
        }}
        style={{ marginBottom: "3%" }}
      >
        {gutterMetric?.metricOptions?.map((currentTab) => (
          <Tab
            key={currentTab.key}
            eventKey={currentTab.key}
            title={currentTab.name}
            tabClassName="tabposition"
          >
            {renderTabDimenData(currentTab)}
          </Tab>
        ))}
      </Tabs>

      <GuttersOptionsTabModal
        showTabModal={showModal}
        onHide={hideModal}
        section={sectionsData?.length + 1}
        modalInfo={data}
        requestId={requestId}
      />
    </>
  );
};

GuttersMetricTab.propTypes = {
  sectionsData: PropTypes.array.isRequired,
};

export default GuttersMetricTab;
