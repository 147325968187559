import React, { useState } from "react";
import PropTypes from "prop-types"; // Import PropTypes
import { Form } from "react-bootstrap";
import PopupModal, { ModalFooter } from "components/popup-modal/popup-modal";

export default function NewQuoteModal({
  show,
  onHide,
  onSaveProposal,
  handleChange,
  name,
  disableSave = false,
}) {
  const [error, setError] = useState("");

  const validateName = () => {
    if (!name.trim()) {
      setError("Name is required.");
      return false;
    }
    if (name.length < 3) {
      setError("Name must be at least 3 characters long.");
      return false;
    }
    if (name.length > 50) {
      setError("Name must not exceed 50 characters.");
      return false;
    }
    setError("");
    return true;
  };

  const handleSave = () => {
    if (validateName()) {
      onSaveProposal();
    }
  };

  return (
    <PopupModal
      show={show}
      heading="Alternate Quote & Proposal"
      onHide={onHide}
      bold
      centered={false}
      styles={{ border: "none" }}
    >
      <div style={{ padding: "0 1em 1em 1em" }}>
        <Form.Group>
          <Form.Label>Name:</Form.Label>
          <Form.Control
            type="text"
            name="name"
            value={name}
            onChange={(e) => {
              handleChange(e);
              setError(""); // Clear error on input change
            }}
            isInvalid={!!error}
          />
          <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
        </Form.Group>
        <i>{"Examples: 'House Only' or 'Garage Only'"}</i>
      </div>

      <ModalFooter
        onCancel={onHide}
        onSubmit={handleSave}
        submitDisabled={disableSave || !name.trim()} // Disable if name is empty
        submitText="Save"
      />
    </PopupModal>
  );
}

// Prop validations using PropTypes
NewQuoteModal.propTypes = {
  show: PropTypes.bool.isRequired, // Required boolean
  onHide: PropTypes.func.isRequired, // Required function
  onSaveProposal: PropTypes.func.isRequired, // Required function
  handleChange: PropTypes.func.isRequired, // Required function
  name: PropTypes.string.isRequired, // Required string
  disableSave: PropTypes.bool, // Optional boolean with default value
};
