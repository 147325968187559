import { ApiEndpoints } from "lib/config/baseSettings";
import { axiosInstance } from "services";

export const generateProposalApi = async (data) => {
  try {
    const URL = `${ApiEndpoints.PROPOSALS.GENERATE}`;
    const response = await axiosInstance.post(URL, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const reGenerateProposalApi = async (data) => {
  try {
    const URL = `${ApiEndpoints.PROPOSALS.REGENERATE}`;
    const response = await axiosInstance.post(URL, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteProposalApi = async (data) => {
  try {
    const params = new URLSearchParams();
    params.set("requestId", data.requestId);
    params.set("proposalId", data.proposalId);
    const URL = `${ApiEndpoints.PROPOSALS.DELETE}?${params}`;
    const response = await axiosInstance.delete(URL, data);
    console.log({
      response,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getProposalsApi = async (data) => {
  try {
    const params = new URLSearchParams();
    params.set("requestId", data.requestId);

    const URL = `${ApiEndpoints.PROPOSALS.GET_PROPOSAL}?${params}`;

    const response = await axiosInstance.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getSeriesTypesApi = async (data) => {
  try {
    const params = new URLSearchParams();
    params.set("category", data.category);
    params.set("manufacture", data.manufacturer);
    params.set("categoryType", data.categoryType);
    params.set("supplierId", data.supplierId);

    const URL = `${ApiEndpoints.PROPOSALS.GET_SERIES}?${params}`;

    const response = await axiosInstance.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const changeMaterialSupplier = async (data) => {
  try {
    const URL = `${ApiEndpoints.PROPOSALS.CHANGE_MATERIAL_SUPPLIER_PROPOSAL}`;

    const response = await axiosInstance.patch(URL, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const changeGutterSupplierApi = async (data) => {
  try {
    const URL = `${ApiEndpoints.PROPOSALS.CHANGE_GUTTER_SUPPLIER_PROPOSAL}`;

    const response = await axiosInstance.patch(URL, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const changeLabourSupplier = async (data) => {
  try {
    const URL = `${ApiEndpoints.PROPOSALS.CHANGE_LABOUR_SUPPLIER_PROPOSAL}`;

    const response = await axiosInstance.patch(URL, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateProposalInfo = async (data) => {
  try {
    const URL = `${ApiEndpoints.PROPOSALS.UPDATE_PROPOSAL_INFO}`;

    const response = await axiosInstance.patch(URL, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getMaterialActiveSuppliers = async (companyId) => {
  try {
    console.log({
      companyId123: companyId,
    });

    const params = new URLSearchParams();
    params.set("companyId", companyId);

    const URL = `${ApiEndpoints.PROPOSALS.GET_ACTIVE_MATERIAL_SUPPLIERS}?${params}`;

    const response = await axiosInstance.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getActiveLabourSuppliers = async (companyId) => {
  try {
    console.log({
      companyId123: companyId,
    });

    const params = new URLSearchParams();
    params.set("companyId", companyId);

    const URL = `${ApiEndpoints.PROPOSALS.GET_ACTIVE_LABOUR_SUPPLIERS}?${params}`;

    const response = await axiosInstance.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getActiveGutterSuppliers = async (companyId) => {
  try {
    console.log({
      companyId123: companyId,
    });

    const params = new URLSearchParams();
    params.set("companyId", companyId);

    const URL = `${ApiEndpoints.PROPOSALS.GET_ACTIVE_LABOUR_GUTTER_SUPPLIERS}?${params}`;

    const response = await axiosInstance.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getActiveCartingSuppliers = async (companyId) => {
  try {
    console.log({
      companyId123: companyId,
    });

    const params = new URLSearchParams();
    params.set("companyId", companyId);

    const URL = `${ApiEndpoints.PROPOSALS.GET_ACTIVE_CARTING_SUPPLIERS}?${params}`;

    const response = await axiosInstance.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getCartingTypes = async (supplierId) => {
  try {
    console.log({
      supplierId123: supplierId,
    });

    const params = new URLSearchParams();
    params.set("supplierId", supplierId);

    const URL = `${ApiEndpoints.PROPOSALS.GET_CARTING_TYPES}?${params}`;

    const response = await axiosInstance.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateCartingSupplierProposal = async (data) => {
  try {
    const URL = `${ApiEndpoints.PROPOSALS.UPDATE_SUPPLIER_CARTING}`;

    const response = await axiosInstance.patch(URL, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteCarting = async (data) => {
  try {
    const URL = `${ApiEndpoints.PROPOSALS.DELETE_SUPPLIER_CARTING}`;

    const response = await axiosInstance.delete(URL, { data });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const addCarting = async (data) => {
  try {
    const URL = `${ApiEndpoints.PROPOSALS.ADD_NEW_CARTING_QUOTE_LINE}`;

    const response = await axiosInstance.post(URL, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateCarting = async (data) => {
  try {
    const URL = `${ApiEndpoints.PROPOSALS.UPDATE_CARTING_QUOTE}`;

    const response = await axiosInstance.patch(URL, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const addMaterialMisc = async (data) => {
  try {
    const URL = `${ApiEndpoints.PROPOSALS.ADD_MISC_MATERIAL}`;

    const response = await axiosInstance.post(URL, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const addLabourMisc = async (data) => {
  try {
    const URL = `${ApiEndpoints.PROPOSALS.ADD_MISC_LABOUR}`;

    const response = await axiosInstance.post(URL, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateLabourQuote = async (data) => {
  try {
    const URL = `${ApiEndpoints.PROPOSALS.UPDATE_LABOUR_QUOTE}`;

    const response = await axiosInstance.patch(URL, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const addGutterMisc = async (data) => {
  try {
    const URL = `${ApiEndpoints.PROPOSALS.ADD_MISC_GUTTER}`;

    const response = await axiosInstance.post(URL, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateGutterQuote = async (data) => {
  try {
    const URL = `${ApiEndpoints.PROPOSALS.UPDATE_GUTTER_QUOTE}`;

    const response = await axiosInstance.patch(URL, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// delete misc quote
export const deleteLabourMiscQuote = async (data) => {
  try {
    const URL = `${ApiEndpoints.PROPOSALS.DELETE_MISC_LABOUR_QUOTE}`;

    const response = await axiosInstance.delete(URL, { data });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteMaterialMiscQuote = async (data) => {
  try {
    const URL = `${ApiEndpoints.PROPOSALS.DELETE_MISC_MATERIAL_QUOTE}`;

    const response = await axiosInstance.delete(URL, { data });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteGutterMiscQuote = async (data) => {
  try {
    const URL = `${ApiEndpoints.PROPOSALS.DELETE_MISC_GUTTER_QUOTE}`;

    const response = await axiosInstance.delete(URL, { data });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getQuoteMarkup = async () => {
  try {
    const URL = `${ApiEndpoints.PROPOSALS.QUOTE_MARKUP}`;
    const response = await axiosInstance.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};
