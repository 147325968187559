import React from "react";

import { Row, Col, Button, Form, Modal, Alert } from "react-bootstrap";
import "./calendar.scss";
import PopupModal from "components/popup-modal/popup-modal";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import {
  convertDate,
  displayPersons,
  parseDateWithoutTime,
  sliceHoursAndMin,
} from "utils/utils";

import {
  CalendarPersons,
  SalesPersons,
  RepairPersons,
  CalendarRequests,
  CalendarRequestsRepair,
  CalendarRequestsSales,
  salesEvents,
  repairEvents,
  CalendarEvents,
} from "lib/redux/calendar/calendar.selector";
import { connect } from "react-redux";
import {
  deleteEvent,
  clearAvailablePersons,
  addToCalendar,
  fetchForCalendar,
  fetchAvailabelPersons,
  fetchEvents,
} from "lib/redux/calendar/calendar.actions";
import { removeTime } from "utils/dateTimeUtils";

export const AddModal = ({ ...props }) => {
  const {
    addModal,
    edit,
    date,
    address,
    type,
    msg,
    msgFor,
    start,
    isAllDay,
    requestFor,
    sRep,
    isunScheduling,
    end,
    isSubmitting,
    isLoadingAvPersons,
    personsData,
    displayBtns,
    active,
    salesPointerEvent,
    timing,
    setIsAllDay,
    setStart,
    setEnd,
    setDisplayBtns,
    setSRep,
    request,
    clearAllFields,
    setAddModal,
    setEdit,
    repairPointerEvent,
  } = props;

  const handleCheck = (event) => {
    const { checked } = event.target;
    setIsAllDay(checked);
  };

  const handleSaveAppointment = () => {
    // const { date, request, start, end, isAllDay, sRep, active } = this.state;

    const { requestId } = request;
    let startTime = "",
      endTime = "";
    if (!isAllDay) {
      const sTime = sliceHoursAndMin(start);
      const eTime = sliceHoursAndMin(end);
      startTime = `${sTime.hour}:${sTime.min}`;
      endTime = `${eTime.hour}:${eTime.min}`;
    }
    let persons = [];
    let names = "";
    if (sRep) {
      sRep.map((rep) => {
        persons.push(rep.value);
        return (names = `${names ? `${names},` : ""} ${rep.label}`);
      });
    }

    console.log({
      saveddate: date,
      dd: new Date(date),
    });
    const newEvent = {
      isRepair: active === "Sales" ? false : true,
      leadRequestId: requestId,
      startTime: startTime,
      endTime: endTime,
      allDay: isAllDay,
      date: removeTime(new Date(date)).toISOString(),
      persons: persons,
    };

    props.addToCalendar({ ...newEvent });
  };

  const checkPersons = () => {
    // const { date, start, end, isAllDay, active } = this.state;
    let startTime = "",
      endTime = "";
    if (!isAllDay) {
      const sTime = sliceHoursAndMin(start);
      const eTime = sliceHoursAndMin(end);
      startTime = `${sTime.hour}:${sTime.min}`;
      endTime = `${eTime.hour}:${eTime.min}`;
    }
    props
      .fetchAvailabelPersons({
        isRepair: active === "Sales" ? false : true,
        startTime: startTime,
        endTime: endTime,
        allDay: isAllDay,
        date: convertDate(date),
      })
      .then(
        () => setDisplayBtns(true)
        // this.setState({ displayBtns: true })
      );
  };

  const displayEndTimingOptions = () => {
    let index = -1;
    if (start) {
      index = timing.indexOf(start);
    }
    return timing.map((time, i) => {
      const nowHour = new Date().getHours();
      const nowMin = new Date().getMinutes();
      let currentTime = sliceHoursAndMin(time, true);
      const isBefore =
        new Date(date).toDateString() === new Date().toDateString() &&
        (nowHour > currentTime.hour ||
          (nowHour === currentTime.hour && nowMin > currentTime.min));

      return (
        <option
          key={time}
          value={time}
          disabled={isBefore || index > i}
          style={isBefore ? {} : { fontWeight: "bold" }}
        >
          {time}
        </option>
      );
    });
  };

  const displayTimingOptions = () => {
    return timing.map((time, i) => {
      const nowHour = new Date().getHours();
      const nowMin = new Date().getMinutes();
      let currentTime = sliceHoursAndMin(time, true);
      const isBefore =
        new Date(date).toDateString() === new Date().toDateString() &&
        (nowHour > currentTime.hour ||
          (nowHour === currentTime.hour && nowMin > currentTime.min));

      return (
        <option
          key={time}
          value={time}
          disabled={isBefore}
          style={isBefore ? {} : { fontWeight: "bold" }}
        >
          {time}
        </option>
      );
    });
  };

  const renderRequestsFor = (requestsFor) => {
    return requestsFor.map((reqData) => {
      return "  " + reqData.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, "");
    });
  };
  const handleEditAppointment = () => {
    // const { date, request, start, end, isAllDay, sRep, active } = this.state;

    const {
      requestObj: { requestId },
    } = request;
    let startTime = "",
      endTime = "";
    if (!isAllDay) {
      const sTime = sliceHoursAndMin(start);
      const eTime = sliceHoursAndMin(end);
      startTime = `${sTime.hour}:${sTime.min}`;
      endTime = `${eTime.hour}:${eTime.min}`;
    }
    let persons = [];
    if (sRep) {
      sRep.map((rep) => {
        return persons.push(rep.value);
      });
    }
    const newEvent = {
      isRepair: active === "Sales" ? false : true,
      leadRequestId: requestId,
      startTime: startTime,
      endTime: endTime,
      allDay: isAllDay,
      date: convertDate(date),
      persons: persons,
    };

    props.addToCalendar({ ...newEvent });
  };
  const handleDateChange = (date) => {
    const dateString = new Date(date).toDateString().split(" ");

    setDate(`${dateString[1]} ${Number(dateString[2])}, ${dateString[3]}`);
    // this.setState({
    //   date: `${dateString[1]} ${Number(dateString[2])}, ${dateString[3]}`,
    // });
  };
  const removeEvent = () => {
    const {
      requestObj: { requestId },
    } = request;

    props.deleteEvent({ leadRequestId: requestId });
  };

  const onHide = () => {
    clearAllFields();
    props.clearAvailablePersons();
    setAddModal(false);
    setEdit(false);
  };

  return (
    <PopupModal
      show={addModal}
      onHide={onHide}
      heading={`${
        edit ? "Edit Appointment Details" : "Appointment Details"
      } - ${date}`}
      subHeading={`${address} - ${type} - ${renderRequestsFor(requestFor)}`}
      bold={true}
      styles={{ background: "#000099", color: "#fff" }}
    >
      {active === "Sales" ? (
        <Col style={salesPointerEvent ? {} : { pointerEvents: "none" }}>
          {msg && msgFor === "modal" && (
            <Col>
              <Alert variant="danger">{msg}</Alert>
            </Col>
          )}
          <Form>
            {edit && (
              <Row>
                <Col style={{ margin: "1em 0" }} sm="12">
                  <DatePicker
                    minDate={new Date()}
                    selected={new Date(date)}
                    disabled={edit}
                    onChange={handleDateChange}
                    className="_date_picker"
                    dateFormat="E M/d/y"
                  />
                </Col>
              </Row>
            )}
            <Row>
              <Col md="4" sm="12">
                <Form.Group style={styles.formInput}>
                  <Form.Label>Start Time</Form.Label>
                  <Form.Control
                    as="select"
                    name="start"
                    onChange={(e) => setStart(e.target.value)}
                    value={start}
                    disabled={isAllDay}
                  >
                    <option value="">Choose...</option>
                    {displayTimingOptions()}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md="4" sm="12">
                <Form.Group style={styles.formInput}>
                  <Form.Label>End Time</Form.Label>
                  <Form.Control
                    as="select"
                    name="end"
                    disabled={isAllDay}
                    onChange={(e) => setEnd(e.target.value)}
                    value={end}
                  >
                    <option value="">Choose...</option>
                    {displayEndTimingOptions()}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col style={{ alignSelf: "center" }} md="4" sm="12">
                <Form.Group>
                  <Form.Check
                    name="isAllDay"
                    defaultChecked={isAllDay}
                    value={isAllDay}
                    onChange={(event) => handleCheck(event)}
                    label="All Day"
                  />
                </Form.Group>
              </Col>
            </Row>

            {personsData.length > 0 ? (
              <Row>
                <Col md="12" sm="12" style={{ padding: "1em" }}>
                  <label>Select Sales Rep:</label>
                  <Select
                    isMulti
                    name="sRep"
                    value={sRep}
                    onChange={(value) => setSRep(value)}
                    options={displayPersons(personsData)}
                  />
                </Col>
              </Row>
            ) : (
              displayBtns && (
                <div className="_message"> No person is available.</div>
              )
            )}
          </Form>
        </Col>
      ) : (
        <Col style={repairPointerEvent ? {} : { pointerEvents: "none" }}>
          {msg && msgFor === "modal" && (
            <Col>
              <Alert variant="danger">{msg}</Alert>
            </Col>
          )}
          <Form>
            {edit && (
              <Row>
                <Col style={{ margin: "1em 0" }} sm="12">
                  <DatePicker
                    minDate={new Date()}
                    selected={new Date(date)}
                    disabled={edit}
                    onChange={handleDateChange}
                    className="_date_picker"
                    dateFormat="E M/d/y"
                  />
                </Col>
              </Row>
            )}
            <Row>
              <Col md="4" sm="12">
                <Form.Group style={styles.formInput}>
                  <Form.Label>Start Time</Form.Label>
                  <Form.Control
                    as="select"
                    name="start"
                    onChange={(e) => setStart(e.target.value)}
                    value={start}
                    disabled={isAllDay}
                  >
                    <option value="">Choose...</option>
                    {displayTimingOptions()}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md="4" sm="12">
                <Form.Group style={styles.formInput}>
                  <Form.Label>End Time</Form.Label>
                  <Form.Control
                    as="select"
                    name="end"
                    disabled={isAllDay}
                    onChange={(e) => setEnd(e.target.value)}
                    value={end}
                  >
                    <option value="">Choose...</option>
                    {displayEndTimingOptions()}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col style={{ alignSelf: "center" }} md="4" sm="12">
                <Form.Group>
                  <Form.Check
                    name="isAllDay"
                    defaultChecked={isAllDay}
                    value={isAllDay}
                    onChange={(event) => handleCheck(event)}
                    label="All Day"
                  />
                </Form.Group>
              </Col>
            </Row>

            {personsData.length > 0 ? (
              <Row>
                <Col md="12" sm="12" style={{ padding: "1em" }}>
                  <label>Select Sales Rep:</label>
                  <Select
                    isMulti
                    name="sRep"
                    value={sRep}
                    onChange={(value) => setSRep(value)}
                    options={displayPersons(personsData)}
                  />
                </Col>
              </Row>
            ) : (
              displayBtns && (
                <div className="_message"> No person is available.</div>
              )
            )}
          </Form>
        </Col>
      )}
      {active === "Sales" ? (
        <Modal.Footer
          style={salesPointerEvent ? {} : { pointerEvents: "none" }}
        >
          {edit && (
            <Button
              variant="danger"
              onClick={() => removeEvent()}
              disabled={isunScheduling}
            >
              {isunScheduling
                ? "Removing From Calender..."
                : "Remove From Calender"}
            </Button>
          )}
          <Button variant="outline-danger" onClick={onHide}>
            Cancel
          </Button>
          {displayBtns ? (
            edit ? (
              <Button
                onClick={() => handleEditAppointment()}
                disabled={
                  personsData.length === 0 ||
                  ((!start ||
                    !end ||
                    !date ||
                    isSubmitting ||
                    !sRep ||
                    (sRep && sRep.length === 0)) &&
                    !isAllDay)
                }
              >
                {isSubmitting ? "Saving..." : "Save Changes"}
              </Button>
            ) : (
              <Button
                onClick={() => handleSaveAppointment()}
                disabled={
                  personsData.length === 0 ||
                  ((!start || !end) && !isAllDay) ||
                  isSubmitting ||
                  !sRep ||
                  (sRep && sRep.length === 0)
                }
              >
                {isSubmitting ? "Saving..." : "Save"}
              </Button>
            )
          ) : (
            <Button
              variant="outline-success"
              onClick={checkPersons}
              disabled={isLoadingAvPersons || ((!start || !end) && !isAllDay)}
            >
              {isLoadingAvPersons
                ? "Checking Availability..."
                : "Get Representative"}
            </Button>
          )}
        </Modal.Footer>
      ) : (
        <Modal.Footer
          style={repairPointerEvent ? {} : { pointerEvents: "none" }}
        >
          {edit && (
            <Button
              variant="danger"
              onClick={() => removeEvent()}
              disabled={isunScheduling}
            >
              {/* {isunScheduling ? "Deleting..." : "Delete"} */}
              {isunScheduling
                ? "Removing From Calender..."
                : "Remove From Calender"}
            </Button>
          )}
          <Button variant="outline-danger" onClick={onHide}>
            Cancel
          </Button>
          {displayBtns ? (
            edit ? (
              <Button
                onClick={() => handleEditAppointment()}
                disabled={
                  personsData.length === 0 ||
                  ((!start ||
                    !end ||
                    !date ||
                    isSubmitting ||
                    !sRep ||
                    (sRep && sRep.length === 0)) &&
                    !isAllDay)
                }
              >
                {isSubmitting ? "Saving..." : "Save Changes"}
              </Button>
            ) : (
              <Button
                onClick={() => handleSaveAppointment()}
                disabled={
                  personsData.length === 0 ||
                  ((!start || !end) && !isAllDay) ||
                  isSubmitting ||
                  !sRep ||
                  (sRep && sRep.length === 0)
                }
              >
                {isSubmitting ? "Saving..." : "Save"}
              </Button>
            )
          ) : (
            <Button
              variant="outline-success"
              onClick={checkPersons}
              disabled={isLoadingAvPersons || ((!start || !end) && !isAllDay)}
            >
              {isLoadingAvPersons
                ? "Checking Availability..."
                : "Get Representative"}
            </Button>
          )}
        </Modal.Footer>
      )}
    </PopupModal>
  );
};

const styles = {
  container: {
    background: "#fff",
    padding: "2em .5em",
  },
  heading: {
    flex: 1,
  },
  header: {
    padding: "25px 20px",
  },
  formInput: {
    marginBottom: "15px",
  },
  formStyle: {
    // marginTop: "5%"
  },
  tabItem: { padding: "1em" },
};

const mapStateToProps = (state) => {
  const {
    calendarInputs: { isLoading, errors },
    availablePersons: { isLoadingAvPersons, personsData, personsErrors },
    scheduleCalendar: { isSubmitting, submitSuccess, submitFail },
    calendarEvents: { eventsError, isFetching },
    unscheduleEvent: { isunScheduling, unscheduleSuccess, unscheduleFail },
    login,
  } = state;
  return {
    isunScheduling,
    unscheduleSuccess,
    unscheduleFail,
    eventsError,
    isFetching,
    isSubmitting,
    submitSuccess,
    submitFail,
    isLoading,
    errors,
    isLoadingAvPersons,
    personsData,
    personsErrors,
    evnts: CalendarEvents(state),
    reqs: CalendarRequests(state),
    Persons: CalendarPersons(state),
    sales: CalendarRequestsSales(state),
    repairs: CalendarRequestsRepair(state),
    salesPersons: SalesPersons(state),
    repairPersons: RepairPersons(state),
    salesEvents: salesEvents(state),
    repairEvents: repairEvents(state),
    userRole: login.user ? login.user.userData.role : null,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchForCalendar: () => dispatch(fetchForCalendar()),
  fetchAvailabelPersons: (data) => dispatch(fetchAvailabelPersons({ ...data })),
  addToCalendar: (data) => dispatch(addToCalendar({ ...data })),
  fetchEvents: () => dispatch(fetchEvents()),
  clearAvailablePersons: () => dispatch(clearAvailablePersons()),
  deleteEvent: (data) => dispatch(deleteEvent({ ...data })),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddModal);
