import React, { useCallback, useEffect, useState } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import styles from "lib/config/baseStyles";
import PriceInput from "components/PriceIput/PriceInput";
import QTYInput from "../../QTYInput";
import { hasNestedFieldChanged } from "features/QuotesProposals/utils";
import { useSelector } from "react-redux";
import showOptions from "../../showOptions";
import { debounce } from "lodash";
import Spinner from "components/common/spinner/spinner";

export default function LabourListItem({
  subType,
  total,
  onChangeQty,
  labour,
  deleteMiscItem,
  proposalStatus,
  isLoadingLabourMiscDelete,
}) {
  const [labourQty, setLabourQty] = useState("");
  const [workType, setWorkType] = useState("");
  const [uom, setUom] = useState("");
  const [price, setPrice] = useState("");
  const [roofPitch, setRoofPitch] = useState("");
  const staticData = useSelector((state) => state.staticAll);
  const allowEdit = proposalStatus !== "As-Collected";

  useEffect(() => {
    if (
      labour?.qtyAndPrice?.quantity &&
      labourQty !== labour.qtyAndPrice.quantity
    ) {
      setLabourQty(labour.qtyAndPrice.quantity);
    }
  }, [labour?.qtyAndPrice?.quantity]);

  useEffect(() => {
    if (labour?.workType && workType !== labour.workType) {
      setWorkType(labour.workType);
    }
  }, [labour?.workType]);

  useEffect(() => {
    if (labour?.roofPitch !== "N/A" && roofPitch !== labour?.roofPitch) {
      setRoofPitch(labour.roofPitch);
    } else if (labour?.accessory !== "N/A") {
      let accessoryName = labour.accessory;
      if (labour?.accessory === "Skylight") {
        accessoryName = `${labour.accessory} - ${labour?.skylight?.height} x ${labour?.skylight?.width}`;
      }
      if (roofPitch !== accessoryName) {
        setRoofPitch(accessoryName);
      }
    } else if (labour?.category && roofPitch !== labour.category) {
      setRoofPitch(labour.category);
    }
  }, [labour?.roofPitch, labour?.category]);

  useEffect(() => {
    if (labour?.uom && uom !== labour.uom) {
      setUom(labour.uom);
    }
  }, [labour?.uom]);

  useEffect(() => {
    if (labour?.uomPrice && price !== labour.uomPrice) {
      setPrice(labour.uomPrice);
    }
  }, [labour?.uomPrice]);

  // Debounced handlers
  const debouncedHandleQtyChange = useCallback(
    debounce((value) => {
      onChangeQty({ labourId: labour._id, quantity: Number(value) });
    }, 800),
    [labour._id]
  );

  const debouncedHandleWorkTypeChange = useCallback(
    debounce((value) => {
      onChangeQty({
        labourId: labour._id,
        quantity: "",
        ...(!labour.category
          ? { workType: value }
          : { skylightTypeOfWork: value }),
      });
    }, 800),
    [labour._id]
  );

  const debouncedHandleUOMChange = useCallback(
    debounce((value) => {
      onChangeQty({
        labourId: labour._id,
        quantity: "",
        workType: "",
        uom: value,
      });
    }, 800),
    [labour._id]
  );

  const debouncedHandlePriceChange = useCallback(
    debounce((value) => {
      onChangeQty({
        labourId: labour._id,
        quantity: "",
        workType: "",
        uom: "",
        price: value,
      });
    }, 800),
    [labour._id]
  );

  // Handlers
  const handleChangeQty = (event) => {
    const value = event.target.value;
    setLabourQty(Number(value));
    if (value && labourQty !== value) {
      debouncedHandleQtyChange(value);
    }
  };

  const handleChangeWorkType = (event) => {
    const value = event.target.value;
    setWorkType(value);
    if (workType !== value) {
      debouncedHandleWorkTypeChange(value);
    }
  };

  const handleChangeUOM = (event) => {
    const value = event.target.value;
    setUom(value);
    if (value && uom !== value) {
      debouncedHandleUOMChange(value);
    }
  };

  const handleChangePrice = (value) => {
    setPrice(value);
    if (value && price !== value) {
      debouncedHandlePriceChange(value);
    }
  };

  const getLabourCategoryName = () => {
    const { subType, categoryType, fascia, category } = labour;
    // Special handling for Fascia
    if (categoryType === "Capping") {
      return subType ? `${categoryType} - ${subType}` : categoryType;
    }

    if (categoryType === "Fascia") {
      return fascia?.size ? `${subType} - ${fascia?.size}` : subType;
    }

    // For other categories, check if categoryType is in the specified list
    return labour?.category;
  };
  return (
    <Row>
      <Col style={styles.reduceWidthCol} lg="2">
        {labour.isMisc ? (
          <Button variant="danger" onClick={deleteMiscItem}>
            {isLoadingLabourMiscDelete ? (
              <Spinner variant="light" label="Deleting" />
            ) : (
              "Delete"
            )}
          </Button>
        ) : (
          <Form.Control
            disabled
            value={roofPitch}
            type="text"
            style={{ textAlign: "center" }}
          />
        )}
      </Col>
      <Col style={styles.reduceWidthCol} lg="2">
        {workType !== "N/A" && labour?.accessory !== "Skylight" && (
          <Form.Control
            disabled={!labour.isMisc}
            value={workType}
            type="text"
            onChange={handleChangeWorkType}
            style={{ textAlign: "center" }}
          />
        )}

        {workType === "N/A" &&
          labour?.accessory !== "Skylight" &&
          (labour?.category === "Capping" || labour?.category === "Fascia") && (
            <Form.Control
              disabled={!labour.isMisc}
              value={getLabourCategoryName()}
              type="text"
              onChange={handleChangeWorkType}
              style={{ textAlign: "center" }}
            />
          )}

        {labour?.accessory === "Skylight" && (
          <Form.Group>
            <Form.Control
              as="select"
              onChange={handleChangeWorkType}
              value={workType || ""}
              style={{
                borderColor: !workType ? "red" : "",
                color: !workType ? "red" : "",
              }}
            >
              <option>Select</option>
              {showOptions(
                staticData?.data?.labourSkylightWorkTypes,
                staticData.isLoading
              )}
            </Form.Control>
          </Form.Group>
        )}
      </Col>
      <Col style={styles.reduceWidthCol} lg="2">
        {subType &&
          subType !== "N/A" &&
          labour?.category !== "Capping" &&
          labour?.category !== "Fascia" && (
            <Form.Control
              disabled
              value={subType}
              type="text"
              style={{ textAlign: "center" }}
            />
          )}
      </Col>
      <Col style={styles.reduceWidthCol}>
        <Form.Group>
          <Form.Control
            disabled={!labour.isMisc}
            type="text"
            onChange={handleChangeUOM}
            style={{ textAlign: "center" }}
            value={uom}
          />
        </Form.Group>
      </Col>
      <Col style={styles.reduceWidthCol}>
        <QTYInput
          value={labourQty?.toString()}
          onChange={handleChangeQty}
          disabled={!allowEdit && !labour.isMisc}
          hasChanged={
            !labour.isMisc &&
            hasNestedFieldChanged(labour, "qtyAndPrice", "quantity")
          }
        />
      </Col>
      <Col style={styles.reduceWidthCol}>
        <PriceInput
          disabled={!labour.isMisc}
          price={price}
          onChange={handleChangePrice}
        />
      </Col>
      <Col style={styles.reduceWidthCol}>
        <PriceInput disabled price={total} input="Total" />
      </Col>
    </Row>
  );
}
