import { axios as axiosNoAuth } from "services";
import { useState, useEffect } from "react";
import { axiosInstance as axios } from "services";
import { ApiEndpoints } from "lib/config/baseSettings";

const useSubscription = (id) => {
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState();
  const [historyData, setHistoryData] = useState();
  const [upcomingInvoice, setUpcomingInvoice] = useState();
  const [particularCompanyData, setParticularCompanyData] = useState();

  useEffect(() => {
    get_all_data().then(() => {
      get_upcoming_invoice();
    });
    get_particular_company_data();
  }, []);

  const get_all_data = () => {
    setLoading(true);
    return axiosNoAuth
      .post(
        ApiEndpoints.SUBSCRIPTION.particularCompanyDataForAdminDromCompanyId,
        { companyId: id }
      )
      .then((res) => {
        if (res?.data?.success) {
          setHistoryData(res?.data?.result);
          return res.data.result;
        } else {
          setHistoryData([]);
        }
      })
      .catch((err) => {
        setHistoryData([]);
        setIsError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const get_upcoming_invoice = (customerId) => {
    setLoading(true);
    return axios
      .get(ApiEndpoints.SUBSCRIPTION.getUpcomingInvoiceForParticularCompany)
      .then((res) => {
        if (res?.data?.success) {
          console.log("Upcoming Invoice", res?.data?.result);
          setUpcomingInvoice(res?.data?.result);
          return res.data.result;
        } else {
          return null;
        }
      })
      .catch((err) => {
        setUpcomingInvoice(null);
        setIsError(err);
        return null;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const get_particular_company_data = () => {
    setLoading(true);
    return axios
      .get(ApiEndpoints.SUBSCRIPTION.particularCompanyDataForAdminFromCompanyId)
      .then((res) => {
        console.log({ res });
        if (res.data.success) {
          setParticularCompanyData(res.data.result);
          return res.data.result;
        } else {
          setParticularCompanyData(null);
          return null;
        }
      })
      .catch((err) => {
        setParticularCompanyData(null);
        setIsError(err);
        return null;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const create_one_time_payment = (body) => {
    const { email, companyId } = body;
    setLoading(true);
    return axiosNoAuth
      .post(ApiEndpoints.SUBSCRIPTION.createPaymentIntent, { email, companyId })
      .then((response) => {
        if (response.data.success) {
          return response;
        } else {
          return null;
        }
      })
      .catch((err) => {
        return null;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const create_subscription = (body) => {
    const { payment_method, email, companyId, additionalUser } = body;
    setLoading(true);
    return axiosNoAuth
      .post(ApiEndpoints.SUBSCRIPTION.createSubscription, {
        payment_method,
        email,
        companyId,
        additionalUser,
      })
      .then((response) => {
        if (response.data.success) {
          return response;
        } else {
          return null;
        }
      })
      .catch((err) => {
        return null;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const change_card_data = (body) => {
    const { token, customerId } = body;
    setLoading(true);
    return axiosNoAuth
      .post(ApiEndpoints.SUBSCRIPTION.changeCardData, { token, customerId })
      .then((response) => {
        if (response.data.success) {
          return response;
        } else {
          return null;
        }
      })
      .catch((err) => {
        return null;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    get_all_data,
    loading,
    isError,
    historyData,
    get_upcoming_invoice,
    upcomingInvoice,
    get_particular_company_data,
    particularCompanyData,
    create_one_time_payment,
    create_subscription,
    change_card_data,
  };
};

export default useSubscription;
