import React, { useState, useEffect } from "react";
import "./QuotesProposals.scss";
import { Row, Col, Tab, Tabs } from "react-bootstrap";
import { Link } from "react-router-dom";

import { QuoteProposalLayout } from "./QuoteProposalLayout/QuoteProposalLayout";
import Estimator from "./Estimator";
import ProposalTab from "./ProposalTab/ProposalTab/ProposalTab";
import { JobNotes } from "./JobNotes/JobNotes";
import { LoadingLoader } from "components";
import { useGetProposals } from "./hooks/useProposals";
import { getQuoteMainTabs } from "lib/constants/proposals/quotes";
import { useSelector } from "react-redux";
import Spinner from "components/common/spinner/spinner";
import { useDispatch } from "react-redux";
import { updateCurrentProposal } from "./redux/proposals/proposals.actions";

export default function QuotesProposals(props) {
  const requestId = props.match?.params?.id || props.leadReqId;
  const QUOTE_MAIN_TABS = getQuoteMainTabs(props.type);
  const dispatch = useDispatch();

  const [key, setKey] = useState(QUOTE_MAIN_TABS[0].id);
  const { type } = props;

  // Directly destructure selectedProposal from the proposal slice in Redux state
  const { selectedProposal } = useSelector((state) => state.proposal) || {};

  const {
    proposals,
    isLoading: isLoadingGetProposal,
    error,
  } = useGetProposals(requestId);

  // UseEffect to set the initial selected proposal to the first proposal
  useEffect(() => {
    if (proposals && proposals.length > 0) {
      const proposal = proposals[0];

      dispatch(updateCurrentProposal(proposal));
    }
  }, [proposals]); // Runs whenever proposals change

  // Log the current state of proposals and selectedProposal for debugging
  console.log({
    proposalsnew: proposals,
    selectedProposal,
  });

  if (isLoadingGetProposal) {
    return (
      <Spinner
        animation="grow"
        size="lg"
        label="We’re fetching your quote information..."
        layout="column-view"
        centerViewport={true}
      />
    );
  }

  if (error) return <p>Error fetching proposals: {error.message}</p>;

  const switchMainTabs = () => {
    switch (key) {
      case QUOTE_MAIN_TABS[0].id:
        return <Estimator key={key} setKey={setKey} requestId={requestId} />;

      case QUOTE_MAIN_TABS[1].id:
        return (
          <div className="_cont_tab __cont_quote">
            <div className="_quote_cont">
              <ProposalTab type={type} tabKey={key} />
            </div>
          </div>
        );

      case QUOTE_MAIN_TABS[2].id:
        return <JobNotes proposalId={selectedProposal?._id} />;
      default:
        return null;
    }
  };

  return (
    <div className="wrapper">
      {/* Header */}
      <Row
        style={{ margin: "0 .2em", alignItems: "center", marginBottom: "15px" }}
      >
        {selectedProposal?.otherInfo?.address && (
          <h4>
            <span style={{ fontWeight: "bold" }}>
              <Link
                to={{
                  pathname: "/dashboard/help",
                  search: "Quotes & Proposals",
                }}
                target="_blank"
                rel="noreferrer"
                style={{ cursor: "pointer" }}
              >
                <b style={{ color: "red", marginRight: "17px" }}>?</b>
              </Link>
              Quotes & Proposals &nbsp; &nbsp; &nbsp;
              <span style={{ fontWeight: "bold", marginLeft: "-2rem" }}>
                {" - "} {selectedProposal?.otherInfo?.address}
              </span>
            </span>
          </h4>
        )}
      </Row>
      <QuoteProposalLayout proposals={proposals}>
        <div style={{ flex: 1 }}>
          <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
          >
            {QUOTE_MAIN_TABS.map((tab) => (
              <Tab key={tab.id} eventKey={tab.id} title={tab.name}>
                {tab.id === key ? switchMainTabs() : null}
              </Tab>
            ))}
          </Tabs>
        </div>
      </QuoteProposalLayout>
    </div>
  );
}
