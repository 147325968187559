import React from "react";
import { ModalTemplate } from "components";
import { ModalFooter } from "components/popup-modal/popup-modal";
import { Row, Col, Button } from "react-bootstrap";
import PercentageInput from "components/PercentageIput";

function ChangePercentage({
  percent,
  changePerData,
  minMarkUp = "",
  maxMarkUp = "",
}) {
  return (
    <Row className="justify-content-center align-items-center mx-2 font-weight-bold">
      <Col className="justify-content-end d-flex" lg="3">
        <Button
          disabled={
            minMarkUp ? percent === 0 || percent === maxMarkUp : percent === 0
          }
          onClick={() => changePerData(false)}
          variant="outline-dark"
          className="modified_btn __proposal_tab_stepper"
          style={{ margin: "0" }}
        >
          -
        </Button>
      </Col>
      <Col lg="3">
        <PercentageInput
          disabled
          percent={percent}
          style={{ textAlign: "center", height: "auto" }}
          className="modified_btn __proposal_tab_stepper"
        />
      </Col>
      <Col lg="3">
        <Button
          onClick={() => changePerData(true)}
          variant="outline-dark"
          className="modified_btn __proposal_tab_stepper"
          style={{ margin: "0" }}
          disabled={maxMarkUp ? percent === minMarkUp : false}
        >
          +
        </Button>
      </Col>
    </Row>
  );
}

export default function PercentageModal({
  onHide,
  show,
  percent,
  onCancel,
  onSubmit,
  changePerData,
  submitText,
  heading,
  minMarkUp = "",
  maxMarkUp = "",
  processing = false,
}) {
  return (
    <ModalTemplate onHide={onHide} show={show} heading={heading}>
      <ChangePercentage
        percent={percent}
        changePerData={changePerData}
        maxMarkUp={maxMarkUp}
        minMarkUp={minMarkUp}
      />
      <ModalFooter
        onCancel={onCancel}
        onSubmit={onSubmit}
        submitText={submitText}
        processing={processing}
        spinnerColor="light"
      />
    </ModalTemplate>
  );
}
