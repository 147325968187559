import React, { useEffect, useState } from "react";
import MetricRoofing from "features/metric/MetricRoofing";
import Gutters from "features/QuotesProposals/components/EstimatorTabs/Gutters";

function Metrics(props) {
  const { leadReqId, type } = props;
  let match = { params: { id: leadReqId } };
  let type_page = "PM";

  const [matchState, setMatchState] = useState(match);
  const [reload, setReload] = useState(true);

  useEffect(() => {
    setMatchState((prev) => ({ params: { id: leadReqId } }));
    setReload(false);
  }, [leadReqId]);

  useEffect(() => {
    if (reload === false) {
      setReload(true);
    }
  }, [reload]);

  if (!reload) return null;
  return (
    <div>
      {type === "roof" ? (
        <MetricRoofing match={matchState} type_page={type_page} history={[]} />
      ) : (
        <Gutters match={matchState} type_page={type_page} history={[]} />
      )}
    </div>
  );
}

export default Metrics;
