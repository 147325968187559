// metricsActions.js
import { handleGlobalError } from "utils/utils";
import {
  addMetricApi,
  deleteMetricApi,
  editMetricApi,
  getMetricsApi,
} from "../../services/gutterMetricsService";
import { metricsActionTypes } from "./metrics.types";
import { updateMetricsGutter } from "../gutter-metric-collection/gutter-metric.action";

export const addGutterMetric = (data) => {
  return async (dispatch) => {
    dispatch({ type: metricsActionTypes.METRIC_ADD_ROOFING_START });

    try {
      const responseData = await addMetricApi(data);

      if (responseData) {
        dispatch({
          type: metricsActionTypes.METRIC_ADD_ROOFING_SUCCESS,
          payload: responseData.metric.sections,
        });
      } else {
        dispatch({
          type: metricsActionTypes.METRIC_ADD_ROOFING_FAILURE,
          payload: responseData.error,
        });
      }
    } catch (error) {
      dispatch({
        type: metricsActionTypes.METRIC_ADD_ROOFING_FAILURE,
        payload: error.message,
      });
      handleGlobalError(error, dispatch);
    }
  };
};

export const getGutterMetrics = (requestId) => {
  return async (dispatch) => {
    dispatch({ type: metricsActionTypes.METRIC_GET_ROOFING_START });

    try {
      const responseData = await getMetricsApi(requestId);
      console.log({ responseData });
      if (responseData) {
        dispatch({
          type: metricsActionTypes.METRIC_GET_ROOFING_SUCCESS,
          payload: responseData,
        });
      } else {
        dispatch({
          type: metricsActionTypes.METRIC_GET_ROOFING_FAILURE,
          payload: responseData.error,
        });
      }
    } catch (error) {
      dispatch({
        type: metricsActionTypes.METRIC_GET_ROOFING_FAILURE,
        payload: error.message,
      });
      handleGlobalError(error, dispatch);
    }
  };
};

export const editGutterSectionMetric = (data) => {
  return async (dispatch) => {
    dispatch({ type: metricsActionTypes.SECTION_UPDATE_START });
    const sectionId = data?.sectionId;

    try {
      const responseData = await editMetricApi(data);
      console.log({ responseData });
      console.log({
        data,
        sectionId,
        responseData,
      });
      if (responseData) {
        dispatch({
          type: metricsActionTypes.SECTION_UPDATE__SUCCESS,
          payload: responseData?.metric,
        });

        const updatedSection = responseData?.metric.sections.find(
          (section) => section._id === sectionId
        );

        // Ensure all sections are included
        const newSection = {
          ...updatedSection, // Override with any provided section values
          guttersAndLeaders: {
            ...(updatedSection.guttersAndLeaders || {}),
          },
          fasciaAndCapping: {
            ...(updatedSection.fasciaAndCapping || {}),
          },
        };
        dispatch(updateMetricsGutter(newSection));
      } else {
        dispatch({
          type: metricsActionTypes.SECTION_UPDATE__FAILURE,
          payload: responseData.error,
        });
        handleGlobalError(responseData?.error, dispatch);
      }
    } catch (error) {
      dispatch({
        type: metricsActionTypes.SECTION_UPDATE__FAILURE,
        payload: error.message,
      });
      handleGlobalError(error, dispatch);
    }
  };
};

export const deleteGutterSectionMetric = (data) => {
  return async (dispatch) => {
    dispatch({ type: metricsActionTypes.SECTION_DELETE_START });

    try {
      const responseData = await deleteMetricApi(data);
      console.log({ responseData });
      if (responseData) {
        dispatch({
          type: metricsActionTypes.SECTION_DELETE__SUCCESS,
          payload: responseData,
        });
      } else {
        dispatch({
          type: metricsActionTypes.SECTION_DELETE__FAILURE,
          payload: responseData.error,
        });
      }
    } catch (error) {
      handleGlobalError(error, dispatch);

      dispatch({
        type: metricsActionTypes.SECTION_DELETE__FAILURE,
        payload: error.message,
      });
    }
  };
};
