import React from "react";
import PropTypes from "prop-types";
import { Button, Modal, Spinner } from "react-bootstrap";
import { PopupModal } from "components";

const ConfirmationModalFooter = ({
  onOkay,
  onCancel,
  btnText = "Confirm",
  disabled = false,
  showNoBtn = false,
  processing = false,
}) => (
  <Modal.Footer className="justify-content-end border-0">
    <Button
      style={{ width: "130px", height: "50px" }}
      variant="danger modified-btn"
      onClick={onOkay}
      disabled={disabled || processing}
      aria-label="Confirm action"
    >
      {processing ? (
        <Spinner
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />
      ) : (
        btnText
      )}
    </Button>

    {showNoBtn && (
      <Button
        style={{ width: "130px", height: "50px" }}
        variant="primary"
        onClick={onCancel}
        disabled={processing}
        aria-label="Cancel action"
      >
        No
      </Button>
    )}
  </Modal.Footer>
);

const ConfirmationModalLayout = ({
  displayText,
  onOkay,
  onCancel,
  processing,
  btnText,
  displayText2,
  showNoBtn,
}) => (
  <>
    <h5 style={{ color: "red", fontWeight: "bold" }}>{displayText}</h5>
    <p style={{ fontWeight: "bold", marginTop: "2rem" }}>{displayText2}</p>
    <ConfirmationModalFooter
      onOkay={onOkay}
      onCancel={onCancel}
      btnText={btnText}
      disabled={processing}
      processing={processing}
      showNoBtn={showNoBtn}
    />
  </>
);

const ConfirmationModal = ({
  showConfirmationModal,
  hideConfirmationModal,
  onOkay,
  processing,
  heading = "Confirmation",
  msgContent,
  msgContent2,
  centered = false,
  btnText,
  showNoBtn,
}) => {
  const displayText = msgContent;

  return (
    <PopupModal
      show={showConfirmationModal}
      heading={heading}
      onHide={hideConfirmationModal}
      bold
      centered={centered}
      styles={{ border: "none" }}
    >
      <ConfirmationModalLayout
        onOkay={onOkay}
        onCancel={hideConfirmationModal}
        processing={processing}
        displayText={displayText}
        btnText={btnText}
        displayText2={msgContent2}
        showNoBtn={showNoBtn}
      />
    </PopupModal>
  );
};

// Define prop types for the ConfirmationModal
ConfirmationModal.propTypes = {
  showConfirmationModal: PropTypes.bool.isRequired,
  hideConfirmationModal: PropTypes.func.isRequired,
  onOkay: PropTypes.func.isRequired,
  processing: PropTypes.bool,
  heading: PropTypes.string,
  msgContent: PropTypes.string,
  msgContent2: PropTypes.string,
  centered: PropTypes.bool,
  btnText: PropTypes.string,
  showNoBtn: PropTypes.bool,
};

// Prop types for ConfirmationModalLayout
ConfirmationModalLayout.propTypes = {
  displayText: PropTypes.string.isRequired,
  onOkay: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  processing: PropTypes.bool,
  btnText: PropTypes.string,
  displayText2: PropTypes.string,
  showNoBtn: PropTypes.bool,
};

// Prop types for ConfirmationModalFooter
ConfirmationModalFooter.propTypes = {
  onOkay: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  btnText: PropTypes.string,
  disabled: PropTypes.bool,
  processing: PropTypes.bool,
  showNoBtn: PropTypes.bool,
};

export default ConfirmationModal;
