import React, { useEffect, useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import styles from "lib/config/baseStyles";

export default function SelectSuppliers({
  selectedSupplier,
  onSelectSupplier,
  loading,
  suppliers,
  emptyText,
  isLockQuoteData,
  isOriginalSupplier,
}) {
  console.log({
    suppliers13: suppliers,
    selectedSupplier,
  });

  const [isPreferedData, setIsPreferedData] = useState([]);

  useEffect(() => {
    if (suppliers?.length) {
      const IsPreferedData = suppliers?.find((data) => {
        return data._id === selectedSupplier?._id;
      });

      setIsPreferedData(IsPreferedData);
    }
  }, [suppliers]);

  console.log({
    isPreferedData,
    selectedSupplier,
    isOriginalSupplier,
  });

  return (
    <Col style={styles.reduceWidthCol} lg="6" md="12">
      <Col
        lg="10"
        md="12"
        style={{ ...styles.reduceWidthCol, paddingLeft: "0" }}
      >
        <Form.Group>
          <Form.Control
            as="select"
            value={selectedSupplier}
            onChange={onSelectSupplier}
            style={
              isOriginalSupplier ? { background: "rgb(246, 212, 111)" } : {}
            }
            disabled={isLockQuoteData?.isLocked}
          >
            <option value="">{loading ? "Loading..." : emptyText}</option>
            {suppliers?.length > 0 &&
              suppliers.map((supplier) => (
                <option value={supplier._id}>{`${supplier.name} ${
                  supplier.locOrBranch ? "-" + supplier.locOrBranch : ""
                }`}</option>
              ))}
          </Form.Control>
        </Form.Group>
      </Col>
    </Col>
  );
}

export function SuppliersList({ suppliers }) {
  return (
    suppliers?.length > 0 &&
    suppliers.map((supplier) => (
      <option
        value={supplier._id}
        selected={selectedSupplier}
      >{`${supplier.name} - ${supplier.locOrBranch}`}</option>
    ))
  );
}
export function SelectSuppliersSection({ children }) {
  return (
    <Row className="mt-4">
      {children}

      <Col style={{ ...styles.reduceWidthCol, textAlign: "center" }}>
        <Form.Label>UOM</Form.Label>
      </Col>
      <Col style={{ ...styles.reduceWidthCol, textAlign: "center" }}>
        <Form.Label>QTY</Form.Label>
      </Col>
      <Col style={{ ...styles.reduceWidthCol, textAlign: "center" }}>
        <Form.Label>Price Per UOM</Form.Label>
      </Col>

      <Col style={{ ...styles.reduceWidthCol, textAlign: "right" }}>
        <Form.Label>Total</Form.Label>
      </Col>
    </Row>
  );
}

export function SupplierItemOnlyName({ supplier }) {
  return <SelectOption value={supplier._id} display={supplier.name} />;
}

export function SupplierItem({ supplier }) {
  return (
    <SelectOption
      value={supplier._id}
      display={`${supplier.name} - ${supplier.locOrBranch}`}
    />
  );
}

export function SelectOption({ value, display }) {
  return <option value={value}>{display}</option>;
}

export const getOptions = (options) =>
  options.map((option) => (
    <SelectOption key={option} value={option} display={option} />
  ));
