import React, { useState } from "react";
import PropTypes from "prop-types";

import {
  categoryMapping,
  formatNumber,
  getShinglesV2,
  sumDimensonTotal,
  sumUnitDimensonTotal,
  sumFlatDimenstionTotal,
  sumShinglesDimenstionTotal,
} from "utils/utils";

import { useDispatch, useSelector } from "react-redux";
import {
  resetAllMetrics,
  updateMetrics,
} from "../redux/roof-metric-collection/metric.action";
import { deleteSectionMetric } from "../redux/metrics-v2/metrics.actions";
import DeleteModal from "components/DeleteModal";

import styles from "./SectionListing.module.css";

const SectionListing = ({ type, sesctionData, editSection, requestId }) => {
  const dispatch = useDispatch();
  const metricData = useSelector((state) => state?.metric?.metrics);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  // Handles viewing a section
  const onView = (section) => {
    const newSection = Object.keys(section).reduce((acc, key) => {
      const newKey = categoryMapping[key] || key;
      acc[newKey] = section[key];
      return acc;
    }, {});

    console.log({
      newSection,
    });

    dispatch(resetAllMetrics());
    setTimeout(() => {
      dispatch(updateMetrics(newSection));
    }, 100);
  };
  console.log({
    sesctionData,
  });

  // Handles deleting a section
  const deleteSection = () => {
    const deleteData = {
      requestId: requestId,
      sectionId: metricData?.sectionId,
    };
    dispatch(deleteSectionMetric(deleteData));
  };

  return (
    <div className={styles.tableContainer}>
      <table className="table table-hover">
        <thead>
          {type === "roofing" && (
            <>
              <tr>
                <th></th>
                <th colSpan={9} className={styles.tableHeader}>
                  Current Building Total
                </th>

                <th
                  className={
                    editSection ? styles.tableDataEdit : styles.tableData
                  }
                >
                  <span className="totalData">
                    {/* {getShinglesV2(data?.installTypes) !== "Flat" && data.length > 0 ? `1000(F)` : ``} */}
                    {sumShinglesDimenstionTotal(sesctionData) || ""}
                  </span>
                </th>

                <th
                  className={
                    editSection ? styles.tableDataEdit : styles.tableData
                  }
                >
                  <span className="totalData">
                    {/* {getShinglesV2(data?.installTypes) !== "Flat" && data.length > 0 ? `1000(S)` : ``} */}
                    {sumFlatDimenstionTotal(sesctionData) || ""}
                  </span>
                </th>

                <th></th>
              </tr>
              <tr>
                <th style={{ width: "10%" }}></th>
                <th className={styles.tableRow}>Sec</th>
                <th className={styles.tableRow}>Floor</th>
                <th className={styles.tableRow}>Elevation</th>
                <th className={styles.tableRow}>Pitch</th>
                <th className={styles.tableRow}>Work Type</th>
                <th className={styles.tableRow}>Install</th>
                <th className={styles.tableRow}>L</th>
                <th className={styles.tableRow}>W</th>
                <th className={styles.tableRow}>F</th>
                <th className={styles.tableRow}>Sq Ft(S)</th>
                <th className={styles.tableRow}>Sq Ft(F)</th>
                <th></th>
                {editSection && <th className={styles.tableRow}></th>}
              </tr>
            </>
          )}
        </thead>
        <tbody>
          {type === "roofing" ? (
            sesctionData.map((elem, index) => (
              <tr key={index}>
                <th scope="row">
                  <button
                    className="btn btn-primary"
                    onClick={() => onView(elem)}
                  >
                    View
                  </button>
                </th>
                <td className={styles.tableRow}>{index + 1}</td>
                <td className={styles.tableRow}>{elem?.floor}</td>
                <td
                  className={`${styles.tableRow} ${styles.whiteSpacePreWrap}`}
                >
                  {/* Elevation */}
                  {elem?.elevation && (
                    <span className={styles.listItem}>{elem.elevation}</span>
                  )}

                  {/* Elevation Type */}
                  {elem?.elevationDetails?.type && (
                    <span className={styles.listItem}>
                      {elem.elevationDetails.type}
                    </span>
                  )}

                  {/* More Lists */}
                  {elem?.elevationDetails?.more?.lists?.length > 0 &&
                    elem.elevationDetails.more.lists.map((m, index) => (
                      <span key={index} className={styles.listItem}>
                        {m}
                      </span>
                    ))}
                </td>

                <td className={styles.tableRow}>{elem?.pitch?.roofPitch}</td>
                <td className={styles.tableRow}>
                  {elem?.workType?.name === "Remove" ? (
                    <div className={styles.flexColumn}>
                      <p>{elem?.workType?.removalTypes?.name}</p>
                      <p>{elem?.workType?.removalTypes?.type}</p>
                    </div>
                  ) : (
                    elem?.workType?.name
                  )}
                </td>
                <td className={styles.tableRow}>
                  {elem?.installTypes?.flat
                    ? elem?.installTypes?.flat?.type
                    : elem?.installTypes?.shingles?.type}
                </td>
                <td className={styles.tableRow}>
                  {sumUnitDimensonTotal(elem?.dimensions, "length")}
                </td>
                <td className={styles.tableRow}>
                  {sumUnitDimensonTotal(elem?.dimensions, "width")}
                </td>
                <td className={styles.tableRow}>
                  {sumUnitDimensonTotal(elem?.dimensions, "factor")}
                </td>
                <td className={styles.tableRow}>
                  {elem?.installTypes?.shingles &&
                  Object.values(elem?.installTypes?.shingles)?.length
                    ? sumDimensonTotal(elem?.dimensions)
                      ? formatNumber(sumDimensonTotal(elem?.dimensions))
                      : 0
                    : ""}
                </td>
                <td className={styles.tableRow}>
                  {elem?.installTypes?.flat &&
                  Object.values(elem?.installTypes?.flat)?.length
                    ? sumDimensonTotal(elem?.dimensions)
                      ? formatNumber(sumDimensonTotal(elem?.dimensions))
                      : 0
                    : ""}
                </td>
                <td>
                  {metricData?.sectionId === elem?._id && (
                    <button
                      className={`tblDel btn btn-danger`}
                      onClick={() => setShowDeleteModal(true)}
                    >
                      Delete
                    </button>
                  )}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="12">No data available</td>
            </tr>
          )}
        </tbody>
      </table>
      <DeleteModal
        deleteData={deleteSection}
        hideDeleteModal={() => setShowDeleteModal(false)}
        onCancel={() => setShowDeleteModal(false)}
        heading="Section Delete Confirmation"
        showDeleteModal={showDeleteModal}
      />
    </div>
  );
};

SectionListing.propTypes = {
  type: PropTypes.string.isRequired,
  sesctionData: PropTypes.array.isRequired,
  editSection: PropTypes.bool,
  requestId: PropTypes.string.isRequired,
};

export default SectionListing;
