export const styles = {
  block: {
    paddingTop: "0.2em",
    fontSize: "2.1em",
    marginRight: "5px",
    // cursor: "pointer",
    // background: "transparent",
    // color: "#999",
    // borderColor: "#999",
  },
  flexCenter: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap",
  },
  inputContainer: {
    display: "flex",
    flexDirection: "row",
  },
  labelText: {
    width: "380px",
    paddingTop: "0.2em",
    fontSize: "2.1em",
  },
  slideRight: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  rowMargin: {
    margin: "1em auto",
  },
  carsl: {
    border: "none",
  },
  blockText: {
    fontSize: "1rem",
  },
};
