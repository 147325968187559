// src/api/materialApi.js

import { ApiEndpoints } from "lib/config/baseSettings";
import { axiosInstance } from "services";

export const getLabourActiveSuppliers = async () => {
    try {

        const URL = `${ApiEndpoints.PROPOSALS.GET_ACTIVE_LABOUR_SUPPLIERS}`;

        const response = await axiosInstance.get(URL);
        return response.data;
    } catch (error) {
        throw new Error(error.message || "An error occurred while fetching labour suppliers");
    }
};

export const changeLabourSupplier = async (data) => {
    try {
        const URL = `${ApiEndpoints.PROPOSALS.CHANGE_LABOUR_SUPPLIER_PROPOSAL}`;
        const response = await axiosInstance.patch(URL, data);
        return response.data;
    } catch (error) {
        throw error;
    }
};



export const updateLabourQuoteLine = async (data) => {
    try {
        const URL = `${ApiEndpoints.PROPOSALS.UPDATE_LABOUR_QUOTE}`;

        const response = await axiosInstance.patch(URL, data);
        return response.data;
    } catch (error) {
        throw error;
    }
};


// Add Misc Material to the Quote
export const addLabourMisc = async (data) => {
    const URL = `${ApiEndpoints.PROPOSALS.ADD_MISC_LABOUR}`;
    try {
        const response = await axiosInstance.post(URL, data);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const deleteLabourMiscQuote = async (data) => {
    try {
        const URL = `${ApiEndpoints.PROPOSALS.DELETE_MISC_LABOUR_QUOTE}`;

        const response = await axiosInstance.delete(URL, { data });
        return response.data;
    } catch (error) {
        throw error;
    }
};