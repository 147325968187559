import React, { useState, useEffect, memo } from "react";

import { Container, Row, Col, Form, Alert } from "react-bootstrap";
import "./calendar.scss";
import mstyles from "./calendar.module.scss";
import SearchField from "components/search-field/search-field";
import Calendar from "components/calendar/Calendar";
import InitialLoader from "components/InitilalLoader";

import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { displayPersons, sliceHoursAndMin } from "utils/utils";
import moment from "moment";

import { FilterCircleOutline, FilterCircle } from "react-ionicons";

import {
  CalendarPersons,
  SalesPersons,
  RepairPersons,
  CalendarRequests,
  CalendarRequestsRepair,
  CalendarRequestsSales,
  salesEvents,
  repairEvents,
  CalendarEvents,
} from "lib/redux/calendar/calendar.selector";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  deleteEvent,
  clearAvailablePersons,
  addToCalendar,
  fetchForCalendar,
  fetchAvailabelPersons,
  fetchEvents,
} from "lib/redux/calendar/calendar.actions";
import { TIMINGS } from "lib/constants/const";
import useSchedulingModal from "hooks/SchedulingModal/useSchedulingModal";
import { Link } from "react-router-dom";
import AddModal from "./AddModal";
import { CalendarEventsList } from "./CalendarEventList";
import RequestDetailModal from "./RequestDetailModal";
import { Can, useAbility } from "components/can";
import { permissions } from "lib/config/permissionSettings";

const links = ["Sales", "Repair"];

const CalendarUI = (props) => {
  const {
    evnts,
    reqs,
    salesEvents,
    repairEvents,
    unscheduleSuccess,
    unscheduleFail,
    eventsError,
    personsErrors,
    errors,
    salesPersons,
    repairPersons,
    repairs,
    submitFail,
    submitSuccess,
    sales,
    paymentStatus,
  } = props;

  //#region states
  let timeout = null;
  const [timing, setTiming] = useState(TIMINGS);
  const [events, setEvent] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [active, setActive] = useState("");
  const [indexE, setIndexE] = useState(null);
  const [addModal, setAddModal] = useState(false);
  const [date, setDate] = useState("");
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [isAllDay, setIsAllDay] = useState(false);
  const [sRep, setSRep] = useState(null);
  const [id, setId] = useState("");
  const [address, setAddress] = useState("");
  const [type, setType] = useState("");
  const [edit, setEdit] = useState(false);
  const [showDetail, setShowDetail] = useState("");
  const [request, setRequest] = useState({});
  const [requestFor, setRequestFor] = useState([]);
  const [persons, setPersons] = useState([]);
  const [requests, setRequests] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectPerson, setSelectPerson] = useState([]);
  const [searchResult, setSearchResult] = useState([]);
  const [displayBtns, setDisplayBtns] = useState(false);
  const [msg, setMsg] = useState("");
  const [msgType, setMsgType] = useState("");
  const [msgFor, setMsgFor] = useState("");
  const [view, setView] = useState("month");
  const [dateObject, setDateObject] = useState(new Date());
  const [showHistory, setShowHistory] = useState(false);

  const [sale, setSales] = useState("");
  //#endregion

  const { user } = useSelector((state) => state.login);

  const { additionalRepairTime, additionalSalesTime } =
    useSchedulingModal(user);

  const ability = useAbility();

  const salesPointerEvent =
    ability.can("create", permissions.create.calendar_sales) ||
    ability.can("update", permissions.update.calendar_sales);
  const repairPointerEvent =
    ability.can("create", permissions.create.calendar_repairs) ||
    ability.can("update", permissions.update.calendar_repairs);
  const hasAccessForSaleCalender = ability.can(
    "read",
    permissions.read.calendar_sales
  );
  const hasAccessForRepairsCalender = ability.can(
    "read",
    permissions.read.calendar_repairs
  );

  const setToday = () => {
    setDateObject(new Date());
  };
  const loadData = () => {
    const { fetchForCalendar, fetchEvents } = props;
    fetchForCalendar();
    fetchEvents();
  };

  useEffect(() => {
    if (hasAccessForSaleCalender) {
      setActive("Sales");
    } else {
      setActive("Repair");
    }
    if (hasAccessForSaleCalender || hasAccessForRepairsCalender) {
      loadData();
    }
  }, [hasAccessForSaleCalender]);

  useEffect(() => {
    getThingsReady();
  }, []);

  useEffect(() => {
    setAddModal(false);
    setMsg(unscheduleSuccess);
    setMsgType("success");
    setMsgFor("!modal");

    clearAllFields();
    if (unscheduleSuccess || unscheduleFail) {
      props.clearAvailablePersons();
      props.fetchForCalendar();
      props.fetchEvents();
    }

    clearMsg();
  }, [unscheduleSuccess]);

  useEffect(() => {
    setAddModal(false);
    setMsg(submitSuccess);
    setMsgType("success");
    setMsgFor("!modal");
    clearAllFields();
    if (submitSuccess || submitFail) {
      props.clearAvailablePersons();
      props.fetchForCalendar();
      props.fetchEvents();
    }
    clearMsg();
  }, [submitSuccess]);

  useEffect(() => {
    if (selectPerson && selectPerson.length > 0) {
      const filtered = events.filter((event) =>
        event.persons.some((person) =>
          selectPerson.some((data) => person.personId === data.value)
        )
      );
      console.log({
        filtered,
      });

      setFiltered(filtered);
    } else {
      setEvent(events);
    }
  }, [selectPerson]);

  console.log({
    selectPerson,
    events,
    salesEvents,
    repairEvents,
    filtered,
  });

  useEffect(() => {
    if (active === "Sales") {
      setRequests(sales);
    } else {
      setRequests(repairs);
    }
  }, [reqs]);

  useEffect(() => {
    if (active === "Sales") {
      setEvent(salesEvents);
    } else {
      setEvent(repairEvents);
    }
  }, [evnts]);

  useEffect(() => {
    setMsg(unscheduleFail);
    setMsgType("danger");
    setMsgFor("modal");
    clearMsg();
  }, [unscheduleFail]);

  useEffect(() => {
    setMsg(eventsError);
    setMsgType("danger");
    setMsgFor("!modal");
    clearMsg();
  }, [eventsError]);

  useEffect(() => {
    setMsg(unscheduleFail);
    setMsgType("danger");
    setMsgFor("modal");
    setDisplayBtns(false);
    clearMsg();
  }, [personsErrors]);

  useEffect(() => {
    setMsg(errors);
    setMsgType("danger");
    setMsgFor("!modal");
    clearMsg();
  }, [errors]);

  useEffect(() => {
    setMsg(submitFail);
    setMsgType("danger");
    setMsgFor("modal");
    setDisplayBtns(false);
    props.clearAvailablePersons();
    clearMsg();
  }, [submitFail]);

  useEffect(() => {
    if (searchTerm.trim() === "") {
      if (active === "Sales") {
        setRequests(sales);
      } else {
        setRequests(repairs);
      }
      return;
    }
    if (searchTerm !== "") {
      const filteredRequests =
        requests &&
        requests.filter(
          (request) =>
            request.address.toLowerCase().includes(searchTerm.toLowerCase()) ||
            request.leadName.toLowerCase().includes(searchTerm.toLowerCase())
        );
      setSearchResult(filteredRequests);
    }
  }, [searchTerm]);

  useEffect(() => {
    if (active === "Sales") {
      if (start) {
        const time = sliceHoursAndMin(start);
        let hour = time.hour + additionalSalesTime;
        const min = time.min;
        const newTime = convert24to12Hours(hour, min);
        if (timing.includes(newTime)) {
          setEnd(newTime);
        } else {
          setEnd("");
        }
      }
    } else {
      if (start) {
        const time = sliceHoursAndMin(start);
        let hour = time.hour + additionalRepairTime;
        const min = time.min;
        const newTime = convert24to12Hours(hour, min);
        if (timing.includes(newTime)) {
          setEnd(newTime);
        } else {
          setEnd("");
        }
      }
    }
  }, [start]);

  useEffect(() => {
    if (active === "Sales") {
      setPersons(salesPersons);
    }
  }, [salesPersons]);

  useEffect(() => {
    if (active === "Repairs") {
      setPersons(repairPersons);
    }
  }, [repairPersons]);

  useEffect(() => {
    if (active === "Sales") {
      setEvent(salesEvents);
      setPersons(salesPersons);
      setRequests(sales);
    } else {
      setEvent(repairEvents);
      setPersons(repairPersons);
      setRequests(repairs);
    }
  }, [active]);

  useEffect(() => {
    if (active === "Sales") {
      setRequests(sales);
    }
  }, [sales]);

  useEffect(() => {
    if (active === "Repairs") {
      setRequests(repairs);
    }
  }, [repairs]);

  useEffect(() => {
    if (isAllDay) {
      setStart("");
      setEnd("");
    }
  }, [isAllDay]);

  useEffect(() => {
    props.clearAvailablePersons();
    setDisplayBtns(false);
  }, [start, end, date]);

  const getThingsReady = () => {
    if (isAllDay || start || end || date) {
      props.clearAvailablePersons();
      setDisplayBtns(false);
    }
  };

  useEffect(() => {
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const changeView = (view) => {
    setView(view);
  };

  const clearMsg = () =>
    (timeout = setTimeout(() => {
      setMsg("");
      setMsgType("");
      setMsgFor("");
    }, 10000));

  const convert24to12Hours = (hour, min) => {
    const meridiem = hour > 11 ? "PM" : "AM";
    const newHour = hour > 12 ? hour - 12 : hour;
    return `${newHour}:${
      Number(min) < 10 ? `0${Number(min)}` : Number(min)
    } ${meridiem}`;
  };

  const editEvent = (id, event) => {
    let newEvents = [...events];
    newEvents.splice(id, 1, event);
    setEvent(newEvents);
  };

  const onEventDrop = ({ event, start, end, allDay }) => {
    const idx = events.indexOf(event);
    const newEvent = {
      id: event.id,
      start,
      end,
      allDay,
      title: `(${convert24to12Hours(
        start.getHours(),
        start.getMinutes()
      )} - ${convert24to12Hours(end.getHours(), end.getMinutes())}) ${
        events[idx].title.split(")")[1]
      }`,
    };
    editEvent(idx, newEvent);
  };

  const onDragStart = (e, request) => {
    setRequest(request);
  };

  const handleDrop = (e, request) => {
    if (paymentStatus !== "Frozen 1") {
      const date = e.dropData.date;
      const dateString = new Date(date).toDateString().split(" ");
      if (request) {
        const { address, propertyType, requestFor } = request;

        setAddModal(true);
        setDate(`${dateString[1]} ${Number(dateString[2])}, ${dateString[3]}`);
        setAddress(address);
        setType(propertyType);
        setRequestFor(requestFor);
      }
    }

    return;
  };

  const handleShowHistoryToggle = () => {
    setShowHistory((show) => !show);
  };

  const clearAllFields = () => {
    setIndexE("");
    setDate("");
    setEnd("");
    setStart("");
    setIsAllDay(false);
    setSRep([]);
    setDisplayBtns(false);
    setRequest(null);
    setEdit(false);
    setShowDetail(false);
    setSales(null);
  };
  const onMultipleSelectChange = (array, value) => {
    let temp = array;
    const idx = temp.indexOf(value);
    temp.includes(value) ? temp.splice(idx, 1) : temp.push(value);

    return setSRep(temp);
  };

  const eventPressed = ({ data }) => {
    const { address, propertyType } = data.requestObj;
    const { date, startTime, endTime, allDay } = data.eventDetails;
    const dateString = new Date(date).toDateString().split(" ");
    let newStart = "",
      newEnd = "";
    if (!allDay) {
      const sTime = sliceHoursAndMin(startTime);
      const eTime = sliceHoursAndMin(endTime);
      newStart = convert24to12Hours(sTime.hour, sTime.min);
      newEnd = convert24to12Hours(eTime.hour, eTime.min);
    }

    let representative = (data.persons || []).map((rep) => ({
      label: rep.personName,
      value: rep.personId,
    }));
    setDate(
      `${dateString[0]} ${dateString[1]} ${Number(dateString[2])}, ${
        dateString[3]
      }`
    );
    setStart(newStart);
    setIsAllDay(allDay);
    setSRep(representative);
    setAddress(address);
    setType(propertyType);
    setStart(newStart);
    setRequest(data);
    setEnd(newEnd);
    setEdit(true);
    data.currentStatus === "Scheduled"
      ? setAddModal(true)
      : setShowDetail(true);
  };

  const changeTab = (tabName) => {
    setActive(tabName);
    setSelectPerson("");
  };

  const onNext = () => {
    setDateObject(moment(dateObject).add(1, "M"));
  };

  const onPrev = () => {
    setDateObject(moment(dateObject).subtract(1, "M"));
  };
  const onNextDay = () => {
    setDateObject(moment(dateObject).add(1, "day"));
  };

  const onPrevDay = () => {
    setDateObject(moment(dateObject).subtract(1, "day"));
  };

  const onNextWeek = () => {
    setDateObject(moment(dateObject).add(1, "weeks"));
  };

  const onPrevWeek = () => {
    setDateObject(moment(dateObject).subtract(1, "weeks"));
  };

  const {
    isunScheduling,
    isLoading,
    isLoadingAvPersons,
    personsData,
    isSubmitting,
    // errors,
    userRole,
  } = props;

  return (
    <>
      <div className="calendar-ui">
        <Container
          fluid="true"
          className="p-3"
          style={styles.container}
          block="true"
        >
          {msg && msgFor === "!modal" && (
            <Col>
              <Alert variant={msgType}>{msg}</Alert>
            </Col>
          )}
          <Row>
            {/* left column */}
            <Col lg="4" md="5" sm="12" style={{ width: "27.333333%" }}>
              {/* tabs and search field */}
              <Col>
                <div className="_tab">
                  <Can do="read" on={permissions.read.calendar_sales}>
                    <button
                      style={styles.tabItem}
                      onClick={() => changeTab(links[0])}
                      className={`_tabItem ${active === links[0] && "active"}`}
                    >
                      Sales &nbsp; &nbsp; &nbsp;
                      <Link
                        to={{
                          pathname: "/dashboard/help",
                          search: "Calendar Sales",
                        }}
                        target="_blank"
                        rel="noreferrer"
                        style={{ cursor: "pointer" }}
                      >
                        <span style={{ color: "red" }}>?</span>
                      </Link>
                    </button>
                  </Can>
                  <Can do="read" on={permissions.read.calendar_repairs}>
                    <button
                      style={styles.tabItem}
                      onClick={() => changeTab(links[1])}
                      className={`_tabItem ${active === links[1] && "active"}`}
                    >
                      Repairs &nbsp; &nbsp; &nbsp;
                      <Link
                        to={{
                          pathname: "/dashboard/help",
                          search: "Calendar Repair",
                        }}
                        target="_blank"
                        rel="noreferrer"
                        style={{ cursor: "pointer" }}
                      >
                        <span style={{ color: "red" }}>?</span>
                      </Link>
                    </button>
                  </Can>
                </div>
                <SearchField
                  type="search"
                  placeholder={`Search for ${active.toLowerCase()}`}
                  value={searchTerm}
                  onChange={({ target: { value } }) => setSearchTerm(value)}
                  paymentStatus={paymentStatus}
                />
              </Col>
              {/* event list */}
              <Can
                do="read"
                on={
                  active === "Sales"
                    ? permissions.read.calendar_sales
                    : permissions.read.calendar_repairs
                }
              >
                <Col className="cal__left__tab">
                  <ol>
                    <CalendarEventsList
                      loading={isLoading}
                      error={errors}
                      onDragStart={onDragStart}
                      handleDrop={handleDrop}
                      requests={requests}
                      searchResult={searchResult}
                      searchTerm={searchTerm}
                      onReload={loadData}
                      paymentStatus={paymentStatus}
                    />
                  </ol>
                </Col>
              </Can>
            </Col>

            {/* right column */}
            <Col>
              {/* top control */}
              <Row style={{ alignItems: "center" }}>
                <Col lg="12">
                  <div className={mstyles.btns_group}>
                    <button
                      className={mstyles.today_btn}
                      onClick={setToday}
                      style={{}}
                    >
                      Today
                    </button>

                    <div>
                      <button
                        className={`${mstyles.today_btn} inverted${
                          view === "month" ? " selected" : ""
                        }`}
                        onClick={() => changeView("month")}
                      >
                        Month
                      </button>
                      <button
                        className={`${mstyles.today_btn} inverted${
                          view === "week" ? " selected" : ""
                        }`}
                        onClick={() => changeView("week")}
                      >
                        Week
                      </button>
                      <button
                        className={`${mstyles.today_btn} inverted${
                          view === "day" ? " selected" : ""
                        }`}
                        onClick={() => changeView("day")}
                      >
                        Day
                      </button>
                    </div>

                    <Form>
                      <Form.Check
                        className={styles.formCheck}
                        type="switch"
                        id="show-history"
                        label="Show History"
                        value={showHistory}
                        onChange={handleShowHistoryToggle}
                        size="lg"
                      />
                    </Form>
                  </div>
                </Col>
                <Can
                  do="read"
                  on={[
                    permissions.read.calendar,
                    permissions.read.calendar_sales,
                    permissions.read.calendar_repairs,
                  ]}
                >
                  <Col>
                    <Form.Group>
                      <Form.Label>View Calendar By {active} Person</Form.Label>
                      {paymentStatus !== "Frozen 1" ? (
                        <Select
                          isMulti
                          name="selectPerson"
                          value={selectPerson}
                          onChange={(value) => setSelectPerson(value)}
                          options={displayPersons(persons)}
                        />
                      ) : (
                        <Select
                          isMulti
                          name="selectPerson"
                          value={selectPerson}
                          onChange={(value) => setSelectPerson(value)}
                          options={displayPersons(persons)}
                          isDisabled={true}
                        />
                      )}
                    </Form.Group>
                  </Col>
                </Can>
              </Row>
              {/* calendar */}
              <Col>
                <Calendar
                  events={
                    selectPerson && selectPerson.length > 0 ? filtered : events
                  }
                  dropFromOutside={handleDrop}
                  dayviewstart="6:00"
                  dayviewend="20:59"
                  eventClicked={(event) => eventPressed(event)}
                  view={view}
                  dateObject={dateObject}
                  setToday={setToday}
                  onNext={onNext}
                  onPrev={onPrev}
                  onNextDay={onNextDay}
                  onPrevDay={onPrevDay}
                  onNextWeek={onNextWeek}
                  onPrevWeek={onPrevWeek}
                  showHistory={showHistory}
                />
              </Col>
            </Col>
          </Row>
        </Container>
        {paymentStatus !== "Frozen 1" && addModal && (
          <AddModal
            addModal={addModal}
            edit={edit}
            date={date}
            address={address}
            type={type}
            requestFor={requestFor}
            msg={msg}
            msgFor={msgFor}
            start={start}
            isAllDay={isAllDay}
            sRep={sRep}
            isunScheduling={isunScheduling}
            end={end}
            isSubmitting={isSubmitting}
            isLoadingAvPersons={isLoadingAvPersons}
            personsData={personsData}
            displayBtns={displayBtns}
            active={active}
            salesPointerEvent={salesPointerEvent}
            timing={timing}
            setIsAllDay={setIsAllDay}
            setEnd={setEnd}
            setStart={setStart}
            displayPersons={displayPersons}
            setDisplayBtns={setDisplayBtns}
            setSRep={setSRep}
            request={request}
            clearAllFields={clearAllFields}
            setAddModal={setAddModal}
            setEdit={setEdit}
            repairPointerEvent={repairPointerEvent}
          />
        )}
        {showDetail && (
          <RequestDetailModal
            isOpen={showDetail}
            date={date}
            address={address}
            type={type}
            msg={msg}
            msgFor={msgFor}
            start={start}
            isAllDay={isAllDay}
            requestFor={requestFor}
            sRep={sRep}
            end={end}
            toggleModal={setShowDetail}
            clearAllFields={clearAllFields}
            clearAvailablePersons={clearAvailablePersons}
          />
        )}
      </div>
    </>
  );
};

const styles = {
  container: {
    background: "#fff",
    padding: "2em .5em",
    position: "relative",
  },
  heading: {
    flex: 1,
  },
  header: {
    padding: "25px 20px",
  },
  formInput: {
    marginBottom: "15px",
  },
  formStyle: {
    // marginTop: "5%"
  },
  tabItem: { padding: "1em" },
  formCheck: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
};

const mapStateToProps = (state) => {
  const {
    calendarInputs: { isLoading, errors },
    availablePersons: { isLoadingAvPersons, personsData, personsErrors },
    scheduleCalendar: { isSubmitting, submitSuccess, submitFail },
    calendarEvents: { eventsError, isFetching },
    unscheduleEvent: { isunScheduling, unscheduleSuccess, unscheduleFail },
    login,
  } = state;
  return {
    isunScheduling,
    unscheduleSuccess,
    unscheduleFail,
    eventsError,
    isFetching,
    isSubmitting,
    submitSuccess,
    submitFail,
    isLoading,
    errors,
    isLoadingAvPersons,
    personsData,
    personsErrors,
    evnts: CalendarEvents(state),
    reqs: CalendarRequests(state),
    Persons: CalendarPersons(state),
    sales: CalendarRequestsSales(state),
    repairs: CalendarRequestsRepair(state),
    salesPersons: SalesPersons(state),
    repairPersons: RepairPersons(state),
    salesEvents: salesEvents(state),
    repairEvents: repairEvents(state),
    userRole: login.user ? login.user.userData.role : null,
    paymentStatus:
      login.user.userData.paymentStatus && login.user.userData.paymentStatus,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchForCalendar: () => dispatch(fetchForCalendar()),
  fetchAvailabelPersons: (data) => dispatch(fetchAvailabelPersons({ ...data })),
  addToCalendar: (data) => dispatch(addToCalendar({ ...data })),
  fetchEvents: () => dispatch(fetchEvents()),
  clearAvailablePersons: () => dispatch(clearAvailablePersons()),
  deleteEvent: (data) => dispatch(deleteEvent({ ...data })),
});

export default connect(mapStateToProps, mapDispatchToProps)(memo(CalendarUI));
