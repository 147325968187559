import React, { useEffect, useState } from "react";
import "./common.scss";
import { Row, Col, Button } from "react-bootstrap";

export default function DisplayTable({ data, columns }) {
  const [page, setPage] = useState(0);
  const [pageData, setPageData] = useState(data.slice(0, 5));

  const pageNumber = Math.ceil(data.length / 5);
  const paginationMapArray = [...Array(pageNumber).keys()];

  useEffect(() => {
    const newPageData = data.slice(5 * page, 5 * (page + 1));
    if (newPageData.length === 0 && page > 0) {
      setPage((prev) => prev - 1);
    }
    setPageData(newPageData);
  }, [page, data]);

  return (
    <div style={{ overflowX: "scroll" }}>
      <table className="table contact-table">
        <thead>
          <tr>
            {columns.map((value, id) => (
              <th
                className="no-scrollbar"
                style={{
                  maxWidth: "200px",
                  overflow: "scroll",
                }}
                //scope="col"
                key={value.dataField || id}
              >
                {value.text}
              </th>
            ))}
          </tr>
        </thead>
        {pageData.map((value, id) => (
          <tr key={id}>
            {Object.keys(value).map((rowValueKey, id) => {
              if (rowValueKey === "propertyId" || rowValueKey === "isCheck")
                return null;
              return (
                <th
                  style={{
                    maxWidth: "200px",
                    overflow: "scroll",
                    boxShadow: "none",
                    // borderRight: "solid 1px #ECECEC",
                    fontWeight: "normal",
                    // padding: "0px !important",
                    // background: id % 2 != 1 && "#C8D7EE",
                  }}
                  key={rowValueKey}
                >
                  {value[rowValueKey]}
                </th>
              );
            })}
          </tr>
        ))}
      </table>

      {/* pagination */}
      {pageNumber > 1 &&
        paginationMapArray.map((thisPage, index) => {
          return (
            <Button
              key={thisPage}
              variant={page === thisPage ? "primary" : "outline-secondary"}
              onClick={() => setPage(thisPage)}
              style={{
                marginRight: "5px",
              }}
            >
              {thisPage + 1}
            </Button>
          );
        })}
    </div>
  );
}
