import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { AbilityContext } from "../can/can.jsx";
import defineAbilityFor from "../../lib/ability";

export const PermissionProvider = ({ children }) => {
  // Get permissions from Redux store
  const rolePermissions = useSelector(
    (state) => state.rolePermissions.rolePermissions
  );

  // Create an Ability instance
  const ability = useMemo(
    () => defineAbilityFor(rolePermissions),
    [rolePermissions]
  );

  return (
    <AbilityContext.Provider value={ability}>
      {children}
    </AbilityContext.Provider>
  );
};

PermissionProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
