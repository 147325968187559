import React, { useState, useMemo } from "react";
import { useDispatch } from "react-redux";
import MaterialListItem from "./MaterialListItem";
import { LoadingLoader, PopupModal } from "components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { PricingList } from "features/Suppliers/Material";
import {
  deleteMaterialMiscQuote,
  getSeriesTypes,
  updateCurrentProposal,
  updateProposalInfo,
} from "features/QuotesProposals/redux/proposals/proposals.actions";
import { useSelector } from "react-redux";
import {
  useDeleteMaterialMisc,
  useSeriesTypes,
} from "features/QuotesProposals/hooks/useMaterial";
import { useUpdateMaterialQuoteline } from "features/QuotesProposals/hooks/useMaterial";
import { toast } from "react-toastify";
import Spinner from "components/common/spinner/spinner";

export default function QuoteDataList({
  loadingData,
  dataError,
  selectedSupplier,
  metricId,
  saveSectionDataToDB,
  deleteMiscItem,
  changeQuantiy,
  LoadingSkeleton,
  displayMsg,
  quoteMaterialSectionData,
  isLockQuoteData,
  selectedProposal,
  isLoading,
  tabData,
}) {
  if (dataError) {
    return displayMsg({ msg: "No data available." });
  }

  if (loadingData) {
    return new Array(10).fill(1).map((_, i) => <LoadingSkeleton key={i} />);
  }

  if (!tabData?.length) {
    return displayMsg({ msg: "No data available." });
  }

  return (
    <div>
      {tabData.map((sectionPricing, idx) => (
        <MaterialItemsWrapper
          key={`${sectionPricing._id}-${sectionPricing.category}`}
          idx={idx}
          pricing={sectionPricing}
          selectedSupplier={selectedSupplier}
          metricId={metricId}
          tabData={tabData}
          saveSectionDataToDB={saveSectionDataToDB}
          quoteMaterialSectionData={quoteMaterialSectionData}
          isLockQuoteData={isLockQuoteData}
          isLoading={isLoading}
          selectedProposal={selectedProposal}
          changeQty={changeQuantiy}
        />
      ))}
    </div>
  );
}

function MaterialItemsWrapper({
  pricing,
  editItem,
  isLockQuoteData,
  isLoading,
  selectedProposal,
  changeQty,
  changePrice,
}) {
  // const { _id, category, subType, uomPrice, qty, total } = pricing;
  const {
    _id: materialId,
    category,
    series,
    uomPrice,
    uom,
    supplierId,
    subType,
    categoryType,
    pricingCategory,
    qtyAndPrice,
    manufacturer,
    fromMetric,
    manufacturers,
    qty,
    order,
    total,
    original_list,
  } = pricing;

  const dispatch = useDispatch();
  const [showSeriesModal, setShowSeriesModal] = useState(false);
  const {
    seriesResponse,
    isLoading: isLoadingSeries,
    error,
  } = useSeriesTypes(
    {
      category,
      categoryType,
      manufacturer,
      supplierId,
    },
    showSeriesModal
  );

  const {
    isLoading: isLoadingUpdateSeries,
    isError: updateQuoteLineError,
    mutate: updateQuoteSeriesMutation,
  } = useUpdateMaterialQuoteline();

  const {
    isLoading: isLoadingUpdateManufactures,
    isError: updateQuoteManufactureresError,
    mutate: updateQuoteManufactureMutation,
  } = useUpdateMaterialQuoteline();

  const {
    isLoading: isLoadingDaterialMiscDelete,
    isError: deleteMiscMaterialError,
    mutate: deleteMiscMaterialMutation,
  } = useDeleteMaterialMisc();

  const onChangeQty = (qty, materialId, price, miscSeries) => {
    changeQty(qty, materialId, price, miscSeries);
  };

  const handlePriceChange = (value) => {
    changeQty("", materialId, value);
  };

  const openSeriesListModal = () => setShowSeriesModal(true);
  const closeModal = () => setShowSeriesModal(false);

  const changeManufacturer = (manufacturer, supplierId) => {
    // Dispatch action to get series list
    const body = {
      requestId: selectedProposal?.requestId,
      supplierId: supplierId,
      // proposalId,
      materialId: materialId,
      proposalId: selectedProposal._id,
      materialQuoteId: selectedProposal?.quotes?.materials?._id,
      manufacturer: manufacturer,
    };
    updateQuoteManufactureMutation(body, {
      onSuccess: (data) => {
        // Assuming `data.result` contains the updated proposals
        const updatedProposal = data?.result?.find(
          (proposal) => proposal._id === selectedProposal?._id // Adjust key based on response
        );
        if (updatedProposal) {
          // Dispatch an action to update the selectedProposal in Redux
          dispatch(updateCurrentProposal(updatedProposal));
        }
        console.log("Manufacturer updated successfully.");
        toast.success("Manufacturer updated successfully!");
      },

      onError: (error) => {
        console.error("Error updating Manufacturer:", error.message);
        handleGlobalError(error, dispatch);
      },
    });
  };

  const changeSeries = (selectedSerieItem) => {
    // Dispatch action to get series list
    const body = {
      requestId: selectedProposal?.requestId,
      supplierId: supplierId,
      // proposalId,
      materialId: materialId,
      proposalId: selectedProposal._id,
      materialQuoteId: selectedProposal?.quotes?.materials?._id,
      manufacturer: manufacturer,
      series: selectedSerieItem,
      // materialQuoteId
    };

    updateQuoteSeriesMutation(body, {
      onSuccess: (data) => {
        // Assuming `data.result` contains the updated proposals
        const updatedProposal = data?.result?.find(
          (proposal) => proposal._id === selectedProposal?._id // Adjust key based on response
        );
        console.log("updatedProposal123", updatedProposal);
        if (updatedProposal) {
          // Dispatch an action to update the selectedProposal in Redux
          dispatch(updateCurrentProposal(updatedProposal));
        }
        setShowSeriesModal(false);
        console.log("Series updated successfully.");
        toast.success("Series updated successfully!");
      },

      onError: (error) => {
        console.error("Error updating series:", error.message);
        handleGlobalError(error, dispatch);
        setShowSeriesModal(false);
      },
    });
  };
  const deleteMiscItem = () => {
    const body = {
      supplierId: selectedProposal?.quotes?.materials?.supplier?._id,
      proposalId: selectedProposal._id,
      materialQuoteId: selectedProposal?.quotes?.materials?._id,
      materialId: pricing._id,
    };

    deleteMiscMaterialMutation(body, {
      onSuccess: (data) => {
        // Assuming `data.result` contains the updated proposals
        const updatedProposal = data?.result?.find(
          (proposal) => proposal._id === selectedProposal?._id // Adjust key based on response
        );
        console.log({
          deleteUp: updatedProposal,
          data,
          selectedProposal,
        });
        if (updatedProposal) {
          // Dispatch an action to update the selectedProposal in Redux
          dispatch(updateCurrentProposal(updatedProposal));
        }
        console.log("Misc item deleted successfully.");
        toast.success("Misc item deleted successfully!");
      },

      onError: (error) => {
        console.error("Error Misc item delete:", error.message);
        handleGlobalError(error, dispatch);
      },
    });
  };

  const PricingListData = useMemo(() => {
    if (seriesResponse.length === 0) {
      return <p>No series available.</p>; // You can return any fallback content here
    }

    return seriesResponse.map((seriesItem) => (
      <li key={seriesItem}>
        <button
          onClick={() => changeSeries(seriesItem)}
          disabled={seriesItem === series}
          className="rounded-pill justify-content-center"
          style={{ border: "1px gray solid", marginTop: "15px" }}
        >
          {isLoadingUpdateSeries ? <Spinner label={seriesItem} /> : seriesItem}
          {seriesItem === series && (
            <FontAwesomeIcon color="#0979fa" icon={faCheck} />
          )}
        </button>
      </li>
    ));
  }, [seriesResponse, series]);

  return (
    <>
      <MaterialListItem
        disablePriceChange={category !== "Skylight"}
        handlePriceChange={handlePriceChange}
        manufacturerList={manufacturers}
        manufacturer={manufacturer}
        proposalStatus={selectedProposal?.status}
        series={series}
        // category={category + subTypeDetails}
        materialId={materialId}
        isLoadingUpdateManufactures={isLoadingUpdateManufactures}
        category={category}
        categoryType={categoryType}
        qty={qtyAndPrice?.quantity}
        uom={uom}
        supplierId={supplierId}
        price={uomPrice}
        total={qtyAndPrice?.totalPrice}
        disabled={fromMetric}
        openSeriesListModal={openSeriesListModal}
        // PricingListModal={PricingListModal}
        changeManufacturer={changeManufacturer}
        material={pricing}
        onChangeQty={onChangeQty}
        original_list={original_list}
        isLockQuoteData={isLockQuoteData?.isLocked}
        isLoading={isLoading}
        deleteMiscItem={deleteMiscItem}
        isLoadingDaterialMiscDelete={isLoadingDaterialMiscDelete}
      />

      <PopupModal
        show={showSeriesModal}
        onHide={closeModal}
        bold
        centered={false}
        styles={{ border: "none" }}
      >
        {isLoadingSeries ? (
          <LoadingLoader />
        ) : (
          <div className="__quote_pricing_list">
            <h5>Select one of the following:</h5>
            {seriesResponse?.length ? <ul>{PricingListData}</ul> : null}
          </div>
        )}
      </PopupModal>
    </>
  );
}
