import numeral from "numeral";
import {
  SLOPED_TYPES,
  FLATTYPES,
  FLOOR,
  ELEVATION,
  ELEVATIONDETAILS,
  DIFFICULTY,
  WORKTYPE,
  G_LEVEL,
  G_ELEVATIONS,
  G_ELEVATION_DETAILS,
  UserRole,
  salespersion,
} from "../lib/constants/const";
import { setGlobalError } from "lib/redux/utilities/error/error.actions";

export function clog(text) { }

export function checkPhone(number) {
  let res = number?.replace("-", "");
  res = res?.replace(" ", "");
  res = res?.replace("(", "");
  res = res?.replace(")", "");
  return res?.includes("_");
}

const parseDateZero = (value) => {
  if (+value < 10) {
    return `${value}`;
  }
  return `${value}`;
};
export function convertDate(date) {
  const DateObj = new Date(date);
  return `${parseDateZero(DateObj.getMonth() + 1)}/${parseDateZero(
    DateObj.getDate()
  )}/${DateObj.getFullYear()}`;
}

export function parseDateWithoutTime(dateString) {
  // Parse the date string into a Date object
  const date = new Date(dateString);

  // Set the time to the start of the day (00:00:00)
  date.setHours(0, 0, 0, 0);

  return date.toISOString();
}

export const checkPassword = /^(?=.*\d)(?=.*[A-Za-z]).{6,20}$/;

export const getDataLabel = (data, id) => {
  const match = data.find((item) => item.id === id);
  if (match) {
    return match.name;
  }
  return id;
};

export const getFlatOrShingles = (data) => {
  if (!data) {
    return "";
  }

  const index = SLOPED_TYPES.findIndex((item) => item.id === data);
  if (index > -1) {
    return "Shingles";
  }
  return "Flat";
};

export const getElevationLetters = (array, data) => {
  let match = "";
  array.map((elem) =>
    data.includes(elem.id)
      ? (match = `${match ? `${match} ` : ""}${elem.name[0].toUpperCase()}`)
      : match
  );
  return match;
};

// export const formatNumber = (num) => numeral(num).format("0,0");

export const debounce = (fn, delay) => {
  let timer;
  return (...args) => {
    const context = this;

    clearTimeout(timer);
    timer = setTimeout(() => {
      fn.call(context, args);
    }, delay);
  };
};

export const getRoofingSectionData = (section, i) => ({
  id: section.sectionId,
  sec: i + 1,
  floor: getDataLabel(FLOOR, section.floor),
  elevation:
    `${getElevationLetters(ELEVATION, section.elevation)}` +
    "\n" +
    `${getDataLabel(ELEVATIONDETAILS, section.elevation_details)}`,
  pitch: getDataLabel(DIFFICULTY, section.pitch),
  workType: getDataLabel(WORKTYPE, section.work_type),
  install: getDataLabel([...SLOPED_TYPES, ...FLATTYPES], section.install_types),
  l:
    section.lwfData.length && section.lwfData.length < 2
      ? section.lwfData[0].l
      : "*",
  w:
    section.lwfData.length && section.lwfData.length < 2
      ? section.lwfData[0].w
      : "*",
  f:
    section.lwfData.length && section.lwfData.length < 2
      ? section.lwfData[0].f
      : "*",
  sq:
    getFlatOrShingles(section.install_types) !== "Flat"
      ? section.lwfData.length && section.lwfData.length < 2
        ? formatNumber(section.lwfData[0].lwfTotal)
        : formatNumber(section.totalFlat)
      : "",
  flat:
    getFlatOrShingles(section.install_types) === "Flat"
      ? section.lwfData.length && section.lwfData.length < 2
        ? formatNumber(section.lwfData[0].lwfTotal)
        : formatNumber(section.totalFlat)
      : "",
});

export const getGuttersSectionData = (section, i) => ({
  id: section.sectionId,
  sec: i + 1,
  level: getDataLabel(G_LEVEL, section.level),
  elevation:
    `${getElevationLetters(G_ELEVATIONS, section.elevation)}` +
    "\n" +
    `${getDataLabel(G_ELEVATION_DETAILS, section.elevation_details)}`,
  gutters: section.guttersTotal ? section.guttersTotal : "",
  leaders: section.leadersTotal ? section.leadersTotal : "",
  screens: section.screensTotal ? section.screensTotal : "",
  fascia: section.fasciaTotal ? section.fasciaTotal : "",
  cap: section.cappingTotal ? section.cappingTotal : "",
  heads: section.leaderheadsTotal ? section.leaderheadsTotal : "",
});

export const getUserPermissions = (pageName, role, history) => {
  const filterData = UserRole[0][role[0]?.roleName?.toLowerCase()][pageName];
  if (filterData?.read === false && pageName !== "subscription") {
    history.goBack();
  }
  return filterData;
};

export const checkAsses = (access) => {
  let short = "no access";
  if (access !== short) {
    return true;
  } else {
    return false;
  }
};

export const displayPersons = (persons) => {
  let Persons = [];
  if (persons && persons.length > 0) {
    persons.map((person) => {
      return Persons.push({
        value: person.personId,
        label: person.personName,
      });
    });
  }
  return Persons;
};

export const displayPersonsPM = (persons, active) => {
  let Persons = [];
  if (persons && persons.length > 0) {
    persons.map((person) => {
      if (person.services.includes(active.toLowerCase()))
        return Persons.push({
          value: person._id,
          label: person.name,
        });
    });
  }
  return Persons;
};

export const sliceHoursAndMin = (start, inNum = false) => {
  const data = start.split(" ");
  const time = data[0];
  const meridiem = data[1];
  let hour = Number(time.split(":")[0]);
  let min = Number(time.split(":")[1]);
  if (meridiem === "PM" && hour !== 12) {
    hour = 12 + hour;
  }

  if (min < 10) {
    min = inNum ? min : `0${min}`;
  }
  return {
    hour,
    min,
  };
};

export const generateUId = () => {
  return (
    Math.random().toString(30).slice(2) + Math.random().toString(30).slice(2)
  );
};

export function timeAgo(timestamp) {
  const date = new Date(timestamp);
  const now = new Date();
  const seconds = Math.floor((now - date) / 1000);

  let interval = Math.floor(seconds / 31536000);

  if (interval >= 1) {
    return interval + " year" + (interval === 1 ? "" : "s") + " ago";
  }
  interval = Math.floor(seconds / 2592000);
  if (interval >= 1) {
    return interval + " month" + (interval === 1 ? "" : "s") + " ago";
  }
  interval = Math.floor(seconds / 86400);
  if (interval >= 1) {
    return interval + " day" + (interval === 1 ? "" : "s") + " ago";
  }
  interval = Math.floor(seconds / 3600);
  if (interval >= 1) {
    return interval + " hour" + (interval === 1 ? "" : "s") + " ago";
  }
  interval = Math.floor(seconds / 60);
  if (interval >= 1) {
    return interval + " minute" + (interval === 1 ? "" : "s") + " ago";
  }
  return "Just now";
}

// /**
//  * Scheduling modal
//  * All Api end point of Scheduling Modal
//  */
// export const GET_SCEDULING_TIME = "admin/getSchedulingTime";
// export const GET_SCEDULING_TIME_BY_ID = "admin/getSchedulingTime/";

// export const UPDATE_SSCHEDULING_MODAL_SALES =
//   "admin/updateSchedulingModalSaleTime/";
// export const UPDATE_SSCHEDULING_MODAL_REPAIR =
//   "admin/updateSchedulingModalRepairTime/";
// export const UPDATE_SSCHEDULING_MODAL_PROJECT =
//   "admin/updateSchedulingModalPMT/";
// export const UPDATE_SSCHEDULING_MODAL_GAPTIME_REPAIR =
//   "admin/updateSchedulingModalRepairGapTime/";
// export const UPDATE_SSCHEDULING_MODAL_GAPTIME_SALES =
//   "admin/updateSchedulingModalSalesGapTime/";
// export const GET_ALLVIDEO = "admin/videos/firstTimeSetup";
// export const GET_ALL_HELP_VIDEO = "admin/videos/getScreensIds";
// export const GET_ALL_COUNT = "admin/notifications/countAndCheck";

// export const GET_STAGE_BEFORE_DATA = "activity/getDataOfTodayAndBefore";
// export const GET_ALL_UPDATEON = "activity/updateWonOrFutureFollowUp";
export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const splitToGetNameAndId = (value) => {
  const splitValue = value.split("-");
  return {
    name: splitValue[0].trim(),
    id: splitValue[1].trim(),
  };
};

export const getActivePaymentMethod = (methods) => {
  return methods.find((m) => m.active);
};

export const getShinglesV2 = (installTypes) => {
  console.log("getShinglesV2", installTypes);
  // installTypes.flat.type ? "Flat" : "Shingles";
};

export const getFlatTotal = (sections) => {
  console.log("sections", sections);
  return 0;
};

export function getTotalValue(arr) {
  return arr.reduce((total, item) => {
    // Convert value to a number if it's not already
    const value = parseFloat(item.value);
    // Add the numeric value to the total
    return total + (isNaN(value) ? 0 : value);
  }, 0);
}

export const getLastElement = (arr) => {
  if (arr.length === 0) {
    return undefined; // or handle the empty array case as needed
  }
  return arr[arr.length - 1];
};

export function sumDimensonTotal(dimensionsArray) {
  return dimensionsArray.reduce((acc, dimension) => {
    const total = parseFloat(dimension.total);
    return acc + total;
  }, 0);
}

export function sumUnitDimensonTotal(dimensionsArray, unitKey) {
  return dimensionsArray?.reduce((acc, dimension) => {
    const total = parseFloat(dimension[unitKey]);
    return acc + total;
  }, 0);
}

export const sumFlatDimenstionTotal = (sections) => {
  let total = 0;
  if (sections.length) {
    for (const section of sections) {
      if (section?.installTypes?.flat && section?.installTypes?.flat?.type) {
        total += sumDimensonTotal(section?.dimensions);
      }
    }
  }
  if (total) {
    total = formatNumber(total);
  }
  return total ? `${total}(F)` : "";
};

export const sumShinglesDimenstionTotal = (sections) => {
  let total = 0;
  if (sections.length) {
    for (const section of sections) {
      if (
        section?.installTypes?.shingles &&
        section?.installTypes?.shingles?.type
      ) {
        total += sumDimensonTotal(section?.dimensions);
      }
    }
  }
  if (total) {
    total = formatNumber(total);
  }
  return total ? `${total}(S)` : "";
};

export function formatNumber(number) {
  return new Intl.NumberFormat().format(number);
}

// export const sumShinglesDimenstionTotal = (metric) => {
//   if(metric?.installTypes?.flat && metric?.installTypes?.flat?.type) {
//     return sumDimensonTotal(metric?.dimensions)
//   }
//   return 0;
// }

export function objectHasValue(obj) {
  return Object.values(obj).some((value) => {
    if (typeof value === "object" && value !== null) {
      // Recursively check nested objects
      return objectHasValue(value);
    }
    return value !== "";
  });
}

/**
 * Calculate the total of a specific numeric field in an array of objects.
 *
 * @param {Array<Object>} array - The array of objects.
 * @param {string} key - The key of the numeric field to sum up.
 * @returns {number} - The total value of the specified key.
 */
export const calculateTotalGutterMetric = (subTab, unitKey) => {
  let total = 0;

  if (subTab?.values?.length) {
    for (const value of subTab?.values) {
      total += value?.[unitKey] || 0;
    }
  }

  console.log("subTab ", subTab, unitKey, total);
  if (!total) return null;
  return total;
};

export const calculateAllTotalOfSubTabGutter = (
  sections,
  category,
  subTab,
  unitKey,
  symbol
) => {
  let total = 0;

  if (sections.length) {
    for (const section of sections) {
      if (section?.[category]?.[subTab]?.values) {
        for (const value of section?.[category]?.[subTab]?.values) {
          total += value?.[unitKey] || 0;
        }
      }
    }
  }

  if (total) {
    total = formatNumber(total);
  }

  if (!total) return null;
  return `${total}${symbol}`;
};

export const findItemByKeyAndSubTab = (metricOptions, mainKey, subTabKey) => {
  // Iterate through the metricOptions array
  for (const metricOption of metricOptions) {
    // Check if the mainKey matches
    if (metricOption.key === mainKey) {
      // Find the subTab that matches the subTabKey
      const subTab = metricOption.subTabs.find((tab) => tab.key === subTabKey);
      if (subTab) {
        return subTab; // Return the found subTab
      }
    }
  }
  // Return null if no matching item is found
  return null;
};

export const categoryMapping = {
  accessories: "Accessories",
  flashing: "Flashing",
  ventilation: "Ventilation",
  skylight: "Skylight",
  wood: "Wood",
  fasciaCapping: "Fascia and Capping",
  miscItems: "Misc Items",
};

export const gutterCategoryMappings = {
  "Gutters & Leaders": "guttersAndLeaders",
  "Fascia & Capping": "fasciaAndCapping",
  // Add other mappings here as needed
};

export function formatAddress(leadAddressData) {
  return [
    leadAddressData?.primaryAddress1,
    leadAddressData?.primaryAddress2,
    leadAddressData?.primaryCity,
    leadAddressData?.primaryState,
    leadAddressData?.primaryZip,
  ]
    .filter(Boolean)
    .join(", ");
}

export const calculateTax = (amount, taxPercentage) => {
  console.log({
    amount,
    taxPercentage,
  });

  // if (typeof amount !== 'number' || typeof taxPercentage !== 'number') {
  //   throw new Error('Both amount and taxPercentage should be numbers');
  // }

  if (taxPercentage) {
    const taxAmount = (amount * taxPercentage) / 100;
    const totalAmount = amount + taxAmount;

    return {
      taxPercentage,
      taxAmount,
      totalAmount,
    };
  }

  return {
    taxPercentage,
    taxAmount: 0,
    totalAmount: amount,
  };
};

export function getLatestStatusRecordByStatus(statusHistory, status) {
  // Initialize a variable to hold the latest record for the specified status
  let latestRecord = null;

  // Iterate over each record in the statusHistory array
  statusHistory?.forEach((record) => {
    // Check if the record's status matches the specified status
    if (record.status === status) {
      // If latestRecord is null or the current record's date is later, update latestRecord
      if (
        !latestRecord ||
        new Date(record.date) > new Date(latestRecord.date)
      ) {
        latestRecord = record;
      }
    }
  });

  return latestRecord;
}

export const cleanBodyObject = (obj) => {
  // If the input is not an object or is null, return it
  if (obj === null || typeof obj !== "object") {
    return obj;
  }

  // Create a new object to hold the cleaned data
  const newObj = Array.isArray(obj) ? [] : {};

  // Iterate over the keys in the object
  for (const key in obj) {
    const value = obj[key];

    // Recursively clean the value
    const cleanedValue = cleanBodyObject(value);

    // Only assign the cleaned value if it's not empty
    if (
      cleanedValue !== undefined &&
      cleanedValue !== null &&
      !(Array.isArray(cleanedValue) && cleanedValue.length === 0) &&
      !(
        typeof cleanedValue === "object" &&
        Object.keys(cleanedValue).length === 0
      )
    ) {
      newObj[key] = cleanedValue; // Add cleaned value to new object
    }
  }

  return newObj; // Return the cleaned object
};
export function handleGlobalError(error, dispatch) {
  // Dispatch global error action
  dispatch(setGlobalError(error));
}

export function handleGlobalSuccess(error, dispatch) {
  // Dispatch global error action
  dispatch(setGlobalError(error));
}
