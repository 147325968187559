import { useSelector } from "react-redux";

import styles from "lib/config/baseStyles";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import { PriceInput } from "components";
import PercentageModal from "features/QuotesProposals/ProposalTab/PercentageModal";
import { useDispatch } from "react-redux";
import {
  getQuoteMarkup,
  updateCurrentProposal,
  updateProposalInfo,
} from "features/QuotesProposals/redux/proposals/proposals.actions";
import { taxChooseDesc } from "lib/constants/const";
import { useUpdateMaterialQuoteline } from "features/QuotesProposals/hooks/useMaterial";
import { toast } from "react-toastify";

const TotalQuote = ({ markUp, isLockQuoteData }) => {
  const [per, setPer] = useState(markUp);
  const [percent, setPercent] = useState(per);
  const [showMarkUpModal, setShowMarkUpModal] = useState(false);
  const proposalData = useSelector((state) => state.proposal);
  const { selectedProposal } = proposalData;
  const changePer = (value) => setPer(value);
  const dispatch = useDispatch();

  const { isLoadingQuoteMarkup, quoteMarkupError, quoteMarkupResponse } =
    useSelector((state) => state.proposal);

  const {
    isLoading: isLoadingUpdateQuoteMarkup,
    isError: updateQuoteLineError,
    mutate: updateQuoteSeriesMutation,
  } = useUpdateMaterialQuoteline();

  console.log({
    quoteMarkupResponse,
    selectedProposal,
  });

  const selectedMarkup = selectedProposal?.quotes?.markupPercentage;
  const stateTax =
    selectedProposal?.quotes?.materials?.tax?.stateTax ||
    selectedProposal?.quotes?.materials?.tax?.exemptId;
  const [markupPercentage, seteMarkupPercentage] = useState(
    selectedMarkup || 0
  );

  const onChangeMarkup = (increase = true) => {
    console.log({ increase });

    if (increase) {
      seteMarkupPercentage((prevValue) => prevValue + 1);
    } else {
      seteMarkupPercentage((prevValue) => prevValue - 1);
    }
  };

  const onMarkupModalShow = () => {
    setShowMarkUpModal(true);
  };

  const hideMarkupModal = () => {
    setShowMarkUpModal(false);
  };
  const saveMarkup = () => {
    const body = {
      requestId: selectedProposal?.requestId,
      // proposalId,
      proposalId: selectedProposal._id,
      materialQuoteId: selectedProposal?.quotes?._id,

      markupPercentage: markupPercentage,
      // materialQuoteId
    };
    updateQuoteSeriesMutation(body, {
      onSuccess: (data) => {
        // Assuming `data.result` contains the updated proposals
        const updatedProposal = data?.result?.find(
          (proposal) => proposal._id === selectedProposal?._id // Adjust key based on response
        );
        console.log("updatedProposal123", updatedProposal);
        if (updatedProposal) {
          // Dispatch an action to update the selectedProposal in Redux
          dispatch(updateCurrentProposal(updatedProposal));
        }
        hideMarkupModal();
        console.log("Quote markup updated successfully.");
        toast.success("Quote markup updated successfully!");
      },

      onError: (error) => {
        console.error("Error updating quote markup:", error.message);
        handleGlobalError(error, dispatch);
        hideMarkupModal();
      },
    });
  };

  const cancelChanges = () => {
    setPercent(per);
    hideMarkupModal();
  };
  const renderRow = (
    label,
    value,
    isSelect = false,
    isGreenBackground = false
  ) => {
    console.log({
      label,
      value,
      isSelect,
      isGreenBackground,
    });
    return (
      <div className="d-flex justify-content-end align-items-center w-100 mb-2">
        <p className="mb-0 me-3">{label}</p>
        {isSelect ? (
          <div
            className="d-flex align-items-center justify-content-end gap-3"
            style={{
              width: "300px",
            }}
          >
            <button
              onClick={onMarkupModalShow}
              style={{
                textAlign: "center",
                background: "yellow",
                color: "#111",
                width: "150px",
              }}
              className="form-control"
              disabled={isLockQuoteData?.isLocked}
            >
              {selectedMarkup}%
            </button>

            <PriceInput
              input="Total"
              style={{
                width: "150px",
              }}
              // price={badStateTax ? 0 : markUpAmt}
              price={(selectedProposal?.total / 100) * selectedMarkup}
              disabled
            />
          </div>
        ) : (
          <PriceInput
            input="Total"
            price={value || 0}
            style={{
              width: "200px",
              ...(isGreenBackground
                ? { background: "#28a745", color: "#fff" }
                : {}),
            }}
            disabled
          />
        )}
      </div>
    );
  };

  return (
    <>
      <Container fluid className="mt-4">
        <Row className="justify-content-end d-flex align-items-center">
          {!stateTax && (
            <p className="text-end mt-3 fw-bold">{taxChooseDesc}</p>
          )}
          {/* Reusable row component for label and input */}
          {renderRow("Total Material & Labor:", selectedProposal?.total)}
          {renderRow("Mark-Up:", "", true)}
          {renderRow(
            "Total Sale Price:",
            selectedProposal?.total || 0,
            false,
            true
          )}
        </Row>
      </Container>
      <PercentageModal
        onHide={hideMarkupModal}
        show={showMarkUpModal}
        percent={markupPercentage}
        onCancel={cancelChanges}
        onSubmit={saveMarkup}
        changePerData={onChangeMarkup}
        submitText="Apply"
        heading="Mark-Up Percentage"
        maxMarkUp={quoteMarkupResponse?.max}
        minMarkUp={quoteMarkupResponse?.min}
        processing={isLoadingUpdateQuoteMarkup}
      />
    </>
  );
};

{
  /* <div style={{ padding: "1em" }}>
<section>
  <Row>
    <Col style={styles.reduceWidthCol} lg="6" />
    <Col style={styles.reduceWidthCol} lg="6">
      {stateTax && (
        <p
          style={{
            textAlign: "right",
            marginTop: "1rem",
            fontWeight: "bold",
          }}
        >
          {taxChooseDesc}
        </p>
      )}
      <Row
        style={{
          justifyContent: "flex-end",
        }}
      >
        <Col
          style={{
            ...styles.reduceWidthCol,
          }}
          lg="9"
        />
      </Row>
      <Row style={{ marginBottom: "1%" }}>
        <Col
          style={{ ...styles.reduceWidthCol, textAlign: "right" }}
          lg="9"
        >
          <Form.Label className="mt-1">
            Total Material & Labor:
          </Form.Label>
        </Col>
        <Col style={styles.reduceWidthCol} lg="3">
          <PriceInput
            input="Total"
            price={selectedProposal?.total || 0}
            disabled
          />
        </Col>
      </Row>
      <Row style={{ marginBottom: "1%" }}>
        <Col
          style={{ ...styles.reduceWidthCol, textAlign: "right" }}
          lg="6"
        >
          <Form.Label className="mt-1">Mark-Up:</Form.Label>
        </Col>
        <Col style={styles.reduceWidthCol} lg="3">
          <button
            onClick={onMarkupModalShow}
            style={{
              textAlign: "center",
              background: "yellow",
              color: "#111",
            }}
            className="form-control"
            disabled={isLockQuoteData?.isLocked}
          >
            {markupPercentage}%
          </button>
          <PercentageModal
            onHide={hideMarkupModal}
            show={showMarkUpModal}
            percent={markupPercentage}
            onCancel={cancelChanges}
            onSubmit={saveMarkup}
            changePerData={onChangeMarkup}
            submitText="Apply"
            heading="Mark-Up Percentage"
            maxMarkUp={quoteMarkupResponse?.max}
            minMarkUp={quoteMarkupResponse?.min}
          />
        </Col>
        <Col style={styles.reduceWidthCol} lg="3">
          <PriceInput
            input="Total"
            // price={badStateTax ? 0 : markUpAmt}
            price={(selectedProposal?.total / 100) * markupPercentage}
            disabled
          />
        </Col>
      </Row>
      <Row>
        <Col
          style={{ ...styles.reduceWidthCol, textAlign: "right" }}
          lg="9"
        >
          <Form.Label className="mt-1">Total Sale Price:</Form.Label>
        </Col>
        <Col style={styles.reduceWidthCol} lg="3">
          <PriceInput
            input="Total"
            // price={badStateTax ? 0 : totalWithMarkUp}
            price={selectedProposal?.total || 0}
            style={{
              background: "#28a745",
              color: "#fff",
            }}
            disabled
          />
        </Col>
      </Row>
    </Col>
  </Row>
</section>
</div> */
}

export default TotalQuote;
