import React from "react";
import {
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
} from "reactstrap";
import PropTypes from "prop-types";
import classnames from "classnames";
import "./wizard.css";
import {
  registerUser,
  resendVerifiation,
  verify_otp,
} from "lib/redux/user/user.actions";
import { toast } from "react-toastify";
import { Alert } from "react-bootstrap";
import { splitToGetNameAndId } from "utils/utils";

class ReactWizard extends React.Component {
  constructor(props) {
    super(props);

    let width;
    if (this.props.steps.length === 1) {
      width = "100%";
    } else if (window.innerWidth < 600) {
      if (this.props.steps.length !== 3) {
        width = "50%";
      } else {
        width = `${100 / 3}%`;
      }
    } else if (this.props.steps.length === 2) {
      width = "50%";
    } else {
      width = `${100 / 3}%`;
    }

    // Component States
    this.state = {
      highestStep: 0,
      color: this.props.color !== undefined ? this.props.color : "primary",
      nextButton: this.props.steps.length > 1,
      previousButton: false,
      finishButton: this.props.steps.length === 1, // this.props.currentStep + 1,
      width,
      wizardData:
        this.props.wizardData !== undefined ? this.props.wizardData : {},
      movingTabStyle: {
        transition: "transform 0s",
      },
      progressbarStyle: {
        width: `${100 / this.props.steps.length / 2}%`,
      },
      errMsg: "",
      errType: "",
      successfullySavedUser: false,
      resendApiCalled: false,
      resendForSignup: false,
      submitting: false,
    };

    this.navigationStepChange = this.navigationStepChange.bind(this);
    this.refreshAnimation = this.refreshAnimation.bind(this);
    this.previousButtonClick = this.previousButtonClick.bind(this);
    this.previousButtonClick = this.previousButtonClick.bind(this);
    this.finishButtonClick = this.finishButtonClick.bind(this);
  }

  componentDidMount() {
    this.refreshAnimation(0);
    window.addEventListener("resize", this.updateWidth.bind(this));
  }

  componentWillUnmount() {
    this.isCancelled = true;
    window.removeEventListener("resize", this.updateWidth);
    let id = window.setTimeout(null, 0);
    while (id--) {
      window.clearTimeout(id);
    }
  }
  componentDidUpdate(prevProps, prevState) {
    const { currentStep, steps, type, updateStep } = this.props;

    // Check if the relevant props have changed
    if (
      currentStep !== prevProps.currentStep ||
      steps.length !== prevProps.steps.length
    ) {
      this.navigationStepChange(currentStep);
    }

    const currentStepName = steps[currentStep]?.stepName;
    const currentStepData = steps[currentStep]?.stepProps;

    // Only update resendApiCalled if resendOtpButtonStatus changes
    if (currentStepData?.resendOtpButtonStatus) {
      this.setState(
        {
          resendApiCalled: false,
          resendForSignup: true,
        },
        () => {
          currentStepData.updateResendButtonStatus(false);
        }
      );
    }

    // Add conditions to prevent duplicate API calls
    if (
      (type === "edit" ||
        type === "Edit_But_Not_Verified" ||
        this.state.resendForSignup) &&
      currentStep === 2 &&
      currentStepName === "User Verification" &&
      currentStepData.aEmail &&
      !this.state.resendApiCalled &&
      // Add check for email change
      (!prevProps.steps[currentStep]?.stepProps.aEmail ||
        prevProps.steps[currentStep].stepProps.aEmail !==
          currentStepData.aEmail)
    ) {
      this.setState({ resendApiCalled: true }, () => {
        this.submitResendVerification(currentStepData.aEmail, {
          isFromSignUp: true,
        });
      });
    }
  }

  updateWidth() {
    !this.isCancelled &&
      setTimeout(() => this.refreshAnimation(this.props.currentStep), 200);
  }

  navigationStepChange(key) {
    if (this.props.navSteps) {
      let validationState = true;
      if (this.props.validate && key > this.props.currentStep) {
        for (let i = this.props.currentStep; i < key; i++) {
          if (
            this.refs[this.props.steps[i].stepName].isValidated !== undefined &&
            this.refs[this.props.steps[i].stepName].isValidated() === false
          ) {
            validationState = false;
            break;
          }
        }
      }

      if (validationState) {
        this.setState({
          wizardData: {
            ...this.state.wizardData,
            [this.props.steps[this.props.currentStep]?.stepName]:
              this.refs[this.props.steps[this.props.currentStep]?.stepName]
                .state,
          },
          // currentStep: key,
          highestStep:
            key > this.state.highestStep ? key : this.state.highestStep,
          nextButton: this.props.steps.length > key + 1,
          previousButton: key > 0,
          finishButton: this.props.steps.length === key + 1,
        });
        this.props.updateStep(key);
        this.refreshAnimation(key);
      }
    }
  }

  saveUserForRegistration(e, currentStepData) {
    e.preventDefault();
    // Destructure props for easier access
    const {
      cName,
      cAddress1,
      cAddress2,
      cCity,
      cState,
      cZip,
      primaryPhone,
      secondaryPhone,
      cWebsite,
      cEmail,
      fax,
      fName,
      lName,
      aEmail,
      aConfirmEmail,
      aPhone,
      password,
      get_company_id,
      county,
      currentCounty,
    } = currentStepData;

    // Set submitting state to true to indicate form submission in progress
    this.setState({
      submitting: true,
      errMsg: "",
      errType: "",
    });

    // Prepare data for registration
    const registrationData = {
      companyName: cName,
      address1: cAddress1,
      address2: cAddress2,
      city: cCity,
      stateId: cState,
      zip: Number(cZip),
      phone1Code: "+1",
      phone1: primaryPhone,
      phone2Code: "+1",
      phone2: secondaryPhone,
      faxCode: "+1",
      fax,
      currentCounty: currentCounty,
      website: cWebsite,
      companyEmail: cEmail,
      firstName: fName,
      lastName: lName,
      email: aEmail,
      password,
      phoneCode: "+1",
      phone: aPhone,
      type: "initial",
      county,
    };

    console.log("Sign up in wizard", { registrationData });

    // Send registration request
    registerUser(registrationData)
      .then((res) => res.data)
      .then((data) => {
        // Handle response
        if (data.success) {
          // Update state upon successful registration
          this.setState({
            onSubmitCheck: true,
            submitting: false,
            submitSuccess: true,
            successfullySavedUser: true,
          });
          const { updateStep, currentStep } = this.props;
          // Increment step key
          const key = currentStep + 1;

          // Call the updateStep function provided by parent component
          updateStep(key);

          // Refresh animation if needed
          this.refreshAnimation(key);

          // Store company ID in localStorage
          localStorage.setItem("companyId", `${data?.result?.companyId}`);
          // Call the get_company_id function provided by parent component
          get_company_id(data?.result?.companyId);

          // Notify user of successful registration
          toast.success(data.result.message);
        } else {
          // Update state if registration failed
          this.setState({
            submitting: false,
            errMsg: data.error,
            errType: "danger",
          });
          // Notify user of registration failure
          // toast.error("Data not saved");
        }
      })
      .catch((err) => {
        // Handle error
        this.setState({
          submitting: false,
          errMsg: err.message,
          errType: "danger",
        });
        // Notify user of error
        toast.error("Something Went Wrong");
      });
  }

  submitOtp(e, currentStepData) {
    const { aOtp, isLoading, updateStep } = currentStepData;
    this.setState({
      isLoading: true,
    });
    e.preventDefault();

    const aEmail = currentStepData.aEmail;
    const verifyType = this.props.type ? "re-send verification" : "verify";

    if (!currentStepData.otpSubmittedStatus) {
      verify_otp(aEmail, aOtp, verifyType)
        .then((res) => {
          if (res.data.success) {
            toast.success("Otp Verified");
            currentStepData.updateOtpStatus(true);
            localStorage.setItem("otp", "verified");
            updateStep(3);
            this.setState({
              check: true,
            });
          } else {
            toast.error("Please check your otp");
            this.setState({
              check: false,
            });
          }
        })
        .catch((err) => {
          console.log({ err });
          toast.error("Something went wrong");
          this.setState({
            check: false,
          });
        })
        .finally(() => {
          this.setState({
            isLoading: false,
          });
        });
    } else {
      updateStep(3);
      this.setState({
        check: true,
      });
    }
  }

  async submitResendVerification(email, others = {}) {
    try {
      const res = await resendVerifiation({ email, ...others });
      const data = res.data;
      if (data.success) {
        toast.success(data.result.message);
      } else {
        toast.error(data.error);
      }
    } catch (err) {
      toast.error(err.message);
    }
  }

  nextButtonClick(e) {
    // Destructure props and state for easier access
    const { validate, steps, currentStep, updateStep, type } = this.props;
    const { wizardData, highestStep } = this.state;

    // Get the name and reference of the current step
    const currentStepName = steps[currentStep]?.stepName;
    const currentStepRef = this.refs[currentStepName];

    // Increment step key
    const key = currentStep + 1;

    // Determine if the next step is the last step
    const isLastStep = key === steps.length;

    // Determine visibility of next and previous buttons
    const nextButton = steps.length > key;
    const previousButton = key > 0;

    const otpSubmittedStatus = localStorage.getItem("otp") === "verified";

    // Check if current step has a validation function, if yes, validate it
    let isValidated =
      currentStepRef && typeof currentStepRef.isValidated === "function"
        ? currentStepRef.isValidated()
        : true;

    if (
      currentStep === 2 &&
      currentStepName == "User Verification" &&
      otpSubmittedStatus
    ) {
      isValidated = true;
    }

    // Check if the wizard should proceed to the next step based on validation
    const shouldProceed = validate === undefined || !validate || isValidated;

    // Save user data and check if the account already registered before

    // Proceed only if validation passes or if no validation is required
    if (shouldProceed) {
      // Update wizard state with new data
      this.setState({
        wizardData: {
          ...wizardData,
          [currentStepName]: currentStepRef.state,
        },
        highestStep: Math.max(key, highestStep),
        nextButton,
        previousButton,
        finishButton: isLastStep,
      });

      // This conditon used for registering new user
      const currentStepData = steps[currentStep]?.stepProps;
      console.log({ currentStep, currentStepName, type });

      if (
        type !== "edit" &&
        type !== "Edit_But_Not_Verified" &&
        currentStep === 1 &&
        currentStepName == "Subscription Administrator"
      ) {
        this.saveUserForRegistration(e, currentStepData);

        // This conditon used for to verify otp
      } else if (currentStep === 2 && currentStepName == "User Verification") {
        if (!otpSubmittedStatus) {
          console.log("submitOtp");
          console.log({ currentStepData, props: this.props });
          this.submitOtp(e, currentStepData);
        } else {
          updateStep(3);
        }
      } else {
        // Call the updateStep function provided by parent component
        updateStep(key);

        // Refresh animation if needed
        this.refreshAnimation(key);
      }
    }
    // }
  }

  previousButtonClick() {
    const key = this.props.currentStep - 1;
    if (key >= 0) {
      this.setState({
        wizardData: {
          ...this.state.wizardData,
          [this.props.steps[this.props.currentStep].stepName]:
            this.refs[this.props.steps[this.props.currentStep].stepName].state,
        },
        // currentStep: key,
        highestStep:
          key > this.state.highestStep ? key : this.state.highestStep,
        nextButton: this.props.steps.length > key + 1,
        previousButton: key > 0,
        finishButton: this.props.steps.length === key + 1,
      });
      this.props.updateStep(key);
      this.refreshAnimation(key);
    }
  }

  finishButtonClick() {
    if (
      (this.props.validate === false &&
        this.props.finishButtonClick !== undefined) ||
      (this.props.validate &&
        ((this.refs[this.props.steps[this.props.currentStep].stepName]
          .isValidated !== undefined &&
          this.refs[
            this.props.steps[this.props.currentStep].stepName
          ].isValidated()) ||
          this.refs[this.props.steps[this.props.currentStep].stepName]
            .isValidated === undefined) &&
        this.props.finishButtonClick !== undefined)
    ) {
      this.setState(
        {
          submitting: true,
          progressbarStyle: {
            width: "100%",
          },
          wizardData: {
            ...this.state.wizardData,
            [this.props.steps[this.props.currentStep].stepName]:
              this.refs[this.props.steps[this.props.currentStep].stepName]
                .state,
          },
        },
        async () => {
          try {
            const isSuccess = await this.props.finishButtonClick(
              this.state.wizardData
            );
            if (!isSuccess) {
              return;
            }
            // Reset OTP status and payment info after successful finish
            const currentStepData =
              this.props.steps[this.props.currentStep]?.stepProps;
            if (currentStepData?.updateOtpStatus) {
              currentStepData.updateOtpStatus(false);
              localStorage.removeItem("otp");
            }

            // Reset company data in Redux store
            if (currentStepData?.resetCompanyData) {
              currentStepData.resetCompanyData();
            }
            localStorage.removeItem("otp");
            localStorage.removeItem("companyId");
          } finally {
            this.setState({ submitting: false });
          }
        }
      );
    }
  }

  refreshAnimation(index) {
    const total = this.props.steps.length;
    let li_width = 100 / total;

    const total_steps =
      this.props.steps !== undefined ? this.props.steps.length : 0;
    let move_distance =
      this.refs.wizard !== undefined
        ? this.refs.navStepsLi.children[0].clientWidth / total_steps
        : 0;
    let index_temp = index;
    let vertical_level = 0;

    const mobile_device = window.innerWidth < 600 && total > 3;

    if (mobile_device) {
      move_distance = this.refs.navStepsLi.children[0].clientWidth / 2;
      index_temp = index % 2;
      li_width = 50;
    }

    this.setState({ width: `${li_width}%` });

    const step_width = move_distance;

    move_distance *= index_temp;

    if (mobile_device) {
      vertical_level = parseInt(index / 2);
      vertical_level *= 38;
    }

    const movingTabStyle = {
      width: step_width,
      transform: `translate3d(${move_distance}px, ${vertical_level}px, 0)`,
      transition: "all 0.5s cubic-bezier(0.29, 1.42, 0.79, 1)",
    };

    this.setState({
      movingTabStyle,
      progressbarStyle: {
        width: move_distance + step_width / 2,
      },
    });
  }

  render() {
    const { errMsg, errType } = this.state;
    return (
      <div className="wizard-container" ref="wizard">
        <div className="card-wizard active" data-color={this.state.color}>
          {this.props.title !== undefined ||
          this.props.description !== undefined ? (
            <CardHeader
              className={
                this.props.headerTextCenter !== undefined ? "text-center" : ""
              }
              data-background-color={this.state.color}
            >
              {/* Stepper section name */}
              {this.props.title !== undefined ? (
                <CardTitle tag="h3">{this.props.title}</CardTitle>
              ) : null}

              {this.props.description !== undefined ? (
                <h3 className="description">{this.props.description}</h3>
              ) : null}

              <div className="wizard-navigation" ref="navStepsLi">
                <div className="progress-with-circle">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={this.state.progressbarStyle}
                  />
                </div>

                <div className="mt-4"></div>

                {/* Map over the steps arary and render the header of the steps */}
                <Nav pills>
                  {this.props.steps.map((step, key) => (
                    <NavItem key={key} style={{ width: this.state.width }}>
                      <NavLink
                        className={classnames(
                          { active: key === this.props.currentStep },
                          { checked: key <= this.state.highestStep }
                        )}
                        // onClick={() => this.navigationStepChange(key)}
                      >
                        {step.stepIcon ? <i className={step.stepIcon} /> : null}

                        {this.props.progressbar ? (
                          <p>{step.stepName}</p>
                        ) : (
                          step.stepName
                        )}
                      </NavLink>
                    </NavItem>
                  ))}
                </Nav>
                {/* margin */}
                {this.props.progressbar ? null : (
                  <div
                    className="mt-4 moving-tab"
                    style={this.state.movingTabStyle}
                  >
                    {this.props.steps[this.props.currentStep]?.stepIcon !==
                      undefined &&
                    this.props.steps[this.props.currentStep]?.stepIcon !==
                      "" ? (
                      <i
                        className={
                          this.props.steps[this.props.currentStep]?.stepIcon
                        }
                      />
                    ) : null}
                    {this.props.steps[this.props.currentStep]?.stepName}
                  </div>
                )}
              </div>
            </CardHeader>
          ) : null}
          {errMsg ? (
            <div className="mt-4">
              <Alert variant={errType}>{errMsg}</Alert>
            </div>
          ) : null}
          {/* Map over the steps arary and render body of the seleted step */}
          <CardBody>
            <TabContent activeTab={this.props.currentStep}>
              {this.props.steps.map((step, key) => (
                <TabPane
                  tabId={key}
                  key={key}
                  className={classnames("fade", {
                    show: this.props.currentStep === key,
                  })}
                >
                  {typeof step.component === "function" ? (
                    <step.component
                      ref={step.stepName}
                      wizardData={this.state.wizardData}
                      company_id={this.props.company_id}
                      additionalUser={this.props.additionalUser}
                      {...step.stepProps}
                    />
                  ) : (
                    <div ref={step.stepName}>{step.component}</div>
                  )}
                </TabPane>
              ))}
            </TabContent>
          </CardBody>
          <CardFooter>
            <div style={{ float: "right" }}>
              {this.state.nextButton ? (
                <Button
                  className={classnames("btn-next", {
                    [this.props.nextButtonClasses]:
                      this.props.nextButtonClasses !== undefined,
                  })}
                  onClick={(e) => this.nextButtonClick(e)}
                >
                  {this.props.nextButtonText !== undefined
                    ? this.props.nextButtonText
                    : "Next"}
                </Button>
              ) : null}
              {this.state.finishButton ? (
                <Button
                  className={classnames("btn-finish d-inline-block", {
                    [this.props.finishButtonClasses]:
                      this.props.finishButtonClasses !== undefined,
                  })}
                  disabled={this.state.submitting}
                  onClick={() => this.finishButtonClick()}
                >
                  {this.state.submitting ? (
                    <span
                      className="spinner-border spinner-border-sm me-2"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : null}
                  {this.props.finishButtonText !== undefined
                    ? this.props.finishButtonText
                    : "Finish"}
                </Button>
              ) : null}
            </div>
            <div style={{ float: "left" }}>
              {this.state.previousButton ? (
                <Button
                  className={classnames("btn-previous", {
                    [this.props.previousButtonClasses]:
                      this.props.previousButtonClasses !== undefined,
                  })}
                  onClick={() => this.previousButtonClick()}
                >
                  {this.props.previousButtonText !== undefined
                    ? this.props.previousButtonText
                    : "Previous"}
                </Button>
              ) : null}
            </div>
            <div className="clearfix" />
          </CardFooter>
        </div>
      </div>
    );
  }
}

ReactWizard.defaultProps = {
  validate: false,
  previousButtonText: "Previous",
  finishButtonText: "Finish",
  nextButtonText: "Next",
  color: "primary",
  progressbar: false,
  company_id: null,
};

ReactWizard.propTypes = {
  color: PropTypes.oneOf(["primary", "green", "orange", "red", "blue"]),
  previousButtonClasses: PropTypes.string,
  finishButtonClasses: PropTypes.string,
  nextButtonClasses: PropTypes.string,
  headerTextCenter: PropTypes.bool,
  navSteps: PropTypes.bool,
  validate: PropTypes.bool,
  finishButtonClick: PropTypes.func,
  updateStep: PropTypes.func,
  previousButtonText: PropTypes.node,
  finishButtonText: PropTypes.node,
  nextButtonText: PropTypes.node,
  title: PropTypes.node,
  type: PropTypes.string,
  description: PropTypes.node,
  progressbar: PropTypes.bool,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      stepName: PropTypes.string.isRequired,
      stepIcon: PropTypes.string,
      component: PropTypes.func.isRequired,
      stepProps: PropTypes.object,
    })
  ).isRequired,
  company_id: PropTypes.string,
};

export default ReactWizard;
