import React, { PureComponent } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Dashboard from "features/dashboard/dashboard";
import { connect } from "react-redux";
import { signOut } from "lib/redux/user/user.actions";
import SalesActivity from "features/activity/sales/sales-activity";
import RepairsActivity from "features/activity/repairs/repairs-activity";
import Calendar from "features/calendar/calendar";
// import MetricRoofing from "screens/metric/roofing";
import MetricRoofing from "features/metric/MetricRoofing";
import ApplicationSettings from "features/ApplicationSettings/ApplicationSettings";
import Suppliers from "features/Suppliers/Suppliers";
import QuotesProposals from "features/QuotesProposals";
import AdminRoutes from "lib/routes/AdminRoutes";
import { Container } from "react-bootstrap";
import Help from "features/Help/Help";
import ProjectManagement from "features/project-management";
import Header from "components/Header/Header";
import { Metric } from "features/metric/Gutters";
import { permissions } from "lib/config/permissionSettings";
import { Can } from "../../components/can";

class DashboardPanel extends PureComponent {
  render() {
    const { signOut, userRole, paymentStatus, activate } = this.props;
    if (paymentStatus == "Frozen 2") {
      return (
        <div>
          <Header signOut={signOut} userRole={userRole} />
          <Container style={{ maxWidth: "100%" }}>
            <Redirect to="/dashboard/application-settings" />
            <AdminRoutes
              path="/dashboard/application-settings"
              component={ApplicationSettings}
            />
          </Container>
        </div>
      );
    } else {
      return (
        <div>
          <Header
            signOut={signOut}
            userRole={userRole}
            paymentStatus={paymentStatus}
          />
          <Container style={{ maxWidth: "100%" }}>
            <Switch>
              <Route
                path="/dashboard/proposal-quotes/:id"
                component={QuotesProposals}
              />

              <AdminRoutes path="/dashboard/suppliers" component={Suppliers} />
              <AdminRoutes
                path="/dashboard/application-settings"
                component={ApplicationSettings}
              />
              <Route
                exact
                path="/dashboard/activity/sales"
                component={SalesActivity}
              />
              <Route
                exact
                path="/dashboard/activity/repairs"
                component={RepairsActivity}
              />
              <Route exact path="/dashboard/calendar" component={Calendar} />
              <Route
                exact
                path="/dashboard/metrics/roof/:id"
                component={MetricRoofing}
              />
              <Route
                exact
                path="/dashboard/metrics/gutters/:id"
                component={Metric}
              />
              <Route
                exact
                path="/dashboard/project-management"
                component={ProjectManagement}
              />
              <Route path="/dashboard/help" component={Help} />

              <Route path="/dashboard" component={Dashboard} />
            </Switch>
          </Container>
        </div>
      );
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    signOut: () => dispatch(signOut()),
  };
}
const mapStateToProps = (state) => {
  const { login } = state;
  return {
    userRole: login.user ? login.user.userData.role : null,
    paymentStatus: login?.user?.userData?.paymentStatus
      ? login.user.userData.paymentStatus
      : null,
    activate:
      login?.user?.userData?.activate === false
        ? login.user.userData.activate
        : null,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPanel);
