import { axios, axiosInstance } from "services";

import AuthService from "services/auth-service";
import { getUserLocal } from "lib/constants/localbase";
import { UserActionTypes } from "./user.types";
import { ApiEndpoints } from "lib/config/baseSettings";

export const LoginFailure = (errors) => ({
  type: UserActionTypes.LOGIN_FAILURE,
  payload: errors,
});

export const LoginStart = () => ({
  type: UserActionTypes.LOGIN_START,
});

export const LoginSuccess = (data) => ({
  type: UserActionTypes.LOGIN_SUCCESS,
  payload: data,
});

export const registerUser = (user) => {
  const Url = ApiEndpoints.USER.registerUser;
  return axios.post(Url, { ...user });
};

export const get_stripe_test_public_key = (user) => {
  const Url = ApiEndpoints.USER.get_stripe_test_public_key;
  return axios.get(Url);
};

export const get_stripe_test_secret_key = (user) => {
  const Url = ApiEndpoints.USER.get_stripe_test_secret_key;
  return axios.post(Url, {});
};

export const verify_otp = (email, otp, verifyType) => {
  const Api = ApiEndpoints.USER.verify_otp;
  return axios.get(`${Api(email, otp, verifyType)}`);
};

// Fetch company redux
export const get_company_by_companyId = (companyId) => {
  console.log({ companyId });
  const url = `${ApiEndpoints.USER.get_company_by_companyId}?companyId=${companyId}`;
  return axios.get(url);
};

export const GetCompanyStart = () => ({
  type: UserActionTypes.GET_COMPANY_START,
});

export const GetCompanySuccess = (data) => ({
  type: UserActionTypes.GET_COMPANY_SUCCESS,
  payload: data,
});

export const GetCompanyFailure = (errors) => ({
  type: UserActionTypes.GET_COMPANY_FAILURE,
  payload: errors,
});

export const getCompanyById = (companyId) => {
  console.log("cd1", companyId);
  return (dispatch) => {
    dispatch(GetCompanyStart());
    return get_company_by_companyId(companyId)
      .then((res) => res.data)
      .then(async (data) => {
        if (data.success) {
          dispatch(GetCompanySuccess(data.result));
        } else {
          dispatch(GetCompanyFailure(data));
        }
      })
      .catch((err) => dispatch(GetCompanyFailure(err)));
  };
};

export const update_paid_in_user = (companyId) => {
  return axios.post(ApiEndpoints.USER.update_paid_in_user, { ...companyId });
};

export const create_payment_details_for_company_in_database = (body) => {
  return axios.post(
    ApiEndpoints.USER.create_payment_details_for_company_in_database,
    {
      ...body,
    }
  );
};

export const userLogin = (user, rememberMe) => {
  // const { isFetching, fetchOfflineData } = useFetchData();
  const Url = ApiEndpoints.USER.userLogin;
  return async (dispatch) => {
    dispatch(LoginStart());
    try {
      const res = await axios.post(Url, { ...user });
      const data = res.data;
      if (data.success) {
        await AuthService.saveToken(data.result, rememberMe);

        //fetch data
        // await cb();
        //full fetch
        // fetchOfflineData();
        // if (data.error === '') {
        // }
        dispatch(LoginSuccess(data.result));
      } else {
        dispatch(LoginFailure(data));
      }
    } catch (err) {
      return dispatch(LoginFailure(err));
    }
  };
};

export const checkUserData = (onFinally) => (dispatch) => {
  if (AuthService.isAuthenticated()) {
    return axiosInstance
      .get(ApiEndpoints.USER.checkUserData)
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          AuthService.saveToken(data.result.token);
          dispatch(LoginSuccess(data.result));
        }
      })
      .catch((err) => {})
      .finally(() => onFinally());
  }
  onFinally();
};

export const clearLoggedIn = () => ({
  type: UserActionTypes.SIGN_OUT,
});

export const signOut = () => {
  AuthService.invalidateUser();

  return (dispatch) => dispatch(clearLoggedIn());
};

export const forgetPassword = (email) => {
  const Url = ApiEndpoints.USER.forgetPassword;
  return axios.post(Url, { ...email });
};

export const resendVerifiation = (email) => {
  const Url = ApiEndpoints.USER.resendVerifiation;
  return axios.post(Url, { ...email });
};

export const getUsers = () => (dispatch) => {
  dispatch({ type: UserActionTypes.GET_USERS_START });

  return axiosInstance
    .get(ApiEndpoints.USER.users)
    .then((res) => res.data)
    .then((data) => {
      if (data.success) {
        return dispatch({
          type: UserActionTypes.GET_USERS_SUCCESS,
          payload: data.result,
        });
      }
      return dispatch({
        type: UserActionTypes.GET_USERS_FAILURE,
        payload: data.error,
      });
    })
    .catch((errors) =>
      dispatch({
        type: UserActionTypes.GET_USERS_FAILURE,
        payload: errors.message,
      })
    );
};

export const addUser = (data) => (dispatch) =>
  axiosInstance.post(ApiEndpoints.USER.users, { ...data });

export const updateUser = (id, data) => (dispatch) =>
  axiosInstance.put(ApiEndpoints.USER.userWithId(id), { ...data });

export const deleteUser = (id) => (dispatch) =>
  axiosInstance.delete(ApiEndpoints.USER.userWithId(id));

export const toggleLock = (uid) => (dispatch) =>
  axiosInstance.patch(ApiEndpoints.USER.toggleLock(uid));

export const validateToken = (cb) => (dispatch) => {
  const hasRememberMeChecked = AuthService.getRememberMe();
  if (!hasRememberMeChecked) {
    const user = AuthService.getTempUser();
    if (!user) {
      dispatch(signOut());
    }
    if (user) {
      dispatch(LoginSuccess(user));
    }

    return cb();
  }

  return getUserLocal()
    .then((user) => {
      if (user) {
        const lastLoggedIn = user.time;
        const more29Days = 1000 * 60 * 60 * 24 * 29;
        const after29Days = lastLoggedIn + more29Days;
        const today = Date.now();

        if (after29Days > today) {
          dispatch(LoginSuccess(user));
        } else {
          dispatch(signOut());
        }
      }
    })
    .catch((err) => {
      dispatch(signOut());
    })
    .finally(() => cb());
};

/**
 * UserRoles
 */

export const getUserRoles = () => (dispatch) => {
  dispatch({ type: UserActionTypes.GET_USERSROLES_START });

  return axiosInstance
    .get(ApiEndpoints.USER.getUserRoles)
    .then((res) => res.data)
    .then((data) => {
      if (data.success) {
        return dispatch({
          type: UserActionTypes.GET_USERSROLES_SUCCESS,
          payload: data.result,
        });
      }
      return dispatch({
        type: UserActionTypes.GET_USERSROLES_FAILURE,
        payload: data.error,
      });
    })
    .catch((errors) =>
      dispatch({
        type: UserActionTypes.GET_USERSROLES_FAILURE,
        payload: errors.message,
      })
    );
};

export const getBasePlan = () => (dispatch) => {
  dispatch({ type: UserActionTypes.GET_BASE_START });

  return axiosInstance
    .get(ApiEndpoints.USER.getBasePlan)
    .then((res) => res.data)
    .then((data) => {
      if (data.success) {
        return dispatch({
          type: UserActionTypes.GET_BASE_SUCCESS,
          payload: data.result,
        });
      }
      // return dispatch({
      //   type: UserActionTypes.GET_BASE_FAILURE,
      //   payload: data.error,
      // });
    })
    .catch((errors) =>
      dispatch({
        type: UserActionTypes.GET_BASE_FAILURE,
        payload: errors.message,
      })
    );
};

export const getBasePlanSignup = () => (dispatch) => {
  dispatch({ type: UserActionTypes.GET_BASE_PLAN_START });

  return axios
    .get(ApiEndpoints.USER.getBasePlanSignup)
    .then((res) => res.data)
    .then((data) => {
      if (data.success) {
        console.log("Base Plan Signup", data.result);
        return dispatch({
          type: UserActionTypes.GET_BASE_PLAN_SUCCESS,
          payload: data.result,
        });
      }
      // return dispatch({
      //   type: UserActionTypes.GET_BASE_FAILURE,
      //   payload: data.error,
      // });
    })
    .catch((errors) =>
      dispatch({
        type: UserActionTypes.GET_BASE_PLAN_FAILURE,
        payload: errors.message,
      })
    );
};

export const basePlanUserUpdate = (data) => {
  return (dispatch) => {
    dispatch({ type: UserActionTypes.BASE_START });

    return axiosInstance
      .post(ApiEndpoints.USER.getBasePlan, { ...data })
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          return dispatch({
            type: UserActionTypes.BASE_SUCCESS,
            payload: data,
          });
        } else {
          return dispatch({
            type: UserActionTypes.BASE_FAILURE,
            payload: data.error,
          });
        }
      })
      .catch((errors) =>
        dispatch({
          type: UserActionTypes.BASE_FAILURE,
          payload: errors.message,
        })
      );
  };
};

/**
 * get all notification
 * the admin and all the user will get the all type of notification from organized roofer company
 */

export const getAllNotification = () => (dispatch) => {
  dispatch({ type: UserActionTypes.GET_NOTIFICATION_START });

  return axiosInstance
    .get(ApiEndpoints.USER.getAllNotification)
    .then((res) => res.data)
    .then((data) => {
      if (data.success) {
        return dispatch({
          type: UserActionTypes.GET_NOTIFICATION_SUCCESS,
          payload: data.result,
        });
      }
      // return dispatch({
      //   type: UserActionTypes.GET_NOTIFICATION_FAILURE,
      //   payload: data.error,
      // });
    })
    .catch((errors) =>
      dispatch({
        type: UserActionTypes.GET_NOTIFICATION_FAILURE,
        payload: errors.message,
      })
    );
};

/**
 * patch api for mark as read of the notification
 */
export const updateNotification = (id) => {
  return (dispatch) => {
    dispatch({ type: UserActionTypes.NOTIFICATION_START });

    return axiosInstance
      .patch(ApiEndpoints.USER.updateNotification(id))
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          return dispatch({
            type: UserActionTypes.NOTIFICATION_SUCCESS,
            payload: data,
          });
        } else {
          return dispatch({
            type: UserActionTypes.NOTIFICATION_FAILURE,
            payload: data.error,
          });
        }
      })
      .catch((errors) =>
        dispatch({
          type: UserActionTypes.NOTIFICATION_FAILURE,
          payload: errors.message,
        })
      );
  };
};

export const UpdateOtpSumbittedStatus = (status) => ({
  type: UserActionTypes.UPDATE_OTP_SUBMITTED_STATUS,
  payload: status,
});

export const ResendButtonClicked = (status) => ({
  type: UserActionTypes.RESNED_OTP_BUTTON_CLICKED,
  payload: status,
});
