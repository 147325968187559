// metricsActions.js

import {
  generateProposalApi,
  reGenerateProposalApi,
  getActiveCartingSuppliers,
  getCartingTypes as getCartingTypesApi,
  getProposalsApi,
  getSeriesTypesApi,
  changeMaterialSupplier as changeMaterialSupplierApi,
  changeLabourSupplier as changeLabourSupplierApi,
  updateProposalInfo as updateBackendProposalInfo,
  updateCartingSupplierProposal,
  deleteCarting as deleteCartingApi,
  addCarting as addCartingApi,
  updateCarting as updateCartingApi,
  addMaterialMisc,
  addLabourMisc,
  addGutterMisc,
  updateGutterQuote as updateGutterQuoteApi,
  updateLabourQuote as updateLabourQuoteApi,
  getMaterialActiveSuppliers,
  getActiveLabourSuppliers,
  getActiveGutterSuppliers,
  deleteMaterialMiscQuote as deleteMaterialMiscQuoteApi,
  deleteGutterMiscQuote as deleteGutterMiscQuoteApi,
  deleteLabourMiscQuote as deleteLabourMiscQuoteApi,
  changeGutterSupplierApi,
  getQuoteMarkup as getMarkupApi,
} from "features/QuotesProposals/services/proposalService";
import { proposalTypes } from "./proposals.types";
import { handleGlobalError } from "utils/utils";

export const generateQuotes = (data, history) => {
  return async (dispatch) => {
    dispatch({ type: proposalTypes.GENERATE_PROPOSAL_START });

    try {
      const responseData = await generateProposalApi(data);
      console.log({
        responseData,
      });

      if (responseData) {
        dispatch({
          type: proposalTypes.GENERATE_PROPOSAL__SUCCESS,
          payload: responseData,
        });
        history.push(`/dashboard/proposal-quotes/${data?.requestId}`);
      } else {
        dispatch({
          type: proposalTypes.GENERATE_PROPOSAL__FAILURE,
          payload: responseData.error,
        });
        handleGlobalError(responseData?.error, dispatch);
      }
    } catch (error) {
      dispatch({
        type: proposalTypes.GENERATE_PROPOSAL__FAILURE,
        payload: error.message,
      });

      console.log({
        globalError1: error,
      });

      // Dispatch global error action
      handleGlobalError(error, dispatch);
    }
  };
};

export const reGenerateQuotes = (data, history) => {
  return async (dispatch) => {
    dispatch({ type: proposalTypes.REGENERATE_PROPOSAL_START });

    try {
      const responseData = await reGenerateProposalApi(data);
      console.log({
        responseData,
      });

      if (responseData) {
        dispatch({
          type: proposalTypes.REGENERATE_PROPOSAL__SUCCESS,
          payload: responseData,
        });
        history.push(`/dashboard/proposal-quotes/${data?.requestId}`);
      } else {
        dispatch({
          type: proposalTypes.REGENERATE_PROPOSAL__FAILURE,
          payload: responseData.error,
        });
        handleGlobalError(responseData?.error, dispatch);
      }
    } catch (error) {
      dispatch({
        type: proposalTypes.REGENERATE_PROPOSAL__FAILURE,
        payload: error.message,
      });

      console.log({
        globalError1: error,
      });

      // Dispatch global error action
      handleGlobalError(error, dispatch);
    }
  };
};

export const getProposal = (data) => {
  return async (dispatch) => {
    dispatch({ type: proposalTypes.PROPOSAL_GET_START });

    try {
      console.log("calling api proposal get");

      const responseData = await getProposalsApi(data);
      console.log({ responseData });
      if (responseData) {
        dispatch({
          type: proposalTypes.PROPOSAL_GET_SUCCESS,
          payload: responseData,
        });
      } else {
        dispatch({
          type: proposalTypes.PROPOSAL_GET_FAILURE,
          payload: responseData.error,
        });
      }
    } catch (error) {
      dispatch({
        type: proposalTypes.PROPOSAL_GET_FAILURE,
        payload: error.message,
      });
      handleGlobalError(error, dispatch);
    }
  };
};

export const changeMaterialSupplier = (data) => {
  return async (dispatch) => {
    dispatch({ type: proposalTypes.UPDATE_MATERIAL_SUPPLIER_START });

    try {
      console.log("calling api proposal get");

      const responseData = await changeMaterialSupplierApi(data);
      console.log({ responseData });
      if (responseData) {
        dispatch({
          type: proposalTypes.UPDATE_MATERIAL_SUPPLIER_SUCCESS,
          payload: responseData,
        });
      } else {
        dispatch({
          type: proposalTypes.UPDATE_MATERIAL_SUPPLIER_FAILURE,
          payload: responseData.error,
        });
      }
    } catch (error) {
      dispatch({
        type: proposalTypes.UPDATE_MATERIAL_SUPPLIER_FAILURE,
        payload: error.message,
      });
      handleGlobalError(error, dispatch);
    }
  };
};

export const changeGutterSupplier = (data) => {
  return async (dispatch) => {
    dispatch({ type: proposalTypes.UPDATE_GUTTER_SUPPLIER_START });

    try {
      console.log("calling api proposal get");

      const responseData = await changeGutterSupplierApi(data);
      console.log({ responseData });
      if (responseData) {
        dispatch({
          type: proposalTypes.UPDATE_GUTTER_SUPPLIER_SUCCESS,
          payload: responseData,
        });
      } else {
        dispatch({
          type: proposalTypes.UPDATE_GUTTER_SUPPLIER_FAILURE,
          payload: responseData.error,
        });
      }
    } catch (error) {
      dispatch({
        type: proposalTypes.UPDATE_GUTTER_SUPPLIER_FAILURE,
        payload: error.message,
      });
      handleGlobalError(error, dispatch);
    }
  };
};

export const changeLabourSupplier = (data) => {
  return async (dispatch) => {
    dispatch({ type: proposalTypes.UPDATE_LABOUR_SUPPLIER_START });

    try {
      console.log("calling api proposal get");

      const responseData = await changeLabourSupplierApi(data);
      console.log({ responseData });
      if (responseData) {
        dispatch({
          type: proposalTypes.UPDATE_LABOUR_SUPPLIER_SUCCESS,
          payload: responseData,
        });
      } else {
        dispatch({
          type: proposalTypes.UPDATE_LABOUR_SUPPLIER_FAILURE,
          payload: responseData.error,
        });
      }
    } catch (error) {
      dispatch({
        type: proposalTypes.UPDATE_LABOUR_SUPPLIER_FAILURE,
        payload: error.message,
      });
      handleGlobalError(error, dispatch);
    }
  };
};

export const updateProposalInfo = (
  data,
  typeOfOperation = "updateProposal"
) => {
  return async (dispatch) => {
    dispatch({
      type: proposalTypes.UPDATE_PROPOSAL_INFO_START,
      payload: { typeOfOperation },
    });

    try {
      const responseData = await updateBackendProposalInfo(data);
      console.log({ responseData });

      if (responseData) {
        const reducerPayload = {
          responseData,
          typeOfOperation,
        };

        dispatch({
          type: proposalTypes.UPDATE_PROPOSAL_INFO_SUCCESS,
          payload: reducerPayload,
        });
      } else {
        dispatch({
          type: proposalTypes.UPDATE_PROPOSAL_INFO_FAILURE,
          payload: {
            error: responseData?.error || "Unknown error",
            typeOfOperation,
          },
        });
      }
    } catch (error) {
      console.log({
        error
      })
      dispatch({
        type: proposalTypes.UPDATE_PROPOSAL_INFO_FAILURE,
        payload: {
          error: error.message || "Failed to update proposal",
          typeOfOperation,
        },
      });
      handleGlobalError(error, dispatch);
    }
  };
};

export const updateCurrentProposal = (body) => ({
  type: proposalTypes.UPDATE_CURRENT_SELECTED_QUOTE,
  payload: body,
});

export const getSeriesTypes = (data) => {
  return async (dispatch) => {
    dispatch({ type: proposalTypes.MATERIAL_SERIES_GET_START });

    try {
      console.log("calling api proposal get");

      const responseData = await getSeriesTypesApi(data);
      console.log({ responseData });
      if (responseData) {
        dispatch({
          type: proposalTypes.MATERIAL_SERIES_GET_SUCCESS,
          payload: responseData,
        });
      } else {
        dispatch({
          type: proposalTypes.MATERIAL_SERIES_GET_FAILURE,
          payload: responseData.error,
        });
      }
    } catch (error) {
      dispatch({
        type: proposalTypes.MATERIAL_SERIES_GET_FAILURE,
        payload: error.message,
      });
      handleGlobalError(error, dispatch);
    }
  };
};

// Add misc material to the quote
export const addMiscMaterialQuote = (data) => {
  console.log({ bb: data });
  return async (dispatch) => {
    dispatch({ type: proposalTypes.ADD_MATERIAL_MISC_START });

    try {
      console.log("calling api proposal get");

      const responseData = await addMaterialMisc(data);
      console.log({ responseData, data });
      if (responseData) {
        dispatch({
          type: proposalTypes.ADD_MATERIAL_MISC_SUCCESS,
          payload: responseData,
        });
      } else {
        dispatch({
          type: proposalTypes.ADD_MATERIAL_MISC_FAILURE,
          payload: responseData.error,
        });
      }
    } catch (error) {
      console.log({
        error,
      });
      dispatch({
        type: proposalTypes.ADD_MATERIAL_MISC_FAILURE,
        payload: error.message,
      });
      handleGlobalError(error, dispatch);
    }
  };
};

// Add misc labour to the quote
export const updateLabourQuote = (data) => {
  console.log({ bb: data });
  return async (dispatch) => {
    dispatch({ type: proposalTypes.UPDATE_LABOUR_START });

    try {
      console.log("calling api proposal get");

      const responseData = await updateLabourQuoteApi(data);
      console.log({ responseData, data });
      if (responseData) {
        dispatch({
          type: proposalTypes.UPDATE_LABOUR_SUCCESS,
          payload: responseData,
        });
      } else {
        dispatch({
          type: proposalTypes.UPDATE_LABOUR_FAILURE,
          payload: responseData.error,
        });
      }
    } catch (error) {
      dispatch({
        type: proposalTypes.UPDATE_LABOUR_FAILURE,
        payload: error.message,
      });
      handleGlobalError(error, dispatch);
    }
  };
};

// Add misc labour to the quote
export const updateGutterQuote = (data) => {
  console.log({ bb: data });
  return async (dispatch) => {
    dispatch({ type: proposalTypes.UPDATE_GUTTER_START });

    try {
      console.log("calling api proposal get");

      const responseData = await updateGutterQuoteApi(data);
      console.log({ responseData, data });
      if (responseData) {
        dispatch({
          type: proposalTypes.UPDATE_GUTTER_SUCCESS,
          payload: responseData,
        });
      } else {
        dispatch({
          type: proposalTypes.UPDATE_GUTTER_FAILURE,
          payload: responseData.error,
        });
      }
    } catch (error) {
      dispatch({
        type: proposalTypes.UPDATE_GUTTER_FAILURE,
        payload: error.message,
      });
      handleGlobalError(error, dispatch);
    }
  };
};

// Add misc labour to the quote
export const addMiscLabourQuote = (data) => {
  console.log({ bb: data });
  return async (dispatch) => {
    dispatch({ type: proposalTypes.ADD_LABOUR_MISC_START });

    try {
      console.log("calling api proposal get");

      const responseData = await addLabourMisc(data);
      console.log({ responseData, data });
      if (responseData) {
        dispatch({
          type: proposalTypes.ADD_LABOUR_MISC_SUCCESS,
          payload: responseData,
        });
      } else {
        dispatch({
          type: proposalTypes.ADD_LABOUR_MISC_FAILURE,
          payload: responseData.error,
        });
      }
    } catch (error) {
      dispatch({
        type: proposalTypes.ADD_LABOUR_MISC_FAILURE,
        payload: error.message,
      });
      handleGlobalError(error, dispatch);
    }
  };
};

// Add misc labour to the quote
export const addMiscGutterQuote = (data) => {
  console.log({ bb: data });
  return async (dispatch) => {
    dispatch({ type: proposalTypes.ADD_GUTTER_MISC_START });

    try {
      console.log("calling api proposal get");

      const responseData = await addGutterMisc(data);
      console.log({ responseData, data });
      if (responseData) {
        dispatch({
          type: proposalTypes.ADD_GUTTER_MISC_SUCCESS,
          payload: responseData,
        });
      } else {
        dispatch({
          type: proposalTypes.ADD_GUTTER_MISC_FAILURE,
          payload: responseData.error,
        });
      }
    } catch (error) {
      dispatch({
        type: proposalTypes.ADD_GUTTER_MISC_FAILURE,
        payload: error.message,
      });
      handleGlobalError(error, dispatch);
    }
  };
};

export const getCartingSuppliers = (companyId) => {
  return async (dispatch) => {
    dispatch({ type: proposalTypes.GET_CARTING_SUPPLIERS_START });

    try {
      console.log("calling api proposal get");

      const responseData = await getActiveCartingSuppliers(companyId);
      console.log({ responseData });
      if (responseData) {
        dispatch({
          type: proposalTypes.GET_CARTING_SUPPLIERS_SUCCESS,
          payload: responseData?.data,
        });
      } else {
        dispatch({
          type: proposalTypes.GET_CARTING_SUPPLIERS_FAILURE,
          payload: responseData.error,
        });
      }
    } catch (error) {
      dispatch({
        type: proposalTypes.GET_CARTING_SUPPLIERS_FAILURE,
        payload: error.message,
      });
      handleGlobalError(error, dispatch);
    }
  };
};

export const getCartingTypes = (supplierId) => {
  return async (dispatch) => {
    dispatch({ type: proposalTypes.GET_CARTING_TYPES_START });

    try {
      console.log("calling api proposal get");

      const responseData = await getCartingTypesApi(supplierId);
      console.log({ responseData });
      if (responseData) {
        dispatch({
          type: proposalTypes.GET_CARTING_TYPES_SUCCESS,
          payload: responseData?.data,
        });
      } else {
        dispatch({
          type: proposalTypes.GET_CARTING_TYPES_FAILURE,
          payload: responseData.error,
        });
      }
    } catch (error) {
      dispatch({
        type: proposalTypes.GET_CARTING_TYPES_FAILURE,
        payload: error.message,
      });
      handleGlobalError(error, dispatch);
    }
  };
};

//   export const editSectionMetric = (data) => {
//     return async (dispatch) => {
//       dispatch({ type: metricsActionTypes.SECTION_UPDATE_START });

//       try {
//         const responseData = await editMetricApi(data);
//         console.log({ responseData });
//         if (responseData) {
//           dispatch({
//             type: metricsActionTypes.SECTION_UPDATE__SUCCESS,
//             payload: responseData.data.sections,
//           });
//         } else {
//           dispatch({
//             type: metricsActionTypes.SECTION_UPDATE__FAILURE,
//             payload: responseData.error,
//           });
//         }
//       } catch (error) {
//         dispatch({
//           type: metricsActionTypes.SECTION_UPDATE__FAILURE,
//           payload: error.message,
//         });
//       }
//     };
//   };

//   export const deleteSectionMetric = (data) => {
//     return async (dispatch) => {
//       dispatch({ type: metricsActionTypes.SECTION_DELETE_START });

//       try {
//         const responseData = await deleteMetricApi(data);
//         console.log({ responseData });
//         if (responseData) {
//           dispatch({
//             type: metricsActionTypes.SECTION_DELETE__SUCCESS,
//             payload: responseData,
//           });
//         } else {
//           dispatch({
//             type: metricsActionTypes.SECTION_DELETE__FAILURE,
//             payload: responseData.error,
//           });
//         }
//       } catch (error) {
//         dispatch({
//           type: metricsActionTypes.SECTION_DELETE__FAILURE,
//           payload: error.message,
//         });
//       }
//     };
//   };

export const updateCartingSupplier = (data) => {
  return async (dispatch) => {
    dispatch({ type: proposalTypes.UPDATE_CARTING_SUPPLIER_START });

    try {
      console.log("calling api proposal get");

      const responseData = await updateCartingSupplierProposal(data);
      console.log({ responseData });
      if (responseData) {
        dispatch({
          type: proposalTypes.UPDATE_CARTING_SUPPLIER_SUCCESS,
          payload: responseData,
        });
      } else {
        dispatch({
          type: proposalTypes.UPDATE_CARTING_SUPPLIER_FAILURE,
          payload: responseData.error,
        });
      }
    } catch (error) {
      dispatch({
        type: proposalTypes.UPDATE_CARTING_SUPPLIER_FAILURE,
        payload: error.message,
      });
      handleGlobalError(error, dispatch);
    }
  };
};

export const deleteCarting = (data) => {
  console.log({ bb: data });
  return async (dispatch) => {
    dispatch({ type: proposalTypes.DELETE_CARTING_START });

    try {
      console.log("calling api proposal get");

      const responseData = await deleteCartingApi(data);
      console.log({ responseData, data });
      if (responseData) {
        dispatch({
          type: proposalTypes.DELETE_CARTING_SUCCESS,
          payload: responseData,
        });
      } else {
        dispatch({
          type: proposalTypes.DELETE_CARTING_FAILURE,
          payload: responseData.error,
        });
      }
    } catch (error) {
      dispatch({
        type: proposalTypes.DELETE_CARTING_FAILURE,
        payload: error.message,
      });
      handleGlobalError(error, dispatch);
    }
  };
};

export const addCarting = (data) => {
  console.log({ bb: data });
  return async (dispatch) => {
    dispatch({ type: proposalTypes.ADD_CARTING_START });

    try {
      console.log("calling api proposal get");

      const responseData = await addCartingApi(data);
      console.log({ responseData, data });
      if (responseData) {
        dispatch({
          type: proposalTypes.ADD_CARTING_SUCCESS,
          payload: responseData,
        });
      } else {
        dispatch({
          type: proposalTypes.ADD_CARTING_FAILURE,
          payload: responseData.error,
        });
      }
    } catch (error) {
      dispatch({
        type: proposalTypes.ADD_CARTING_FAILURE,
        payload: error.message,
      });
      handleGlobalError(error, dispatch);
    }
  };
};

export const updateCarting = (data) => {
  console.log({ bb: data });
  return async (dispatch) => {
    dispatch({ type: proposalTypes.UPDATE_CARTING_START });

    try {
      console.log("calling api proposal get");

      const responseData = await updateCartingApi(data);
      console.log({ responseData, data });
      if (responseData) {
        dispatch({
          type: proposalTypes.UPDATE_CARTING_SUCCESS,
          payload: responseData,
        });
      } else {
        dispatch({
          type: proposalTypes.UPDATE_CARTING_FAILURE,
          payload: responseData.error,
        });
      }
    } catch (error) {
      dispatch({
        type: proposalTypes.UPDATE_CARTING_FAILURE,
        payload: error.message,
      });
      handleGlobalError(error, dispatch);
    }
  };
};

export const getMaterialSuppliers = (companyId) => {
  return async (dispatch) => {
    dispatch({ type: proposalTypes.GET_MATERIAL_SUPPLIERS_START });

    try {
      console.log("calling api proposal get");

      const responseData = await getMaterialActiveSuppliers(companyId);
      console.log({ responseData });
      if (responseData) {
        dispatch({
          type: proposalTypes.GET_MATERIAL_SUPPLIERS_SUCCESS,
          payload: responseData?.data,
        });
      } else {
        dispatch({
          type: proposalTypes.GET_MATERIAL_SUPPLIERS_FAILURE,
          payload: responseData.error,
        });
      }
    } catch (error) {
      dispatch({
        type: proposalTypes.GET_MATERIAL_SUPPLIERS_FAILURE,
        payload: error.message,
      });
      handleGlobalError(error, dispatch);
    }
  };
};

export const getLabourSuppliers = (companyId) => {
  return async (dispatch) => {
    dispatch({ type: proposalTypes.GET_LABOUR_SUPPLIERS_START });

    try {
      console.log("calling api proposal get");

      const responseData = await getActiveLabourSuppliers(companyId);
      console.log({ responseData });
      if (responseData) {
        dispatch({
          type: proposalTypes.GET_LABOUR_SUPPLIERS_SUCCESS,
          payload: responseData?.data,
        });
      } else {
        dispatch({
          type: proposalTypes.GET_LABOUR_SUPPLIERS_FAILURE,
          payload: responseData.error,
        });
      }
    } catch (error) {
      dispatch({
        type: proposalTypes.GET_LABOUR_SUPPLIERS_FAILURE,
        payload: error.message,
      });
      handleGlobalError(error, dispatch);
    }
  };
};

export const getGutterSuppliers = (companyId) => {
  return async (dispatch) => {
    dispatch({ type: proposalTypes.GET_GUTTER_SUPPLIERS_START });

    try {
      console.log("calling api proposal get");

      const responseData = await getActiveGutterSuppliers(companyId);
      console.log({ responseData });
      if (responseData) {
        dispatch({
          type: proposalTypes.GET_GUTTER_SUPPLIERS_SUCCESS,
          payload: responseData?.data,
        });
      } else {
        dispatch({
          type: proposalTypes.GET_GUTTER_SUPPLIERS_FAILURE,
          payload: responseData.error,
        });
      }
    } catch (error) {
      dispatch({
        type: proposalTypes.GET_GUTTER_SUPPLIERS_FAILURE,
        payload: error.message,
      });
      handleGlobalError(error, dispatch);
    }
  };
};

export const deleteMaterialMiscQuote = (data) => {
  console.log({ bb: data });
  return async (dispatch) => {
    dispatch({ type: proposalTypes.DELETE_MATERIAL_MISC_START });

    try {
      console.log("calling api proposal get");

      const responseData = await deleteMaterialMiscQuoteApi(data);
      console.log({ responseData, data });
      if (responseData) {
        dispatch({
          type: proposalTypes.DELETE_MATERIAL_MISC_SUCCESS,
          payload: responseData,
        });
      } else {
        dispatch({
          type: proposalTypes.DELETE_MATERIAL_MISC_FAILURE,
          payload: responseData.error,
        });
      }
    } catch (error) {
      dispatch({
        type: proposalTypes.DELETE_MATERIAL_MISC_FAILURE,
        payload: error.message,
      });
      handleGlobalError(error, dispatch);
    }
  };
};

export const deleteLabourMiscQuote = (data) => {
  console.log({ bb: data });
  return async (dispatch) => {
    dispatch({ type: proposalTypes.DELETE_LABOUR_MISC_START });

    try {
      console.log("calling api proposal get");

      const responseData = await deleteLabourMiscQuoteApi(data);
      console.log({ responseData, data });
      if (responseData) {
        dispatch({
          type: proposalTypes.DELETE_LABOUR_MISC_SUCCESS,
          payload: responseData,
        });
      } else {
        dispatch({
          type: proposalTypes.DELETE_LABOUR_MISC_FAILURE,
          payload: responseData.error,
        });
      }
    } catch (error) {
      dispatch({
        type: proposalTypes.DELETE_LABOUR_MISC_FAILURE,
        payload: error.message,
      });
      handleGlobalError(error, dispatch);
    }
  };
};

export const deleteGutterMiscQuote = (data) => {
  console.log({ bb: data });
  return async (dispatch) => {
    dispatch({ type: proposalTypes.DELETE_GUTTER_MISC_START });

    try {
      console.log("calling api proposal get");

      const responseData = await deleteGutterMiscQuoteApi(data);
      console.log({ responseData, data });
      if (responseData) {
        dispatch({
          type: proposalTypes.DELETE_GUTTER_MISC_SUCCESS,
          payload: responseData,
        });
      } else {
        dispatch({
          type: proposalTypes.DELETE_GUTTER_MISC_FAILURE,
          payload: responseData.error,
        });
      }
    } catch (error) {
      dispatch({
        type: proposalTypes.DELETE_GUTTER_MISC_FAILURE,
        payload: error.message,
      });
      handleGlobalError(error, dispatch);
    }
  };
};

export const getQuoteMarkup = (data) => {
  return async (dispatch) => {
    dispatch({ type: proposalTypes.GET_QUOTE_MARKUP_START });
    try {
      console.log("calling api proposal get");

      const responseData = await getMarkupApi();
      console.log({ responseData });
      if (responseData) {
        dispatch({
          type: proposalTypes.GET_QUOTE_MARKUP_SUCCESS,
          payload: responseData?.data,
        });
      } else {
        dispatch({
          type: proposalTypes.GET_QUOTE_MARKUP_FAILURE,
          payload: responseData.error,
        });
      }
    } catch (error) {
      dispatch({
        type: proposalTypes.GET_QUOTE_MARKUP_FAILURE,
        payload: error.message,
      });
      handleGlobalError(error, dispatch);
    }
  };
};
