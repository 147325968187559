import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { addMaterialMisc, changeMaterialSupplier, fetchSeriesTypes, getMaterialActiveSuppliers, updateMaterialProposalTaxInfo, updateMaterialQuoteLine, deleteMaterialMiscQuote } from "../api/materialApi";

export const useMaterialSuppliers = () => {
    const { data, isLoading, isError, error } = useQuery(
        ["materialSuppliers"], // Query key for caching
        getMaterialActiveSuppliers,
        {
            // Ensures the query runs once and doesn't repeat unless needed
            staleTime: 1000 * 60 * 5,  // 5 minutes for caching data
            cacheTime: 1000 * 60 * 30, // Cache for 30 minutes before garbage collection
        }
    );

    return {
        suppliers: data?.result || [],  // Return suppliers from the data or empty array if undefined
        isLoading,
        isError,
        error,
    };
};

export const useChangeMaterialSupplier = () => {
    return useMutation(changeMaterialSupplier);
};

export const useSeriesTypes = (body, enabled) => {
    const { data, isLoading, isError, error } = useQuery({
        queryKey: ["seriesTypes", body], // Unique key for caching
        queryFn: () => fetchSeriesTypes(body), // Fetching function
        enabled: enabled, // Ensures query runs only if data is provided
    });
    console.log({
        seriesResponseDaa: data
    })
    return {
        seriesResponse: data?.result || [],  // Return suppliers from the data or empty array if undefined
        isLoading,
        isError,
        error,
    };
};


// Update a proposal tax info
export const useUpdateProposalTaxInfo = () => {
    return useMutation(updateMaterialProposalTaxInfo);
};



// Update a proposal tax info
export const useUpdateMaterialQuoteline = () => {
    return useMutation(updateMaterialQuoteLine);
};


// Hook for adding Misc Material to Quote
export const useAddMaterialMisc = () => {
    return useMutation(addMaterialMisc);
};


// Hook for adding Misc Material to Quote
export const useDeleteMaterialMisc = () => {
    return useMutation(deleteMaterialMiscQuote);
};
