import React, { createContext, useContext, useLayoutEffect, useState } from "react";

// Create a context for the CASL Ability
export const AbilityContext = createContext();

// Create a hook to use the Ability context
export const useAbility = () => useContext(AbilityContext);

// Create a custom Can component to check permissions
const CustomCan = ({ do: action, on, this: _this, ...props }) => {
  const ability = useAbility();
  const [hasPermission, setHasPermission] = useState(false);

  useLayoutEffect(() => {
    const checkPermission = (subject) => {
      return ability.can(action, subject);
    };
  
    const permit = Array.isArray(on)
      ? on.some(checkPermission)
      : checkPermission(on);

    setHasPermission(permit);
  }, [ability]);



  return hasPermission ? props.children : null;
};

// Create a Can component using the custom implementation
const Can = (props) => <CustomCan {...props} />;

export { Can };
