import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import PopupModal from "components/popup-modal/popup-modal";
import { Button, Col, Modal, Row } from "react-bootstrap";
import Numpad from "../../Numpad";
import { useDispatch } from "react-redux";
import {
  EditValue,
  RemoveValue,
  resetOptionsSelected,
  toggleInputSelected,
  toggleOptionSelected,
  updateOtherOptionValue,
} from "../../../redux/roof-metric-collection/metric.action";
import { useSelector } from "react-redux";
import { categoryMapping, cleanBodyObject, getTotalValue } from "utils/utils";
import { editSectionMetric } from "../../../redux/metrics-v2/metrics.actions";
const { uuid } = require("uuidv4");
import { styles } from "./style";
import Spinner from "components/common/spinner/spinner";

// Define all styles at the top

const isTablet = window.innerWidth <= 1024; // Example for iPad width

const AccessoriesTabModal = ({
  showTabModal,
  section,
  selectedData,
  onHide,
  handleSelectedData,
  modalInfo,
  requestId,
}) => {
  const [selectedDimension, setSelectedDimension] = useState({});

  const modalHeader = `Enter ${modalInfo?.data?.name} ${modalInfo?.data?.additionalLabel}`;
  const [selectedTallyRecordIdForDelete, setSelectedTallyRecordIdForDelete] =
    useState("");
  const { isLoadingEdit, editResponse, errors, isLoadingDeleTallyRecord } =
    useSelector((state) => state.metricV2Data);

  const dispatch = useDispatch();
  const metric = useSelector((state) => state.metric);

  const [edit, setEdit] = useState({
    isEdit: false,
    id: "",
  });

  const [viewTallyMode, setViewTallyMode] = useState(false);
  const [showNextContent, setShowNextContent] = useState(false);

  // Select the current values of the opened tab
  const [currentValue, setCurrentValue] = useState({});

  useEffect(() => {
    const value =
      metric?.metrics?.[modalInfo?.data?.categoryName]?.[modalInfo?.data?.key];
    setCurrentValue(value);
  }, [metric?.metrics, modalInfo]);

  const reverseCategoryMapping = Object.entries(categoryMapping).reduce(
    (acc, [key, value]) => {
      acc[value] = key;
      return acc;
    },
    {}
  );

  const findCurrentTab = (metricOptions, categoryName, subTabName) => {
    const category = metricOptions.find((mt) => mt.name === categoryName);
    if (!category) return null;

    const subTab = category.subTabs.find((st) => st.name === subTabName);
    return subTab || null;
  };

  const addAndUpdateSection = async ({ fromOptions, clearText = false }) => {
    // Check if the next content is available in the modal
    const isNextContentAvailable = modalInfo?.data?.isNextAvailable;

    // If next content is available and the action is not from options or clear text, show next content
    if (isNextContentAvailable && !fromOptions && !clearText) {
      setShowNextContent(true);
      return; // Exit the function early
    }

    // Generate a unique ID for the data entry
    const id = uuid();
    const categoryName = modalInfo?.data?.categoryName;
    const key = modalInfo?.data?.key;

    // Initialize the data object with essential properties
    let data = {
      // category: categoryName,
      key: modalInfo?.data?.key,
      value: selectedDimension[modalInfo?.data?.unitKey],
      unitKey: modalInfo?.data?.unitKey,
      unit: modalInfo?.data?.unit,
      selectedDimension: selectedDimension,
      id: id,
    };

    // If options are available in the modal info
    if (modalInfo?.data?.options) {
      // Find the current tab based on the metric options
      const currentTab = findCurrentTab(
        metric?.metricOptions,
        categoryName,
        modalInfo?.data?.name
      );

      // If the current tab is found
      if (currentTab) {
        // Gather selected options from the current tab
        const allSelectedOptions = Object.keys(
          currentTab.options?.values || {}
        ).reduce((acc, key) => {
          const allOptions = currentTab.options.values[key]?.values || [];
          const selectedOption = allOptions.find((value) => value.selected); // Find the selected value

          // Get the others associated with the selected value
          const selectedOthers = selectedOption?.others;
          const selectedOtherInputs = selectedOthers?.inputs?.filter(
            (input) => input.selected
          ); // Filter selected inputs

          // If a selected value exists, add it to the accumulator
          if (selectedOption) {
            let dynamicKey = key;
            if (currentTab.options?.values[key].key) {
              dynamicKey = currentTab.options?.values[key].key;
            }
            acc[dynamicKey] = {
              name: currentTab.options?.values[key]?.name,
              value: selectedOption?.name,
              ...(selectedOption?.queryValue && {
                queryValue: selectedOption?.queryValue, // For common nails query for quotes
              }),
              others: {
                ...selectedOthers,
                inputs: selectedOtherInputs,
              },
            };
          }

          return acc; // Return the accumulator
        }, {});
        // Merge selected options into data object
        data = { ...data, options: allSelectedOptions };
      } else {
        console.log("Current tab not found");
      }
    }

    // Prepare API data to be sent
    let apiData = {
      key: modalInfo?.data?.key,
      value: selectedDimension[modalInfo?.data?.unitKey],
      unitKey: modalInfo?.data?.unitKey,
      unit: modalInfo?.data?.unit,
      id: id,
      ...(data.options && { options: data?.options }),
      ...selectedDimension,
    };

    // Create a section object to update the metrics
    const section = {
      ...metric?.metrics,
      [categoryName]: {
        ...metric?.metrics?.[categoryName], // Spread existing data for categoryName
        [key]: [
          ...(metric?.metrics?.[categoryName]?.[key] || []), // Ensure key is an array
          apiData, // Add the new apiData
        ],
      },
    };

    // Check if `workType.name` is not "Remove" and delete `removalTypes` if true
    if (section.workType?.name !== "Remove") {
      delete section.workType.removalTypes;
    } else if (section.workType?.removalTypes?.name === "") {
      // Only keep `type` if `name` is empty
      section.workType.removalTypes = {
        type: section.workType.removalTypes.type,
      };
    }

    // Reverse the category mapping for the new section
    const newSection = Object.keys(section).reduce((acc, key) => {
      const newKey = reverseCategoryMapping[key] || key; // Map key to its reversed category
      acc[newKey] = section?.[key]; // Assign the section data to the new key
      return acc; // Return the accumulator
    }, {});

    // Add requestId to the new section and remove unnecessary properties
    newSection.requestId = requestId;
    delete newSection?.section;
    delete newSection?._id;

    // Clean the newSection object to remove empty keys
    const cleanedNewSection = cleanBodyObject(newSection);

    try {
      // Dispatch action to edit the section metric
      dispatch(editSectionMetric(cleanedNewSection)); // Wait for editSectionMetric to complete

      // [TODO]: Need check if api is success then close else don't

      // If next content is available and action comes from options, reset state
      if (isNextContentAvailable && fromOptions) {
        setShowNextContent(false);
        resetOptions(); // Reset options
      }

      setSelectedDimension({}); // Clear selected dimensions
    } catch (error) {
      console.error("Error updating section metric: ", error);
      // Handle the error accordingly
    }
  };

  const toggleKeypad = () => {
    setViewTallyMode((prevState) => !prevState);
  };

  const deleteCurrentValue = (currentRow) => {
    try {
      currentRow.category = modalInfo?.data?.categoryName;
      if (!currentRow.hasOwnProperty("key")) {
        currentRow.key = modalInfo?.data?.key;
      }

      const { category, key, id } = currentRow;
      setSelectedTallyRecordIdForDelete(id);
      console.log({
        currentRow,
        dd: metric?.metrics?.[category]?.[key],
      });
      const section = {
        ...metric?.metrics,
        [category]: {
          ...metric?.metrics?.[category],
          [key]: metric?.metrics?.[category]?.[key].filter(
            (item) => item.id !== id
          ),
        },
      };

      // Reverse the category mapping for the new section
      const newSection = Object.keys(section).reduce((acc, key) => {
        const newKey = reverseCategoryMapping[key] || key; // Map key to its reversed category
        acc[newKey] = section?.[key]; // Assign the section data to the new key
        return acc; // Return the accumulator
      }, {});

      // Add requestId to the new section and remove unnecessary properties
      newSection.requestId = requestId;
      delete newSection?.section;
      delete newSection?._id;

      // Clean the newSection object to remove empty keys
      const cleanedNewSection = cleanBodyObject(newSection);

      // Dispatch action to edit the section metric
      dispatch(editSectionMetric(cleanedNewSection, "deleteMetricTally")); // Wait for editSectionMetric to complete

      // If next content is available and action comes from options, reset state
      // if (isNextContentAvailable && fromOptions) {
      //   setShowNextContent(false);
      //   resetOptions(); // Reset options
      // }

      setSelectedDimension({}); // Clear selected dimensions
    } catch (error) {
      console.error("Error updating section metric: ", error);
      // Handle the error accordingly
    }
  };

  // Edit vaue
  const editCurrentValue = (currentRow) => {
    currentRow.category = modalInfo?.data?.categoryName;
    setViewTallyMode(false);
    const inputs = modalInfo?.data?.inputs;

    const editRow = currentValue?.find((cv) => cv.id === currentRow?.id);

    for (let input of inputs) {
      setSelectedDimension((prevState) => ({
        ...prevState,
        [input?.key]: editRow[input?.key],
      }));
    }

    const selectedOptions = editRow?.options;

    if (selectedOptions) {
      for (const option of Object.keys(selectedOptions)) {
        const data = {
          categoryName: modalInfo?.data?.categoryName,
          subTabName: modalInfo?.data?.name,
          optionKey: option,
          optionValueName: selectedOptions?.[option]?.value,
        };

        dispatch(toggleOptionSelected(data));
      }
    }

    setEdit({
      isEdit: true,
      id: currentRow?.id,
    });
  };

  const toggleInputSelection = (input) => {
    const toggleData = {
      categoryName: modalInfo?.data?.categoryName,
      subTabName: modalInfo?.data?.name,
      inputKey: input?.key,
    };
    dispatch(toggleInputSelected(toggleData));
  };

  const HideModal = () => {
    onHide();
    setSelectedDimension({});
    setViewTallyMode(false);
    setShowNextContent(false);
    if (modalInfo?.data?.options) {
      resetOptions();
    }
    setSelectedDimension({}); // Set all dimensions to an empty state

    setEdit({ isEdit: false, id: "" });
  };

  const resetOptions = () => {
    const data = {
      categoryName: modalInfo?.data?.categoryName,
      subTabName: modalInfo?.data?.name,
    };

    dispatch(resetOptionsSelected(data));
  };

  /**
   *
   * @returns
   * @description This will render in the top like list of sections, unit and number of units
   */
  const topView = () => {
    // Check if modalInfo and necessary keys are present
    if (
      !modalInfo ||
      !modalInfo.data ||
      !modalInfo.data.unit ||
      !modalInfo.data.categoryName ||
      !modalInfo.data.key
    ) {
      return (
        <div>
          {/* Render a message or placeholder when necessary keys are not available */}
          <p>Missing necessary data in modalInfo</p>
        </div>
      );
    }

    let totalLabel = modalInfo.data.unit || "Linear Feet";
    const currentCategoryValue = metric?.metrics
      ? metric?.metrics[modalInfo.data.categoryName][modalInfo.data.key]
      : [];
    const total = currentCategoryValue?.length
      ? getTotalValue(currentCategoryValue)
      : 0;

    return (
      <Row>
        <Col>
          <div className="_lbl">Section</div>
          <p className="_txt">{modalInfo?.sectionNumber}</p>
        </Col>
        <Col>
          <div className="_lbl">Total {totalLabel}</div>
          <p className="_txt">{total}</p>
        </Col>
        <Col>
          <div className="_lbl">Entries</div>
          <p className="_txt">
            {currentCategoryValue && currentCategoryValue.length
              ? currentCategoryValue.length
              : 0}
          </p>
        </Col>
      </Row>
    );
  };

  const setSelectedOption = (btn, optionKey) => {
    const data = {
      categoryName: modalInfo?.data?.categoryName,
      subTabName: modalInfo?.data?.name,
      optionKey: optionKey,
      optionValueName: btn?.name,
    };
    dispatch(toggleOptionSelected(data));
  };

  const checkIfSelected = (value) => {
    return value.selected;
  };

  const updateOtherOption = (value, option, optionKey, newValue = "") => {
    const updateData = {
      categoryName: modalInfo?.data?.categoryName,
      subTabName: modalInfo?.data?.name,
      optionKey: optionKey,
      optionValueName: option?.name,
      optionOtherInputName: value?.name,
    };
    if (newValue) {
      updateData.newValue = newValue;
    }
    dispatch(updateOtherOptionValue(updateData));
  };

  const handleInputChange = (input, option, optionKey, e) => {
    const newValue = e.target.value;
    updateOtherOption(input, option, optionKey, newValue);
  };

  const renderInputField = (input, index, option, optionKey) => {
    return (
      <>
        {input.type === "input" && (
          <div>
            {index > 0 ? (
              <label style={{ textAlign: "center", width: "100%" }}>x</label>
            ) : null}{" "}
            <input
              onChange={(e) => handleInputChange(input, option, optionKey, e)}
              placeholder={input.name}
              min="0"
              type={input?.type || "number"}
              className="form-control"
            />
          </div>
        )}
        {input.type === "button" && (
          <button
            onClick={() => updateOtherOption(input, option, optionKey)}
            className={`ClickableBtn ${
              checkIfSelected(input) ? "selected" : ""
            }`}
            style={{ width: "28%" }}
          >
            {input.name}
          </button>
        )}
      </>
    );
  };

  function updateSelectedValues(originalArray, updatedArray) {
    // Create a map from the updatedArray for quick lookup
    const updatedMap = new Map(
      updatedArray.map((item) => [item.name, item.selected])
    );

    // Iterate over the originalArray and update the selected value if a match is found
    return originalArray.map((item) => {
      if (updatedMap.has(item.name)) {
        return { ...item, selected: updatedMap.get(item.name) };
      }
      return item;
    });
  }

  // Function to process inputs and update selected values
  function processInputs(value, currentValue) {
    const inputArray = value?.others?.inputs;

    if (inputArray && inputArray?.length) {
      let updatedInputs = [];

      currentValue.forEach((cv) => {
        if (cv?.options) {
          Object.keys(cv?.options).forEach((cvKey) => {
            const option = cv?.options?.[cvKey];
            const valueName = option?.value;

            if (valueName === value?.name && option?.others?.inputs) {
              const updated = updateSelectedValues(
                inputArray,
                option.others.inputs
              );
              option.others.inputs = updated;
              updatedInputs = updated; // Capture the updated inputs
            }
          });
        }
      });
      return updatedInputs; // Return the updated inputs
    }

    return []; // Return an empty array if inputArray is not defined
  }

  const renderOtherValuesOption = (optionValues) => {
    // Return null if optionValues is not an object or if it's empty
    if (!optionValues || Object.keys(optionValues).length === 0) return null;

    return Object.keys(optionValues).map((option) => {
      const optionDetails = optionValues[option];
      if (!optionDetails?.values?.length) return null;

      return (
        <div
          className="otherOption"
          style={{
            display: "flex",
            flexDirection: optionDetails?.type || "row",
          }}
          key={option}
        >
          {optionDetails.values.map((value) => {
            const inputArray = value?.others?.inputs;
            if (!checkIfSelected(value)) return null;

            return (
              <React.Fragment key={value.name}>
                <div className="col" style={{ width: "100%" }}>
                  {value?.others?.name && (
                    <label
                      className="btnheading"
                      style={{ width: "100%", textAlign: "center" }}
                    >
                      {value?.others?.name}
                    </label>
                  )}
                  <div
                    className="row"
                    style={{ justifyContent: "space-between" }}
                  >
                    {inputArray?.map((input, index) =>
                      renderInputField(input, index, value, option)
                    )}
                  </div>
                </div>
              </React.Fragment>
            );
          })}
        </div>
      );
    });
  };

  const isOptionHidden = (options, optionKey, selectedOptions = {}) => {
    const hideConfig = options[optionKey]?.config?.hideOptions;

    if (hideConfig?.length) {
      // Check if the current option should be hidden based on another option's selection
      const shouldHide = hideConfig.some((hideCon) => {
        const hiddenKey = hideCon?.name;
        const hiddenValue = hideCon?.value;

        // Find if the hidden key option has a selected value matching the hide condition
        const selectedValueForHiddenKey = options[hiddenKey]?.values?.find(
          (optionValue) => optionValue.selected
        );

        const showOnStart = options[optionKey]?.config?.showOnStart;
        if (!showOnStart && !selectedValueForHiddenKey?.name) {
          return true;
        }
        if (selectedValueForHiddenKey?.name === hiddenValue) {
          return true;
        }
        return false;
      });

      if (shouldHide) return true;
    }

    // // Check based on the "type" key selection
    // const currentSelection = selectedOptions?.type?.name;
    // if (hideConfig) {
    //   return hideConfig.some(
    //     (hideOption) =>
    //       hideOption.name === "types" && hideOption.value === currentSelection
    //   );
    // }
    return false;
  };

  const getSelectedOptions = (options) => {
    const selectedOptions = {};

    // Loop through each option in the options object
    Object.keys(options).forEach((optionKey) => {
      const option = options[optionKey];

      // Check if the option has values and is of type "row" or "column"
      if (option?.values?.length) {
        const selectedValue = option.values.find((value) => value.selected);

        // If there's a selected value, store it in the selectedOptions object
        if (selectedValue) {
          selectedOptions[optionKey] = selectedValue;
        }
      }
    });

    return selectedOptions;
  };

  const renderValues = (options, values, optionKey, selectedOptions) => {
    return values.map((value) => {
      return (
        <React.Fragment key={value.name}>
          <button
            className={`ClickableBtn ${
              checkIfSelected(value) ? "selected" : ""
            }`}
            style={{ width: "28%" }}
            onClick={() => setSelectedOption(value, optionKey)}
          >
            {value?.name}
          </button>
        </React.Fragment>
      );
    });
  };

  const renderOptions = (options) => {
    const currentTab = findCurrentTab(
      metric?.metricOptions,
      modalInfo?.data?.categoryName,
      modalInfo?.data?.name
    );
    if (!currentTab) return null;

    const selectedOptions = getSelectedOptions(currentTab.options);

    return Object.keys(options).map((optionKey) => {
      // Check if the option should be hidden
      if (isOptionHidden(options, optionKey, selectedOptions)) return null;

      return (
        <div key={optionKey}>
          {(options[optionKey]?.type === "row" ||
            options[optionKey]?.type === "column") && (
            <label
              className="btnheading"
              style={{ textAlign: "center", width: "100%" }}
            >
              {options[optionKey].name}
            </label>
          )}

          <div
            style={{
              display: "flex",
              flexDirection: options[optionKey]?.type || "row",
              marginBottom: "1.5rem",
              justifyContent: "space-around",
              marginTop: ".2rem",
            }}
          >
            {options[optionKey]?.values &&
              renderValues(
                options,
                options[optionKey].values,
                optionKey,
                selectedOptions
              )}
          </div>
        </div>
      );
    });
  };

  /**
   *
   * @returns
   * @description This is to render the value of inputs in the top of Numpads
   */
  const renderInputs = (inputs, selectedDimension, fromNextSection = false) => {
    const blockWidth =
      inputs?.length === 1
        ? "23.5rem"
        : inputs?.length === 2
        ? "11.5rem"
        : isTablet
        ? "7.5rem" // Adjust this value as needed
        : "11.5rem"; // Default width when there are more than 2 inputs

    return inputs.map((input, index) => (
      <div
        key={index}
        style={{
          ...styles.block,
          width: blockWidth,
        }}
        onClick={() => toggleInputSelection(input)}
        className={`_block ${input.selected ? "selected" : "unselected"} ${
          fromNextSection && "unselected"
        }`}
      >
        {inputs?.length > 1 && (
          <p style={styles.blockText} className="_block-txt">
            {input?.name}
          </p>
        )}
        <p style={styles.blockText} className="_block-txt">
          {selectedDimension[input?.key] || ""}
        </p>
      </div>
    ));
  };

  /**
   *
   * @returns
   * @description This will render if the item has next options
   */
  const renderNextOptions = () => {
    const currentTab = findCurrentTab(
      metric?.metricOptions,
      modalInfo?.data?.categoryName,
      modalInfo?.data?.name
    );
    const inputs = currentTab?.inputs;
    const activeInput = inputs?.find((ct) => ct.selected);

    const isDisabled =
      !activeInput?.key ||
      !(selectedDimension && selectedDimension[activeInput.key]);
    const optionValues = currentTab?.options?.values;
    const optionStyles = currentTab?.options?.style;

    return (
      <>
        <Row style={styles.flexCenter}>
          <div
            style={{
              ...(inputs?.length != 1
                ? {
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }
                : {
                    display: "flex",
                    justifyContent: "center",
                  }),
            }}
          >
            {inputs && inputs.length
              ? renderInputs(inputs, selectedDimension, true)
              : null}
          </div>
        </Row>
        <div
          style={{
            display: "flex",
            flexDirection: optionStyles?.type || "row",
            justifyContent: "space-around",
            marginTop: "1rem",
            marginLeft: "5rem",
            marginRight: "5rem",
          }}
        >
          {optionValues ? (
            renderOptions(optionValues, optionStyles)
          ) : (
            <div>No options available</div>
          )}
          {optionValues && renderOtherValuesOption(optionValues) ? (
            renderOtherValuesOption(optionValues)
          ) : (
            <div>No options available</div>
          )}
        </div>
        <Modal.Footer style={{ border: "none" }}>
          <Button className="modified_btn" variant="danger" onClick={HideModal}>
            Close
          </Button>
          <Button
            className="modified_btn"
            onClick={() => addAndUpdateSection({ fromOptions: true })}
            disabled={isDisabled}
          >
            Save
          </Button>
        </Modal.Footer>
      </>
    );
  };

  /**
   *
   * @returns
   * @description This will render the content of modal for the numpad input
   */
  const renderModalContent = (modalInfo) => {
    const currentTab = findCurrentTab(
      metric?.metricOptions,
      modalInfo?.data?.categoryName,
      modalInfo?.data?.name
    );
    const inputs = currentTab?.inputs;
    const activeInput = inputs?.find((ct) => ct.selected);

    const isDisabled =
      !activeInput?.key ||
      !(selectedDimension && selectedDimension[activeInput.key]);

    return (
      <>
        <div style={styles.flexCenter}>
          <div
            style={{
              ...(inputs?.length != 1
                ? { display: "flex", flexDirection: "row" }
                : {}),
            }}
          >
            {inputs && inputs.length
              ? renderInputs(inputs, selectedDimension)
              : null}
          </div>
          <Numpad
            addIntoDimen={(data, num) => {
              setSelectedDimension((prevState) => {
                if (activeInput?.key) {
                  // Check if num is not undefined or empty
                  if (num !== undefined && num !== "") {
                    return {
                      ...prevState,
                      [activeInput.key]:
                        (prevState[activeInput.key] || "") + num.toString(),
                    };
                  } else {
                    // If num is undefined or empty, just return the current state
                    return {
                      ...prevState,
                      [activeInput.key]: "",
                    };
                  }
                }
                return prevState;
              });
            }}
            selectDimen={"Starter"}
            handleAdd={() => addAndUpdateSection({ fromOptions: false })}
            editDimen={""}
            disableAddOrSave={isDisabled}
            saveText={modalInfo?.data?.isNextAvailable ? "Next" : "Save"}
          />
        </div>
        <Modal.Footer style={{ border: "none" }}>
          <Button className="modified_btn" variant="danger" onClick={HideModal}>
            Close
          </Button>
          <Button className="modified_btn" onClick={toggleKeypad}>
            View Tally
          </Button>
        </Modal.Footer>
      </>
    );
  };

  /**
   *
   * @returns
   * @description This will render the tally view
   */
  const renderTally = () => {
    const currentTab = findCurrentTab(
      metric?.metricOptions,
      modalInfo?.data?.categoryName,
      modalInfo?.data?.name
    );

    // Check if currentTab is defined and has inputs and options
    if (currentTab && currentTab.inputs) {
      // Check if inputs is an array
      const inputsArray = Array.isArray(currentTab.inputs)
        ? currentTab.inputs
        : [];
      // Check if options.values is an object
      const optionsValues =
        typeof currentTab?.options?.values === "object"
          ? currentTab?.options?.values
          : {};

      if (inputsArray.length && currentValue && currentValue.length) {
        return (
          <div
            className="slide-right"
            style={{
              flexDirection: "column",
            }}
          >
            <>
              <table className="dimen_table">
                <thead>
                  <tr>
                    <th></th>
                    {/* Render headers for inputs */}
                    {inputsArray.map((thead) => (
                      <th key={thead?.key}>{thead?.name}</th>
                    ))}

                    {/* Render headers for options */}
                    {Object.keys(optionsValues).map(
                      (thead) =>
                        !isOptionHidden(optionsValues, thead) && (
                          <React.Fragment>
                            <th key={thead}>{optionsValues[thead]?.name}</th>
                            {currentTab?.categoryName === "Misc Items" &&
                              currentTab?.name === "Metal Sheet" && (
                                <th key={thead}>Dimensions</th>
                              )}
                          </React.Fragment>
                        )
                    )}
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {/* Render rows for each value in currentCategoryValue */}
                  {currentValue.map((value) => (
                    <tr key={value.id}>
                      <td>
                        <Button onClick={() => editCurrentValue(value)}>
                          Edit
                        </Button>
                      </td>
                      {/* Render input values */}
                      {inputsArray.map((thead) => (
                        <td key={`${value.id}-${thead.key}`}>
                          {value?.[thead.key]
                            ? value?.[thead.key]
                            : value?.value}
                        </td>
                      ))}

                      {/* Render selected option values */}
                      {optionsValues
                        ? Object.keys(optionsValues).map((optionKey) => (
                            <React.Fragment key={`${value.id}-${optionKey}`}>
                              {/* Render the main option value */}
                              {!isOptionHidden(optionsValues, optionKey) && (
                                <td>
                                  {optionsValues[optionKey]?.key &&
                                  value?.options?.[optionsValues[optionKey].key]
                                    ?.value
                                    ? value?.options?.[
                                        optionsValues[optionKey].key
                                      ]?.value
                                    : value?.options?.[optionKey]?.value || "-"}
                                </td>
                              )}

                              {/* Render additional inputs under `others.inputs` if they exist */}
                              {value?.options?.[optionKey]?.others?.inputs
                                ?.length > 0
                                ? value.options[optionKey].others.inputs.map(
                                    (otherInputValue, index) => (
                                      <td
                                        key={`${value.id}-${optionKey}-${index}`}
                                      >
                                        {otherInputValue?.name || "-"}
                                      </td>
                                    )
                                  )
                                : null}
                            </React.Fragment>
                          ))
                        : null}

                      {/* Render delete button */}
                      <td>
                        {isLoadingDeleTallyRecord &&
                        selectedTallyRecordIdForDelete === value?.id ? (
                          <Spinner />
                        ) : (
                          <Button
                            variant="danger"
                            onClick={() => deleteCurrentValue(value)}
                          >
                            Delete
                          </Button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>

            <Modal.Footer
              style={{ justifyContent: "space-between", border: "none" }}
            >
              <div>
                <Button
                  className="modified_btn"
                  style={{ float: "left" }}
                  onClick={toggleKeypad}
                >
                  Keypad
                </Button>
              </div>
              {showPagination()}
              <div>
                <Button
                  className="modified_btn"
                  variant="danger"
                  onClick={() => {
                    HideModal();
                  }}
                >
                  Close
                </Button>
              </div>
            </Modal.Footer>
          </div>
        );
      }
    }
    return null;
  };

  const showPagination = () => (
    <div style={{ display: "flex", alignItems: "baseline" }}>
      <button
        aria-label="Prev"
        className="pagination_btns"
        // onClick={this.prevPage}
        // disabled={this.state.page_num === 1}
      >
        {/* {getIcon(faChevronLeft)} */}
        {"<"}
      </button>
      {/* <p className="page_num">{this.state.page_num}</p> */}
      <p className="page_num">1</p>

      <button
        aria-label="Next"
        className="pagination_btns"
        // disabled={
        //   this.state.page_num * this.state.max_item >=
        //   this.state[this.state.selectData].length
        // }
        // onClick={this.nextPage}
      >
        {/* {getIcon(faChevronRight)} */}

        {">"}
      </button>
    </div>
  );

  return (
    <PopupModal
      show={showTabModal}
      heading={modalHeader}
      onHide={() => {
        HideModal();
        if (viewTallyMode) setViewTallyMode(false);
      }}
      bold
      styles={styles.carsl}
      scrollable={false}
    >
      <div className="_carsl">
        <div className="slide-left view">
          {topView()}
          {!viewTallyMode
            ? showNextContent
              ? renderNextOptions()
              : renderModalContent(modalInfo)
            : renderTally()}
        </div>
      </div>
    </PopupModal>
  );
};

AccessoriesTabModal.propTypes = {
  label: PropTypes.string,
  showTabModal: PropTypes.bool,
  selectDimension: PropTypes.any,
  editDimension: PropTypes.bool,
  section: PropTypes.number,
  selectedData: PropTypes.any,
  onHide: PropTypes.func,
  totalLabel: PropTypes.string,
  modalInfo: PropTypes.any,
};

export default AccessoriesTabModal;
