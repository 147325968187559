import { useMutation, useQuery } from "@tanstack/react-query";
import {
  addGutterMisc,
  changeGutterupplier,
  deleteGutterMiscQuote,
  getGutterActiveSuppliers,
  updateGutterQuoteLine,
} from "../api/gutterApi";

export const useGutterSuppliers = () => {
  const { data, isLoading, isError, error } = useQuery(
    ["gutterSuppliers"], // Query key for caching
    getGutterActiveSuppliers,
    {
      // Ensures the query runs once and doesn't repeat unless needed
      staleTime: 1000 * 60 * 5, // 5 minutes for caching data
      cacheTime: 1000 * 60 * 30, // Cache for 30 minutes before garbage collection
    }
  );

  return {
    suppliers: data?.result || [], // Return suppliers from the data or empty array if undefined
    isLoading,
    isError,
    error,
  };
};

export const useChangeGutterSupplier = () => {
  return useMutation(changeGutterupplier);
};

// Hook for adding Misc Material to Quote
export const useAddGutterlMisc = () => {
  return useMutation(addGutterMisc);
};

// Hook for adding Misc Material to Quote
export const useDeleteGutterMisc = () => {
  return useMutation(deleteGutterMiscQuote);
};

// Hook for adding Misc Material to Quote
export const useUpdateGutterQuoteLine = () => {
  return useMutation(updateGutterQuoteLine);
};
