import React from "react";
import { Container, Row, Col, Button, Modal, Alert } from "react-bootstrap";

import { connect } from "react-redux";
import {
  getAllSubscriptionInfo,
  editSubscriptionInfo,
} from "lib/redux/base-pricing/base-pricing.actions";
import {
  GetCompanySuccess,
  ResendButtonClicked,
  UpdateOtpSumbittedStatus,
  getBasePlan,
  getBasePlanSignup,
  get_company_by_companyId,
  registerUser,
  update_paid_in_user,
} from "lib/redux/user/user.actions";

import { Animated } from "react-animated-css";
import ReactWizard from "components/wizard/wizard";
import { Link } from "react-router-dom";
import Logo from "components/Logo";

import AdminDetails from "components/admin-details/admin-details";
import ReviewSubmit from "components/review-submit/review-submit";
import CompanyDetails from "components/company-details/company-details";
import PricingDetails from "components/pricing-details/pricing-details";
import PopupModal from "components/popup-modal/popup-modal";
import { capitalizeFirstLetter, getActivePaymentMethod } from "utils/utils";
import { toast } from "react-toastify";
import OtpDetails from "components/otp-details/OtpDetails";
import { debounce } from "lodash";
import {
  create_subscription,
  get_county_tax_by_state_zip,
} from "./signup.service";
import {
  hideCheckoutModal,
  showCheckoutModal,
} from "lib/redux/checkout/checkout.action";

const newStyle = {
  fullHt: {
    height: "auto",
    minHeight: "100vh",
  },
  formInput: {
    marginBottom: "15px",
  },
  formStyle: {
    // marginTop: "5%"
  },
  loginDiv: {
    textAlign: "right",
    margin: "auto",
  },
  wizardCol: {
    marginTop: "15px",
  },
  submitView: {
    background: "white",
    borderRadius: "5px",
  },
  noteText: {
    fontSize: "14px",
    color: "#636363",
  },
};

class Signup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      phoneCode: "+1",
      fName: "",
      lName: "",
      aEmail: "",
      aOtp: "",
      aConfirmEmail: "",
      aPhone: "",
      password: "",
      confirmPassword: "",
      cName: "",
      cAddress1: "",
      cAddress2: "",
      cCity: "",
      cState: "",
      currentCounty: {
        county_tax: 0,
        county: "",
        id: "",
        stateTaxRate: 0,
        oneTimeFeeTax: 0,
      },
      cZip: "",
      primaryPhone: "",
      secondaryPhone: "",
      cWebsite: "",
      cEmail: "",
      fax: "",
      cardHolderName: "",
      cardNumber: "",
      expirationMonth: "12",
      expirationYear: "2030",
      cvv: "",
      additionalUser: 0,
      monthlySubscription: 15,
      bAddressChecked: false,
      billingAddress: "",
      billingAddress2: "",
      bCity: "",
      bState: "",
      bZip: "",
      basePrice: 0,
      additionalPricePerUser: 0,
      currency: "USD",
      modal: false,
      submitting: false,
      errMsg: "",
      errType: "warning",
      currentStep: 0,
      submitSuccess: false,
      subscriptionApiCalled: false,
      baseUsers: 5,
      otp: "",
      companyId: "",
      county: "",
      total_tax: "",
      storedCompanyId: this.props.company_id_edit,
      on_boarding_price: "",
      loadingCounty: false,
      company_id: null,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleChecked = this.handleChecked.bind(this);
    this.selectRegion = this.selectRegion.bind(this);
    this.updateStep = this.updateStep.bind(this);
    this.get_company_id = this.get_company_id.bind(this);
    this.finishButtonClick = this.finishButtonClick.bind(this);
    this.editForm = this.editForm.bind(this);
    this.afterSubmission = this.afterSubmission.bind(this);
    this.increaseAdditionalUser = this.increaseAdditionalUser.bind(this);
    this.decreaseAddditionalUser = this.decreaseAddditionalUser.bind(this);
    this.calculateMonthlySubscription =
      this.calculateMonthlySubscription.bind(this);
    this.onClose = this.onClose.bind(this);
    this.getCurrentCounty = this.getCurrentCounty.bind(this);
    this.submitForm = this.submitForm.bind(this);

    this.debouncedSearch = debounce(this.handleSearch.bind(this), 900);
  }

  componentDidMount() {
    this.props.getAllSubscriptionInfo();
    this.props.getBasePlanSignup();

    let company_id = localStorage.getItem("companyId");

    if (this.props.type === "edit") company_id = this.props?.company_id_edit;
    if (this.props.location?.state?.inCompletePayment) {
      company_id = this.props.location?.state?.companyId;
    }

    if (company_id) {
      this.setState({ company_id: company_id });
    }
    const { password } = this.state;

    const payment = localStorage.getItem("payment");

    if (payment === "success") {
      localStorage.removeItem("companyId");
      localStorage.removeItem("otp");
      localStorage.removeItem("payment");
    } else if (
      (company_id !== "" && company_id) ||
      this.props.type === "edit"
    ) {
      get_company_by_companyId(company_id).then((res) => {
        let {
          address1,
          address2,
          city,
          companyEmail,
          companyName,
          fax,
          faxCode,
          phone1,
          phone1Code,
          phone2,
          phone2Code,
          website,
          zip,
          county,
          admin: [{ firstName = "", lastName = "", email = "" }],
          currentCounty,
          onboarding_price,
        } = res?.data?.result[0];
        console.log("Company Details", { company: res?.data?.result[0] });

        this.props.updateCompanyDataInState(res?.data?.result[0]);

        const state = res?.data?.result[0]?.stateId;
        if (zip) {
          this.debouncedSearch(zip);
        }
        this.setState({
          cName: companyName,
          cAddress1: address1,
          phoneCode: phone1Code,
          fName: firstName,
          lName: lastName,
          aEmail: email,
          aConfirmEmail: email,
          aPhone: phone1,
          cAddress2: address2,
          cCity: city,
          cState: state,
          cZip: String(zip),
          primaryPhone: phone1,
          secondaryPhone: phone2,
          cWebsite: website,
          cEmail: companyEmail,
          fax: fax,
          password: password && password,
          county: county ? county : "",
          total_tax: currentCounty?.county_tax,
          on_boarding_price: onboarding_price,
          additionalUser: this.props.allBasePlan?.baseList?.additionalUser || 0,
        });
        console.log(
          "additionalUser",
          this.props.allBasePlan?.baseList?.additionalUser
        );
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      fName,
      lName,
      bAddressChecked,
      cAddress1,
      cAddress2,
      cCity,
      cState,
      cZip,
    } = this.state;
    const { subscriptionInfo, getbaseplan, allBasePlan } = this.props;

    if (bAddressChecked) {
      if (cAddress1 !== prevState.cAddress1) {
        this.setState({ billingAddress: cAddress1 });
      }
      if (cAddress2 !== prevState.cAddress2) {
        this.setState({ billingAddress2: cAddress2 });
      }
      if (cCity !== prevState.cCity) {
        this.setState({ bCity: cCity });
      }
      if (cZip !== prevState.cZip) {
        this.setState({ bZip: cZip });
      }
      if (cState !== prevState.cState) {
        this.setState({ bState: cState });
      }
    }
    if (prevState.bAddressChecked !== bAddressChecked) {
      if (bAddressChecked) {
        this.setState({
          billingAddress: cAddress1,
          billingAddress2: cAddress2,
          bCity: cCity,
          bZip: cZip,
          bState: cState,
        });
      } else {
        this.setState({
          billingAddress: "",
          bCity: "",
          bZip: "",
          bState: "",
        });
      }
    }

    if (prevProps.getbaseplan?.basePlanList !== getbaseplan.basePlanList) {
      if (getbaseplan.basePlanList) {
        const {
          additionalUser,
          baseplanPrice,
          baseplanUsers,
          pricePerAdditionalUser,
          oneTimeSetipPrice,
          totalPrice,
        } = getbaseplan?.basePlanList;

        this.setState({
          basePrice: baseplanPrice,
          additionalPricePerUser: pricePerAdditionalUser,
          additionalUser: additionalUser,
          monthlySubscription: baseplanPrice,
          baseUsers: baseplanUsers,
          oneTimeCharge: oneTimeSetipPrice,
        });
      }
    }

    /**
     *
     */
    if (
      prevProps.subscriptionInfo?.subscriptionInfo !==
      subscriptionInfo?.subscriptionInfo
    ) {
      if (subscriptionInfo?.subscriptionInfo) {
        const { companyDetail, paymentDetail, adminDetails } =
          subscriptionInfo?.subscriptionInfo;

        if (
          companyDetail !== null &&
          paymentDetail !== null &&
          adminDetails !== null &&
          companyDetail !== undefined &&
          paymentDetail !== undefined &&
          adminDetails !== undefined
        ) {
          const {
            _id,
            address1,
            address2,
            billingAddress1,
            billingAddress2,
            billingAddressSameAsCompanyAddress,
            billingCity,
            billingState,
            billingZip,
            city,
            companyEmail,
            companyName,
            fax,
            phone1,
            phone1Code,
            phone2,
            state,
            website,
            zip,
            county,
          } = companyDetail;
          const { cardNumber, cvv, expiryMonth, expiryYear, nameOnCard } =
            paymentDetail;

          const { firstName, lastName, email, phone } = adminDetails;

          let stateName = stateName ? capitalizeFirstLetter(state) : "";
          let bstateName = billingState
            ? capitalizeFirstLetter(billingState)
            : "";

          this.setState({
            cName: companyName,
            cAddress1: address1,
            phoneCode: phone1Code,
            fName: firstName,
            lName: lastName,
            aEmail: email,
            aConfirmEmail: email,
            aPhone: phone,
            cAddress2: address2,
            cCity: city,
            cState: stateName,
            cZip: String(zip),
            primaryPhone: phone1,
            secondaryPhone: phone2,
            cWebsite: website,
            cEmail: companyEmail,
            fax: fax,
            cardHolderName: nameOnCard,
            cardNumber: cardNumber,
            expirationMonth: expiryMonth,
            expirationYear: expiryYear,
            cvv: cvv,
            bAddressChecked: billingAddressSameAsCompanyAddress,
            billingAddress: billingAddress1,
            billingAddress2: billingAddress2,
            bCity: billingCity,
            bState: bstateName,
            bZip: String(billingZip),
            county: county,
            storedCompanyId: _id,
          });
        }
      }
    }

    /**
     *
     */

    if (prevState.fName !== fName) {
      this.setState((state) => ({
        cardHolderName: `${fName}${lName ? ` ${lName}` : ""}`,
      }));
    }
    if (prevState.lName !== lName) {
      this.setState((state) => ({
        cardHolderName: `${fName ? `${fName} ` : ""}${lName}`,
      }));
    }
  }

  // Once submit is clicked Subscription Pricing
  async finishButtonClick(allStates) {
    const { aEmail: email, additionalUser } = this.state;
    const { company } = this.props;
    const companyInfo = this.props.company?.company;

    if (
      companyInfo &&
      companyInfo.paymentMethods &&
      companyInfo.paymentMethods.length
    ) {
      const paymentInfo = getActivePaymentMethod(companyInfo.paymentMethods);
      const paymentId = paymentInfo.paymentMethodId;
      const companyId = companyInfo._id;
      this.setState({ subscriptionApiCalled: true });

      try {
        const res = await create_subscription({
          payment_method: paymentId,
          email: email,
          companyId: companyId,
          additionalUser: additionalUser,
        });

        if (!res || !res.data || !res.data.result) {
          toast.error("Something Went Wrong");
          return false;
        }

        // localStorage.setItem("payment", "success");

        const updateRes = await update_paid_in_user({ companyId: companyId });

        if (updateRes.data.success) {
          toast.success(
            "Your Subscription Is Successful with Organized Roofer"
          );
          this.setState({ submitSuccess: true, modal: true });
          return true;
        }
      } catch (error) {
        toast.error("Something went wrong");
        throw error; // Re-throw to be caught by wizard's error handling
      }
    } else {
      toast.error("Please add a card and then proceed for payment!");
      throw new Error("Payment method required");
      return false;
    }
    this.setState({ subscriptionApiCalled: false });
  }

  editForm() {
    this.setState({
      isSubmited: false,
      submitting: false,
      submitSuccess: false,
      errMsg: "",
      modal: false,
    });
  }

  async handleSearch(searchTerm) {
    const { cState, cZip } = this.state;
    try {
      if (cState && cZip.length > 1) {
        this.setState({
          loadingCounty: true,
        });

        const response = await get_county_tax_by_state_zip(cState, searchTerm);

        if (
          response &&
          response.data &&
          response.data.result &&
          response.data.result.length > 0
        ) {
          console.log("County Details", response.data.result[0]);
          const {
            county,
            county_tax,
            county_id,
            _id,
            stateTaxRate,
            oneTimeFeeTax,
          } = response.data.result[0];

          this.setState({
            loadingCounty: false,
            currentCounty: {
              county_tax: county_tax,
              county: county,
              id: county_id,
              stateTaxRate: stateTaxRate,
              oneTimeFeeTax: oneTimeFeeTax,
            },
            county: county,
            total_tax: county_tax,
          });
        } else {
          console.log("No results found for the search term");
          toast.error("No county found for the entered zip code.");
          this.setState({
            county: "",
            total_tax: 0,
            currentCounty: {
              county_tax: 0,
              county: "",
              id: "",
              stateTaxRate: 0,
              oneTimeFeeTax: 0,
            },
          });
        }
      } else {
        this.setState({
          county: "",
          total_tax: 0,
          currentCounty: {
            county_tax: 0,
            county: "",
            id: "",
            stateTaxRate: 0,
            oneTimeFeeTax: 0,
          },
        });
      }
    } catch (error) {
      console.error("Error occurred during county search:", error);
      toast.error("Error occurred during county search", { delay: 200 });
      this.setState({
        loadingCounty: false,
        total_tax: 0,
        currentCounty: {
          county_tax: 0,
          county: "",
          id: "",
          stateTaxRate: 0,
          oneTimeFeeTax: 0,
        },
      });
    }
  }

  async submitForm() {
    const {
      phoneCode,
      fName,
      lName,
      aEmail,
      aPhone,
      password,
      cName,
      cAddress1,
      cAddress2,
      cCity,
      cState,
      cStateId,
      cZip,
      primaryPhone,
      secondaryPhone,
      cWebsite,
      cEmail,
      fax,
      additionalUser,
      monthlySubscription,
      oneTimeCharge,
      bAddressChecked,
      billingAddress,
      billingAddress2,
      bCity,
      bState,
      bZip,
      cardHolderName,
      cardNumber,
      expirationMonth,
      expirationYear,
      cvv,
      baseUsers,
      companyId,
      county,
    } = this.state;
    this.setState({ submitting: true });

    if (this.props.type === "edit") {
      return editSubscriptionInfo({
        paymentDetails: {
          nameOnCard: cardHolderName,
          cardNumber: Number(cardNumber),
          expiryMonth: expirationMonth,
          expiryYear: Number(expirationYear),
          cvv: Number(cvv),
        },
        companyDetails: {
          companyName: cName,
          address1: cAddress1,
          address2: cAddress2,
          city: cCity,
          state: cState,
          zip: Number(cZip),
          phone1Code: phoneCode,
          phone1: primaryPhone,
          phone2Code: phoneCode,
          phone2: secondaryPhone,
          faxCode: phoneCode,
          fax: fax,
          website: cWebsite,
          companyEmail: cEmail,
          billingAddressSameAsCompanyAddress: bAddressChecked,
          billingAddress1: billingAddress,
          billingAddress2: billingAddress2,
          billingCity: bCity,
          billingState: bState,
          billingZip: Number(bZip),
          county: county,
        },
        adminDetails: {
          firstName: fName,
          lastName: lName,
          email: aEmail,
          // password: password,
          phoneCode: phoneCode,
          phone: aPhone,
        },
        basePlanUsers: {
          additionalUser: additionalUser,
        },
      })
        .then((res) => res.data)
        .then((data) => {
          if (data.success) {
            this.setState({
              submitting: false,
              modal: false,
              submitSuccess: true,
            });
            toast.success("Subscription Information updated succesfully.");

            // this.props.history?.push("/dashboard/application-settings");
          } else {
            this.setState({
              submitting: false,
              errMsg: data.error,
              errType: "danger",
            });
            toast.error(data.error);
          }
        })
        .catch((err) => {
          this.setState({
            submitting: false,
            errMsg: err.message,
            errType: "danger",
          });
          toast.error(err.message);
        });
    } else {
      return registerUser({
        billingAddressSameAsCompanyAddress: bAddressChecked,
        billingAddress1: billingAddress,
        billingAddress2: billingAddress2,
        billingCity: bCity,
        billingState: bState,
        billingZip: Number(bZip),

        password: password,

        county: county,
        totalUsers: baseUsers + additionalUser,
        totalCost: monthlySubscription,

        nameOnCard: cardHolderName,
        cardNumber: Number(cardNumber),
        expiryMonth: expirationMonth,
        expiryYear: Number(expirationYear),
        cvv: Number(cvv),
        company_id: localStorage.getItem("companyId"),
      })
        .then((res) => res.data)
        .then((data) => {
          if (data.success) {
            this.setState({
              submitting: false,
              submitSuccess: true,
            });
          } else {
            this.setState({
              submitting: false,
              errMsg: data.error,
              errType: "danger",
            });
          }
        })
        .catch((err) => {
          this.setState({
            submitting: false,
            errMsg: err.message,
            errType: "danger",
          });
        });
    }
  }

  afterSubmission() {
    this.editForm();
    return setTimeout(() => {
      this.editForm();
      this.props.history?.push("/login");
    }, 3000);
  }

  increaseAdditionalUser() {
    this.setState(
      (prevState) => ({ additionalUser: prevState.additionalUser + 1 }),
      () => this.calculateMonthlySubscription()
    );
  }

  decreaseAddditionalUser() {
    if (this.state.additionalUser - 1 < 0) return;
    return this.setState(
      (prevState) => ({ additionalUser: prevState.additionalUser - 1 }),
      () => this.calculateMonthlySubscription()
    );
  }

  get_company_id(id) {
    // localStorage.setItem("companyId", id);
    this.setState({
      companyId: id,
    });
  }

  calculateMonthlySubscription() {
    const { additionalUser, basePrice, additionalPricePerUser } = this.state;
    const newMonthlySubscription =
      basePrice + additionalPricePerUser * additionalUser;

    this.setState({
      monthlySubscription: newMonthlySubscription,
    });
  }

  handleChange(event) {
    if (event.target) {
      this.setState({ [event.target.name]: event.target.value });
      if (event.target.name === "cZip") {
        this.debouncedSearch(event.target.value);
      }

      // Written this forn to get the otp from OtpDetaiols component and set here
    } else if (event.aOtp) {
      this.setState({ aOtp: event.aOtp });
    }
  }

  handleChecked(event) {
    this.setState({ [event.target.name]: event.target.checked });
  }

  selectRegion(name, e) {
    this.setState({ [name]: e.target.value });
  }

  updateStep(currentStep) {
    this.setState({ currentStep });
  }

  navigationStepChange() {}

  onClose() {
    this.setState({ modal: false, submitting: false });
    if (this.state.submitSuccess) {
      this.afterSubmission();
    }
  }

  getCurrentCounty() {
    const { currentCounty } = this.state;
    return currentCounty.county
      ? currentCounty
      : this.props.company && this.props.company.company
      ? this.props.company.company.currentCounty
      : null;
  }

  componentWillUnmount() {
    // Reset OTP status when component unmounts
    if (this.props.updateOtpStatus) {
      this.props.updateOtpStatus(false);
    }
  }

  render() {
    const {
      isSubmited,
      fName,
      lName,
      aEmail,
      aOtp,
      aConfirmEmail,
      aPhone,
      password,
      confirmPassword,
      cName,
      cAddress1,
      cAddress2,
      cCity,
      cState,
      cZip,
      oneTimeCharge,
      primaryPhone,
      secondaryPhone,
      cWebsite,
      cEmail,
      fax,
      additionalUser,
      monthlySubscription,
      bAddressChecked,
      billingAddress,
      billingAddress2,
      bCity,
      bState,
      bZip,
      cardHolderName,
      cardNumber,
      expirationMonth,
      expirationYear,
      cvv,
      basePrice,
      additionalPricePerUser,
      currency,
      modal,
      submitting,
      submitSuccess,
      subscriptionApiCalled,
      currentStep,
      baseUsers,
      otp,
      companyId,
      county,
      total_tax,
      storedCompanyId,
      on_boarding_price,
    } = this.state;

    const {
      handleChange,
      increaseAdditionalUser,
      decreaseAddditionalUser,
      handleChecked,
      updateStep,
      selectRegion,
      get_company_id,
    } = this;

    const { errType, errMsg, company_id } = this.state;
    let type = this.props.type;
    let from = this.props.from;
    let { subscriptionInfo } = this.props;

    // || this.props?.login?.company_id_edit;
    let otp_verified = false;

    if (this.props.user) {
      otp_verified = this.props.user.verified;
    }

    if (company_id != null && otp_verified) {
      type = "edit";
    }
    if (company_id !== "" && company_id && !otp_verified) {
      type = "Edit_But_Not_Verified";
    }

    console.log({ company_id, type, otp_verified });
    return (
      <Container className="p-3" style={newStyle.fullHt}>
        <Row>
          {this.props.type !== "edit" && (
            <Col xs="12" md="6" lg="4">
              <Logo height="135px" />
            </Col>
          )}

          <Col xs="12" md="6" lg="8" style={newStyle.loginDiv}>
            {this.props.type === "edit" ? (
              ""
            ) : (
              <Button variant="link" id="toLogin">
                <Link to="/login">Already have an account? Log in here.</Link>
              </Button>
            )}
          </Col>

          <Col
            xs="12"
            style={this.props.type === "edit" ? {} : { marginTop: "2%" }}
          >
            <Animated
              isVisible={true}
              className={isSubmited ? "hidden" : ""}
              style={this.props.type === "edit" ? "" : newStyle.wizardCol}
            >
              {type === "edit" && from ? (
                <ReactWizard
                  type={type}
                  steps={[
                    {
                      stepName: "Company Information",
                      component: CompanyDetails,
                      stepProps: {
                        cName,
                        cAddress1,
                        cAddress2,
                        cCity,
                        cState,
                        cZip,
                        primaryPhone,
                        secondaryPhone,
                        cWebsite,
                        cEmail,
                        fax,
                        county,
                        handleChange,
                        selectRegion: (n, v) => selectRegion(n, v),
                      },
                    },
                    {
                      stepName: "Subscription Administrator",
                      component: AdminDetails,
                      stepProps: {
                        cName,
                        cAddress1,
                        cAddress2,
                        cCity,
                        cState,
                        cZip,
                        primaryPhone,
                        secondaryPhone,
                        cWebsite,
                        cEmail,
                        fax,
                        fName,
                        lName,
                        aEmail,
                        aConfirmEmail,
                        aPhone,
                        password,
                        confirmPassword,
                        currentStep,
                        handleChange,
                        type,
                        baseUsers,
                        county,
                        get_company_id,
                        company_id,
                      },
                    },
                    {
                      stepName: "Subscription Pricing",
                      component: PricingDetails,
                      stepProps: {
                        aEmail: this.state.aEmail,
                        additionalUser: additionalUser,
                        monthlySubscription,
                        bAddressChecked,
                        billingAddress,
                        billingAddress2,
                        bCity,
                        bState,
                        bZip,
                        company: this.props.company?.company,
                        cardHolderName,
                        cardNumber,
                        expirationMonth,
                        expirationYear,
                        cvv,
                        increaseAdditionalUser,
                        decreaseAddditionalUser,
                        handleChange,
                        handleChecked,
                        basePrice,
                        additionalPricePerUser,
                        currency,
                        selectRegion: (n, v) => selectRegion(n, v),
                        baseUsers,
                        type,
                        company_id,
                        from,
                        subscriptionInfo,
                        total_tax,
                        storedCompanyId,
                        oneTimeCharge: oneTimeCharge,
                        company: this.props.company?.company,
                        countyTax: this.state.currentCounty,
                        showCheckoutModal: this.props.showCheckoutModal,
                        hideCheckoutModal: this.props.hideCheckoutModal,
                        checkoutModalDisplay:
                          this.props.checkout.checkoutModalDisplay,
                        resetCompanyData: () => {
                          this.props.updateCompanyDataInState(null);
                        },
                      },
                    },
                    {
                      stepName: "Review / Submit",
                      component: ReviewSubmit,
                      stepProps: {
                        fName,
                        lName,
                        aEmail,
                        aPhone,
                        cName,
                        cAddress1,
                        cAddress2,
                        cCity,
                        cState,
                        cZip,
                        billingAddress,
                        billingAddress2,
                        bState,
                        bCity,
                        bZip,
                        bAddressChecked,
                        primaryPhone,
                        secondaryPhone,
                        cWebsite,
                        cEmail,
                        fax,
                        additionalUser,
                        monthlySubscription,
                        basePrice,
                        additionalPricePerUser,
                        updateStep,
                        currency,
                        baseUsers,
                        total_tax,
                        county,
                        currentCounty: this.getCurrentCounty(),
                        oneTimeCharge: oneTimeCharge,
                      },
                    },
                  ]}
                  title={
                    this.props.type === "edit"
                      ? "Subscription Information"
                      : "Signup"
                  }
                  navSteps
                  headerTextCenter
                  validate
                  color="primary"
                  finishButtonText="Submit"
                  previousButtonClasses="wizard-prev-button"
                  nextButtonClasses="wizard-next-button"
                  finishButtonClasses="wizard-next-button"
                  finishButtonClick={this.finishButtonClick}
                  currentStep={currentStep}
                  updateStep={this.updateStep}
                  additionalUser={this.state.additionalUser}
                  company_id={company_id || localStorage.getItem("companyId")}
                />
              ) : type === "edit" && !from ? (
                <ReactWizard
                  type={type}
                  steps={[
                    {
                      stepName: "Company Information",
                      component: CompanyDetails,
                      stepProps: {
                        cName,
                        cAddress1,
                        cAddress2,
                        cCity,
                        cState,
                        cZip,
                        primaryPhone,
                        secondaryPhone,
                        cWebsite,
                        cEmail,
                        fax,
                        county,
                        handleChange,
                        selectRegion: (n, v) => selectRegion(n, v),
                      },
                    },
                    {
                      stepName: "Subscription Administrator",
                      component: AdminDetails,
                      stepProps: {
                        cName,
                        cAddress1,
                        cAddress2,
                        cCity,
                        cState,
                        cZip,
                        primaryPhone,
                        secondaryPhone,
                        cWebsite,
                        cEmail,
                        fax,
                        fName,
                        lName,
                        aEmail,
                        aConfirmEmail,
                        aPhone,
                        password,
                        confirmPassword,
                        currentStep,
                        handleChange,
                        type,
                        baseUsers,
                        county,
                        get_company_id,
                        company_id,
                      },
                    },
                    {
                      stepName: "Subscription Pricing",
                      component: PricingDetails,
                      stepProps: {
                        aEmail: this.state.aEmail,
                        additionalUser,
                        monthlySubscription,
                        bAddressChecked,
                        billingAddress,
                        billingAddress2,
                        bCity,
                        bState,
                        bZip,
                        cardHolderName,
                        cardNumber,
                        expirationMonth,
                        expirationYear,
                        cvv,
                        county,
                        cState,
                        increaseAdditionalUser,
                        decreaseAddditionalUser,
                        handleChange,
                        handleChecked,
                        basePrice,
                        additionalPricePerUser,
                        currency,
                        selectRegion: (n, v) => selectRegion(n, v),
                        baseUsers,
                        type,
                        company: this.props.company?.company,
                        company_id,
                        from,
                        subscriptionInfo,
                        total_tax,
                        storedCompanyId,
                        oneTimeCharge: oneTimeCharge,
                        countyTax: this.state.currentCounty,
                        showCheckoutModal: this.props.showCheckoutModal,
                        hideCheckoutModal: this.props.hideCheckoutModal,
                        checkoutModalDisplay:
                          this.props.checkout.checkoutModalDisplay,
                        resetCompanyData: () => {
                          this.props.updateCompanyDataInState(null);
                        },
                      },
                    },
                  ]}
                  additionalUser={this.state.additionalUser}
                  title={
                    this.props.type === "edit"
                      ? "Edit Subscription Information"
                      : "Signup"
                  }
                  navSteps
                  headerTextCenter
                  validate
                  color="primary"
                  finishButtonText="Submit"
                  previousButtonClasses="wizard-prev-button"
                  nextButtonClasses="wizard-next-button"
                  finishButtonClasses="wizard-next-button"
                  finishButtonClick={this.finishButtonClick}
                  currentStep={currentStep}
                  updateStep={this.updateStep}
                  company_id={company_id || localStorage.getItem("companyId")}
                />
              ) : type === "Edit_But_Not_Verified" ? (
                <ReactWizard
                  type={type}
                  steps={[
                    {
                      stepName: "Company Information",
                      component: CompanyDetails,
                      stepProps: {
                        cName,
                        cAddress1,
                        cAddress2,
                        cCity,
                        cState,
                        cZip,
                        primaryPhone,
                        secondaryPhone,
                        cWebsite,
                        cEmail,
                        fax,
                        county,
                        handleChange,
                        selectRegion: (n, v) => selectRegion(n, v),
                      },
                    },
                    {
                      stepName: "Subscription Administrator",
                      component: AdminDetails,
                      stepProps: {
                        cName,
                        cAddress1,
                        cAddress2,
                        cCity,
                        cState,
                        cZip,
                        primaryPhone,
                        secondaryPhone,
                        cWebsite,
                        cEmail,
                        fax,
                        fName,
                        lName,
                        aEmail,
                        aConfirmEmail,
                        aPhone,
                        password,
                        confirmPassword,
                        currentStep,
                        handleChange,
                        type,
                        baseUsers,
                        county,
                        get_company_id,
                        company_id,
                        currentCounty: this.getCurrentCounty(),
                      },
                    },
                    {
                      stepName: "User Verification",
                      component: OtpDetails,
                      stepProps: {
                        hello: "world",
                        aEmail,
                        aOtp,
                        handleChange,
                        updateStep,
                        updateOtpStatus: this.props?.updateOtpStatus,
                        otpSubmittedStatus:
                          this.props?.login?.otpSubmittedStatus,
                        updateResendButtonStatus:
                          this.props?.updateResendButtonStatus,
                        resendOtpButtonStatus:
                          this.props?.login?.resendOtpButtonStatus,
                      },
                    },
                    {
                      stepName: "Subscription Pricing",
                      component: PricingDetails,
                      stepProps: {
                        aEmail: this.state.aEmail,
                        additionalUser,
                        monthlySubscription,
                        bAddressChecked,
                        billingAddress,
                        billingAddress2,
                        bCity,
                        bState,
                        bZip,
                        cardHolderName,
                        cardNumber,
                        expirationMonth,
                        expirationYear,
                        cvv,
                        county,
                        cState,
                        aEmail,
                        increaseAdditionalUser,
                        decreaseAddditionalUser,
                        handleChange,
                        handleChecked,
                        basePrice,
                        additionalPricePerUser,
                        currency,
                        selectRegion: (n, v) => selectRegion(n, v),
                        baseUsers,
                        type,
                        company_id,
                        subscriptionInfo,
                        total_tax,
                        storedCompanyId,
                        checkoutModalDisplay:
                          this.props.checkout.checkoutModalDisplay,
                        showCheckoutModal: this.props.showCheckoutModal,
                        hideCheckoutModal: this.props.hideCheckoutModal,
                        company: this.props.company?.company,
                        oneTimeCharge: oneTimeCharge,
                        countyTax: this.state.currentCounty,
                        resetCompanyData: () => {
                          this.props.updateCompanyDataInState(null);
                        },
                      },
                    },
                  ]}
                  additionalUser={this.state.additionalUser}
                  title={
                    this.props.type === "edit"
                      ? "Edit Subscription Information"
                      : "Signup"
                  }
                  navSteps
                  headerTextCenter
                  validate
                  color="primary"
                  finishButtonText="Submit"
                  previousButtonClasses="wizard-prev-button"
                  nextButtonClasses="wizard-next-button"
                  finishButtonClasses="wizard-next-button"
                  finishButtonClick={this.finishButtonClick}
                  currentStep={currentStep}
                  updateStep={this.updateStep}
                  position_finder={this.position_finder}
                  submitSuccess={submitSuccess}
                  subscriptionApiCalled={subscriptionApiCalled}
                  company_id={company_id || localStorage.getItem("companyId")}
                />
              ) : (
                <ReactWizard
                  type={type}
                  additionalUser={this.state.additionalUser}
                  steps={[
                    {
                      stepName: "Company Information",
                      component: CompanyDetails,
                      stepProps: {
                        cName,
                        cAddress1,
                        cAddress2,
                        cCity,
                        cState,
                        cZip,
                        primaryPhone,
                        secondaryPhone,
                        cWebsite,
                        cEmail,
                        fax,
                        county,
                        handleChange,
                        selectRegion: (n, v) => selectRegion(n, v),
                      },
                    },
                    {
                      stepName: "Subscription Administrator",
                      component: AdminDetails,
                      stepProps: {
                        cName,
                        cAddress1,
                        cAddress2,
                        cCity,
                        cState,
                        cZip,
                        primaryPhone,
                        secondaryPhone,
                        cWebsite,
                        cEmail,
                        fax,
                        fName,
                        lName,
                        aEmail,
                        aConfirmEmail,
                        aPhone,
                        password,
                        confirmPassword,
                        currentStep,
                        handleChange,
                        type,
                        baseUsers,
                        county,
                        get_company_id,
                        company_id,
                        currentCounty: this.getCurrentCounty(),
                      },
                    },
                    {
                      stepName: "User Verification",
                      component: OtpDetails,
                      stepProps: {
                        hello: "world",
                        aEmail,
                        handleChange,
                        updateStep,
                        aOtp: this.state.aOtp,
                        updateOtpStatus: this.props?.updateOtpStatus,
                        otpSubmittedStatus:
                          this.props?.login?.otpSubmittedStatus,
                        updateResendButtonStatus:
                          this.props?.updateResendButtonStatus,
                        resendOtpButtonStatus:
                          this.props?.login?.resendOtpButtonStatus,
                      },
                    },
                    {
                      stepName: "Subscription Pricing",
                      component: PricingDetails,
                      stepProps: {
                        aEmail: this.state.aEmail,
                        additionalUser,
                        monthlySubscription,
                        bAddressChecked,
                        billingAddress,
                        billingAddress2,
                        bCity,
                        bState,
                        bZip,
                        cardHolderName,
                        cardNumber,
                        expirationMonth,
                        expirationYear,
                        cvv,
                        county,
                        company: this.props.company?.company,
                        cState,
                        increaseAdditionalUser,
                        decreaseAddditionalUser,
                        handleChange,
                        handleChecked,
                        basePrice,
                        additionalPricePerUser,
                        currency,
                        selectRegion: (n, v) => selectRegion(n, v),
                        baseUsers,
                        type,
                        company_id,
                        subscriptionInfo,
                        total_tax,
                        storedCompanyId,
                        oneTimeCharge: oneTimeCharge,
                        countyTax: this.state.currentCounty,
                        showCheckoutModal: this.props.showCheckoutModal,
                        hideCheckoutModal: this.props.hideCheckoutModal,
                        checkoutModalDisplay:
                          this.props.checkout.checkoutModalDisplay,
                        resetCompanyData: () => {
                          this.props.updateCompanyDataInState(null);
                        },
                      },
                    },
                  ]}
                  title={
                    this.props.type === "edit"
                      ? "Edit Subscription Information"
                      : "Signup"
                  }
                  navSteps
                  headerTextCenter
                  validate
                  color="primary"
                  finishButtonText="Submit"
                  previousButtonClasses="wizard-prev-button"
                  nextButtonClasses="wizard-next-button"
                  finishButtonClasses="wizard-next-button"
                  finishButtonClick={this.finishButtonClick}
                  currentStep={currentStep}
                  updateStep={this.updateStep}
                  position_finder={this.position_finder}
                  company_id={company_id || localStorage.getItem("companyId")}
                />
              )}
            </Animated>
          </Col>

          <PopupModal
            show={modal}
            onHide={this.onClose}
            heading={
              this.props.type === "edit"
                ? `${
                    submitSuccess
                      ? "Organized Roofer Subscription Information Updated"
                      : "Organized Roofer Subscription Information Updated"
                  }`
                : `${
                    submitSuccess
                      ? "Organized Roofer Subscription Confirmation"
                      : "Organized Roofer Subscription Confirmation"
                  }`
            }
          >
            <Col xs="12">
              {errMsg && <Alert variant={errType}>{errMsg}</Alert>}
            </Col>
            {submitSuccess && this.props.type !== "edit" && (
              <React.Fragment>
                <p>
                  Thank you for subscribing to Organized Roofer, click to sign
                  in.
                </p>
                {/* <p>
                  A verification email was sent to the subscription
                  administrator. Please check your email and click verify. You
                  will not be able to login to Organized Roofer, until the email
                  has been verified.
                </p>
                <p>
                  If not received please first check your spam or junk mail
                  folder. If still not received call customer service at
                  1-800-555-1212
                </p> */}
              </React.Fragment>
            )}
            {!submitSuccess && password !== "" && (
              <p style={newStyle.noteText}>
                Proceed by clicking the Submit button or to cancel simply close
                this page.
              </p>
            )}
            {!submitSuccess &&
              this.props.type !== "edit" &&
              password === "" && (
                <p>Please add payment method to finalize the subscription</p>
              )}
            <Modal.Footer>
              {!submitSuccess && (
                <Button variant="outline-danger" onClick={this.editForm}>
                  {password !== "" ? "Edit" : "Close"}
                </Button>
              )}
              {!submitSuccess && password !== "" && (
                <Button
                  variant="outline-success"
                  disabled={this.state.submitting}
                  onClick={this.state.submitting ? null : this.submitForm}
                >
                  {this.state.submitting ? "Submitting..." : "Submit"}
                </Button>
              )}
              {submitSuccess && this.props.type !== "edit" && (
                <Button
                  id="signup-success"
                  variant="outline-success"
                  onClick={this.afterSubmission}
                >
                  Sign In
                </Button>
              )}
            </Modal.Footer>
          </PopupModal>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = ({
  subscriptionInfo,
  allBasePlan,
  getbaseplan,
  company,
  checkout,
  login,
  // subscriptioninfoedit,
}) => ({
  subscriptionInfo,
  allBasePlan,
  getbaseplan,
  checkout,
  company,
  login,
  // subscriptioninfoedit,
});

const mapDispatchToProps = (dispatch) => ({
  getAllSubscriptionInfo: () => dispatch(getAllSubscriptionInfo()),
  getBasePlan: () => dispatch(getBasePlan()),
  getBasePlanSignup: () => dispatch(getBasePlanSignup()),
  showCheckoutModal: () => dispatch(showCheckoutModal()),
  hideCheckoutModal: () => dispatch(hideCheckoutModal()),
  updateCompanyDataInState: (companyResponse) =>
    dispatch(GetCompanySuccess(companyResponse)),
  updateOtpStatus: (status) => dispatch(UpdateOtpSumbittedStatus(status)),
  updateResendButtonStatus: (status) => dispatch(ResendButtonClicked(status)),
  // editSubscriptionInfo: (data) => dispatch(editSubscriptionInfo({ ...data })),
});

export default connect(mapStateToProps, mapDispatchToProps)(Signup);
