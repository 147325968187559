import {
  PermissionActionTypes,
  GetRolePermissionsActionTypes,
} from "./permission.type";

const INITIAL_STATE_pERMISSIONS = {
  gettingPermission: false,
  permission: null,
  permissionErr: null,
};

export const GetAllPermissionReducer = (
  state = INITIAL_STATE_pERMISSIONS,
  action
) => {
  switch (action.type) {
    case PermissionActionTypes.GET_PERMISSION_START:
      return {
        ...state,
        gettingPermission: true,
        permission: null,
        permissionErr: null,
      };

    case PermissionActionTypes.GET_PERMISSION_SUCCESS:
      return {
        ...state,
        gettingPermission: false,
        permission: action.payload,
        permissionErr: null,
      };

    case PermissionActionTypes.GET_PERMISSION_FAILURE:
      return {
        ...state,
        gettingPermission: false,
        permission: null,
        permissionErr: action.payload,
      };

    default:
      return state;
  }
};

const INITIAL_STATE_ROLE_PERMISSIONS = {
  gettingRolePermissions: false,
  rolePermissions: null,
  rolePermissionsErr: null,
};

export const GetRolePermissionsReducer = (
  state = INITIAL_STATE_ROLE_PERMISSIONS,
  action
) => {
  switch (action.type) {
    case GetRolePermissionsActionTypes.GET_ROLE_PERMISSIONS_START:
      return {
        ...state,
        gettingRolePermissions: true,
        rolePermissions: null,
        rolePermissionsErr: null,
      };

    case GetRolePermissionsActionTypes.GET_ROLE_PERMISSIONS_SUCCESS:
      return {
        ...state,
        gettingRolePermissions: false,
        rolePermissions: action.payload,
        rolePermissionsErr: null,
      };

    case GetRolePermissionsActionTypes.GET_ROLE_PERMISSIONS_FAILURE:
      return {
        ...state,
        gettingRolePermissions: false,
        rolePermissions: null,
        rolePermissionsErr: action.payload,
      };

    default:
      return state;
  }
};
