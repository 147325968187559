import axios from "axios";
import { ApiEndpoints } from "lib/config/baseSettings";
import { axiosInstance } from "services";

export const getProposalsApi = async (data) => {
  try {
    const params = new URLSearchParams();
    params.set("requestId", data.requestId);

    const URL = `${ApiEndpoints.PROPOSALS.GET_PROPOSAL}?${params}`;
    const response = await axiosInstance.get(URL); // Replace axiosInstanceInstance if you use a custom instance
    return response.data;
  } catch (error) {
    throw new Error(
      error.response?.data?.message || "Failed to fetch proposals"
    );
  }
};

export const updateProposalApi = async (data) => {
  try {
    const URL = `${ApiEndpoints.PROPOSALS}`;
    const response = await axiosInstance.put(URL, data);
    return response.data;
  } catch (error) {
    throw new Error(
      error.response?.data?.message || "Failed to update proposal"
    );
  }
};

export const deleteOneProposalApi = async (data) => {
  try {
    // Create query parameters for the DELETE request
    const params = new URLSearchParams();
    params.set("requestId", data.requestId);
    params.set("proposalId", data.proposalId);

    // Construct the URL with query parameters
    const URL = `${ApiEndpoints.PROPOSALS.DELETE}?${params.toString()}`;

    // Send the DELETE request with the URL and data (if necessary)
    const response = await axiosInstance.delete(URL);

    // Return the response data
    return response.data;
  } catch (error) {
    // Log and throw the error to be handled by the caller
    console.error("Error deleting proposal:", error);
    throw error;
  }
};

export const updateProposalTitleApi = async (data) => {
  try {
    const URL = `${ApiEndpoints.PROPOSALS.RENAME}`;

    const response = await axiosInstance.patch(URL, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const generateProposalApi = async (data) => {
  try {
    const URL = `${ApiEndpoints.PROPOSALS.GENERATE}`;
    const response = await axiosInstance.post(URL, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const reGenerateProposalApi = async (data) => {
  try {
    const URL = `${ApiEndpoints.PROPOSALS.REGENERATE}`;
    const response = await axiosInstance.post(URL, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};
