import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Form, Row, Col, Button, Container } from "react-bootstrap";

import { mapStateToProps, mapDispatchToProps } from "./metricRoofingConnect";
import { toast } from "react-toastify";

import InitialLoader from "components/InitilalLoader";

import {
  ElevationDetailsMetric,
  InstallType,
  SectionListing,
  MetricTab,
  WorkTypeMetric,
} from "./components";

import MetricHeader, { Header } from "./components/MetricHeader";

import {
  FLOOR,
  ELEVATIONDETAILS,
  ELEVATION,
  DIFFICULTY,
  ELEVATIONOTHER,
} from "lib/constants/const";
import Dimensions from "./components/metric/dimensions";
import { useDispatch, useSelector } from "react-redux";
import userDetails from "hooks/user/userDetails";
import {
  resetAllMetrics,
  updateField,
  updateMetrics,
} from "./redux/roof-metric-collection/metric.action";
import {
  getMetrics,
  addMetric,
  editSectionMetric,
} from "./redux/metrics-v2/metrics.actions";
import { categoryMapping, objectHasValue } from "utils/utils";
import { uuid } from "uuidv4";
import PitchMetric from "./components/metric/pitch";
import FloorMetric from "./components/metric/floor";
import ElevationMetric from "./components/metric/elevation";
import { useHistory } from "react-router-dom";
import Spinner from "components/common/spinner/spinner";
import {
  generateQuotes,
  reGenerateQuotes,
} from "features/QuotesProposals/redux/proposals/proposals.actions";
import { LoaderModal } from "components";
import "./metric.scss";
import ConfirmationModal from "components/common/modals/ConfirmationModal";
import {
  useGenerateProposalQuote,
  userRegenerateProposalQuote,
} from "features/QuotesProposals/hooks/useProposals";

const styles = {
  col: {
    margin: 0,
    padding: "1px",
  },
};

const MetricRoofing = (props) => {
  const { data, match, leadReqId, getRoofData, image_type, type_page } = props;

  const { companyId } = userDetails();
  const requestId = match?.params?.id || leadReqId;
  const roofData = useSelector((state) => state.metric.metrics);
  const metric = useSelector((state) => state.metric);
  // const history = useHistory();

  const tableData = useSelector((state) => state.metric.tableData);
  const reduxDispatch = useDispatch();
  const metricData = useSelector((state) => state.metricV2Data);
  const proposalData = useSelector((state) => state.proposal);

  console.log("proposalData ", proposalData);

  const currentAddedSection = metricData?.currentAddedSection;

  const {
    isLoading: isLoadingRegenerateQuote,
    isError: addMaterialMiscError,
    mutate: regeneratePrpoposalMutation,
  } = userRegenerateProposalQuote();

  const {
    isLoading: isLoadingGenerateQuote,
    isError: generateQuoteError,
    mutate: generateQuoteMutation,
  } = useGenerateProposalQuote();

  const dispatch = useDispatch();

  useEffect(() => {
    if (currentAddedSection) {
      dispatch(updateMetrics(currentAddedSection));
    }
  }, [currentAddedSection]);

  useEffect(() => {
    if (
      Object.keys(metricData?.deleteResponse).length &&
      !metricData?.isLoadingDelete
    ) {
      loadData();
      toast.success("Successfully removed section");
      dispatch(resetAllMetrics());
    }
  }, [metricData?.deleteResponse]);

  useEffect(() => {
    return () => {
      dispatch(resetAllMetrics());
    };
  }, [dispatch]);

  const {
    address,
    building,
    coopUnits,
    isLocked,
    isQuoteLock,
    floor,
    elevation,
    elevationDetails,
    pitch,
    workType,
    msg,
    msgType,
    installTypes,
    dimensions,
  } = roofData;

  console.log({
    roofData,
  });

  console.log({ elevationDetails });
  // Example of updating single field once
  const updateMetricField = (field, value) => {
    reduxDispatch(updateField(field, value));
  };

  useEffect(() => {
    return () => {
      console.log("leaving...");
      // This code runs when the component unmounts
      reduxDispatch(resetAllMetrics({}));
    };
  }, [reduxDispatch]);

  const openImages = () => {
    if (isLocked) {
      updateMetricField("isQuoteLock", true);
    } else {
      updateMetricField("isQuoteLock", false);
    }
    updateMetricField("images", true);
  };

  const getBuildingName = (building) => getBuildingName(coopUnits, building);

  const showBuildingList = () => updateMetricField("showCoopModal", true);

  // components thats needed for this component
  const MainHeader = () => (
    <Header
      address={address}
      metricType="Roofing"
      buildingName={building ? getBuildingName(building) : ""}
    />
  );

  const addFloor = (label, id, name, exec) => {
    console.log({
      label,
    });

    if (label === "floor") {
      // for edit
      if (!metric?.metrics?.sectionId) {
        updateMetricField("floor", name);
      } else {
        updateSection("floor", name);
      }
    }
  };

  const addPitch = (label, id, name, exec) => {
    if (label === "pitch") {
      // Check if the current roofPitch is the same as the name being passed
      const newRoofPitch = roofData.pitch.roofPitch === name ? "" : name;

      if (!metric?.metrics?.sectionId) {
        updateMetricField("pitch", {
          ...roofData.pitch,
          roofPitch: newRoofPitch,
        });
      } else {
        updateSection(
          "pitch",
          {
            ...roofData.pitch,
            roofPitch: newRoofPitch,
          },
          "pitch"
        );
      }
    }
  };

  const handleElevation = (name) => {
    if (!metric?.metrics?.sectionId) {
      updateMetricField("elevation", name);
    } else {
      updateSection("elevation", name);
    }
  };

  const removeElevationData = (elevation, name) => {
    const updatedElevation = [...elevation]; // Create a copy of the elevation array
    const index = updatedElevation.indexOf(name);

    if (index !== -1) {
      updatedElevation.splice(index, 1); // Remove the item from the copy
      // updateMetricField("elevation", updatedElevation); // Update the state with the new array
      if (!metric?.metrics?.sectionId) {
        updateMetricField("elevation", updatedElevation);
      } else {
        updateSection("elevation", updatedElevation);
      }
    }
  };

  const handleMultipleMetric = (name) => {
    if (elevation.includes(name)) {
      removeElevationData(elevation, name);
    } else {
      if (!metric?.metrics?.sectionId) {
        updateMetricField("elevation", [...elevation, name]);
      } else {
        updateSection("elevation", [...elevation, name]);
      }
    }
  };

  // const removeElevationData = (elevation, name) => {
  //   const updatedElevation = elevation.filter((item) => item !== name); // Use filter for immutability
  //   updateMetricField("elevation", updatedElevation); // Update the state with the new array
  // };

  // const handleMultipleMetric = (name) => {
  //   console.log({
  //     name111: name,
  //   });
  //   if (elevation.includes(name)) {
  //     removeElevationData(elevation, name);
  //   } else {
  //     // Ensure name is added as a whole string
  //     const newElevation = [...elevation, name];
  //     console.log({
  //       newElevation,
  //       elevation,
  //     });
  //     updateMetricField("elevation", newElevation);
  //   }
  // };

  console.log({
    newElevation1: elevation,
  });

  const handleClickElevationDetails = (name, type) => {
    if (type === "not_more") {
      if (!metric?.metrics?.sectionId) {
        updateMetricField("elevationDetails", {
          ...elevationDetails,
          type: name,
        });
      } else {
        updateSection("elevationDetails", {
          ...elevationDetails,
          type: name,
        });
      }
    } else if (type === "more") {
      const more = roofData.elevationDetails.more || { lists: [] }; // Ensure more exists and has a list
      const list = more.lists || []; // Ensure list is an array

      const updatedList = list.includes(name)
        ? list.filter((item) => item !== name) // Remove name if it exists
        : [...list, name];

      const newMore = {
        ...more,
        lists: updatedList,
      };

      if (name === "Other" && !updatedList.includes(name)) {
        // newMore.other = ""; // Clear the other value if "other" is deselected
        console.log("POOO");
        newMore.other = "";
      }

      if (!metric?.metrics?.sectionId) {
        updateMetricField("elevationDetails", {
          ...roofData.elevationDetails,
          more: newMore,
        });
      } else {
        updateSection("elevationDetails", {
          ...roofData.elevationDetails,
          more: newMore,
        });
      }
    }
  };

  const handleElevationClickOther = (event) => {
    const { name, value } = event.target;
    const more = roofData.elevationDetails.more || {
      lists: [],
      other: "",
    }; // Ensure more exists and has the correct structure

    if (!metric?.metrics?.sectionId) {
      updateMetricField("elevationDetails", {
        ...roofData.elevationDetails,
        more: {
          ...more,
          other: value,
        },
      });
    } else {
      updateSection("elevationDetails", {
        ...roofData.elevationDetails,
        more: {
          ...more,
          other: value,
        },
      });
    }
  };
  const handleOtherPitchTextChange = (event) => {
    const { name, value } = event.target;
    if (!metric?.metrics?.sectionId) {
      updateMetricField("pitch", {
        ...roofData.pitch,
        optionalText: value,
      });
    } else {
      updateSection(
        "pitch",
        {
          ...roofData.pitch,
          optionalText: value,
        },
        "pitch"
      );
    }
  };

  const handleWorkType = (name) => {
    console.log("worktype2", name);

    if (name === "Remove") {
      return;
    }
    const newRemovalTypes = ["New", "Overlay"].includes(name)
      ? { name: "", type: "" }
      : roofData.workType.removalTypes;

    const data = {
      ...roofData.workType,
      name: roofData.workType.name === name ? "" : name,
      removalTypes: newRemovalTypes,
    };

    if (!metric?.metrics?.sectionId) {
      updateMetricField("workType", data);
    } else {
      updateSection("workType", data);
    }
  };

  const handleWorkTypeRemove = (removeType) => {
    const isRemoveTypes = ["Shingles", "Flat"].includes(removeType);

    const data = {
      ...roofData.workType,
      name: !isRemoveTypes ? "Remove" : roofData?.workType?.name,
      ...((roofData?.workType?.removalTypes?.name || removeType) && {
        removalTypes: {
          ...roofData?.workType?.removalTypes,
          type: removeType,
        },
      }),
    };

    if (!metric?.metrics?.sectionId) {
      updateMetricField("workType", data);
    } else {
      updateSection("workType", data);
    }
  };

  const handleClickOtherWorkType = (typeName) => {
    console.log("worktype1", typeName, roofData?.workType);
    const data = {
      ...roofData?.workType,
      ...((roofData?.workType?.removalTypes?.name || typeName) && {
        removalTypes: {
          ...roofData?.workType?.removalTypes,
          name: typeName,
        },
      }),
    };

    if (!metric?.metrics?.sectionId) {
      updateMetricField("workType", data);
    } else {
      updateSection("workType", data);
    }
  };

  const handleInstallTypeClickData = (type, data) => {
    if (type === "Shingles") {
      const installTypeData = {
        ...roofData.installTypes,
        shingles: {
          type: roofData?.installTypes?.shingles?.type === data ? "" : data,
        },
      };

      if (!metric?.metrics?.sectionId) {
        updateMetricField("installTypes", installTypeData);
      } else {
        updateSection("installTypes", installTypeData);
      }
    } else {
      const installTypeData = {
        ...roofData?.installTypes,
        flat: {
          type: roofData?.installTypes?.flat?.type === data ? "" : data,
        },
      };
      if (!metric?.metrics?.sectionId) {
        updateMetricField("installTypes", installTypeData);
      } else {
        updateSection("installTypes", installTypeData);
      }
    }
  };

  const reverseCategoryMapping = Object.entries(categoryMapping).reduce(
    (acc, [key, value]) => {
      acc[value] = key;
      return acc;
    },
    {}
  );

  // Make API Call
  const addDimensionData = (dimension, operationType = "add") => {
    let dimensions = [];

    if (operationType === "delete") {
      dimensions = roofData?.dimensions.filter(
        (dim) => dim.id !== dimension?.id
      );
    }

    const newDimensionArray = [...roofData.dimensions, dimension];

    if (operationType === "add") {
      if (!dimension?.id) {
        dimension.id = uuid();
        dimensions = [...roofData.dimensions, dimension];
      } else {
        dimensions = roofData.dimensions?.map((dim) => {
          if (dim.id === dimension?.id) {
            return { ...dim, ...dimension };
          }
          return dim;
        });
      }
    }

    updateMetricField("dimensions", dimensions);
    // if sectionId is present then it is edit
    if (metric?.metrics?.sectionId) {
      // Create a copy of `roofData` for editing
      const editData = {
        ...roofData,
        dimensions: dimensions,
        requestId: requestId,
      };

      // Check if `workType.name` is not "Remove" and delete `removalTypes` if true
      if (roofData.workType?.name !== "Remove") {
        delete editData.workType.removalTypes;
      } else if (editData.workType?.removalTypes?.name === "") {
        // Only keep `type` if `name` is empty
        editData.workType.removalTypes = {
          type: editData.workType.removalTypes.type,
        };
      }

      // Remove unwanted fields
      delete editData?.section;
      delete editData?._id;

      // Create `newSection` by mapping keys using `reverseCategoryMapping`
      const newSection = Object.keys(editData).reduce((acc, key) => {
        const newKey = reverseCategoryMapping[key] || key;
        acc[newKey] = editData?.[key];
        return acc;
      }, {});

      console.log({
        editData,
        newSection,
      });

      // Dispatch the edited data
      dispatch(editSectionMetric(newSection)); // Wait for `editSectionMetric` to complete
      return;
    }

    // return;
    const elevation_details = {
      ...elevationDetails,
      more: elevationDetails?.more
        ? {
            ...elevationDetails.more,
            ...(elevationDetails.more.other === "" && { other: undefined }),
          }
        : undefined,
    };

    const pitchBodyData = pitch.optionalText
      ? pitch
      : { roofPitch: pitch.roofPitch };
    const work_type = workType?.removalTypes?.name
      ? workType
      : { name: workType.name };

    const installTypeBodyData = installTypes?.flat?.type
      ? { flat: { type: installTypes?.flat?.type } }
      : { shingles: { type: installTypes?.shingles?.type } };

    console.log({ elevation_details, elevationDetails });
    // calling api
    const body = {
      requestId: requestId,
      projectName: metricData.data.projectName,
      companyId: companyId,
      isLocked: false,
      coverImages: [],
      sections: [
        {
          floor: floor,
          elevation: elevation,
          elevationDetails: elevation_details,
          pitch: pitchBodyData,
          workType: work_type,
          installTypes: installTypeBodyData,
          dimensions: newDimensionArray,
        },
      ],
    };
    reduxDispatch(addMetric(body));
  };

  // fetching the section data by request id
  const loadData = (resetData = false) => {
    const id = match?.params?.id || leadReqId;
    if (id) {
      if (resetData) dispatch(resetAllMetrics());

      reduxDispatch(getMetrics(id));
    }
  };

  const reload = () => {
    loadData();
  };

  useEffect(() => {
    loadData(true);
  }, [requestId]);

  useEffect(() => {
    if (image_type === "PM_IMAGE") {
      updateMetricField("images", true);
    }
  }, [image_type]);

  const addAnotherSection = () => {
    dispatch(resetAllMetrics());
  };

  const updateSection = async (field, value, updateType = "other") => {
    // Create a section object to update the metrics
    const section = {
      ...metric?.metrics,
      [field]: value,
    };

    // Check if `workType.name` is not "Remove" and delete `removalTypes` if true
    if (roofData.workType?.name !== "Remove") {
      delete section.workType.removalTypes;
    } else if (section.workType?.removalTypes?.name === "") {
      // Only keep `type` if `name` is empty
      section.workType.removalTypes = {
        type: section.workType.removalTypes.type,
      };
    }

    // Reverse the category mapping for the new section
    const newSection = Object.keys(section).reduce((acc, key) => {
      const newKey = reverseCategoryMapping[key] || key; // Map key to its reversed category
      acc[newKey] = section?.[key]; // Assign the section data to the new key
      return acc; // Return the accumulator
    }, {});

    // Add requestId to the new section and remove unnecessary properties
    newSection.requestId = requestId;
    newSection.updateType = updateType;
    delete newSection?.section;
    delete newSection?._id;

    // Few checks to ensure install type
    if (
      !newSection?.installTypes?.flat?.type &&
      !newSection?.installTypes?.shingles?.type
    ) {
      delete newSection?.installTypes?.flat;
    }

    if (!newSection?.installTypes?.shingles?.type) {
      delete newSection?.installTypes?.shingles;
    }

    try {
      // Dispatch action to edit the section metric
      dispatch(editSectionMetric(newSection)); // Wait for editSectionMetric to complete
    } catch (error) {
      console.error("Error updating section metric: ", error);
      // Handle the error accordingly
    }
  };

  const {
    isLoadingDelete,
    isLoading,
    isLoadingEdit,
    editResponse,
    deleteError,
    editError,
    errors,
  } = metricData || {};

  const hasSections = metricData?.data?.sections?.length > 0;

  console.log({
    isLoadingDelete,
    isLoadingEdit,
    isLoading,
  });

  const isLoadingData = () => {
    return isLoadingDelete || isLoadingEdit || isLoading;
  };
  const getError = () => {
    return deleteError?.mesg || editError?.msg || errors?.msg;
  };

  function Btn({ onClick, disabled, children, variant }) {
    return (
      <Button
        style={{ float: "right" }}
        variant={variant || "primary"}
        onClick={onClick}
        disabled={disabled || false}
      >
        {children}
      </Button>
    );
  }

  const SaveOrViewQuote = () => {
    const { proposalData, history } = props;
    const { data, isLoading } = metricData || {};
    const proposalExists = data?.proposalExists;
    const isModifiedMetrics = data?.isModifiedMetrics;

    const handleGenerateQuote = () => {
      const body = { requestId };

      generateQuoteMutation(body, {
        onSuccess: (data) => {
          history.push(`/dashboard/proposal-quotes/${requestId}`);

          console.log("Quote generated successfully.");
          toast.success("Quote generated  successfully!");
        },

        onError: (error) => {
          console.error("Error Quote regeneration:", error.message);
          handleGlobalError(error, dispatch);
        },
      });
    };

    const handleViewQuote = () => {
      if (isModifiedMetrics) {
        const body = { requestId };
        regeneratePrpoposalMutation(body, {
          onSuccess: (data) => {
            history.push(`/dashboard/proposal-quotes/${requestId}`);

            console.log("Quote regenerated successfully.");
            toast.success("Quote regenerated  successfully!");
          },

          onError: (error) => {
            console.error("Error Quote regeneration:", error.message);
            handleGlobalError(error, dispatch);
          },
        });
      } else {
        history.push(`/dashboard/proposal-quotes/${requestId}`);
      }
    };

    // Determine which button to render
    if (!proposalExists) {
      return (
        <Btn
          onClick={handleGenerateQuote}
          className="w-90"
          disabled={isLoadingGenerateQuote}
        >
          {isLoadingGenerateQuote ? (
            <Spinner variant="light" label="Generate Quote" />
          ) : (
            "Generate Quote"
          )}
        </Btn>
      );
    }

    return (
      <Btn onClick={handleViewQuote} disabled={isLoadingRegenerateQuote}>
        {isLoadingRegenerateQuote ? (
          <Spinner variant="light" label="Regenerating Quote" />
        ) : isModifiedMetrics ? (
          "Regenerate Quote"
        ) : (
          "View Quote"
        )}
      </Btn>
    );
  };

  return (
    <Container fluid className="px-5 py-2">
      {/* Header */}
      <Row>
        <Col>
          <MetricHeader
            metricType="Roofing"
            buildingName={building ? getBuildingName(coopUnits, building) : ""}
            openImages={openImages}
            addAnotherSection={() => addAnotherSection()}
            disabledAddSection={!roofData?.sectionId}
            disabledImageBtn={tableData.length === 0}
            requestId={requestId}
            address={metricData?.data?.address || ""}
            isBuilding={data?.isBuilding ?? "false"}
            msg={msg}
            msgType={msgType}
            isQuoteLock={isLocked}
            type_page={type_page}
            hasOtherMetric={metricData?.data?.hasGutters}
            onClickBuilding={showBuildingList}
          />
        </Col>
      </Row>

      {/* First Row */}
      <Row className="mt-3">
        {/* Display floor */}
        <Col md="6" lg="4">
          <Row>
            <FloorMetric
              label="Floor"
              array={floor}
              data={FLOOR}
              addFloor={addFloor}
              isQuoteLock={isQuoteLock}
              prevMetric={
                (!(data && data.isBuilding) ||
                  (data && data.isBuilding && this.state.building)) &&
                !isQuoteLock
              }
            />
          </Row>
        </Col>
        {/* Display Elevation */}
        <Col md="6" lg="4" className="d-flex flex-column">
          <label className="btnheading">Elevation</label>
          <Row>
            <ElevationMetric
              metricsData={ELEVATION}
              selectedData={elevation}
              isDisable={!floor || isQuoteLock}
              isQuoteLock={isQuoteLock}
              handleClick={handleMultipleMetric}
            />
          </Row>
        </Col>

        {/* Displat elevation Details */}
        <Col lg="4">
          <ElevationDetailsMetric
            elevationDetailsData={ELEVATIONDETAILS}
            elevationSelectedData={elevationDetails?.type}
            isQuoteLock={isQuoteLock}
            label="Elevation Details"
            prevMetric={elevation?.length && !isQuoteLock}
            elevationOther={ELEVATIONOTHER}
            onClickBtn={handleClickElevationDetails}
            elevationOtherSelected={elevationDetails?.more?.lists}
            handleClickOtherInputValue={handleElevationClickOther}
            otherSelectValue={elevationDetails?.more.other}
          />
        </Col>
      </Row>
      {/* Second Row of metrics */}
      <Row className="mt-5">
        <Col md="4">
          <PitchMetric
            label="Pitch"
            array={pitch?.roofPitch}
            data={DIFFICULTY}
            size={"24%"}
            addFloor={addPitch}
            isQuoteLock={isQuoteLock}
            prevMetric={elevationDetails?.type && !isQuoteLock}
          />
          {(pitch?.roofPitch === "Walkie" ||
            pitch?.roofPitch === "Non-Walkie") && (
            <Row className="input_info_pitch">
              <Form.Control
                type="text"
                name={"optionalText"}
                value={pitch.optionalText}
                onChange={handleOtherPitchTextChange}
                placeholder="Roof Pitch(Optional)"
              />
            </Row>
          )}
        </Col>
        <Col md="4">
          <WorkTypeMetric
            pitch={pitch}
            workType={workType}
            onHandleClick={handleWorkType}
            handleClickTypeData={handleWorkTypeRemove}
            handleOtherClickTypeData={handleClickOtherWorkType}
            isQuoteLock={isQuoteLock}
          />
          {workType?.removalTypes?.type && (
            <Row className="input_info">
              <Form.Control
                type="text"
                value={
                  workType?.removalTypes?.name
                    ? ` ${workType?.removalTypes?.type} -  ${workType?.removalTypes.name}`
                    : workType?.removalTypes?.type
                }
                disabled={true}
              />
            </Row>
          )}
        </Col>
        <Col md="4">
          <Row>
            <Col md="12" lg="5">
              <InstallType
                installTypes={installTypes}
                isQuoteLock={isQuoteLock}
                pitch={pitch}
                handleClickData={handleInstallTypeClickData}
                workType={workType}
              />
              {(installTypes?.flat?.type || installTypes?.shingles?.type) && (
                <Row className="input_info mt-1">
                  {/* <Col> */}
                  <Form.Control
                    ype="text"
                    placeholder="Install Types"
                    disabled={true}
                    value={
                      installTypes?.flat?.type
                        ? installTypes?.flat?.type
                        : installTypes?.shingles?.type
                    }
                  />
                  {/* </Col> */}
                </Row>
              )}
            </Col>

            <Col lg="7">
              <Dimensions
                dimensions={dimensions}
                addDimensionData={addDimensionData}
                disabled={!objectHasValue(installTypes)}
              />
            </Col>
          </Row>

          {/* <Row className="mt-5">
          </Row> */}
        </Col>
      </Row>
      {/* handling sections */}
      {roofData?.sectionId ? (
        <>
          {/* tabs for accessories and etc */}
          <Row className="mt-3">
            {/* <Col> */}
            <MetricTab
              sectionsData={metricData?.data?.sections}
              requestId={requestId}
            />
            {/* </Col> */}
          </Row>
          <Row className="mt-3 pl-0">
            <Form.Group style={styles.formInput}>
              <Form.Label>Section Notes:</Form.Label>
              <Form.Control
                as="textarea"
                rows="3"
                name="notes"
                // value={notes}
                style={{ resize: "none" }}
                // onChange={(e) => {
                //   this.handleChange(e);
                //   this.saveDataIntoDb();
                // }}
              />
            </Form.Group>
          </Row>
        </>
      ) : null}

      {!isLoadingData() ? (
        <>
          {metricData?.data?.sections?.length ? (
            <Row className="mt-4 mr-2">
              <SectionListing
                type={"roofing"}
                sesctionData={metricData?.data?.sections}
                requestId={requestId}
              />
            </Row>
          ) : null}
        </>
      ) : (
        <InitialLoader error={getError()} onReload={reload} />
      )}

      {metricData?.data?.sections?.length ? (
        <Row>
          <Col>{SaveOrViewQuote()}</Col>
        </Row>
      ) : null}

      <LoaderModal show={proposalData?.isLoadingGenerate} />
      {/* <ConfirmationModal
        showConfirmationModal={true}
        msgContent="kfhf wfbjwsb"
      /> */}
    </Container>
  );
};

MetricRoofing.propTypes = {
  data: PropTypes.any,
  // isLoading: PropTypes.bool,
  // errors: PropTypes.any,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  leadReqId: PropTypes.any,
  image_type: PropTypes.string.isRequired,
  match: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(MetricRoofing);
