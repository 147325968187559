import React, { useCallback, useEffect, useState } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";

import { PriceInput } from "components";
import styles from "lib/config/baseStyles";
import { getOptions } from "../../SelectSupliers";
import QTYInput from "../../QTYInput";
import {
  hasFieldChanged,
  hasNestedFieldChanged,
} from "features/QuotesProposals/utils";
import { useSelector } from "react-redux";
import { debounce } from "lodash";
import { useSeriesTypes } from "features/QuotesProposals/hooks/useMaterial";
import Spinner from "components/common/spinner/spinner";

export default function MaterialListItem({
  manufacturerList,
  manufacturer,
  category,
  openSeriesListModal,
  series,
  uom,
  qty,
  price,
  total,
  changeManufacturer,
  onChangeQty,
  categoryType,
  supplierId,
  material,
  deleteMiscItem,
  proposalStatus,
  isLoadingUpdateManufactures,
  isLoadingDaterialMiscDelete,
}) {
  const [materialQty, setMaterialQty] = useState();
  const [materialPrice, setMaterialPrice] = useState();
  const [miscSeriesPrice, setMiscSeriesPrice] = useState("");

  const proposalData = useSelector((state) => state.proposal);
  const { isLoadingDeleteMaterialMisc } = proposalData;
  const isSkylight = categoryType === "Skylight";
  const allowEdit = proposalStatus !== "As-Collected";

  useEffect(() => {
    if (qty) {
      setMaterialQty(qty);
    }
  }, [qty]);

  useEffect(() => {
    if (material?.miscSeries) {
      setMiscSeriesPrice(material?.miscSeries);
    }
  }, [material?.miscSeries]);

  useEffect(() => {
    if (price) {
      setMaterialPrice(price);
    }
  }, [material?.price]);

  const handleOpenModaAndGetSeries = () => {
    if (!allowEdit) return;
    openSeriesListModal();
    // callSeriesApiCallack(category, categoryType, manufacturer, supplierId);
  };

  const handleChangeManufacturer = (manufacturer) => {
    if (manufacturer) changeManufacturer(manufacturer, supplierId);
  };

  // Debounced version of handleChangeQty
  const debouncedHandleChangeQty = useCallback(
    debounce((value) => {
      if (value) onChangeQty(Number(value), material._id, "");
    }, 800), // Adjust the delay time as needed
    [material._id]
  );

  const handleChangeQty = (event) => {
    const value = event.target.value;
    setMaterialQty(Number(value));

    // Use the debounced function
    if (value) debouncedHandleChangeQty(value);
  };

  // Debounced version of handlePriceChange
  const debouncedHandlePriceChange = useCallback(
    debounce((value) => {
      if (value) onChangeQty("", material._id, Number(value));
    }, 800), // Adjust the delay time as needed
    [material._id] // Add dependencies if necessary
  );

  // Debounced version of handleChangeQty
  const debouncedHandleChangeSeriesMisc = useCallback(
    debounce((value) => {
      if (value) onChangeQty("", material._id, "", value);
    }, 800), // Adjust the delay time as needed
    [material._id]
  );

  const handlePriceChange = (value) => {
    setMaterialPrice(value);
    console.log({ value });

    // Use the debounced function
    if (value) debouncedHandlePriceChange(value);
  };

  const handleChangeMiscSeries = (event) => {
    const value = event.target.value;
    setMiscSeriesPrice(value);
    console.log({
      value,
    });
    if (value) debouncedHandleChangeSeriesMisc(value);
  };

  console.log("allowEdit:", allowEdit, "material.isMisc:", material.isMisc);
  console.log("Disabled Condition:", !allowEdit && !material.isMisc);

  const format = (value) => {
    const numericValue = parseFloat(value);

    if (!isNaN(numericValue)) {
      const formattedValue =
        numericValue % 1 === 0 ? `${numericValue}.00` : numericValue.toFixed(2);
      return formattedValue;
    }
  };
  useEffect(() => {
    const rr = hasFieldChanged(material, "manufacturer");
    console.log({
      rr,
    });
  }, []);

  const getMaterialName = () => {
    if (category === "Shingles") {
      return material.subType
        ? `${categoryType} - ${material.subType}`
        : categoryType;
    }

    if (category === "Nails" && !material?.materialFor === "Hand Nails") {
      return "Coil Nails";
    }

    if (category === "Nails" && material?.materialFor === "Hand Nails") {
      return material?.materialFor;
    }

    if (category === "Nails" && material?.materialFor === "Common Nails") {
      return material?.materialFor;
    }

    if (category === "Nails" && material?.materialFor === "Shingle Roofing") {
      return material?.categoryType
        ? `${category} - ${categoryType}`
        : category;
    }

    if (category === "Metal Sheets" && material?.materialFor === "Wall") {
      return material?.materialFor
        ? `${category} - ${material?.materialFor}`
        : category;
    }
    if (category === "Metal Sheets" && material?.materialFor === "Wall") {
      return material?.materialFor
        ? `${category} - ${material?.materialFor}`
        : category;
    }
    if (
      category === "Metal Sheets" &&
      ["Trim Metal Gravel Stop", "Metal Sheets"].includes(material?.materialFor)
    ) {
      return material?.materialFor ? `${category} - Misc` : category;
    }

    // Special handling for Power Fan
    if (categoryType === "Power Fan") {
      return material.materialFor;
    }

    // Check for Coil Rolls and return materialFor if it is "capping"
    if (categoryType === "Coil Rolls" && material?.materialFor === "Capping") {
      return material.materialFor;
    }

    // Special handling for Fascia
    if (category === "Fascia") {
      return material?.categoryType
        ? `${category} - ${categoryType}`
        : category;
    }

    // For other categories, check if categoryType is in the specified list
    return [
      "Rake",
      "Leak Barrier",
      "Eve",
      "Starter",
      "Hip & Ridge",
      "Valley",
      "Vent Pipe",
      "Step",
      "Ridge Vent",
      "Louvers",
      "Hood/Goose Neck",
      "Plywood",
      "Rafter/Stud",
      "Furring Strips",
    ].includes(categoryType)
      ? categoryType
      : category;
  };

  console.log({
    manufacturerList,
  });
  return (
    <>
      <Row>
        <Col style={styles.reduceWidthCol}>
          {isLoadingUpdateManufactures && (
            <div className="d-flex justify-content-center">
              <Spinner />
            </div>
          )}
          {!isLoadingUpdateManufactures &&
            manufacturer &&
            manufacturerList?.length &&
            ![
              "Valley",
              "Vent Pipe",
              "Step",
              "Plywood",
              "Furring Strips",
            ].includes(categoryType) && (
              <Form.Group>
                <Form.Control
                  disabled={!allowEdit}
                  as="select"
                  value={manufacturer}
                  onChange={(e) => handleChangeManufacturer(e.target.value)}
                  style={
                    hasFieldChanged(material, "manufacturer")
                      ? {
                          backgroundColor: "#f6d46f",
                        }
                      : {}
                  }
                >
                  {manufacturerList.map((optionName) => (
                    <option value={optionName}>{optionName}</option>
                  ))}

                  {/* {manufacturer && getOptions(manufacturerList)} */}
                </Form.Control>
              </Form.Group>
            )}
        </Col>

        <Col style={styles.reduceWidthCol} lg="2">
          {category && !material.isMisc && (
            <Form.Group>
              <Form.Control
                disabled
                type="text"
                style={{ textAlign: "center" }}
                value={getMaterialName()}
              />
            </Form.Group>
          )}

          {material.isMisc && (
            <Button
              variant="danger"
              disabled={isLoadingDeleteMaterialMisc}
              onClick={deleteMiscItem}
            >
              {isLoadingDaterialMiscDelete ? (
                <Spinner variant="light" label="Deleting" />
              ) : (
                "Delete"
              )}
            </Button>
          )}
        </Col>
        <Col style={styles.reduceWidthCol} lg="4">
          {!material.isMisc ? (
            <button
              disabled={isSkylight || !allowEdit}
              onClick={handleOpenModaAndGetSeries}
              className="__quote_series_txt"
              style={
                hasFieldChanged(material, "series")
                  ? {
                      backgroundColor: "#f6d46f",
                    }
                  : {}
              }
            >
              {series}
            </button>
          ) : (
            <Form.Group>
              <Form.Control
                // disabled
                disabled={!allowEdit && !material.isMisc}
                type="text"
                style={{ textAlign: "center" }}
                value={miscSeriesPrice}
                onChange={handleChangeMiscSeries}
              />
            </Form.Group>
          )}
        </Col>
        <Col style={styles.reduceWidthCol}>
          {!material.isMisc && (
            <Form.Group>
              <Form.Control
                disabled
                type="text"
                style={{ textAlign: "center" }}
                value={uom}
              />
            </Form.Group>
          )}
        </Col>
        <Col style={styles.reduceWidthCol}>
          <QTYInput
            value={materialQty?.toString()}
            onChange={handleChangeQty}
            hasChanged={
              !material.isMisc &&
              hasNestedFieldChanged(material, "qtyAndPrice", "quantity")
            }
            disabled={!allowEdit && !material.isMisc}
          />
        </Col>
        <Col style={styles.reduceWidthCol}>
          <PriceInput
            disabled={!material.isMisc && !isSkylight}
            price={
              !material.isMisc && !isSkylight
                ? format(materialPrice)
                : materialPrice
            }
            // price={materialPrice}
            onChange={(value) => handlePriceChange(value)}
          />
        </Col>
        <Col style={styles.reduceWidthCol}>
          <PriceInput
            disabled
            price={!material.isMisc && !isSkylight ? format(total) : total}
            input="Total"
          />
        </Col>
      </Row>
    </>
  );
}
