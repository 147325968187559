import React from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { PriceInput } from "components";

import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import styles from "lib/config/baseStyles";
import displayMsg from "components/displayMsg";
import SelectSuppliers, { SupplierItemOnlyName } from "../../SelectSupliers";

import LoadingSkeleton from "../../LoadingSkeleton";
import CartingListData from "./CartingListData";
import {
  addCarting,
  updateCurrentProposal,
} from "features/QuotesProposals/redux/proposals/proposals.actions";
import {
  hasSupplierFieldChanged,
  supplierEmptyText,
} from "features/QuotesProposals/utils";
import QuoteUpdateLoader from "components/common/loaders/QuoteUpdateLoader";
import {
  useAddAdditionalCartingQuoteLine,
  useCartingSuppliers,
  useCartingTypes,
  useChangeCartingSupplier,
  useUpdateCartingQuoteline,
} from "features/QuotesProposals/hooks/useCarting";
import QuoteSkeleton from "../../SkeletonLoader/Quote";
import { handleGlobalError } from "utils/utils";

export default function DebrisRemoval({ isLockQuoteData }) {
  const proposalData = useSelector((state) => state.proposal);
  const selectedProposal = proposalData?.selectedProposal;

  const selectedSupplier =
    proposalData?.selectedProposal?.quotes?.dumpsters?.supplier;

  const dispatch = useDispatch();
  const {
    isLoadingCartingSuppliers,
    isLoadingUpdateCartingSupplier,
    isLoadingDeleteCarting,
    isLoadingUpdateCarting,
  } = proposalData;

  const {
    suppliers,
    isLoading: isLoadingSuppliers,
    isError,
    error: fetchSupplierError,
  } = useCartingSuppliers();

  const supplierId = selectedSupplier?._id;

  const {
    cartingTypes,
    isLoading: isLoadingCartingTypes,
    isError: isCartingTypesError,
    error: fetchCartingTypesError,
  } = useCartingTypes(supplierId);

  const {
    data,
    isLoading: isLoadingUpdateSupplier,
    isError: isUpdate,
    isSuccess,
    mutate: updateSupplierMutation,
  } = useChangeCartingSupplier();

  const {
    isLoading: isLoadingUpdateCartingQuoteLine,
    isError: updateQuoteLineError,
    mutate: updateCartingQuotelineMutation,
  } = useUpdateCartingQuoteline();

  const {
    isLoading: isLoadingAddAdditionalQuote,
    isError: addAdditionalQuoteError,
    mutate: addAdditionalQuoteMutation,
  } = useAddAdditionalCartingQuoteLine();

  const onChangeSupplier = (e) => {
    const supplierId = e.target.value;

    const body = {
      requestId: selectedProposal?.requestId,
      supplierId: supplierId,
      proposalId: selectedProposal._id,
    };
    updateSupplierMutation(body, {
      onSuccess: (data) => {
        // Assuming `data.result` contains the updated proposals
        const updatedProposal = data?.result?.find(
          (proposal) => proposal._id === selectedProposal?._id // Adjust key based on response
        );
        if (updatedProposal) {
          // Dispatch an action to update the selectedProposal in Redux
          dispatch(updateCurrentProposal(updatedProposal));
        }

        console.log("Carting supplier updated successfully.");
        toast.success("Carting supplier updated successfully!");
      },

      onError: (error) => {
        console.error("Error updating carting supplier:", error.message);
        handleGlobalError(error, dispatch);
      },
    });
  };

  const addAditional = () => {
    const {
      _id: proposalId,
      quotes: {
        dumpsters: {
          _id: dumpsterQuoteId,
          supplier: { _id: supplierId } = {},
        } = {},
      } = {},
    } = selectedProposal || {};

    // Basic validation check
    if (!supplierId || !proposalId || !dumpsterQuoteId) {
      console.error("Missing required fields. Cannot proceed with the action.");
      return;
    }

    const body = {
      supplierId,
      proposalId,
      dumpsterQuoteId,
      category: "", // Ensure these values are set properly
      pricingType: "", // Set the appropriate defaults if required
      uomPrice: "",
      size: "",
      qty: "",
    };

    addAdditionalQuoteMutation(body, {
      onSuccess: (data) => {
        // Assuming `data.result` contains the updated proposals
        const updatedProposal = data?.result?.find(
          (proposal) => proposal._id === selectedProposal?._id // Adjust key based on response
        );
        console.log("updatedProposal123", updatedProposal);
        if (updatedProposal) {
          // Dispatch an action to update the selectedProposal in Redux
          dispatch(updateCurrentProposal(updatedProposal));
        }

        console.log("Quote item added successfully.");
        toast.success("Quote item added successfully!");
      },

      onError: (error) => {
        console.error("Error adding Quote item:", error.message);
        handleGlobalError(error, dispatch);
      },
    });
  };

  const retry = () => {};

  const updateCartingQuote = (body) => {
    updateCartingQuotelineMutation(body, {
      onSuccess: (data) => {
        // Assuming `data.result` contains the updated proposals
        const updatedProposal = data?.result?.find(
          (proposal) => proposal._id === selectedProposal?._id // Adjust key based on response
        );
        console.log("updatedProposal123", updatedProposal);
        if (updatedProposal) {
          // Dispatch an action to update the selectedProposal in Redux
          dispatch(updateCurrentProposal(updatedProposal));
        }

        console.log("Quote updated successfully.");
        toast.success("Quote updated successfully!");
      },

      onError: (error) => {
        console.error("Error updating Quote:", error.message);
        handleGlobalError(error, dispatch);
      },
    });
  };

  if (
    isLoadingSuppliers ||
    isLoadingCartingTypes ||
    isLoadingUpdateSupplier ||
    isLoadingUpdateCartingQuoteLine ||
    isLoadingAddAdditionalQuote
  ) {
    return <QuoteSkeleton />;
  }

  return (
    <div style={{ margin: "1em" }}>
      <section>
        <Row>
          <Col style={styles.reduceWidthCol}>
            <h4 style={{ marginBottom: "-.5rem" }}>Debris Removal</h4>
          </Col>
          <Col className="d-flex justify-content-end">
            {/* {isLoadingProposal ? null : error ? (
                  <SmRetryBtn retry={retry} />
                ) : null} */}
            <QuoteUpdateLoader
              loading={
                isLoadingCartingSuppliers ||
                isLoadingUpdateCartingSupplier ||
                isLoadingDeleteCarting ||
                isLoadingUpdateCarting
              }
              retry={retry}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: "2rem" }}>
          <SelectSuppliers
            selectedSupplier={selectedSupplier._id}
            onSelectSupplier={onChangeSupplier}
            loading={false}
            suppliers={suppliers}
            emptyText={supplierEmptyText(suppliers?.length)}
            SupplierItem={SupplierItemOnlyName}
            isOriginalSupplier={hasSupplierFieldChanged(
              selectedProposal.originalQuoteSupplier,
              "cartingSupplierId",
              selectedSupplier?._id
            )}
            isLockQuoteData={isLockQuoteData}
          />
        </Row>

        <DebrisSectionDataList
          quoteId={selectedProposal?.quotes?.dumpsters?._id}
          proposalId={selectedProposal?._id}
          supplierId={selectedProposal?.quotes?.dumpsters?.supplier?._id}
          dumpsterQuotes={selectedProposal?.quotes?.dumpsters?.quotes}
          cartingTypes={cartingTypes}
          proposalStatus={selectedProposal?.status}
          updateCartingQuote={updateCartingQuote}
          selectedProposal={selectedProposal}
        />

        <Row>
          <Col style={styles.reduceWidthCol} lg="2">
            <Button
              onClick={() => addAditional()}
              disabled={isLockQuoteData?.isLocked}
            >
              <strong>Add</strong>
            </Button>
          </Col>
        </Row>
        {selectedProposal && (
          <TotalSection
            total={selectedProposal?.quotes?.dumpsters?.total || 0}
          />
        )}
      </section>
    </div>
  );
}

function TotalSection({ total }) {
  return (
    <Row>
      <Col style={styles.reduceWidthCol} lg="6" />
      <Col style={{ ...styles.reduceWidthCol, ...{ flex: "77%" } }}>
        <Row>
          <Col
            style={{
              ...styles.reduceWidthCol,
              ...{ textAlign: "right", paddingRight: "none" },
            }}
          >
            <Form.Label>Debris Removal Total:</Form.Label>
          </Col>
        </Row>
      </Col>
      <Col style={styles.reduceWidthCol}>
        <PriceInput
          price={total}
          disabled
          style={{ background: "#28a745", color: "#fff" }}
        />
      </Col>
    </Row>
  );
}

function DebrisSectionDataList({
  loadingData,
  dumpsterQuotes,
  cartingTypes,
  quoteId,
  proposalId,
  supplierId,
  proposalStatus,
  updateCartingQuote,
  selectedProposal,
}) {
  if (dumpsterQuotes && !dumpsterQuotes.length) {
    return displayMsg({ msg: "No data available." });
  }

  if (loadingData) {
    return new Array(10).fill(1).map((_, i) => <LoadingSkeleton key={i} />);
  }

  if (dumpsterQuotes) {
    return dumpsterQuotes.map((sectionPricing, idx) => (
      <CartingListData
        data={sectionPricing}
        cartingTypes={cartingTypes}
        quoteId={quoteId}
        proposalId={proposalId}
        supplierId={supplierId}
        proposalStatus={proposalStatus}
        updateCartingQuote={updateCartingQuote}
        selectedProposal={selectedProposal}
      />
    ));
  }

  return null;
}
