import React from "react";
import PropTypes from "prop-types";
import { Row } from "react-bootstrap";
import PopupModal, { ModalFooter } from "components/popup-modal/popup-modal";

const DisplayOtherModalExtended = ({
  show,
  label,
  hide,
  displayOtherExtendedModalData,
  displayOtherExtendedModalOtherData,
  heading1,
  disable = false,
  displayOtherTypeArr,
  handleClickTypeData,
  workType,
  handleOtherClickTypeData,
}) => {
  return (
    <PopupModal
      show={show}
      heading={label}
      onHide={hide}
      bold
      styles={{ padding: "1em 1em 0 1em", border: "none" }}
    >
      {heading1 && <label className="btnheading">{heading1}</label>}
      <Row style={{ margin: "0.2em .5em .5em .5em", alignItems: "center" }}>
        {displayOtherExtendedModalData.map((item) => (
          <button
            style={
              item.name === "Architectural"
                ? { maxWidth: "20%", padding: "0" }
                : { maxWidth: "15%", padding: "0" }
            }
            key={item.id}
            className={`ClickableBtnModal ${
              workType?.removalTypes?.type === item.name ? "selected" : ""
            }`}
            onClick={() => handleClickTypeData(item.name)}
          >
            {item.name}
          </button>
        ))}
      </Row>
      {/* other type */}
      {displayOtherTypeArr.includes(workType?.removalTypes?.type) &&
        displayOtherExtendedModalOtherData.map((item) => (
          <button
            key={item.id}
            style={{ maxWidth: "20%", padding: "0" }}
            onClick={() => handleOtherClickTypeData(item.name)}
            className={`ClickableBtnModal ${
              workType?.removalTypes?.name === item.name ? "selected" : ""
            }`}
          >
            {item.name}
          </button>
        ))}
      <ModalFooter
        onCancel={hide}
        onSubmit={() => {
          hide();
        }}
        submitDisabled={disable}
      />
    </PopupModal>
  );
};

DisplayOtherModalExtended.propTypes = {
  show: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  hide: PropTypes.func.isRequired,
  displayOtherExtendedModalData: PropTypes.array.isRequired,
  array: PropTypes.any,
  arrName: PropTypes.string.isRequired,
  other: PropTypes.object,
  displayOtherExtendedModalOtherData: PropTypes.array.isRequired,
  heading1: PropTypes.string,
  disable: PropTypes.bool,
  addData: PropTypes.func.isRequired,
  addSecondaryData: PropTypes.func.isRequired,
  displayOtherTypeArr: PropTypes.array,
  handleClickTypeData: PropTypes.func.isRequired,
  workType: PropTypes.any,
  handleOtherClickTypeData: PropTypes.func.isRequired,
};

export default DisplayOtherModalExtended;
