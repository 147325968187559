export const PermissionActionTypes = {
  GET_PERMISSION_START: "GET_PERMISSION_START",
  GET_PERMISSION_SUCCESS: "GET_PERMISSION_SUCCESS",
  GET_PERMISSION_FAILURE: "GET_PERMISSION_FAILURE",
};

export const GetRolePermissionsActionTypes = {
  GET_ROLE_PERMISSIONS_START: "GET_ROLE_PERMISSIONS_START",
  GET_ROLE_PERMISSIONS_SUCCESS: "GET_ROLE_PERMISSIONS_SUCCESS",
  GET_ROLE_PERMISSIONS_FAILURE: "GET_ROLE_PERMISSIONS_FAILURE",
};
