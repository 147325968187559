import React, { useCallback, useEffect, useState } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";

import { PriceInput } from "components";
import styles from "lib/config/baseStyles";
import QTYInput from "../../QTYInput";
import { debounce } from "lodash";
import Spinner from "components/common/spinner/spinner";

export default function GutterListItem({
  quote,
  onChangeQty,
  deleteMiscItem,
  isLoadingGutterMiscDelete,
}) {
  const { color, size, categoryType, qtyAndPrice, type, isMisc } = quote;

  const [gutterQty, setGutterQty] = useState("");
  const [style, setStyle] = useState("");
  const [uom, setUom] = useState("");
  const [price, setPrice] = useState("");

  useEffect(() => {
    if (quote?.qtyAndPrice?.quantity) {
      setGutterQty(quote?.qtyAndPrice?.quantity);
    }
  }, [quote?.qtyAndPrice?.quantity]);

  useEffect(() => {
    if (quote?.style) {
      setStyle(quote.style);
    }
  }, [quote?.style]);

  useEffect(() => {
    if (quote?.uom) {
      setUom(quote.uom);
    }
  }, [quote?.uom]);

  useEffect(() => {
    if (quote?.pricePerUOM) {
      setPrice(quote?.pricePerUOM);
    }
  }, [quote?.pricePerUOM]);

  // Debounced version of handlePriceChange
  const debouncedHandleQtyChange = useCallback(
    debounce((value) => {
      onChangeQty({ gutterId: quote._id, quantity: Number(value) });
    }, 800), // Adjust the delay time as needed
    [quote._id] // Add dependencies if necessary
  );

  const debouncedHandleStyleChange = useCallback(
    debounce((value) => {
      onChangeQty({ gutterId: quote._id, quantity: "", style: value });
    }, 800), // Adjust the delay time as needed
    [quote.style] // Add dependencies if necessary
  );

  const debouncedHandleUOMChange = useCallback(
    debounce((value) => {
      onChangeQty({
        gutterId: quote._id,
        quantity: "",
        workType: "",
        uom: value,
      });
    }, 800), // Adjust the delay time as needed
    [quote.style] // Add dependencies if necessary
  );

  const handleChangeQty = (event) => {
    const value = event.target.value;
    setGutterQty(Number(value));
    if (value) debouncedHandleQtyChange(value);
  };

  const handleChangeStyle = (event) => {
    const value = event.target.value;
    setStyle(value);
    if (value) debouncedHandleStyleChange(value);
  };

  const handleChangeUOM = (event) => {
    const value = event.target.value;
    setUom(value);
    if (value) {
      debouncedHandleUOMChange(value);
    }
  };

  const handleChangePrice = (value) => {
    setPrice(value);
    if (value) {
      onChangeQty({
        gutterId: quote._id,
        quantity: "",
        workType: "",
        uom: "",
        price: Number(value),
      });
    }
  };

  return (
    <Row>
      <Col style={styles.reduceWidthCol}>
        {isMisc ? (
          <Button variant="danger" onClick={deleteMiscItem}>
            {isLoadingGutterMiscDelete ? (
              <Spinner variant="light" label="Deleting" />
            ) : (
              "Delete"
            )}
          </Button>
        ) : (
          <Form.Group>
            <Form.Control
              disabled={true}
              value={type}
              type="text"
              style={{ textAlign: "center" }}
            />
          </Form.Group>
        )}
      </Col>
      <Col style={{ ...styles.reduceWidthCol, width: "40%" }}>
        <Form.Group>
          <Form.Control
            disabled={!isMisc}
            onChange={handleChangeStyle}
            value={style === "N/A" ? categoryType : style}
            type="text"
            style={{ textAlign: "center" }}
          />
        </Form.Group>
      </Col>
      <Col style={styles.reduceWidthCol}>
        {size && !isMisc !== "N/A" ? (
          <Form.Group>
            <Form.Control
              disabled={true}
              value={size}
              type="text"
              style={{ textAlign: "center" }}
            />
          </Form.Group>
        ) : null}
      </Col>
      <Col style={styles.reduceWidthCol}>
        {color && color !== "N/A" && !isMisc ? (
          <Form.Group>
            <Form.Control
              disabled={true}
              value={color}
              type="text"
              style={{ textAlign: "center" }}
            />
          </Form.Group>
        ) : null}

        {categoryType === "Fascia" && quote.length ? (
          <Form.Group>
            <Form.Control
              disabled={true}
              value={quote?.length}
              type="text"
              style={{ textAlign: "center" }}
            />
          </Form.Group>
        ) : null}
      </Col>

      <Col style={styles.reduceWidthCol}>
        <Form.Group>
          <Form.Control
            disabled={!isMisc}
            onChange={handleChangeUOM}
            value={uom}
            type="text"
            style={{ textAlign: "center" }}
          />
        </Form.Group>
      </Col>
      <Col style={styles.reduceWidthCol}>
        <QTYInput
          value={gutterQty}
          onChange={handleChangeQty}
          // styless={original_list?.qty != qty}
          disabled={!isMisc} //isLockQuoteData?.isLocked} //client request: gutters' QTY is readonly
        />
      </Col>
      <Col style={styles.reduceWidthCol}>
        <PriceInput
          disabled={!isMisc}
          price={price}
          onChange={handleChangePrice}
        />
      </Col>
      <Col style={styles.reduceWidthCol}>
        <PriceInput
          disabled={true}
          price={qtyAndPrice.totalPrice}
          input="Total"
        />
      </Col>
    </Row>
  );
}
