import React from "react";
import { Link, Route, useHistory, Switch } from "react-router-dom";
import Proposal from "./Proposal";
import DropdownMenus from "./DropdownMenus/DropdownMenus";
import Subscription from "./Subscription/Subscription";
import "./ApplicationSettings.scss";
import { Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import LetterHead from "./Leaderhead/Letterhead";
import { Can } from "components/can";
import { permissions } from "lib/config/permissionSettings";

export default function ApplicationSettings() {
  const { user } = useSelector((state) => state.login);

  const links = [
    {
      name: "Subscription / Users",
      link: "/dashboard/application-settings",
      permissions: [permissions.read.admin_subscriptions_users],
    },
    {
      name: "Proposal Text & Templates",
      link: "/dashboard/application-settings/proposal",
      permissions: [permissions.read.admin_proposals_text_and_templates],
    },
    {
      name: "Proposal & Contract Letterhead",
      link: "/dashboard/application-settings/letterHead",
      permissions: [permissions.read.admin_proposals_text_and_templates],
    },
    {
      name: "Drop Down Menus",
      link: "/dashboard/application-settings/drop-down-menus",
      permissions: [permissions.read.admin_drop_downs],
    },
  ];
  const {
    location: { pathname },
  } = useHistory();

  return (
    <div className="wrapper">
      <h4 className="p-0 text-lg font-bold">
        <span>Application Settings</span>
      </h4>
      <Row style={{ marginRight: 0, marginTop: "10px" }}>
        <Col style={{ paddingRight: 0 }}>
          <div className="_row">
            <div className="_menu_left">
              <ul>
                {links.map((link) => (
                  <li key={link.name}>
                    <Can do="read" on={link.permissions}>
                      <Link
                        className={pathname === link.link ? "active" : ""}
                        to={link.link}
                      >
                        <span>{link.name}</span>
                      </Link>
                    </Can>
                  </li>
                ))}
              </ul>
            </div>
            <div className="_menu_right">
              {user.userData.paymentStatus === "Frozen 2" ? (
                <Switch>
                  <Route exact path={links[0].link} component={Subscription} />
                </Switch>
              ) : (
                <Switch>
                  <Route exact path={links[3].link} component={DropdownMenus} />
                  <Route exact path={links[1].link} component={Proposal} />

                  <Route exact path={links[0].link} component={Subscription} />

                  <Route exact path={links[2].link} component={LetterHead} />
                </Switch>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}
