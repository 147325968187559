export const metricsActionTypes = {
  METRIC_ROOFING_GET_START: "METRIC_ROOFING_GET_START_ROOF",
  METRIC_ROOFING_GET_SUCCESS: "METRIC_ROOFING_GET_SUCCESS_ROOF",
  METRIC_ROOFING_GET_FAILURE: "METRIC_ROOFING_GET_FAILURE_ROOF",

  METRIC_ADD_ROOFING_START: "METRIC_ADD_ROOFING_START_ROOF",
  METRIC_ADD_ROOFING_SUCCESS: "METRIC_ADD_ROOFING_SUCCESS_ROOF",
  METRIC_ADD_ROOFING_FAILURE: "METRIC_ADD_ROOFING_FAILURE_ROOF",

  SECTION_UPDATE_START: "SECTION_UPDATE_START_ROOF",
  SECTION_UPDATE__SUCCESS: "SECTION_UPDATE__SUCCESS_ROOF",
  SECTION_UPDATE__FAILURE: "SECTION_UPDATE__FAILURE_ROOF",

  SECTION_DELETE_START: "SECTION_DELETE_START_ROOF",
  SECTION_DELETE__SUCCESS: "SECTION_DELETE__SUCCESS_ROOF",
  SECTION_DELETE__FAILURE: "SECTION_DELETE__FAILURE_ROOF",
};
