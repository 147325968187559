// src/api/materialApi.js

import { ApiEndpoints } from "lib/config/baseSettings";
import { axiosInstance } from "services";

export const getCartingActiveSuppliers = async () => {
    try {

        const URL = `${ApiEndpoints.PROPOSALS.GET_ACTIVE_CARTING_SUPPLIERS}`;

        const response = await axiosInstance.get(URL);
        return response.data;
    } catch (error) {
        throw new Error(error.message || "An error occurred while fetching cqarting suppliers");
    }
};


export const changeCartingSupplier = async (data) => {
    try {
        const URL = `${ApiEndpoints.PROPOSALS.UPDATE_SUPPLIER_CARTING}`;
        const response = await axiosInstance.patch(URL, data);
        return response.data;
    } catch (error) {
        throw error;
    }
};


export const fetchCartingTypes = async (supplierId) => {
    if (!supplierId) throw new Error("Supplier ID is required");

    const params = new URLSearchParams();
    params.set("supplierId", supplierId);

    const URL = `${ApiEndpoints.PROPOSALS.GET_CARTING_TYPES}?${params}`;

    try {
        const response = await axiosInstance.get(URL);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || "Failed to fetch carting types");
    }
};


export const updateCartingQuoteLine = async (data) => {
    try {
        const URL = `${ApiEndpoints.PROPOSALS.UPDATE_CARTING_QUOTE}`;

        const response = await axiosInstance.patch(URL, data);
        return response.data;
    } catch (error) {
        throw error;
    }
};


// Add Misc Material to the Quote
export const addCartingToQuote = async (data) => {
    const URL = `${ApiEndpoints.PROPOSALS.ADD_NEW_CARTING_QUOTE_LINE}`;
    try {
        const response = await axiosInstance.post(URL, data);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const deleteCartingQuoteLine = async (data) => {
    try {
        const URL = `${ApiEndpoints.PROPOSALS.DELETE_SUPPLIER_CARTING}`;

        const response = await axiosInstance.delete(URL, { data });
        return response.data;
    } catch (error) {
        throw error;
    }
};