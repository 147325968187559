import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  resetAllMetricsGutter,
  updateFieldGutter,
  updateMetricsGutter,
} from "./redux/gutter-metric-collection/gutter-metric.action";
import { getGutterMetrics } from "./redux/gutter-metrics-v2/metrics.actions";
import MetricHeader from "./components/MetricHeader";
import DisplayMetric from "./components/DisplayMetric";
import ElevationDetailsMetric from "./components/metric/elevation-details";
import MultipleMetric from "./components/MultipleMetric";
import GuttersMetricTab from "./components/gutters/GuttersMetricTab";
import GutterSectionListing from "./components/gutters/GutterSectionListing";
import InitialLoader from "components/InitilalLoader";
import { toast } from "react-toastify";
import { Button, Col, Form, Row } from "react-bootstrap";
import {
  ELEVATION,
  ELEVATIONDETAILS,
  ELEVATIONOTHER,
  FLOOR,
} from "lib/constants/const";
import {
  generateQuotes,
  reGenerateQuotes,
} from "features/QuotesProposals/redux/proposals/proposals.actions";
import { LoaderModal } from "components";

const styles = {
  col: {
    margin: 0,
    padding: "1px",
  },
  mainContainer: {
    // backgroundColor: '#f2f2f2',
    // height: '100vh'
  },
};

export const Metric = (props) => {
  const { data, match, leadReqId, type_page } = props;

  const dispatch = useDispatch();
  const gutterData = useSelector((state) => state.gutterMetric.metrics);
  const tableData = useSelector((state) => state.gutterMetric.tableData);
  const metricData = useSelector((state) => state.gutterMetricV2Data);
  const proposalData = useSelector((state) => state.proposal);

  const requestId = match?.params?.id || leadReqId;

  useEffect(() => {
    if (requestId) {
      loadData();
    }
  }, [requestId]);

  console.log({
    metricData,
    requestId,
  });

  const {
    isLoadingDelete,
    deleteResponse,
    addResponse,
    isLoadingAdd,
    isLoading,
    isLoadingEdit,
    editResponse,
    currentAddedSection,
    deleteError,
    editError,
    errors,
  } = metricData || {};

  useEffect(() => {
    if (
      deleteResponse &&
      !isLoadingDelete &&
      Object.keys(metricData.deleteResponse).length > 0
    ) {
      loadData();
      toast.success("Successfully removed section");
      dispatch(resetAllMetricsGutter());
    }
  }, [deleteResponse, isLoadingDelete]);

  useEffect(() => {
    if (errors && !isLoading && Object.keys(metricData.errors).length > 0) {
      toast.error("Something went wrong");
    }
  }, [errors, isLoading]);

  useEffect(() => {
    return () => {
      dispatch(resetAllMetricsGutter());
    };
  }, [dispatch]);

  // Load once added new
  useEffect(() => {
    if (!isLoadingAdd && Object.keys(addResponse).length > 0) {
      console.log({ isLoading, data, errors, currentAddedSection });
      currentAddedSection.sectionId = currentAddedSection._id;
      console.log({
        currentAddedSection,
      });

      dispatch(updateMetricsGutter(currentAddedSection));
      loadData();
    }
  }, [isLoadingAdd, addResponse]);

  // Load after update
  useEffect(() => {
    if (
      !isLoadingEdit &&
      metricData?.data?.sections?.length &&
      editResponse?.length > 0
    ) {
      const currentUpdatedSection = metricData?.editResponse?.find(
        (section) => section._id === gutterData?.sectionId
      );

      console.log({
        currentUpdatedSection,
        metricData,
      });

      if (currentUpdatedSection) {
        currentUpdatedSection.sectionId = currentUpdatedSection._id;

        dispatch(updateMetricsGutter(currentUpdatedSection));
        loadData();
      }
    }
  }, [isLoadingEdit, editResponse]);

  const loadData = () => {
    if (requestId) {
      dispatch(resetAllMetricsGutter());

      dispatch(getGutterMetrics(requestId));
    }
  };

  const isLoadingData = () => {
    return isLoadingDelete || isLoadingEdit || isLoading;
  };

  const getError = () => {
    return deleteError?.mesg || editError?.msg || errors?.msg;
  };

  const handleMetricFieldUpdate = (field, value) => {
    dispatch(updateFieldGutter(field, value));
  };

  const handleMultipleMetric = (name) => {
    const updatedElevation = gutterData.elevation.includes(name)
      ? gutterData.elevation.filter((item) => item !== name)
      : [...gutterData.elevation, name];
    handleMetricFieldUpdate("elevation", updatedElevation);
  };

  const handleClickElevationDetails = (name, type) => {
    const { elevationDetails } = gutterData;
    console.log({
      name,
      type,
    });

    if (type === "not_more") {
      handleMetricFieldUpdate("elevationDetails", {
        ...elevationDetails,
        type: name,
      });
    } else if (type === "more") {
      const more = elevationDetails.more || { lists: [] };
      const updatedList = more.lists.includes(name)
        ? more.lists.filter((item) => item !== name)
        : [...more.lists, name];
      console.log({
        elevationDetails,
        updatedList,
      });

      handleMetricFieldUpdate("elevationDetails", {
        ...elevationDetails,
        more: {
          ...more,
          lists: updatedList,
          other:
            name === "Other" && !updatedList.includes(name) ? "" : more.other,
        },
      });
    }
  };

  const addLevel = (label, id, name, exec) => {
    console.log({ label, id, name, exec });
    handleMetricFieldUpdate("level", name);
  };

  const handleOnChangeSectionNote = (event) => {
    console.log({
      value: event.target.value,
    });
    const value = event.target.value;
    handleMetricFieldUpdate("notes", value);
  };

  function Btn({ onClick, disabled, children, variant }) {
    return (
      <Button
        style={{ float: "right" }}
        variant={variant || "primary"}
        onClick={onClick}
        disabled={disabled || false}
      >
        {children}
      </Button>
    );
  }

  const SaveOrViewQuote = () => {
    const { proposalData, history } = props;
    const { data, isLoading } = metricData || {};
    const proposalExists = data?.proposalExists;
    const isModifiedMetrics = data?.isModifiedMetrics;
    console.log({
      gutterData: data,
    });

    // Button text and onClick handlers
    const generateQuoteText = proposalData?.isLoadingGenerate ? (
      <Spinner />
    ) : (
      "Generate Quote"
    );

    const handleGenerateQuote = () => {
      const body = { requestId };
      dispatch(generateQuotes(body, history));
    };

    const handleViewQuote = () => {
      if (isModifiedMetrics) {
        const body = { requestId };
        dispatch(reGenerateQuotes(body, history));
      } else {
        history.push(`/dashboard/proposal-quotes/${requestId}`);
      }
    };

    // Determine which button to render
    if (!proposalExists) {
      return (
        <Btn onClick={handleGenerateQuote} className="w-90">
          {generateQuoteText}
        </Btn>
      );
    }

    return (
      <Btn onClick={handleViewQuote}>
        {isModifiedMetrics ? "Regenerate Quote" : "View Quote"}
      </Btn>
    );
  };

  return (
    <div className="metric" style={styles.mainContainer}>
      <MetricHeader
        metricType="Gutters"
        buildingName=""
        addAnotherSection={() => dispatch(resetAllMetricsGutter())}
        disabledAddSection={!gutterData?.sectionId}
        disabledImageBtn={tableData.length === 0}
        requestId={requestId}
        address={metricData?.data?.address || ""}
        isBuilding={data?.isBuilding ?? "false"}
        msg={gutterData.msg}
        msgType={gutterData.msgType}
        isQuoteLock={gutterData.isLocked}
        type_page={type_page}
        hasOtherMetric={metricData?.data?.hasRoof}
      />

      <div style={{ margin: "1em 0" }}>
        <Row style={{ margin: "1em 0", justifyContent: "flex-end" }}>
          <Col>
            <DisplayMetric
              label="Level"
              isGutter={true}
              array={gutterData.level}
              data={FLOOR}
              size="24%"
              addFloor={addLevel}
              isQuoteLock={gutterData.isQuoteLock}
              prevMetric={
                !(data?.isBuilding && !data?.building) &&
                !gutterData.isQuoteLock
              }
            />
          </Col>
          <Col>
            <label className="btnheading">Elevation</label>
            <div className="ele_grid">
              <MultipleMetric
                metricsData={ELEVATION}
                selectedData={gutterData.elevation}
                isDisable={!gutterData.level || gutterData.isQuoteLock}
                isQuoteLock={gutterData.isQuoteLock}
                handleClick={handleMultipleMetric}
              />
            </div>
          </Col>
          <Col>
            <ElevationDetailsMetric
              elevationDetailsData={ELEVATIONDETAILS}
              elevationSelectedData={gutterData.elevationDetails?.type}
              isQuoteLock={gutterData.isQuoteLock}
              label="Elevation Details"
              prevMetric={
                gutterData.elevation.length && !gutterData.isQuoteLock
              }
              elevationOther={ELEVATIONOTHER}
              onClickBtn={handleClickElevationDetails}
              elevationOtherSelected={gutterData.elevationDetails?.more?.lists}
              handleClickOtherInputValue={(event) => {
                const { value } = event.target;
                const more = gutterData.elevationDetails.more || {
                  lists: [],
                  other: "",
                };
                console.log({
                  more,
                });

                handleMetricFieldUpdate("elevationDetails", {
                  ...gutterData.elevationDetails,
                  more: { ...more, other: value },
                });
              }}
              otherSelectValue={gutterData.elevationDetails?.more?.other}
            />
          </Col>
        </Row>

        {/* Gutter metric tab */}
        {gutterData.level &&
          gutterData.elevation &&
          gutterData.elevationDetails?.type && (
            <>
              <Row>
                <Col style={{ margin: "1em 0" }}>
                  <GuttersMetricTab
                    sectionsData={metricData?.data?.sections}
                    requestId={requestId}
                  />
                </Col>
              </Row>
              <Row style={{ marginBottom: "14px" }}>
                <Col>
                  <Form.Group style={styles.formInput}>
                    <Form.Label>Section Notes:</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows="3"
                      name="notes"
                      style={{ resize: "none" }}
                      value={gutterData?.notes}
                      onChange={handleOnChangeSectionNote}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </>
          )}

        {!isLoadingData() ? (
          <>
            {metricData?.data?.sections?.length > 0 && (
              <Row>
                <Col>
                  <GutterSectionListing
                    type="roofing"
                    data={metricData?.data?.sections}
                    requestId={requestId}
                  />
                </Col>
              </Row>
            )}
          </>
        ) : (
          <InitialLoader error={getError()} />
        )}
      </div>

      {metricData?.data?.sections?.length ? (
        <Row>
          <Col>{SaveOrViewQuote()}</Col>
        </Row>
      ) : null}

      <LoaderModal show={proposalData?.isLoadingGenerate} />
    </div>
  );
};

export default Metric;
