import React, { useEffect } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { PriceInput } from "components";

import { useDispatch, useSelector } from "react-redux";
import styles from "lib/config/baseStyles";
import displayMsg from "components/displayMsg";
import SelectSuppliers, {
  SupplierItemOnlyName,
  SelectSuppliersSection,
} from "../../SelectSupliers";
import LoadingSkeleton from "../../LoadingSkeleton";
import LabourQuoteList from "./LabourQuoteList";
import userDetails from "hooks/user/userDetails";
import {
  addMiscLabourQuote,
  changeLabourSupplier,
  getLabourSuppliers,
  updateCurrentProposal,
} from "features/QuotesProposals/redux/proposals/proposals.actions";
import {
  hasSupplierFieldChanged,
  supplierEmptyText,
} from "features/QuotesProposals/utils";
import QuoteUpdateLoader from "components/common/loaders/QuoteUpdateLoader";
import {
  useAddLabourlMisc,
  useChangeLabourSupplier,
  useLabourSuppliers,
  useUpdateLabourQuoteline,
} from "features/QuotesProposals/hooks/useLabour";
import QuoteSkeleton from "../../SkeletonLoader/Quote";
import { toast } from "react-toastify";

export default function RoofingLabor({ metricId, isLockQuoteData }) {
  const dispatch = useDispatch();
  const { companyId } = userDetails();

  // New changes
  const proposalData = useSelector((state) => state.proposal);

  const {
    selectedProposal,
    isLoadingDeleteLabourMisc,
    isLoadingUpdateLabourSupplier,
  } = proposalData;

  const {
    suppliers,
    isLoading: isLoadingSuppliers,
    isError,
    error: fetchSupplierError,
  } = useLabourSuppliers();

  const {
    data,
    isLoading: isLoadingUpdateSupplier,
    isError: isUpdate,
    isSuccess,
    mutate: updateSupplierMutation,
  } = useChangeLabourSupplier();

  const {
    isLoading: isLoadingAddLabourMisc,
    isError: isAddLabourMiscError,
    mutate: addLabourMiscMutation,
  } = useAddLabourlMisc();

  console.log({
    proposalDataL: proposalData,
    suppliersl: suppliers,
    isLoadingAddLabourMisc,
  });

  const selectedSupplier =
    proposalData?.selectedProposal?.quotes?.labours?.supplier;

  console.log({
    selectedProposalLabour: selectedProposal,
  });

  const {
    isLoading: isLoadingLabourUpdateInfoProposal,
    isError: updateQuoteLineError,
    mutate: updateQuotelineMutation,
  } = useUpdateLabourQuoteline();

  const onChangeSupplier = (e) => {
    // changeSupplier(e.target.value, setSelectedSupplier);
    const supplierId = e.target.value;

    console.log({
      supplierId,
      e,
    });
    const body = {
      requestId: selectedProposal?.requestId,
      supplierId: supplierId,
      proposalId: selectedProposal._id,
    };

    updateSupplierMutation(body, {
      onSuccess: (data) => {
        // Assuming `data.result` contains the updated proposals
        const updatedProposal = data?.result?.find(
          (proposal) => proposal._id === selectedProposal?._id // Adjust key based on response
        );
        if (updatedProposal) {
          // Dispatch an action to update the selectedProposal in Redux
          dispatch(updateCurrentProposal(updatedProposal));
        }

        console.log("Labour supplier updated successfully.");
        toast.success("Labour supplier updated successfully!");
      },

      onError: (error) => {
        console.error("Error updating labour supplier:", error.message);
        handleGlobalError(error, dispatch);
      },
    });
  };

  const totalPrice = selectedProposal.quotes?.labours.total;

  console.log({
    totalPrice,
  });

  const retry = () => {
    // saveLaborSectionDataToDB(quoteLaborSectionData);
  };

  const onAddMisc = () => {
    const body = {
      supplierId: selectedSupplier.supplier,
      proposalId: selectedProposal._id,
      labourQuoteId: selectedProposal.quotes.labours._id,
      workType: "",
      uomPrice: "",
      uomQty: "",
    };

    addLabourMiscMutation(body, {
      onSuccess: (data) => {
        // Assuming `data.result` contains the updated proposals
        const updatedProposal = data?.result?.find(
          (proposal) => proposal._id === selectedProposal?._id // Adjust key based on response
        );
        console.log("updatedProposal123", updatedProposal);
        if (updatedProposal) {
          // Dispatch an action to update the selectedProposal in Redux
          dispatch(updateCurrentProposal(updatedProposal));
        }

        console.log("Misc item added successfully.");
        toast.success("Misc item added successfully!");
      },

      onError: (error) => {
        console.error("Error adding Misc item:", error.message);
        handleGlobalError(error, dispatch);
      },
    });
  };

  const changeQuantiy = (
    labourId,
    quantity,
    workType,
    uom,
    price,
    skylightTypeOfWork
  ) => {
    console.log({
      labourId,
      quantity,
      workType,
      uom,
      price,
    });

    const body = {
      requestId: selectedProposal?.requestId,
      proposalId: selectedProposal._id,
      labourQuoteId: selectedProposal?.quotes?.labours?._id,
      labourId: labourId,
      skylightTypeOfWork: skylightTypeOfWork,
    };

    if (price) {
      body.uomPrice = price;
    }

    if (quantity) {
      body.uomQty = quantity;
    }

    if (workType) {
      body.workType = workType;
    }

    if (uom) {
      body.uom = uom;
    }

    updateQuotelineMutation(body, {
      onSuccess: (data) => {
        // Assuming `data.result` contains the updated proposals
        const updatedProposal = data?.result?.find(
          (proposal) => proposal._id === selectedProposal?._id // Adjust key based on response
        );
        if (updatedProposal) {
          // Dispatch an action to update the selectedProposal in Redux
          dispatch(updateCurrentProposal(updatedProposal));
        }

        console.log("Material supplier updated successfully.");
        toast.success("Material supplier updated successfully!");
      },

      onError: (error) => {
        console.error("Error updating material supplier:", error.message);
        handleGlobalError(error, dispatch);
      },
    });
  };

  if (
    isLoadingSuppliers ||
    isLoadingUpdateSupplier ||
    isLoadingLabourUpdateInfoProposal ||
    (isLoadingAddLabourMisc && !isAddLabourMiscError)
  ) {
    return <QuoteSkeleton />;
  }

  return (
    <div style={{ margin: "1em" }}>
      <section>
        <Row lg="12">
          <Col style={styles.reduceWidthCol}>
            <h4 style={{ marginBottom: "-.5rem" }}>Roofing Labor</h4>
          </Col>
          <Col className="d-flex justify-content-end">
            {/* {isLoadingProposal ? null : error ? (
                  <SmRetryBtn retry={retry} />
                ) : null} */}
            <QuoteUpdateLoader
              loading={
                isLoadingLabourUpdateInfoProposal ||
                isLoadingDeleteLabourMisc ||
                isLoadingAddLabourMisc ||
                isLoadingUpdateSupplier
              }
              retry={retry}
            />
          </Col>
        </Row>
        <Row>
          <SelectSuppliers
            selectedSupplier={selectedSupplier?._id}
            onSelectSupplier={onChangeSupplier}
            loading={false}
            suppliers={suppliers}
            emptyText={supplierEmptyText(suppliers?.length)}
            SupplierItem={SupplierItemOnlyName}
            isLockQuoteData={isLockQuoteData}
            isOriginalSupplier={hasSupplierFieldChanged(
              selectedProposal.originalQuoteSupplier,
              "labourSupplierId",
              selectedSupplier?._id
            )}
          />
        </Row>
        <SelectSuppliersSection>
          <Col
            lg="2"
            style={{
              ...styles.reduceWidthCol,
              textAlign: "center",
            }}
          >
            <Form.Label style={{ marginTop: "6%" }}>Difficulty</Form.Label>
          </Col>
          <Col
            lg="2"
            style={{
              ...styles.reduceWidthCol,
              textAlign: "center",
            }}
          >
            <Form.Label style={{ marginTop: "6%" }}>Work Type</Form.Label>
          </Col>
          <Col
            lg="2"
            style={{
              ...styles.reduceWidthCol,
              textAlign: "center",
            }}
          >
            <Form.Label style={{ marginTop: "6%" }}>Install</Form.Label>
          </Col>
        </SelectSuppliersSection>

        <LabourQuoteList
          selectedProposal={selectedProposal}
          tabData={selectedProposal?.quotes?.labours?.quotes}
          metricId={metricId}
          // MiscItemComponent={LaborMiscItem}
          LoadingSkeleton={LoadingSkeleton}
          // IndividualDataItem={LaborIndividualDataItem}
          displayMsg={displayMsg}
          isLockQuoteData={isLockQuoteData}
          changeQuantiy={changeQuantiy}
        />

        <MiscItemComponent
          onAddMisc={onAddMisc}
          isLockQuoteData={isLockQuoteData}
          isLoadingAddLabourMisc={isLoadingAddLabourMisc}
        />
        {totalPrice && <LaborTotalSection total={totalPrice} />}
      </section>
    </div>
  );
}

const MiscItemComponent = ({
  onAddMisc,
  isLockQuoteData,
  isLoadingAddLabourMisc,
}) => (
  <Row className="mt-4">
    <Col style={styles.reduceWidthCol} lg="2">
      <Button
        onClick={onAddMisc}
        disabled={isLoadingAddLabourMisc || isLockQuoteData?.isLocked}
      >
        Add Misc Item
      </Button>
    </Col>
  </Row>
);

function LaborTotalSection({ total }) {
  return (
    <Row>
      <div className="d-flex justify-content-end align-items-center w-100 mb-2">
        <p className="mb-0 me-3">{"Roofing Labor Sub Total:"}</p>
        <PriceInput
          price={total}
          disabled
          style={{ background: "#28a745", color: "#fff" }}
        />
      </div>
    </Row>
  );
}
