// src/api/materialApi.js

import { ApiEndpoints } from "lib/config/baseSettings";
import { axiosInstance } from "services";

export const getGutterActiveSuppliers = async () => {
  try {
    const URL = `${ApiEndpoints.PROPOSALS.GET_ACTIVE_LABOUR_GUTTER_SUPPLIERS}`;

    const response = await axiosInstance.get(URL);
    return response.data;
  } catch (error) {
    throw new Error(
      error.message || "An error occurred while fetching gutter suppliers"
    );
  }
};

export const changeGutterupplier = async (data) => {
  try {
    const URL = `${ApiEndpoints.PROPOSALS.CHANGE_GUTTER_SUPPLIER_PROPOSAL}`;
    const response = await axiosInstance.patch(URL, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateGutterQuoteLine = async (data) => {
  try {
    const URL = `${ApiEndpoints.PROPOSALS.UPDATE_GUTTER_QUOTE}`;

    const response = await axiosInstance.patch(URL, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Add Misc Material to the Quote
export const addGutterMisc = async (data) => {
  const URL = `${ApiEndpoints.PROPOSALS.ADD_MISC_GUTTER}`;
  try {
    const response = await axiosInstance.post(URL, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteGutterMiscQuote = async (data) => {
  try {
    const URL = `${ApiEndpoints.PROPOSALS.DELETE_MISC_GUTTER_QUOTE}`;

    const response = await axiosInstance.delete(URL, { data });
    return response.data;
  } catch (error) {
    throw error;
  }
};
