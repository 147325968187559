import React from "react";
import ReactDOM from "react-dom/client";
import "./App.css";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "./index.scss";
//Redux Import Statements
import { Provider } from "react-redux";
import store from "./lib/redux/store";
import reportWebVitals from "./reportWebVitals";
import { PermissionProvider } from "./components/permission-provider";
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { toast } from "react-toastify";

const root = ReactDOM.createRoot(document.getElementById("root"));
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      onError: (error) => {
        console.error("Global Query Error:", error.message);
        toast.error("An error occurred while fetching data.");
      },
    },
    mutations: {
      onError: (error) => {
        console.error("Global Mutation Error:", error.message);
        toast.error("An error occurred while performing an action.");
      },
    },
  },
});

root.render(
  <QueryClientProvider client={queryClient}>
    {/* <ReactQueryDevtools /> */}

    <Provider store={store}>
      <PermissionProvider>
        <App />
      </PermissionProvider>
    </Provider>
  </QueryClientProvider>
);
reportWebVitals();
