import React, { useState, useEffect, useLayoutEffect } from "react";
import { Button, Modal, Col, Row } from "react-bootstrap";
import { PopupModal, ArrowPagination } from "components";
import {
  sumUnitDimensonTotal,
  sumDimensonTotal,
  formatNumber,
} from "utils/utils";

import staticTexts from "./staticTexts";
import { useSelector } from "react-redux";
import Spinner from "components/common/spinner/spinner";

const MetricTypes = {
  roof: "Switch to Roofing",
  gutter: "Switch to  Gutter",
};

const RoofingTableHeadsAndDataMapping = {
  sec: "Sec",
  floor: "Floor",
  elevation: "Elevation",
  pitch: "Pitch",
  workType: "Work Type",
  install: "Install",
  l: "L",
  w: "W",
  f: "F",
  sq: "Sq Ft(S)",
  flat: "Sq Ft(F)",
};

const GutterTableHeadsAndDataMapping = {
  sec: "Sec",
  level: "Level",
  elevation: "Elevation",
  gutters: "Gutters",
  leaders: "Leaders",
  screens: "Screens",
  fascia: "Fascia",
  cap: "Cap",
  heads: "Heads",
};

const style = {
  includeAllBtn: {
    width: "250px",
    height: "50px",
    margin: "0 1em 1em 0",
    float: "right",
    fontWeight: "bold",
  },
};
const SectionTable = ({
  metricSections,
  startI,
  lastI,
  tableDataMapping,
  toggleSectionSelection,
}) => {
  console.log({
    metricSections,
  });
  return metricSections.length > 0 ? (
    <div className="__quote_tbl_wrapper">
      <table className="__quote_tbl">
        <thead>
          <tr>
            {Object.keys(tableDataMapping).map((key) => (
              <th key={key}>{tableDataMapping[key]}</th>
            ))}
            <th />
          </tr>
        </thead>
        <tbody>
          {metricSections.slice(startI, lastI).map((section, index) => (
            <tr key={section._id}>
              <td>{index + 1}</td>
              <td>{section.floor}</td>
              <td>{section.elevation}</td>
              <td>{section?.pitch?.roofPitch}</td>
              <td>{section?.workType?.name}</td>
              <td>
                {section?.installTypes?.flat?.type}{" "}
                {section?.installTypes?.shingles?.type}{" "}
              </td>
              <td> {sumUnitDimensonTotal(section?.dimensions, "length")}</td>
              <td> {sumUnitDimensonTotal(section?.dimensions, "width")}</td>
              <td> {sumUnitDimensonTotal(section?.dimensions, "factor")}</td>

              <td>
                {" "}
                {section?.installTypes?.shingles &&
                Object.values(section?.installTypes?.shingles)?.length
                  ? sumDimensonTotal(section?.dimensions)
                    ? formatNumber(sumDimensonTotal(section?.dimensions))
                    : 0
                  : ""}
              </td>
              <td>
                {section?.installTypes?.flat &&
                Object.values(section?.installTypes?.flat)?.length
                  ? sumDimensonTotal(section?.dimensions)
                    ? formatNumber(sumDimensonTotal(section?.dimensions))
                    : 0
                  : ""}
              </td>
              <td>
                <Button
                  variant={section.included ? "danger" : "primary"}
                  onClick={() => toggleSectionSelection(section._id)}
                >
                  {!section.included ? "Include" : "Exclude"}
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  ) : (
    <div
      style={{
        textAlign: "center",
        margin: "3em 0 1em 0",
        color: "#999",
        fontWeight: "bold",
      }}
    >
      No section data available.
    </div>
  );
};

const SectionTableHeader = ({
  selectedBuildingName,
  isAllSectionRoof,
  selectedSections,
  toggleAllSelection,
}) => {
  const hyphen = selectedBuildingName ? " - " : "";
  console.log({
    selectedSections,
  });
  return (
    <Row>
      <Col>
        <h4 style={{ textAlign: "center", margin: "8px 0" }} className="my-2  ">
          {selectedBuildingName + hyphen + "Roofing"}
          {/* {currentMetricType === MetricTypes.roof
            ? selectedBuildingName + hyphen + "Roofing"
            : selectedBuildingName + hyphen + "Gutters"} */}
        </h4>
      </Col>
      <Col lg="3">
        {isAllSectionRoof ? (
          <Button
            className={`${style.includeAllBtn} ${
              selectedSections ? "btn btn-danger" : "btn btn-primary"
            }`} // Conditionally apply class
            onClick={toggleAllSelection}
          >
            {selectedSections ? "Exclude All Sections" : "Include All Sections"}{" "}
            {/* Conditionally change button label */}
          </Button>
        ) : null}
      </Col>
    </Row>
  );
};

function SectionListModal({
  show,
  onHide,
  altProposalName,
  onSaveNewQuote,
  isLoadingUpdateSupplier,
}) {
  const metricData = useSelector((state) => state.metricV2Data);

  const [metrics, setMetrics] = useState([]);

  const [pageNum, setPageNum] = useState(1);
  const [maxItem] = useState(5);
  const [address, setAddress] = useState(5);

  const [showDifferentMetric, setShowDifferentMetric] = useState(false);
  const [currentMetricType, setCurrentMetricType] = useState(
    metrics?.roof?.hasRoof ? MetricTypes?.roof : MetricTypes?.gutter
  );

  console.log({
    metricData,
  });

  useEffect(() => {
    if (!metricData.isLoading && metricData?.data?.sections?.length) {
      setMetrics(
        metricData?.data?.sections.map((section) => {
          return { ...section, included: false };
        })
      );
    }
  }, [metricData.isLoading]);

  const prevPage = () => setPageNum(pageNum - 1);

  const nextPage = () => setPageNum(pageNum + 1);

  const lastI = pageNum * maxItem;
  const startI = lastI - maxItem;

  const toggleSectionSelection = (sectionId) => {
    setMetrics((prevMetrics) =>
      prevMetrics.map((section) => {
        if (section._id === sectionId) {
          console.log({
            sectionId,
            in: !section.included,
          });
          return { ...section, included: !section.included }; // Toggle `included`
        }
        return section;
      })
    );
  };

  const toggleAllSelection = () => {
    setMetrics((prevMetrics) =>
      prevMetrics.map((section) => {
        return { ...section, included: !section.included };
      })
    );
  };

  const selectedSections = metrics.some((section) => section.included === true);

  console.log({
    selectedSections,
  });
  const handleGenerateProposal = () => {
    const sections = metrics.map((section) => section._id);
    onSaveNewQuote(sections);
  };
  return (
    <PopupModal
      show={show}
      heading=""
      onHide={() => onHide()}
      bold
      centered={false}
      styles={{ border: "none" }}
      size="2xl"
    >
      <Row style={{ marginTop: "-1.5em" }}>
        <Col>
          <h3 style={{ fontWeight: "bold" }}>
            {staticTexts.sectionHeading} - &nbsp;
            {address}
          </h3>
        </Col>
      </Row>
      <p
        style={{
          fontWeight: "500",
          // marginTop: '1rem',
          lineHeight: "1.1rem",
          // marginTop: '-1.5em'
        }}
      >
        {staticTexts.sectionSubHead}
      </p>
      <p
        style={{
          fontWeight: "500",
          marginTop: "-0.9rem",
          lineHeight: "1.1rem",
        }}
      >
        {`To remove all ${
          currentMetricType === MetricTypes.roof ? "roofing" : "gutter"
        }` + staticTexts.sectionSubHead2}
      </p>

      <SectionTableHeader
        selectedBuildingName={altProposalName}
        currentMetricType={currentMetricType}
        MetricTypes={MetricTypes}
        isAllSectionRoof={true}
        isAllSectionGutter={false}
        selectedSections={selectedSections}
        toggleAllSelection={toggleAllSelection}
      />
      <SectionTable
        metricSections={metrics}
        toggleSectionSelection={toggleSectionSelection}
        startI={startI}
        lastI={lastI}
        tableDataMapping={RoofingTableHeadsAndDataMapping}
      />

      <Modal.Footer
        style={{
          justifyContent: "space-between",
          border: "none",
          padding: "0 .75rem",
          marginTop: "20px",
        }}
      >
        <div>
          {showDifferentMetric && (
            <Button
              style={{
                width: "200px",
                height: "50px",
                margin: "0 1em 1em 0",
                float: "left",
                fontWeight: "bold",
              }}
              onClick={toggleMetric}
            >
              {currentMetricType === MetricTypes.roof
                ? MetricTypes.gutter
                : MetricTypes.roof}
            </Button>
          )}
        </div>
        <ArrowPagination
          totalItem={metrics.length}
          pageNum={pageNum}
          prevPage={prevPage}
          nextPage={nextPage}
          maxItem={maxItem}
        />
        <div>
          <Button
            style={{
              width: "130px",
              height: "50px",
              margin: "0 1em 1em 0",
              fontWeight: "bold",
            }}
            variant="danger modified-btn"
            onClick={onHide}
          >
            Cancel
          </Button>
          {/* {showBuilding && (
            <Button
              style={{
                width: "200px",
                height: "50px",
                margin: "0 1em 1em 0",
                fontWeight: "bold",
              }}
              onClick={() => toggleModals(false, true)}
            >
              Show Building List
            </Button>
          )} */}
          <Button
            style={{
              width: "130px",
              height: "50px",
              margin: "0 1em 1em 0",
              fontWeight: "bold",
            }}
            onClick={handleGenerateProposal}
            disabled={isLoadingUpdateSupplier || !selectedSections}
          >
            {isLoadingUpdateSupplier ? (
              <Spinner
                variant="light"
                layout="row-view"
                label="Generate"
                size="sm"
              />
            ) : (
              "Generate"
            )}
          </Button>
        </div>
      </Modal.Footer>
    </PopupModal>
  );
}

export default SectionListModal;
