import React from "react";
import { Spinner as Bootstrapspinner } from "react-bootstrap";
import PropTypes from "prop-types"; // Import PropTypes for validation
import "./style.css";

function Spinner({
  variant = "primary", // Default value for variant
  size = "sm", // Default value for size
  animation = "border", // Default value for animation
  label = "", // Default value for label
  layout = "row-view", // New prop to control layout, default is 'row'
  centerViewport = false, // New prop to center the spinner in the viewport
}) {
  return (
    <div
      className={`spinner-container ${layout} ${
        centerViewport ? "center-viewport" : ""
      }`}
    >
      <Bootstrapspinner animation={animation} variant={variant} size={size} />
      {label && <span>{label}</span>}
    </div>
  );
}

// Adding prop validation
Spinner.propTypes = {
  variant: PropTypes.oneOf([
    "primary",
    "secondary",
    "success",
    "danger",
    "warning",
    "info",
    "light",
    "dark",
  ]), // Valid values for variant
  size: PropTypes.oneOf(["sm", "lg", "md"]), // Valid values for size
  animation: PropTypes.oneOf(["border", "grow"]), // Valid values for animation
  label: PropTypes.string, // Label should be a string
  layout: PropTypes.oneOf(["row-view", "column-view"]), // New prop to control layout
  centerViewport: PropTypes.bool, // Boolean to control if spinner should be centered in the viewport
};

export default Spinner;
