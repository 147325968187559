import React, { useState, useMemo } from "react";
import { useDispatch } from "react-redux";
import { LoadingLoader, PopupModal } from "components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { PricingList } from "features/Suppliers/Material.jsx";
import {
  deleteLabourMiscQuote,
  getSeriesTypes,
  updateCurrentProposal,
  updateLabourQuote,
  updateProposalInfo,
} from "features/QuotesProposals/redux/proposals/proposals.actions.js";
import { useSelector } from "react-redux";
import LabourListItem from "./LabourListItem.jsx";
import {
  useDeleteLabourMisc,
  useUpdateLabourQuoteline,
} from "features/QuotesProposals/hooks/useLabour.js";
import { handleGlobalError } from "utils/utils.js";
import { toast } from "react-toastify";

export default function LabourQuoteList({
  loadingData,
  dataError,
  selectedSupplier,
  metricId,
  saveSectionDataToDB,
  deleteMiscItem,
  changeQuantiy,
  LoadingSkeleton,
  displayMsg,
  quoteMaterialSectionData,
  isLockQuoteData,
  selectedProposal,
  isLoading,
  tabData,
}) {
  console.log({
    tabData,
    l: tabData,
  });

  if (dataError) {
    return displayMsg({ msg: "No data available." });
  }

  if (loadingData) {
    return new Array(10).fill(1).map((_, i) => <LoadingSkeleton key={i} />);
  }

  if (!tabData?.length) {
    return displayMsg({ msg: "No data available." });
  }

  return (
    <div>
      {tabData.map((labour, idx) => (
        <LabourLstItemWrapper
          key={`${labour?._id}-${labour?.category}`}
          idx={idx}
          pricing={labour}
          selectedSupplier={selectedSupplier}
          metricId={metricId}
          tabData={tabData}
          saveSectionDataToDB={saveSectionDataToDB}
          quoteMaterialSectionData={quoteMaterialSectionData}
          isLockQuoteData={isLockQuoteData}
          isLoading={isLoading}
          editItem={(dataItem) => changeQuantiy(dataItem)}
          selectedProposal={selectedProposal}
          changeQty={changeQuantiy}
        />
      ))}
    </div>
  );
}

function LabourLstItemWrapper({
  pricing,
  selectedSupplier,
  editItem,
  isLockQuoteData,
  selectedProposal,
  changeQty,
}) {
  const {
    field,
    type,
    uomPrice,
    uom,
    subType,
    series,
    additionalDetail,
    original_list,
    qtyAndPrice,
    workType,
    roofPitch,
  } = pricing;

  const dispatch = useDispatch();
  const {
    isLoading: isLoadingLabourMiscDelete,
    isError: deleteMiscLabourError,
    mutate: deleteMiscLabourMutation,
  } = useDeleteLabourMisc();

  const onChangeQty = ({
    labourId,
    quantity = "",
    workType = "",
    uom = "",
    price = "",
    skylightTypeOfWork = "",
  }) => {
    changeQty(labourId, quantity, workType, uom, price, skylightTypeOfWork);
  };

  const typeText = `${type}${additionalDetail ? ` - ${additionalDetail}` : ""}`;
  console.log({
    pricing,
  });

  const onDeleteMiscQuote = () => {
    const body = {
      supplierId: selectedProposal?.quotes?.labours?.supplier?._id,
      proposalId: selectedProposal._id,
      labourQuoteId: selectedProposal?.quotes?.labours?._id,
      labourId: pricing._id,
    };

    deleteMiscLabourMutation(body, {
      onSuccess: (data) => {
        // Assuming `data.result` contains the updated proposals
        const updatedProposal = data?.result?.find(
          (proposal) => proposal._id === selectedProposal?._id // Adjust key based on response
        );
        console.log({
          deleteUp: updatedProposal,
          data,
          selectedProposal,
        });
        if (updatedProposal) {
          // Dispatch an action to update the selectedProposal in Redux
          dispatch(updateCurrentProposal(updatedProposal));
        }
        console.log("Misc item deleted successfully.");
        toast.success("Misc item deleted successfully!");
      },

      onError: (error) => {
        console.error("Error Misc item delete:", error);
        handleGlobalError(error, dispatch);
      },
    });
  };

  return series ? (
    <SkylightLaborListData
      selectedSupplier={selectedSupplier}
      data={pricing}
      onChangeQty={onChangeQty}
      editItem={editItem}
      isLockQuoteData={isLockQuoteData}
    />
  ) : (
    <LabourListItem
      field={field}
      subType={subType}
      type={typeText}
      qty={qtyAndPrice?.quantity}
      total={qtyAndPrice?.totalPrice}
      uom={uom}
      roofPitch={roofPitch}
      workType={workType}
      price={uomPrice}
      onChangeQty={onChangeQty}
      original_list={original_list}
      isLockQuoteData={isLockQuoteData}
      labour={pricing}
      deleteMiscItem={onDeleteMiscQuote}
      proposalStatus={selectedProposal?.status}
      isLoadingLabourMiscDelete={isLoadingLabourMiscDelete}
    />
  );
}
