import React, { useState, useEffect, useMemo } from "react";

import { Container, Row, Col, Form, Alert } from "react-bootstrap";
import "./css/pm.scss";
import SearchField from "components/search-field/search-field";
import Calendar from "./components/ProjectCalendarDetails/Calendar";
import QuotesProposals from "features/QuotesProposals";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import {
  displayPersons,
  displayPersonsPM,
  sliceHoursAndMin,
} from "utils/utils";
import moment from "moment";
import useProjectData from "hooks/useProjectManagement";
import ContactInfo from "./components/ContactInfo";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchForCalendar,
  fetchEvents,
} from "lib/redux/calendar/calendar.actions";
import { TIMINGS, project_manager_link } from "lib/constants/const";
import userPermission from "hooks/usePermission";
import { getAllPermissions } from "../../lib/redux/permission/permission.actions";
import useSchedulingModal from "hooks/SchedulingModal/useSchedulingModal";
import { Link } from "react-router-dom";
import AddModal from "./components/ScheduleProjectModal";
import Metrics from "./components/Metrics";
import PMImages from "./components/PMImages";
import PMdetails from "./components/PMdetails";
import Notes from "./components/Notes";
import CalendarEventsList from "./components/CalendarEventLists";

const links = ["Roofing", "Gutters"];

const styles = {
  container: {
    background: "#fff",
    padding: "2em .5em",
    position: "relative",
  },
  heading: {
    flex: 1,
  },
  header: {
    padding: "25px 20px",
  },
  formInput: {
    marginBottom: "15px",
  },
  tabItem: { padding: "1em" },
};

const ProjectManagement = (props) => {
  const {
    reqs,
    salesEvents,
    repairEvents,
    unscheduleSuccess,
    unscheduleFail,
    eventsError,
    personsErrors,
    errors,
    repairs,
    submitFail,
    submitSuccess,
    sales,
    paymentStatus,
  } = props;

  // #region states
  let timeout = null;
  const [timing, setTiming] = useState(TIMINGS);
  const [projectId, setProjectId] = useState();

  const [events, setEvent] = useState([]);
  const [activeTab, setActiveTab] = useState("");
  const [active, setActive] = useState("");
  const [addModal, setAddModal] = useState(false);
  const [date, setDate] = useState("");
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [isAllDay, setIsAllDay] = useState(false);
  const [sRep, setSRep] = useState(null);
  const [address, setAddress] = useState("");
  const [type, setType] = useState("");
  const [edit, setEdit] = useState(false);
  const [request, setRequest] = useState({});
  const [requestFor, setRequestFor] = useState([]);
  const [requests, setRequests] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectPerson, setSelectPerson] = useState([]);
  const [searchResult, setSearchResult] = useState([]);
  const [displayBtns, setDisplayBtns] = useState(false);
  const [msg, setMsg] = useState("");
  const [msgType, setMsgType] = useState("");
  const [msgFor, setMsgFor] = useState("");
  const [view, setView] = useState("month");
  const [dateObject, setDateObject] = useState(new Date());
  const [requestId, setRequestId] = useState("");
  const [proposalId, setProposalId] = useState("");
  const [isCheck, setIsCheck] = useState(false);

  const [leadReqId, setLeadReqId] = useState("");
  const [calendar_schedule_project, set_calendar_schedule_project] = useState(
    []
  );

  // #endregion

  const { gettingPermission, permission } = useSelector(
    (state) => state.allPermissions
  );
  const { user } = useSelector((state) => state.login);

  const { additionalProjectmanagementTime } = useSchedulingModal(user);

  const {
    fetch_scheduled_events,
    fetch_un_scheduled_events,
    unscheduledProject,
    scheduledProject,
    supplier,
    useScheduleProject,
    fetch_supplier,
    setUnscheduledProject,
    setScheduledProject,
  } = useProjectData({ proposalId: proposalId });

  const dispatch = useDispatch();

  const link = project_manager_link;
  const { salesPointerEvent } = userPermission(permission);
  const { repairPointerEvent } = userPermission(permission);
  const { hasAccessForSaleCalender, hasAccessForPM } =
    userPermission(permission);

  const { hasAccessForRepairsCalender } = userPermission(permission);

  const setToday = () => {
    setDateObject(new Date());
  };
  const loadData = async (data) => {
    if (!hasAccessForPM) return;
    let { unscheduledProject } = await fetch_un_scheduled_events();
    let { scheduledProject } = await fetch_scheduled_events();
    fetch_supplier();

    setUnscheduledProject(unscheduledProject);
    setScheduledProject(scheduledProject);
  };

  useEffect(() => {
    if (hasAccessForSaleCalender) {
      setActive("Gutters");
    } else {
      setActive("Roofing");
    }
    if (hasAccessForPM) {
      loadData();
    }
  }, [hasAccessForSaleCalender, hasAccessForPM]);

  useEffect(() => {
    dispatch(fetchForCalendar());
    dispatch(fetchEvents());
  }, []);

  useEffect(() => {
    getThingsReady();
  }, []);

  useEffect(() => {
    setAddModal(false);
    setMsg(unscheduleSuccess);
    setMsgType("success");
    setMsgFor("!modal");

    clearAllFields();

    props.clearAvailablePersons();
    props.fetchForCalendar();
    props.fetchEvents();
    clearMsg();
  }, [unscheduleSuccess]);

  useEffect(() => {
    setAddModal(false);
    setMsg(submitSuccess);
    setMsgType("success");
    setMsgFor("!modal");
    clearAllFields();

    props.clearAvailablePersons();
    props.fetchForCalendar();
    props.fetchEvents();
    clearMsg();
  }, [submitSuccess]);

  useEffect(() => {
    if (selectPerson && selectPerson.length > 0) {
      const filtered = events.filter((event) =>
        event.persons.some((person) =>
          selectPerson.some((data) => person.personId === data.value)
        )
      );
      setEvent(filtered);
    } else {
      setEvent(events);
    }
  }, [selectPerson]);

  useEffect(() => {
    if (active === "Roofing") {
      setRequests(sales);
    } else {
      setRequests(repairs);
    }
  }, [reqs]);

  useEffect(() => {
    setMsg(unscheduleFail);
    setMsgType("danger");
    setMsgFor("modal");
    clearMsg();
  }, [unscheduleFail]);

  useEffect(() => {
    setMsg(eventsError);
    setMsgType("danger");
    setMsgFor("!modal");
    clearMsg();
  }, [eventsError]);

  useEffect(() => {
    setMsg(unscheduleFail);
    setMsgType("danger");
    setMsgFor("modal");
    setDisplayBtns(false);
    clearMsg();
  }, [personsErrors]);

  useEffect(() => {
    setMsg(errors);
    setMsgType("danger");
    setMsgFor("!modal");
    clearMsg();
  }, [errors]);

  useEffect(() => {
    setMsg(submitFail);
    setMsgType("danger");
    setMsgFor("modal");
    setDisplayBtns(false);
    props.clearAvailablePersons();
    clearMsg();
  }, [submitFail]);

  useEffect(() => {
    if (searchTerm.trim() === "") {
      if (active === "Roofing") {
        setRequests(sales);
      } else {
        setRequests(repairs);
      }
      return;
    }
    if (searchTerm !== "") {
      const filteredRequests =
        requests &&
        requests.filter(
          (request) =>
            request.address.toLowerCase().includes(searchTerm.toLowerCase()) ||
            request.leadName.toLowerCase().includes(searchTerm.toLowerCase())
        );
      setSearchResult(filteredRequests);
    }
  }, [searchTerm]);

  useEffect(() => {
    if (active === "Sales") {
      setEvent(salesEvents);
      setRequests(sales);
    } else {
      setEvent(repairEvents);
      setRequests(repairs);
    }
  }, [active]);

  useEffect(() => {
    if (active === "Sales") {
      setRequests(sales);
    }
  }, [sales]);

  useEffect(() => {
    if (active === "Repairs") {
      setRequests(repairs);
    }
  }, [repairs]);

  useEffect(() => {
    if (isAllDay) {
      setStart("");
      setEnd("");
    }
  }, [isAllDay]);

  useEffect(() => {
    props.clearAvailablePersons();
    setDisplayBtns(false);
  }, [start, end, date]);

  const getThingsReady = () => {
    if (isAllDay || start || end || date) {
      props.clearAvailablePersons();
      setDisplayBtns(false);
    }
  };

  useEffect(() => {
    changeMainTab(link[0]);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const changeView = (view) => {
    setView(view);
  };

  const clearMsg = () =>
    (timeout = setTimeout(() => {
      setMsg("");
      setMsgType("");
      setMsgFor("");
    }, 10000));

  const convert24to12Hours = (hour, min) => {
    const meridiem = hour > 11 ? "PM" : "AM";
    const newHour = hour > 12 ? hour - 12 : hour;
    return `${newHour}:${
      Number(min) < 10 ? `0${Number(min)}` : Number(min)
    } ${meridiem}`;
  };

  const onDragStart = (e, request) => {
    setRequest(request);
  };

  const handleDrop = (e, request) => {
    if (paymentStatus != "Frozen 1") {
      const date = e.dropData.date;
      if (request) {
        const { address, propertyType, requestFor } = request;
        setAddModal(true);
        setDate(date);
        setAddress(address);
        setType(propertyType);
        setRequestFor(requestFor);
      }
    }

    return;
  };

  const clearAllFields = () => {
    setDate(null);
    setEnd("");
    setStart("");
    setIsAllDay(false);
    setSRep([]);
    setDisplayBtns(false);
    setRequest(null);
    setEdit(false);
  };

  const eventPressed = ({ data }) => {
    let {
      type,
      leadRequestId,
      schedules: [{ scheduleStartDate, scheduleStartTime }],
      leadRequestData: [
        {
          propertyList: [
            {
              propertyAddress: [
                {
                  propertyType: [{ roleType }],
                },
              ],
            },
          ],
        },
      ],
    } = data;
    const address = "";
    let newStart = "",
      newEnd = "";

    const sTime = sliceHoursAndMin(scheduleStartTime);
    newStart = convert24to12Hours(sTime.hour, sTime.min);

    let personsData = [];
    setDate(new Date(scheduleStartDate));
    setEnd(newStart);
    setSRep(personsData);
    setAddress(address);
    setType(roleType);
    setStart(newStart);
    setRequest(data);
    setAddModal(true);
    setEdit(true);
    setActive(type === "roof" ? "Roofing" : "Gutters");
    setRequestId(leadRequestId);
  };

  const changeTab = (tabName) => {
    setActive(tabName);
    setSelectPerson("");
  };
  const changeMainTab = (tabName) => {
    setActiveTab(tabName);
    setSelectPerson("");
  };

  const onNext = () => {
    setDateObject(moment(dateObject).add(1, "M"));
  };

  const onPrev = () => {
    setDateObject(moment(dateObject).subtract(1, "M"));
  };
  const onNextDay = () => {
    setDateObject(moment(dateObject).add(1, "day"));
  };

  const onPrevDay = () => {
    setDateObject(moment(dateObject).subtract(1, "day"));
  };

  const onNextWeek = () => {
    setDateObject(moment(dateObject).add(1, "weeks"));
  };

  const onPrevWeek = () => {
    setDateObject(moment(dateObject).subtract(1, "weeks"));
  };

  const {
    isunScheduling,
    isLoading,
    isLoadingAvPersons,
    personsData,
    isSubmitting,
  } = props;

  let onClick = (e, request) => {
    setRequest(request);
  };

  useEffect(() => {
    let value = active === "Roofing" ? "roof" : "gutter";
    let temp =
      scheduledProject.length > 0
        ? scheduledProject.filter((val) => val.type === value)
        : [];

    if (selectPerson.length > 0) {
      const indexes = selectPerson.map((index) => {
        return index.value;
      });

      temp = temp.filter((value) => {
        return (
          indexes.includes(
            value.schedules[0].crews[0].crewSupplierId[0]?._id
            //WARNING LACK OF SUBCONTRACTOR
          ) ||
          indexes.includes(
            value.schedules[0].crews[0].subSupplierId[0]?._id
            //WARNING LACK OF SUBCONTRACTOR
          )
        );
      });
    }

    set_calendar_schedule_project(temp);
  }, [selectPerson, scheduledProject, active]);

  const pmData = (
    id,
    leadRequestId,
    type,
    eventData,
    proposalId,
    isScheduled
  ) => {
    setRequest(eventData);
    setProjectId(id);
    setLeadReqId(leadRequestId);
    setType(type);
    setRequestId(leadRequestId);
    setProposalId(proposalId);
    setIsCheck(isScheduled);
    setType(type);
  };

  if (!hasAccessForPM) {
    return <p>No Access for Project Management</p>;
  }

  return (
    <div className="calendar-ui">
      <Container
        fluid="true"
        className="p-3"
        style={styles.container}
        block="true"
      >
        {msg && msgFor === "!modal" && (
          <Col>
            <Alert variant={msgType}>{msg}</Alert>
          </Col>
        )}

        <Row>
          <Col lg="3" md="4" sm="12">
            <Col>
              <div className="_tab">
                {!hasAccessForSaleCalender ? (
                  <button
                    className={`_tabItem ${active === links[1]}`}
                    style={{ color: "gray", pointerEvents: "none" }}
                  >
                    Roofing &nbsp; &nbsp; &nbsp;
                    <Link
                      to={{
                        pathname: "/dashboard/help",
                        search: "Calendar Roofing",
                      }}
                      target="_blank"
                      rel="noreferrer"
                      style={{ cursor: "pointer" }}
                    ></Link>
                  </button>
                ) : (
                  <button
                    style={styles.tabItem}
                    onClick={() => {
                      changeTab(links[0]),
                        changeMainTab(link[0]),
                        setProjectId("");
                    }}
                    className={`_tabItem ${active === links[0] && "active"}`}
                  >
                    Roofing &nbsp; &nbsp; &nbsp;
                    <Link
                      to={{
                        pathname: "/dashboard/help",
                        search: "Calendar Roofing",
                      }}
                      target="_blank"
                      rel="noreferrer"
                      style={{ cursor: "pointer" }}
                    ></Link>
                  </button>
                )}
                {!hasAccessForRepairsCalender ? (
                  <button
                    className={`_tabItem ${active === links[1]}`}
                    style={{ color: "gray", pointerEvents: "none" }}
                  >
                    Gutters &nbsp; &nbsp; &nbsp;
                    <Link
                      to={{
                        pathname: "/dashboard/help",
                        search: "Calendar Gutters",
                      }}
                      target="_blank"
                      rel="noreferrer"
                      style={{ cursor: "pointer" }}
                    ></Link>
                  </button>
                ) : (
                  <button
                    style={styles.tabItem}
                    onClick={() => {
                      changeTab(links[1]),
                        changeMainTab(link[0]),
                        setProjectId("");
                    }}
                    className={`_tabItem ${active === links[1] && "active"}`}
                  >
                    Gutters &nbsp; &nbsp; &nbsp;
                    <Link
                      to={{
                        pathname: "/dashboard/help",
                        search: "Calendar Gutters",
                      }}
                      target="_blank"
                      rel="noreferrer"
                      style={{ cursor: "pointer" }}
                    ></Link>
                  </button>
                )}
              </div>
              <SearchField
                type="search"
                placeholder={`Search ${active} Contracts`}
                value={searchTerm}
                onChange={({ target: { value } }) => setSearchTerm(value)}
                paymentStatus={paymentStatus}
              />
            </Col>
            {active === "Roofing" ? (
              <Col className="project_side_tab">
                <CalendarEventsList
                  loading={isLoading}
                  error={errors}
                  onDragStart={onDragStart}
                  handleDrop={handleDrop}
                  requests={"roof"}
                  searchResult={searchResult}
                  searchTerm={searchTerm}
                  onReload={loadData}
                  onClick={onClick}
                  setLeadReqId={setLeadReqId}
                  unscheduledProject={unscheduledProject}
                  projectId={projectId}
                  setProjectId={setProjectId}
                  setRequestId={setRequestId}
                  setIsCheck={setIsCheck}
                  setType={setType}
                  setProposalId={setProposalId}
                  selectPerson={selectPerson}
                  paymentStatus={paymentStatus}
                />
              </Col>
            ) : (
              <Col className="project_side_tab">
                <a
                  style={
                    repairPointerEvent
                      ? {}
                      : {
                          pointerEvents: "none",
                          padding: "0 !important",
                          margin: "0 !important",
                        }
                  }
                >
                  <CalendarEventsList
                    loading={isLoading}
                    error={errors}
                    onDragStart={onDragStart}
                    handleDrop={handleDrop}
                    requests={"gutter"}
                    searchResult={searchResult}
                    searchTerm={searchTerm}
                    onReload={loadData}
                    setLeadReqId={setLeadReqId}
                    unscheduledProject={unscheduledProject}
                    onClick={onClick}
                    projectId={projectId}
                    setProjectId={setProjectId}
                    setRequestId={setRequestId}
                    setIsCheck={setIsCheck}
                    setType={setType}
                    setProposalId={setProposalId}
                    selectPerson={selectPerson}
                  />
                </a>
              </Col>
            )}
          </Col>

          <Col lg="9" md="8" sm="12">
            <Row>
              <div className="_tab" style={{ overflowX: "auto" }}>
                <button
                  style={{
                    padding: "1rem",
                    backgroundColor: `${projectId ? "white" : "#f1f1f1"}`,
                  }}
                  onClick={() => changeMainTab(link[0])}
                  className={`_tabItem ${
                    activeTab === link[0] && "active"
                  } && text-nowrap `}
                  disabled={!projectId}
                >
                  Project Calendar &nbsp; &nbsp; &nbsp;
                  <Link
                    to={{
                      pathname: "/dashboard/help",
                      search: "PM Calendar",
                    }}
                    target="_blank"
                    rel="noreferrer"
                    style={{ cursor: "pointer" }}
                  ></Link>
                </button>

                <button
                  style={{
                    padding: "1rem",
                    backgroundColor: `${projectId ? "white" : "#f1f1f1"}`,
                  }}
                  onClick={() => changeMainTab(link[1])}
                  className={`_tabItem ${
                    activeTab === link[1] && "active"
                  } && text-nowrap `}
                  disabled={!projectId}
                >
                  Contact Info &nbsp; &nbsp; &nbsp;
                  <Link
                    to={{
                      pathname: "/dashboard/help",
                      search: "PM Contact",
                    }}
                    target="_blank"
                    rel="noreferrer"
                    style={{ cursor: "pointer" }}
                    disabled={!projectId}
                  ></Link>
                </button>

                <button
                  style={{
                    padding: "1rem",
                    backgroundColor: `${projectId ? "white" : "#f1f1f1"}`,
                  }}
                  onClick={() => changeMainTab(link[3])}
                  className={`_tabItem ${
                    activeTab === link[3] && "active"
                  } && text-nowrap `}
                  disabled={!projectId}
                >
                  Quotes & Proposals &nbsp; &nbsp; &nbsp;
                  <Link
                    to={{
                      pathname: "/dashboard/help",
                      search: "Calendar Sales",
                    }}
                    target="_blank"
                    rel="noreferrer"
                    style={{ cursor: "pointer" }}
                  ></Link>
                </button>
                <button
                  style={{
                    padding: "1rem",
                    backgroundColor: `${projectId ? "white" : "#f1f1f1"}`,
                  }}
                  onClick={() => changeMainTab(link[2])}
                  className={`_tabItem ${
                    activeTab === link[2] && "active"
                  } && text-nowrap `}
                  disabled={!projectId}
                >
                  Metrics &nbsp; &nbsp; &nbsp;
                  <Link
                    to={{
                      pathname: "/dashboard/help",
                      search: "Calendar Sales",
                    }}
                    target="_blank"
                    rel="noreferrer"
                    style={{ cursor: "pointer" }}
                  ></Link>
                </button>
                <button
                  style={{
                    padding: "1rem",
                    backgroundColor: `${projectId ? "white" : "#f1f1f1"}`,
                  }}
                  onClick={() => changeMainTab(link[4])}
                  className={`_tabItem ${
                    activeTab === link[4] && "active"
                  } && text-nowrap `}
                  disabled={!projectId}
                >
                  Images &nbsp; &nbsp; &nbsp;
                  <Link
                    to={{
                      pathname: "/dashboard/help",
                      search: "Calendar Sales",
                    }}
                    target="_blank"
                    rel="noreferrer"
                    style={{ cursor: "pointer" }}
                  ></Link>
                </button>
                <button
                  style={{
                    padding: "1rem",
                    backgroundColor: `${projectId ? "white" : "#f1f1f1"}`,
                  }}
                  onClick={() => changeMainTab(link[5])}
                  className={`_tabItem ${
                    activeTab === link[5] && "active"
                  } && text-nowrap `}
                  disabled={!projectId}
                >
                  PM Details &nbsp; &nbsp; &nbsp;
                  <Link
                    to={{
                      pathname: "/dashboard/help",
                      search: "Calendar Sales",
                    }}
                    target="_blank"
                    rel="noreferrer"
                    style={{ cursor: "pointer" }}
                  ></Link>
                </button>
                <button
                  style={{
                    padding: "1rem",
                    backgroundColor: `${projectId ? "white" : "#f1f1f1"}`,
                  }}
                  onClick={() => changeMainTab(link[6])}
                  className={`_tabItem ${
                    activeTab === link[6] && "active"
                  } && text-nowrap `}
                  disabled={!projectId}
                >
                  Notes &nbsp; &nbsp; &nbsp;
                  <Link
                    to={{
                      pathname: "/dashboard/help",
                      search: "Calendar Sales",
                    }}
                    target="_blank"
                    rel="noreferrer"
                    style={{ cursor: "pointer" }}
                  ></Link>
                </button>
              </div>
            </Row>

            {activeTab === "calendar" && (
              <Row style={{ alignItems: "center", marginTop: "10px" }}>
                <Col lg="8">
                  <div className="btns-group">
                    <button className="today-btn" onClick={setToday} style={{}}>
                      Today
                    </button>
                    <div>
                      <button
                        className={`today-btn inverted${
                          view === "month" ? " selected" : ""
                        }`}
                        onClick={() => changeView("month")}
                      >
                        Month
                      </button>
                      <button
                        className={`today-btn inverted${
                          view === "week" ? " selected" : ""
                        }`}
                        onClick={() => changeView("week")}
                      >
                        Week
                      </button>
                      <button
                        className={`today-btn inverted${
                          view === "day" ? " selected" : ""
                        }`}
                        onClick={() => changeView("day")}
                      >
                        Day
                      </button>
                    </div>
                  </div>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>View Calendar By Crew</Form.Label>
                    {active === "Roofing" ? (
                      <Select
                        placeholder="All"
                        isMulti
                        name="selectPerson"
                        value={selectPerson}
                        onChange={(value) => setSelectPerson(value)}
                        options={displayPersonsPM(
                          supplier.labor_supplier,
                          active
                        )}
                        isDisabled={
                          user &&
                          user.userData &&
                          user.userData.paymentStatus &&
                          user.userData.paymentStatus === "Frozen 1"
                        }
                      />
                    ) : (
                      <Select
                        placeholder="All"
                        isMulti
                        name="selectPerson"
                        value={selectPerson}
                        onChange={(value) => setSelectPerson(value)}
                        options={displayPersonsPM(
                          supplier.labor_supplier,
                          active
                        )}
                      />
                    )}
                  </Form.Group>
                </Col>
              </Row>
            )}

            <Col style={{ marginTop: "3%" }}>
              {activeTab === "calendar" ? (
                <Calendar
                  events={calendar_schedule_project}
                  dropFromOutside={handleDrop}
                  dayviewstart="6:00"
                  dayviewend="20:59"
                  eventClicked={(event) => eventPressed(event)}
                  view={view}
                  dateObject={dateObject}
                  setToday={setToday}
                  onNext={onNext}
                  onPrev={onPrev}
                  onNextDay={onNextDay}
                  onPrevDay={onPrevDay}
                  onNextWeek={onNextWeek}
                  onPrevWeek={onPrevWeek}
                  active={active}
                  scheduledProject={scheduledProject}
                  pmData={pmData}
                  selectPerson={selectPerson}
                />
              ) : activeTab === "contact-info" ? (
                <div>
                  <ContactInfo
                    request={request}
                    projectId={projectId}
                    requestId={requestId}
                  />
                </div>
              ) : activeTab === "Metrics" ? (
                <div>
                  <Metrics
                    leadReqId={requestId}
                    type={request?.type}
                    onReload={loadData}
                  />
                </div>
              ) : activeTab === "PM Details" ? (
                <div>
                  <PMdetails
                    projectId={projectId}
                    scheduledProject={scheduledProject}
                    leadReqId={leadReqId}
                    supplier={supplier}
                    unscheduledProject={unscheduledProject}
                    proposalId={proposalId}
                    onReload={loadData}
                    type={type}
                  />
                </div>
              ) : activeTab === "Quotes & Proposal" ? (
                <div>
                  <QuotesProposals leadReqId={leadReqId} type={null} />
                </div>
              ) : activeTab === "Images" ? (
                <PMImages
                  leadReqId={requestId}
                  type={request?.type}
                  images={true}
                />
              ) : activeTab === "Notes" ? (
                <Notes proposalId={proposalId} onReload={loadData} />
              ) : (
                ""
              )}
            </Col>
          </Col>
        </Row>
      </Container>
      {/* Add Modal */}
      {addModal && (
        <AddModal
          loading={isLoading}
          error={errors}
          addModal={addModal}
          edit={edit}
          scheduledProject={scheduledProject}
          date={date}
          address={address}
          type={type}
          requestFor={requestFor}
          msg={msg}
          msgFor={msgFor}
          start={start}
          isAllDay={isAllDay}
          sRep={sRep}
          isunScheduling={isunScheduling}
          end={end}
          isSubmitting={isSubmitting}
          isLoadingAvPersons={isLoadingAvPersons}
          personsData={personsData}
          displayBtns={displayBtns}
          permission={permission}
          active={active}
          salesPointerEvent={salesPointerEvent}
          timing={timing}
          setIsAllDay={setIsAllDay}
          setEnd={setEnd}
          setStart={setStart}
          displayPersons={displayPersons}
          setDisplayBtns={setDisplayBtns}
          setSRep={setSRep}
          request={request}
          clearAllFields={clearAllFields}
          setAddModal={setAddModal}
          setEdit={setEdit}
          repairPointerEvent={repairPointerEvent}
          supplier_data={supplier}
          useScheduleProject={useScheduleProject}
          onReload={loadData}
          additionalProjectmanagementTime={additionalProjectmanagementTime}
        />
      )}
    </div>
  );
};

export default ProjectManagement;
