import { useMutation, useQuery } from "@tanstack/react-query";
import {
    changeCartingSupplier, fetchCartingTypes,
    getCartingActiveSuppliers, updateCartingQuoteLine,
    addCartingToQuote, deleteCartingQuoteLine
} from "../api/cartingApi";

export const useCartingSuppliers = () => {
    const { data, isLoading, isError, error } = useQuery(
        ["cartingSuppliers"], // Query key for caching
        getCartingActiveSuppliers,
        {
            // Ensures the query runs once and doesn't repeat unless needed
            staleTime: 1000 * 60 * 5,  // 5 minutes for caching data
            cacheTime: 1000 * 60 * 30, // Cache for 30 minutes before garbage collection
        }
    );

    return {
        suppliers: data?.result || [],  // Return suppliers from the data or empty array if undefined
        isLoading,
        isError,
        error,
    };
};


export const useCartingTypes = (supplierId) => {
    const { data, isLoading, isError, error } = useQuery(
        ['cartingTypes', supplierId], // Unique query key
        () => fetchCartingTypes(supplierId), // Query function
        {
            enabled: !!supplierId, // Only fetch if supplierId is available
            retry: 3, // Retry failed requests up to 3 times
            staleTime: 60000, // Data is considered fresh for 1 minute
            cacheTime: 300000, // Cache data for 5 minutes
            onError: (error) => {
                console.error("Error fetching carting types:", error.message);
            },
        }
    );

    return {
        cartingTypes: data?.result || [],  // Return suppliers from the data or empty array if undefined
        isLoading,
        isError,
        error,
    };
};


export const useChangeCartingSupplier = () => {
    return useMutation(changeCartingSupplier);
};


// Update a proposal tax info
export const useUpdateCartingQuoteline = () => {
    return useMutation(updateCartingQuoteLine);
};




// Hook for adding Misc Material to Quote
export const useAddAdditionalCartingQuoteLine = () => {
    return useMutation(addCartingToQuote);
};


// Hook for adding Misc Material to Quote
export const useDeleteAdditionalCartingQuoteLine = () => {
    return useMutation(deleteCartingQuoteLine);
};
