import React, { useEffect, useState } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { PriceInput } from "components";
import QTYInput from "../../QTYInput";
import styles from "lib/config/baseStyles";
import { debounce } from "lodash";
import validator from "validator";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteCarting,
  updateCarting,
  updateCurrentProposal,
} from "features/QuotesProposals/redux/proposals/proposals.actions";
import {
  hasFieldChanged,
  hasNestedFieldChanged,
} from "features/QuotesProposals/utils";
import { useDeleteAdditionalCartingQuoteLine } from "features/QuotesProposals/hooks/useCarting";
import Spinner from "components/common/spinner/spinner";
import { toast } from "react-toastify";

export default function CartingListData({
  listData,
  data,
  editItem,
  isLockQuoteData,
  sectionData,
  cartingTypes,
  quoteId,
  proposalId,
  supplierId,
  proposalStatus,
  updateCartingQuote,
  selectedProposal,
}) {
  const [selectedCategory, setSelectedCategory] = useState(data.pricingId);
  const dispatch = useDispatch();

  const handleEdit = (newData) => {
    // editItem(newData);
  };

  const {
    isLoading: isLoadingDeleteAdditionalQuote,
    isError: deleteAdditionalQuoteError,
    mutate: deleteAdditionalQuoteMutation,
  } = useDeleteAdditionalCartingQuoteLine();

  const handleChangeQty = (qty) => {
    const body = {
      supplierId,
      proposalId,
      dumpsterQuoteId: quoteId,
      dumpsterId: data._id,
      qty: Number(qty),
    };

    updateCartingQuote(body);
  };

  const onDelete = (carting) => {
    const body = {
      supplierId,
      proposalId,
      dumpsterQuoteId: quoteId,
      dumpsterId: carting._id,
    };

    deleteAdditionalQuoteMutation(body, {
      onSuccess: (data) => {
        // Assuming `data.result` contains the updated proposals
        const updatedProposal = data?.result?.find(
          (proposal) => proposal._id === selectedProposal?._id // Adjust key based on response
        );
        console.log("updatedProposal123", updatedProposal);
        if (updatedProposal) {
          // Dispatch an action to update the selectedProposal in Redux
          dispatch(updateCurrentProposal(updatedProposal));
        }

        console.log("Quote item deleted successfully.");
        toast.success("Quote item deleted successfully!");
      },

      onError: (error) => {
        console.error("Error adding Quote item:", error.message);
        handleGlobalError(error, dispatch);
      },
    });
  };

  const onChangeCartingTypes = (e) => {
    const cartingId = e.target.value;

    const body = {
      supplierId,
      proposalId,
      dumpsterQuoteId: quoteId,
      newCartingTypeId: cartingId,
      dumpsterId: data._id,
    };

    console.log({
      cartingId,
      dataId: data._id,
    });

    updateCartingQuote(body);
  };

  return (
    <DebrisDataDisplay
      onDelete={onDelete}
      pricingType={data.pricingType}
      size={data.size}
      listData={listData}
      selectedCategory={selectedCategory}
      onChangeCartingTypes={onChangeCartingTypes}
      onChangeQty={handleChangeQty}
      qty={data.qty}
      price={data.uomPrice}
      total={data.total}
      isLockQuoteData={isLockQuoteData}
      sectionData={sectionData}
      cartingTypes={cartingTypes}
      cartingId={data.cartingId}
      qtyAndPrice={data.qtyAndPrice}
      uomPrice={data.uomPrice}
      carting={data}
      proposalStatus={proposalStatus}
      isLoadingDeleteAdditionalQuote={isLoadingDeleteAdditionalQuote}
    />
  );
}

function DebrisDataDisplay({
  price,
  onChangeQty,
  selectedCategory,
  onChangeCartingTypes,
  listData,
  isLockQuoteData,
  total,
  sectionData,
  size,
  cartingTypes,
  cartingId,
  onDelete,
  qtyAndPrice,
  uomPrice,
  carting,
  proposalStatus,
  isLoadingDeleteAdditionalQuote,
}) {
  const deleteButtonStyle = {
    ...styles.reduceWidthCol,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
  };

  const allowEdit = proposalStatus !== "As-Collected";
  const [qty, setQuantity] = useState(0);

  const handleChangeQrty = (event) => {
    const value = event.target.value;
    console.log({
      value,
    });

    setQuantity(value);
    onChangeQty(value);
  };

  useEffect(() => {
    if (qtyAndPrice?.quantity) {
      setQuantity(qtyAndPrice?.quantity);
    }
  }, [qtyAndPrice?.quantity]);

  return (
    <Row>
      <Col style={deleteButtonStyle} lg="2">
        <Button
          style={{ width: "fit-content" }}
          variant="danger"
          onClick={() => onDelete(carting)}
          disabled={isLockQuoteData?.isLocked}
        >
          {isLoadingDeleteAdditionalQuote ? (
            <Spinner variant="light" label="Deleting" />
          ) : (
            "Delete"
          )}
        </Button>
      </Col>

      <Col style={styles.reduceWidthCol} lg="2">
        <Form.Group>
          <Form.Label style={{ marginLeft: "40%" }}>
            <strong>Size</strong>
          </Form.Label>
          <Form.Control
            as="select"
            value={cartingId}
            onChange={onChangeCartingTypes}
            disabled={!carting?.isMisc && !allowEdit}
            style={
              hasFieldChanged(carting, "cartingId")
                ? {
                    backgroundColor: "#f6d46f",
                  }
                : {}
            }
          >
            <option value="">Choose...</option>
            {cartingTypes?.length > 0 ? (
              cartingTypes.map((carting) => (
                <option key={carting._id} value={carting._id}>
                  {carting.size} {carting.pricingType}
                </option>
              ))
            ) : (
              <option value="">No data available</option>
            )}
          </Form.Control>
        </Form.Group>
      </Col>

      <Col style={styles.reduceWidthCol} lg="4"></Col>

      <Col style={deleteButtonStyle}>
        <QTYInput
          value={qty}
          onChange={handleChangeQrty}
          disabled={!carting?.isMisc && !allowEdit}
          hasChanged={
            !carting.isMisc &&
            hasNestedFieldChanged(carting, "qtyAndPrice", "quantity")
          }
        />
      </Col>

      <Col style={deleteButtonStyle}>
        <PriceInput disabled price={uomPrice} />
      </Col>

      <Col style={deleteButtonStyle}>
        <PriceInput disabled price={qtyAndPrice?.totalPrice} input="Total" />
      </Col>
    </Row>
  );
}
