import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
// import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import useSubscription from "hooks/useSubscription";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { ArchiveBook } from "iconsax-react";
import "./UpcomingPaymentAccordion.css";
import { toast } from "react-toastify";
import { axiosInstance as axios } from "services";
import { ApiEndpoints } from "lib/config/baseSettings";

const PaymentHistory = (props) => {
  const { historyData } = props;

  const [product, setProduct] = useState([]);
  const [upcomingInvoice, setUpcomingInvoice] = useState();
  const [companyId, setCompanyId] = useState();
  const [line, setLine] = useState();
  const [invoiceId, setInvoiceId] = useState();
  const [periodStart, setPeriodStart] = useState();
  const [periodEnd, setPeriodEnd] = useState();
  const [invoiceUrl, setInvoiceUrl] = useState();
  const [invoicePdf, setInvoicePdf] = useState();
  const [paymentShow, setPaymentShow] = useState(false);
  const [paymentAmt, setPaymentAmt] = useState();
  const [totalLineAmount, setTotalLineAmount] = useState();
  const [totalLineAmountDate, setTotalLineAmountDate] = useState();
  const [totalUser, setTotalUser] = useState();
  const [paymentType, setPaymentType] = useState();
  // const month = [
  //   "January",
  //   "February",
  //   "March",
  //   "April",
  //   "May",
  //   "June",
  //   "July",
  //   "August",
  //   "September",
  //   "October",
  //   "November",
  //   "December",
  // ];

  const handleStatusClose = () => {
    setPaymentShow(false);
  };

  useEffect(() => {
    if (historyData && historyData.length && historyData[0].history.length) {
      let product = historyData[0].history.map((val) => {
        let {
          referenceId,
          paymentStatus,
          paymentPlanUser,
          paymentType,
          dateOfPayment,
          paymentAmount,
          lines,
          invoice_id,
          period_start,
          period_end,
          invoice_url,
          invoice_pdf,
        } = val;

        return {
          companyId: historyData[0]?.companyId,
          // id: referenceId,
          companyName: historyData[0]?.company?.companyName,
          // paymentStatus: paymentStatus,
          paymentType: paymentType,
          paymentPlanUser: paymentPlanUser,
          paymentAmount: `$ ${paymentAmount}`,
          DateOfPayment: dateOfPayment,
          paymentStatus: paymentStatus,
          lines,
          invoice_id,
          period_start,
          period_end,
          invoice_url,
          invoice_pdf,
        };
      });

      console.log({ historyData, product });

      setProduct(product);
      setCompanyId(historyData[0]?.companyId);
    }
  }, [historyData]);

  useEffect(() => {
    let total = 0;
    upcomingInvoice &&
      setTotalLineAmountDate(
        new Date(upcomingInvoice.period_start * 1000).toDateString()
      );
    upcomingInvoice &&
      upcomingInvoice.lines.data.map((val, index) => {
        if (index !== upcomingInvoice.lines.data.length - 1) {
          total = total + Number(val.amount);
        } else {
          setTotalUser((val.amount / 100 - 40) / 30);
        }
      });
    setTotalLineAmount(total);
  }, [historyData, upcomingInvoice]);

  const buttonFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <button
        className="btn btn-primary"
        onClick={() => formatExtraData.onClick(row)}
      >
        {formatExtraData.buttonText}
      </button>
    );
  };

  const handleArchiveIconClick = (invoiceId) => {
    // Navigate to the invoice page using your routing logic
    // For example, using React Router:
    // history.push(`/invoice/${invoiceId}`);
    // return <a href={invoiceId} target='_blank'></a>
    invoiceId
      ? window.open(invoiceId, "_blank")
      : toast.error("No Invoice Available");
  };

  const ArchiveIconColumn = ({ onClick }) => (
    <div className="archive-button" onClick={onClick}>
      <ArchiveBook size="32" color="#0d6efd" />{" "}
    </div>
  );

  const columns = [
    {
      dataField: "index", // Special data field for serial number
      text: "Serial Number", // Header for the serial number column
      formatter: (cell, row, rowIndex) => rowIndex + 1, // Display the serial number
      headerStyle: { width: "5%" }, // Set the width for the header cell
      style: { width: "5%" }, // Set the width for the data cells
    },
    {
      dataField: "paymentStatus",
      text: "Payment Status",
    },
    {
      dataField: "paymentType",
      text: "Payment Type",
    },
    {
      dataField: "paymentPlanUser",
      text: "Payment Plan (number of users)",
      headerStyle: { width: "10%" }, // Set the width for the header cell
      style: { width: "10%" }, // Set the width for the data cells
    },
    {
      dataField: "paymentAmount",
      text: "Payment Amount",
    },
    {
      dataField: "DateOfPayment",
      text: "Date of Payment",
    },
    {
      dataField: "id", // Replace with your data field
      text: "Actions", // Column header
      formatter: buttonFormatter, // Custom button formatter
      formatExtraData: {
        onClick: (row) => {
          // Handle button click for the specific row
          setPeriodStart(
            row.paymentType === "Sign-up"
              ? new Date(row.DateOfPayment).getTime() / 1000
              : row?.lines &&
                  row.lines.length &&
                  row.lines[0].data[0].period.start
          );
          setPeriodEnd(
            row?.lines && row.lines.length && row.lines[0].data[0].period.end
          );
          setPaymentType(row.paymentType);
          setPaymentShow(true);
          setLine(row?.lines && row.lines);
          setInvoiceUrl(row?.invoice_url && row.invoice_url);
          setPaymentAmt(row.paymentAmount.trim());
        },
        buttonText: "View Payment", // Text for the button
      },
    },
    {
      dataField: "invoice_url",
      text: "Invoice",
      formatter: (cell, row) => {
        return (
          <ArchiveIconColumn
            onClick={() => handleArchiveIconClick(row.invoice_url)} // Replace with your onClick logic
          />
        );
      },
    },
  ];

  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 5,
    lastPageText: ">>",
    firstPageText: "<<",
    nextPageText: ">",
    prePageText: "<",
    showTotal: true,
    alwaysShowAllBtns: true,
    onPageChange: function (page, sizePerPage) {},
    onSizePerPageChange: function (page, sizePerPage) {},
  });

  let today = new Date();
  const nextMonth = today.getMonth() + 1;
  const nextMonth2 = today.getMonth() + 2;
  const year = today.getFullYear() + (nextMonth === 12 ? 1 : 0);
  const year2 = today.getFullYear() + (nextMonth2 === 12 ? 1 : 0);
  const firstDayOfNextMonth = new Date(
    upcomingInvoice &&
      upcomingInvoice.lines &&
      upcomingInvoice.lines.data &&
      upcomingInvoice.lines.data.length &&
      upcomingInvoice.lines.data[0].period.start * 1000
  ).toDateString();
  const firstDayOfNextMonth2 = new Date(
    upcomingInvoice &&
      upcomingInvoice.lines &&
      upcomingInvoice.lines.data &&
      upcomingInvoice.lines.data.length &&
      upcomingInvoice.lines.data[0].period.end * 1000
  ).toDateString();

  if (!product.length) {
    return (
      <div
        style={{
          height: "60vh",
          display: "flex",
          flexDirection: "column",
          //justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <UpcomingPayment historyData={historyData} />
        <img
          style={{ width: "10vw", marginBottom: "2rem" }}
          src="/no-subs.png"
          alt=""
        />
        <h5 style={{ alignSelf: "center" }}>
          Currently there is no payment history.
        </h5>
      </div>
    );
  }

  return (
    <>
      <Modal show={paymentShow} onHide={handleStatusClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {periodStart
              ? `${
                  line &&
                  line.length &&
                  line[0].data &&
                  line[0].data.length &&
                  paymentType === "Monthly"
                    ? "Monthly"
                    : line &&
                      line.length &&
                      line[0].data &&
                      line[0].data.length &&
                      paymentType === "Users"
                    ? "Additional Users"
                    : "Sign-Up"
                } ${
                  line &&
                  line.length &&
                  line[0].data &&
                  line[0].data.length &&
                  paymentType === "Monthly"
                    ? "Payment For"
                    : ""
                } ${
                  line &&
                  line.length &&
                  line[0].data &&
                  line[0].data.length &&
                  paymentType === "Monthly"
                    ? month[new Date(periodStart * 1000).getUTCMonth()]
                    : ""
                }`
              : ""}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <strong>
              <p>Date: </p>
            </strong>{" "}
            <p>
              {periodStart && line && line.length
                ? new Date(periodStart * 1000).toDateString()
                : periodStart && line && !line.length
                ? Date(periodStart).split(" ").slice(0, 4).join(" ")
                : ""}{" "}
              {periodEnd && paymentType === "Monthly" ? "-" : ""}{" "}
              {periodEnd && paymentType === "Monthly"
                ? new Date(periodEnd * 1000).toDateString()
                : ""}
            </p>
          </div>
          {/* <p style={{ wordWrap: "break-word" }}>
            <strong>invoice url: </strong>
            <a
              href={invoiceUrl && invoiceUrl}
              target="_blank"
              style={{ textDecoration: "underline" }}
            >
              {invoiceUrl && invoiceUrl}
            </a>
          </p> */}
          {line && line.length && line[0].data && line[0].data.length
            ? line[0].data.map((val, index) => {
                return (
                  // {val.description}{":"} <strong>{val.amount}</strong>
                  <>
                    {/* <ul className="list-unstyled">
                      <li
                        key={index}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span className="product-description">
                          <strong>
                            {val.description}
                            {" : "}
                          </strong>
                        </span>
                        <span className="product-amount">
                          ${(val.amount / 100).toFixed(2)}
                        </span>
                      </li>
                    </ul> */}
                    {/* <p className="total-amount-due">
                      <strong>Total Amount Due:</strong> $
                      {(val.amount / 100).toFixed(2)}
                    </p> */}
                  </>
                );
              })
            : ""}

          {
            <p
              className="total-amount-due"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <strong>Total: </strong>{" "}
              {line && line.length && line[0].data && line[0].data.length
                ? "$"
                : ""}
              {line && line.length && line[0].data && line[0].data.length
                ? line[0].data
                    .reduce(
                      (accumulator, currentValue) =>
                        accumulator + currentValue.amount / 100,
                      0
                    )
                    .toFixed(2)
                : paymentAmt
                ? paymentAmt
                : 0}
            </p>
          }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleStatusClose}>
            Close
          </Button>
          {/* <Button variant="primary" onClick={() => {}}>
            Update Status
          </Button> */}
        </Modal.Footer>
      </Modal>
      <div style={{ width: "100%" }}>
        <div
          className="custom-card"
          style={{
            marginTop: "1rem",
            marginBottom: "1rem",
            height: "fit-content",
            background: "#EEF0F2",
            borderRadius: "15px",
            boxShadow: "4px 4px 8px 4px #06031514",
            padding: "1rem",
            // overflow: "scroll"
          }}
        >
          <UpcomingPayment historyData={historyData} />
        </div>
        <h4 style={{ margin: "1rem" }}>Previous Payments:</h4>
        <div className="table-container">
          {product && product.length && (
            <BootstrapTable
              bootstrap4
              keyField="id"
              data={product}
              columns={columns}
              // rowEvents={{ onClick: (e, row) => handleRowClick(row) }}
              pagination={pagination}
              // bordered={true}
              // filter={filterFactory()}
            />
          )}
        </div>
      </div>
    </>
  );
};

const get_company_basePlan_by_companyId = (id) => {
  // setIsLoading(true);
  return axios
    .get("/admin/baseplanuser/getCompanyBasePlan")
    .then((res) => res.data)
    .then((data) => {
      if (data.success) {
        // setCompanyBasePlan(data.result)
        return data.result;
      }
    })
    .catch(
      (errors) => {
        return null;
      }
      // setIsError(errors)
    )
    .finally(() => {
      // setIsLoading(false)
    });
};

const month = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

function UpcomingPayment({ historyData }) {
  const [upcomingInvoice, setUpcomingInvoice] = useState("");
  const [companyBasePlan, setCompanyBasePlan] = useState("");
  const { get_upcoming_invoice } = useSubscription(historyData[0]?.companyId);

  const stripeCustomerId = historyData[0]?.stripeCustomerId;
  const companyId = historyData[0]?.companyId;

  useEffect(() => {
    get_upcoming_invoice(stripeCustomerId)
      .then((res) => {
        setUpcomingInvoice(res);
      })
      .catch((err) => {
        setUpcomingInvoice(null);
      });
  }, [stripeCustomerId]);

  useEffect(() => {
    get_company_basePlan_by_companyId(companyId)
      .then((res) => {
        console.log("Company Base Plan", res);
        setCompanyBasePlan(res);
      })
      .catch((err) => {
        setCompanyBasePlan(null);
      });
  }, [companyId]);

  return (
    <div
      style={{
        margin: "1rem",
        marginBottom: "5rem",
        width: "calc(100% - 2rem)",
        height: "fit-content",
        padding: "1rem",
      }}
    >
      <div>
        <h4 className="card-title" style={{ marginBottom: "1rem" }}>
          {upcomingInvoice
            ? `Upcoming Payment For ${
                month[new Date(upcomingInvoice.created * 1000).getMonth()]
              }`
            : "No Upcoming Invoice"}
        </h4>
      </div>
      <div className="card-body">
        {upcomingInvoice ? (
          <>
            <p
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <strong>Period:</strong>{" "}
              {`${new Date(
                upcomingInvoice.lines.data[0].period.start * 1000
              ).toDateString()} - ${new Date(
                upcomingInvoice.lines.data[0].period.end * 1000
              ).toDateString()}`}
            </p>
            {upcomingInvoice.lines?.data && (
              <>
                <ul className="list-unstyled">
                  <li
                    className="product-item"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span className="product-description">
                      <strong>
                        {`Base Plan`}
                        {" : "}
                      </strong>
                    </span>
                    <span className="product-amount">
                      ${companyBasePlan && companyBasePlan.baseplanPrice}
                    </span>
                  </li>

                  <li
                    className="product-item"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span className="product-description">
                      <strong>
                        {`Additional Users`}
                        {" : "}
                      </strong>
                    </span>
                    <span className="product-amount">
                      {companyBasePlan && companyBasePlan.additionalUser}
                    </span>
                  </li>

                  <li
                    className="product-item"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span className="product-description">
                      <strong>
                        {`Additional Per User`}
                        {" : "}
                      </strong>
                    </span>
                    <span className="product-amount">
                      $
                      {companyBasePlan &&
                        companyBasePlan.additionalUser *
                          companyBasePlan.pricePerAdditionalUser}
                    </span>
                  </li>

                  <li
                    className="product-item"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span className="product-description">
                      <strong>
                        {`Tax`}
                        {" : "}
                      </strong>
                    </span>
                    <span className="product-amount">
                      $
                      {`${
                        companyBasePlan &&
                        companyBasePlan.baseplanPrice &&
                        upcomingInvoice.amount_due &&
                        (
                          (upcomingInvoice.amount_due -
                            (companyBasePlan.baseplanPrice * 100 +
                              companyBasePlan.additionalUser *
                                companyBasePlan.pricePerAdditionalUser *
                                100)) /
                          100
                        ).toFixed(2)
                      }`}
                    </span>
                  </li>
                </ul>
                <p
                  className="total-amount-due"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <strong>Total Amount Due:</strong>{" "}
                  <span className="total-amount">
                    ${(upcomingInvoice.amount_due / 100).toFixed(2)}
                  </span>
                </p>
              </>
            )}
          </>
        ) : (
          <p>No upcoming invoice available.</p>
        )}
      </div>
    </div>
  );
}

export default PaymentHistory;
