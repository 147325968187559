import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import {
  deleteOneProposalApi,
  generateProposalApi,
  getProposalsApi,
  reGenerateProposalApi,
  updateProposalApi,
  updateProposalTaxInfo,
  updateProposalTitleApi,
  updateQuoteLine,
} from "../api/proposalsApi";

// Fetch proposals
export const useGetProposals = (requestId) => {
  const { data, isLoading, isError, error } = useQuery(
    ["proposals", requestId],
    () => getProposalsApi({ requestId }),
    {
      enabled: !!requestId, // Fetch only if requestId is provided
    }
  );

  return {
    proposals: data?.result || [], // Return suppliers from the data or empty array if undefined
    isLoading,
    isError,
    error,
  };
};

// Update a proposal
export const useUpdateProposal = () => {
  const queryClient = useQueryClient();

  return useMutation(updateProposalApi, {
    onSuccess: (updatedProposal) => {
      queryClient.setQueryData(["proposals"], (oldProposals) =>
        oldProposals.map((proposal) =>
          proposal._id === updatedProposal._id ? updatedProposal : proposal
        )
      );
    },
    onError: (error) => {
      console.error("Failed to update proposal:", error);
    },
  });
};

export const useDeleteOneQuoteProposal = () => {
  return useMutation(deleteOneProposalApi);
};

// Update a proposal title of proposal
export const useUpdateProposalTitle = () => {
  return useMutation(updateProposalTitleApi);
};

// Update a proposal title of proposal
export const useGenerateProposalQuote = () => {
  return useMutation(generateProposalApi);
};

// Update a proposal title of proposal
export const userRegenerateProposalQuote = () => {
  return useMutation(reGenerateProposalApi);
};
